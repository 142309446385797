import { ErrorMessage } from "formik";
import React from "react";
import { PhoneInput } from "react-international-phone";
import ATMButton from "../../../../../atoms/ATMButton/ATMButton";
import ATMFieldError from "../../../../../atoms/ATMFieldError/ATMFieldError";
import ATMFieldLabel from "../../../../../atoms/ATMFieldLabel/ATMFieldLabel";
import ATMTextField from "../../../../../atoms/ATMTextField/ATMTextField";

const BasicDetailsForm = ({ formikProps, formType }) => {
  const { values, setFieldValue, handleBlur, isSubmitting } = formikProps;

  return (
    <>
      <div>
        <div className="border rounded-md p-5 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4 items-center">
          <div>
            <ATMTextField
              type="text"
              name="firstName"
              value={values.firstName || ""}
              label="First Name"
              placeholder="Enter your First Name"
              onChange={(e) => setFieldValue("firstName", e.target.value)}
              onBlur={handleBlur}
              required
            />
          </div>
          <div>
            <ATMTextField
              type="text"
              name="lastName"
              value={values.lastName || ""}
              label="Last Name"
              placeholder="Enter your Last Name"
              onChange={(e) => setFieldValue("lastName", e.target.value)}
              onBlur={handleBlur}
              required
            />
          </div>
          {/* email  */}
          <div className="">
            <ATMFieldLabel required> Email ID</ATMFieldLabel>
            <div className="relative">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={1.5}
                stroke="currentColor"
                className="absolute top-2 transform  left-3 text-gray-400 pointer-events-none"
                style={{ height: 24, width: 24 }}
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M21.75 6.75v10.5a2.25 2.25 0 0 1-2.25 2.25h-15a2.25 2.25 0 0 1-2.25-2.25V6.75m19.5 0A2.25 2.25 0 0 0 19.5 4.5h-15a2.25 2.25 0 0 0-2.25 2.25m19.5 0v.243a2.25 2.25 0 0 1-1.07 1.916l-7.5 4.615a2.25 2.25 0 0 1-2.36 0L3.32 8.91a2.25 2.25 0 0 1-1.07-1.916V6.75"
                />
              </svg>
              <input
                size="lg"
                placeholder="olivia@untitledui.com"
                className="w-full px-3 h-[40px] border pl-10 items-center border-gray-300 min-w-[200px] rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-indigo-500"
                disabled
                value={values.email}
                onChange={(e) => setFieldValue("email", e.target.value)}
                id="email"
                name="email"
              />
              <ErrorMessage name="email">
                {(errorMessage) => (
                  <ATMFieldError> {errorMessage} </ATMFieldError>
                )}
              </ErrorMessage>
            </div>
          </div>

          {/* contact number  */}

          {/* {profileData?.recruiter?.company?.[0]?.company_identification_numb && ( */}
          <div className="">
            <ATMFieldLabel required>Contact Number</ATMFieldLabel>
            <div className=" min-w-[200px]">
              <PhoneInput
                defaultCountry="us"
                value={values.contactNumber}
                onChange={(value) => setFieldValue("contactNumber", value)}
                onBlur={handleBlur}
                id="contactNumber"
                name="contactNumber"
                className="border w-full rounded-md px-2"
                style={{
                  "--react-international-phone-height": "39px",
                  "--react-international-phone-border-color": "transparent",
                  "--react-international-phone-border-radius": "4px",
                }}
              />
              <ErrorMessage name="contactNumber">
                {(errorMessage) => (
                  <ATMFieldError> {errorMessage} </ATMFieldError>
                )}
              </ErrorMessage>
            </div>
          </div>
        </div>
        <div className="mt-8 w-[100px]">
          <ATMButton label="Save" loading={isSubmitting} />
        </div>
      </div>
    </>
  );
};

export default BasicDetailsForm;
