// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* Custom scrollbar for X-axis and Y-axis */
::-webkit-scrollbar {
  width: 4px; /* Vertical scrollbar width */
  height: 4px; /* Horizontal scrollbar height */
}

/* Scrollbar track (background) */
::-webkit-scrollbar-track {
  background: #1cadbd2f; /* Light gray background */
}

/* Scrollbar thumb (the draggable part) */
::-webkit-scrollbar-thumb {
  background: #1cacbd; /* Gray color for scrollbar */
  border-radius: 5px; /* Rounded corners */
}

/* Scrollbar thumb on hover */
::-webkit-scrollbar-thumb:hover {
  background: #1cacbd; /* Darker gray on hover */
  cursor: pointer;
}
`, "",{"version":3,"sources":["webpack://./src/ScrollBarStyles.css"],"names":[],"mappings":"AAAA,2CAA2C;AAC3C;EACE,UAAU,EAAE,6BAA6B;EACzC,WAAW,EAAE,gCAAgC;AAC/C;;AAEA,iCAAiC;AACjC;EACE,qBAAqB,EAAE,0BAA0B;AACnD;;AAEA,yCAAyC;AACzC;EACE,mBAAmB,EAAE,6BAA6B;EAClD,kBAAkB,EAAE,oBAAoB;AAC1C;;AAEA,6BAA6B;AAC7B;EACE,mBAAmB,EAAE,yBAAyB;EAC9C,eAAe;AACjB","sourcesContent":["/* Custom scrollbar for X-axis and Y-axis */\n::-webkit-scrollbar {\n  width: 4px; /* Vertical scrollbar width */\n  height: 4px; /* Horizontal scrollbar height */\n}\n\n/* Scrollbar track (background) */\n::-webkit-scrollbar-track {\n  background: #1cadbd2f; /* Light gray background */\n}\n\n/* Scrollbar thumb (the draggable part) */\n::-webkit-scrollbar-thumb {\n  background: #1cacbd; /* Gray color for scrollbar */\n  border-radius: 5px; /* Rounded corners */\n}\n\n/* Scrollbar thumb on hover */\n::-webkit-scrollbar-thumb:hover {\n  background: #1cacbd; /* Darker gray on hover */\n  cursor: pointer;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
