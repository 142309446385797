import { ErrorMessage } from "formik";
import React, { useState } from "react";
import { IoMdEye, IoMdEyeOff } from "react-icons/io";
import ATMFieldError from "../ATMFieldError/ATMFieldError";
import ATMFieldLabel from "../ATMFieldLabel/ATMFieldLabel";

const ATMPassword = ({ name, label, onChange, value, onBlur }) => {
  const [show, setShow] = useState(false);
  return (
    <div>
      <div className="">
        <ATMFieldLabel htmlFor={name}>{label}</ATMFieldLabel>
        <div className="relative">
          <input
            type={show ? "text" : "password"}
            placeholder="Enter your password"
            className="w-full px-3 py-2 placeholder:text-[11px]  outline-none border border-gray-300 min-w-[200px] rounded-md  "
            labelProps={{
              className: "before:content-none after:content-none",
            }}
            value={value}
            onChange={onChange}
            onBlur={onBlur}
          />
          <div
            onClick={() => setShow(!show)}
            className="absolute top-1/2 transform -translate-y-1/2 text-2xl right-3 cursor-pointer"
          >
            {show ? (
              <IoMdEye size={18} className="text-slate-800" />
            ) : (
              <IoMdEyeOff size={18} className="text-slate-800" />
            )}
          </div>
          <ErrorMessage name={name}>
            {(errorMessage) => <ATMFieldError> {errorMessage} </ATMFieldError>}
          </ErrorMessage>
        </div>
      </div>
    </div>
  );
};

export default ATMPassword;
