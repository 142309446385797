import React from "react";
import { useNavigate } from "react-router";
const calculateAvgScores = (scores) => {
  const Sum = scores.reduce((sum, item) => sum + (item?.score1 || 0), 0);

  const score1Percentage = (Sum / 500) * 100;
  return score1Percentage;
};
const calculateAvgCummScores = (scores) => {
  const Sum = scores.reduce((sum, item) => sum + (item?.score2 || 0), 0);

  const score1Percentage = (Sum / 500) * 100;
  return score1Percentage;
};

function ApplicationTable({ data }) {
  const navigate = useNavigate();
  return (
    <div className="overflow-x-auto">
      <table className="w-full table-auto border border-collapse text-[#667085]   text-xs ">
        <thead>
          <tr className="font-bold">
            <th className="px-2 py-2  border-r">ID</th>
            <th className="px-2 py-2 border-r min-w-48">Data Details</th>
            <th className="px-2 py-2 border-r">Experience</th>
            <th className="px-2 py-2 border-r min-w-[200px]"> Skills</th>
            <th className="px-2 py-2 border-r min-w-[100px]"> Skill Score</th>
            <th className="px-2 py-2 border-r min-w-[130px]">
              Communication Score
            </th>
            <th className="px-2 py-2 border-r">Status</th>
            <th className="px-2 py-2  ">Actions</th>
          </tr>
        </thead>
        <tbody>
          {data.map((item, index) => (
            <tr key={index} className="">
              <td className="px-4 py-0  border-t text-center">
                <div>{item?.id}</div>
              </td>
              <td className="px-4 py-0 border-t border-l relative min-w-48">
                <div className="flex items-center gap-3">
                  <div>
                    {item?.candidate?.profile_pic ? (
                      <div className="w-12 h-12">
                        {" "}
                        <img
                          src={item?.candidate?.profile_pic}
                          alt="Candidate Profile"
                          className="w-full h-full border-2 rounded-full border-[#373737]"
                        />
                      </div>
                    ) : (
                      <div className="uppercase  text-lg font-semibold  text-[#1CACBD] border rounded-full w-12 h-12  flex justify-center items-center  ">
                        <span className="">
                          {item?.candidate?.first_name?.charAt(0)}
                        </span>
                        <span className="">
                          {item?.candidate?.last_name?.charAt(0)}
                        </span>
                      </div>
                    )}
                  </div>
                  <div className="break-words">
                    <div className="font-bold text-[#101828] capitalize break-words">
                      {item?.candidate?.first_name} {item?.candidate?.last_name}
                    </div>
                    <div className=" text-gray-600 break-words ">
                      {item?.candidate?.email}
                    </div>
                    <div className=" text-gray-600 text-[8px]">
                      {/* {item?.candidate?.city},{" "} */}
                      {item?.candidate?.state} ,{item?.candidate?.country}
                    </div>
                  </div>
                </div>
              </td>
              <td className="px-4 py-0  border-t border-l  text-center">
                <div>{item?.candidate?.experience_year}</div>
                <div>{item?.company}</div>
              </td>
              <td className="px-4 py-0  border-t border-l   ">
                <div className="flex flex-wrap text-center gap-2">
                  {item?.candidate_form_data?.skill?.map((skill, idx) => (
                    <span
                      key={idx}
                      className="border text-gray-500 h-fit font-semibold mr-2 px-1 py-0.5 rounded-md"
                    >
                      {skill?.label}
                    </span>
                  ))}
                </div>
              </td>
              <td className="px-4 py-1  text-center border-t border-l ">
                <div className="flex flex-col items-center">
                  <div className=" border  border-b-2 p-2 border-b-[#03A400] flex justify-center items-center flex-col rounded-md  border-[#E8E8E8] font-bold">
                    {calculateAvgScores(item?.ques_ans)?.toFixed(0)} / 100
                  </div>
                </div>
              </td>
              <td className="px-4 py-1  text-center border-t border-l ">
                <div className="flex flex-col items-center">
                  <div className=" border  border-b-2 p-2 border-b-[#FDA700] flex justify-center items-center flex-col rounded-md  border-[#E8E8E8] font-bold">
                    {calculateAvgCummScores(item?.ques_ans)?.toFixed(0)} / 100
                  </div>
                </div>
              </td>
              <td className="px-4 py-1 text-center border-t border-l ">
                {item?.status === "submitted" ? (
                  <span className="px-3 py-1 text-sm text-white w-[100px] bg-green-500 rounded-full">
                    Applied
                  </span>
                ) : (
                  <span className="px-3 py-1 text-sm text-white  w-[100px] bg-yellow-500 rounded-full">
                    Shortlisted
                  </span>
                )}
              </td>
              <td className="px-4 py-2 text-center border-t border-l  flex flex-col items-center gap-3">
                <button
                  onClick={() =>
                    navigate(
                      `/recruit/job-status/application/view/${item?.id}/${item?.candidate?.id}/${item?.job?.id}`
                    )
                  }
                  className="bg-[#1CACBD] w-[150px]  text-white px-2 h-8 font-semibold flex items-center justify-center gap-2 rounded-lg"
                >
                  Application View
                  <svg
                    width="10"
                    height="11"
                    viewBox="0 0 10 11"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M4.88428 1.55664L9.19964 5.67585L4.88428 9.79506"
                      stroke="white"
                      strokeWidth="1.28571"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M9.19975 5.67578L0.799805 5.67578"
                      stroke="white"
                      strokeWidth="1.28571"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                </button>
                <button
                  onClick={() =>
                    navigate(
                      `/recruit/job-status/application/candidate/${item.candidate.id}`
                    )
                  }
                  className="bg-blue-100  bg-[#1cadbd23]  w-[150px]  text-[#505050] font-semibold px-4 h-8 rounded-lg flex items-center justify-center"
                >
                  Jivahire Profile
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}

export default ApplicationTable;
