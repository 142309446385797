import { City, Country, State } from "country-state-city";
import { ErrorMessage, FieldArray, Form, Formik } from "formik";
import React, { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { axiosCandidateRequest } from "../../../../api/api";
import { axiosCandidateFromRequest } from "../../../../api/formApi";
import ATMCircularProgress from "../../../../atoms/ATMCircularProgress/ATMCircularProgress";
import ATMCurrencyAmount from "../../../../atoms/ATMCurrencyAmount/ATMCurrencyAmount";
import ATMFieldError from "../../../../atoms/ATMFieldError/ATMFieldError";
import ATMFieldLabel from "../../../../atoms/ATMFieldLabel/ATMFieldLabel";
import ATMMultiSelect from "../../../../atoms/ATMMultiSelect/ATMMultiSelect";
import ATMSelect from "../../../../atoms/ATMSelect/ATMSelect";
import ATMTextField from "../../../../atoms/ATMTextField/ATMTextField";
import { setCandidateData } from "../../../../features/candidateData/candidateData";
import { array, boolean, object, string } from "yup";
import ATMDatePicker from "../../../../atoms/ATMDatePicker/ATMDatePicker";
const calculateExperience = (experienceArray) => {
  let totalYears = 0;
  let totalMonths = 0;

  // Iterate over each experience and accumulate total years and months
  experienceArray.forEach((experience) => {
    const start = new Date(experience.company_start_duration);
    const end = new Date(experience.company_end_duration);

    let years = end.getFullYear() - start.getFullYear();
    let months = end.getMonth() - start.getMonth();

    if (months < 0) {
      years--;
      months += 12;
    }

    // Accumulate the total years and months
    totalYears += years;
    totalMonths += months;
  });

  // Convert total months to years if greater than or equal to 12
  totalYears += Math.floor(totalMonths / 12);
  totalMonths = totalMonths % 12; // Get remaining months

  return { totalYears, totalMonths };
};

const getCurrentCompany = (experiences) => {
  if (!experiences || experiences.length === 0) return " ";

  // Find the company where the end_date is "Present" or null (current job)
  const currentCompany = experiences.find(
    ({ company_end_duration }) =>
      !company_end_duration || company_end_duration === "Present"
  );

  if (currentCompany) {
    return currentCompany.company_name;
  }

  // If no current company, find the job with the most recent end_date
  const lastCompany = experiences.reduce((latest, experience) => {
    const latestEndDate = latest.company_end_duration
      ? new Date(latest.company_end_duration)
      : null;
    const currentEndDate = experience.company_end_duration
      ? new Date(experience.company_end_duration)
      : null;

    if (!latestEndDate || (currentEndDate && currentEndDate > latestEndDate)) {
      return experience;
    }

    return latest;
  });

  return lastCompany?.company_name || " ";
};
// Example usage

const baseUrl = process.env.REACT_APP_VITE_BACKEND_API_URL;
const options = [
  { label: "On-Site", value: "On-Site" },
  { label: "Remote Working", value: "Remote Working" },
  { label: "Hybrid", value: "Hybrid" },
  { label: "Flexible", value: "Flexible" },
];

const ApplyForm = () => {
  const { id, id1 } = useParams();
  const [isLoading, setIsLoading] = useState(false);
  const [terms, setTerms] = useState(false);

  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    const getUserDetails = async () => {
      setIsLoading(true);
      try {
        const response = await axiosCandidateRequest(
          `${baseUrl}/api/candidate/user/`,
          {
            method: "GET",
          }
        );

        if (response.status === 200) {
          dispatch(setCandidateData(response.data.data.candidate));
          setIsLoading(false);
        } else if (response.status === 400) {
          toast.error("Failed to get data");
        }
      } catch (error) {
        if (error.response && error.response.status === 400) {
          toast.error(error.response.data.message);
        } else {
          toast.error("An unexpected error occurred");
        }
      } finally {
        setIsLoading(false);
      }
    };
    getUserDetails();
    // eslint-disable-next-line
  }, []);
  const userData = useSelector((state) => state.candidateData.candidateData);

  const workExperienceData = userData?.workexperience_candidate || [];
  const { totalYears, totalMonths } = calculateExperience(workExperienceData);
  const currentCompany = getCurrentCompany(workExperienceData);

  const skillsArray = (userData?.skills && JSON.parse(userData?.skills)) || [];
  const formattedSkills = skillsArray.map((skill) => ({
    label: skill,
    value: skill,
  }));
  const initialValues = {
    jobPreference:
      { label: userData?.job_preference, value: userData?.job_preference } ||
      null,
    miscellaneousInformation: "",
    currentSalaryType: "",
    ctc: userData?.salary || "",
    noticePeriod: "",
    skill: formattedSkills || [],
    experience: `${totalYears} years ${totalMonths} months`,

    asPerStandard: false,
    salaryTo: "",
    salaryFrom: "",
    currency: "",
    salaryType: "",
    currentSalaryCurrency: "",

    availableFrom: null,
    immediatelyAvailable: false,
    current_company: currentCompany || "",
    selectedHobbies: [],
    country: { value: userData?.country, label: userData?.country } || "",
    state: { value: userData?.state, label: userData?.state } || "",
    city: { value: userData?.city, label: userData?.city } || "",
    zipCode: "",
    preferredLocations:
      userData?.preferredlocation_candidate?.length > 0
        ? userData.preferredlocation_candidate.map((el) => ({
            country: el?.country
              ? { value: el.country, label: el.country }
              : null,
            state: el?.state ? { value: el.state, label: el.state } : null,
            city: el?.city ? { value: el.city, label: el.city } : null,
          }))
        : [{ country: null, state: null, city: null }],

    acknowledgment: false,
  };

  const validationSchema = object().shape({
    jobPreference: object().required("Job preference is required").nullable(),

    currentSalaryCurrency: string().required("currency "),
    ctc: string().required("CTC is required"),
    noticePeriod: string().required("Notice period is required"),
    skill: array()
      .min(1, "At least one skill is required")
      .required("Skills are required"),
    experience: string().required("Experience is required"),

    asPerStandard: boolean().required(
      "Salary expectation standard is required"
    ),
    salaryTo: string().test(
      "min-required",
      "Minimum salary is required",
      function (value) {
        const { asPerStandard } = this.parent;
        return asPerStandard === false ? !!value : true; // Only required if asPerStandard is false
      }
    ),
    salaryFrom: string().test(
      "max-required",
      "Maximum salary is required",
      function (value) {
        const { asPerStandard } = this.parent;
        return asPerStandard === false ? !!value : true; // Only required if asPerStandard is false
      }
    ),
    currency: string()
      .test(
        "currency-required",
        "Salary currency is required",
        function (value) {
          const { asPerStandard } = this.parent;
          return asPerStandard === false ? !!value : true; // Only required if asPerStandard is false
        }
      )
      .nullable(),

    availableFrom: string().test(
      "available-from-required",
      "Available from is required",
      function (value) {
        const { immediatelyAvailable } = this.parent;
        return immediatelyAvailable === false ? !!value : true; // Only required if immediatelyAvailable is false
      }
    ),
    immediatelyAvailable: boolean().required(
      "Immediately available status is required"
    ),
    current_company: string().required("Current company is required"),

    country: object().required("Country is required").nullable(),
    state: object().required("State is required").nullable(),
    city: object().required("City is required").nullable(),
    zipCode: string().required("Zip code is required"),
    preferredLocations: array().of(
      object().shape({
        country: object().required("Preferred location country is required"),
        state: object().required("Preferred location state is required"),
        city: object().required("Preferred location city is required"),
      })
    ),
    acknowledgment: boolean()
      .oneOf([true], "You must agree to the Terms and Conditions.")
      .required("You must agree to the Terms and Conditions."),
  });
  const onSubmit = async (values) => {
    try {
      const formData = new FormData();
      formData.append("job", id);
      formData.append("org_id", id1);

      formData.append("candidate_form_data", JSON.stringify(values));

      const response = await axiosCandidateFromRequest(
        `${baseUrl}/api/candidate/apply/job/`,
        { method: "POST", data: formData }
      );

      if (response.status === 201) {
        toast.success("Data updated successfully");
        navigate(`/recorder/${response.data.data.job.id}`);
      } else {
        toast.error("Failed to update data");
      }
    } catch (error) {
      if (error.response && error.response.status === 400) {
        toast.error(error.response.data.message);
      } else {
        toast.error("An unexpected error occurred");
      }
    }
  };

  return (
    <>
      {isLoading ? (
        <div className="h-full flex justify-center items-center">
          <ATMCircularProgress />
        </div>
      ) : (
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={onSubmit}
        >
          {({ values, setFieldValue }) => (
            <Form className=" flex flex-col text-[11px] gap-4 px-2 my-2">
              <div>
                <h1 className="text-[#333333] text-lg font-inter font-semibold capitalize">
                  Hello, {userData?.first_name} {userData?.last_name}
                </h1>
                <div className="w-fit rounded-lg bg-[#F0EC913D] px-[12px] py-[8px]">
                  <p className="text-[#303030] text-[11px] font-inter font-base">
                    Below information is valid only for this job application and
                    it will not be part of your profile.
                  </p>
                </div>
              </div>

              <div className="flex flex-col gap-4">
                {/* Form Fields */}
                <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3  gap-4 font-inter text-xs border p-5 rounded-md ">
                  <div>
                    <ATMSelect
                      required
                      name={`jobPreference`}
                      label="Job Preference"
                      options={options}
                      onChange={(newValue) => {
                        setFieldValue(`jobPreference`, newValue);
                      }}
                      value={values?.jobPreference}
                      isClearable={false}
                    />
                  </div>
                  <div>
                    <ATMTextField
                      required
                      type="text"
                      name="current_company"
                      value={values.current_company}
                      label="Current Company"
                      placeholder="Enter your Current Company"
                      onChange={(e) =>
                        setFieldValue("current_company", e.target.value)
                      }
                    />
                  </div>

                  <div>
                    <ATMTextField
                      required
                      type="text"
                      name="experience"
                      value={values.experience || ""}
                      label=" Total Years of Experience"
                      placeholder="Enter your Total Years of Experience"
                      onChange={(e) =>
                        setFieldValue("experience", e.target.value)
                      }
                    />
                  </div>
                  <div className="md:col-span-2 lg:col-span-3">
                    <ATMMultiSelect
                      required
                      name="skill"
                      value={values.skill || formattedSkills}
                      onChange={(newValue) => setFieldValue("skill", newValue)}
                      options={[]}
                      label="Skills"
                      placeholder="Select Skills"
                      keysToSelectoption={["Enter", ","]}
                      isSkills={true}
                    />
                  </div>
                  <div>
                    <ATMTextField
                      required
                      type="text"
                      name="noticePeriod"
                      value={values.noticePeriod || ""}
                      label="Notice Period (Days)"
                      placeholder="Enter your Notice Period (Days)"
                      onChange={(e) =>
                        setFieldValue("noticePeriod", e.target.value)
                      }
                    />
                  </div>
                  <div>
                    <ATMCurrencyAmount
                      required
                      currencyValue={values.currentSalaryCurrency}
                      onCurrencyChange={(newValue) =>
                        setFieldValue("currentSalaryCurrency", newValue)
                      }
                      name="ctc"
                      currencyname="currentSalaryCurrency"
                      salarytypehide
                      onSalaryTypeChange={(newValue) =>
                        setFieldValue("currentSalaryType", newValue)
                      }
                      SalaryTypeValue={values?.currentSalaryType}
                      label="Current Salary (CTC)"
                      placeholder="Enter your Current Salary (CTC)"
                      value={values.ctc}
                      onChange={(e) => setFieldValue("ctc", e.target.value)}
                    />
                  </div>

                  <div>
                    <ATMFieldLabel required>Available From</ATMFieldLabel>

                    <ATMDatePicker
                      selectedDate={values.availableFrom}
                      onChange={(date) => setFieldValue("availableFrom", date)}
                      minDate={new Date()}
                      disabled={values.immediatelyAvailable}
                    />

                    <div className="flex items-center mt-1">
                      <input
                        type="checkbox"
                        checked={values.immediatelyAvailable}
                        onChange={() => {
                          setFieldValue(
                            "immediatelyAvailable",
                            !values.immediatelyAvailable
                          );
                          setFieldValue("availableFrom", new Date());
                        }}
                        className="mr-2"
                      />

                      <div className="font-medium text-[11px]">
                        Immediately Available
                      </div>
                    </div>
                    <div>
                      {" "}
                      <ErrorMessage name={"availableFrom"}>
                        {(errorMessage) => (
                          <ATMFieldError>{errorMessage}</ATMFieldError>
                        )}
                      </ErrorMessage>
                    </div>
                  </div>

                  <div className="md:col-span-2">
                    <ATMFieldLabel required>Salary Expectation </ATMFieldLabel>
                    <div className=" border rounded-md p-4 flex flex-col gap-1">
                      <div className="flex gap-2 items-center">
                        <input
                          type="checkbox"
                          name="asPerStandard"
                          checked={values.asPerStandard}
                          onChange={() => {
                            setFieldValue(
                              "asPerStandard",
                              !values.asPerStandard
                            );
                            setFieldValue("salaryType", null);
                            setFieldValue("currency", null);
                            setFieldValue("salaryTo", "");
                            setFieldValue("salaryFrom", "");
                          }}
                        />
                        <ATMFieldLabel>As Per Standard</ATMFieldLabel>
                      </div>

                      <p className="font-bold text-md text-slate-500">OR</p>
                      <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-4  gap-2 justify-between">
                        <ATMCurrencyAmount
                          label="Salary Type"
                          currencyhide={false}
                          onSalaryTypeChange={(newValue) =>
                            setFieldValue("salaryType", newValue)
                          }
                          SalaryTypeValue={values?.salaryType}
                          salarytypehide
                          inputhide={false}
                          disabled={values.asPerStandard}
                        />
                        <ATMCurrencyAmount
                          label="Currency"
                          currencyname={"currency"}
                          currencyValue={values.currency}
                          onCurrencyChange={(newValue) =>
                            setFieldValue("currency", newValue)
                          }
                          inputhide={false}
                          disabled={values.asPerStandard}
                        />
                        <ATMCurrencyAmount
                          name="salaryFrom"
                          currencyhide={false}
                          label="Minimum"
                          value={values.salaryFrom || ""}
                          onChange={(e) =>
                            setFieldValue("salaryFrom", Number(e.target.value))
                          }
                          placeholder="400000"
                          disabled={values.asPerStandard}
                        />
                        <ATMCurrencyAmount
                          name="salaryTo"
                          currencyhide={false}
                          label="Maximum"
                          value={values.salaryTo}
                          onChange={(e) =>
                            setFieldValue("salaryTo", Number(e.target.value))
                          }
                          placeholder="500000"
                          disabled={values.asPerStandard}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="md:col-span-2 lg:col-span-3">
                    <ATMFieldLabel>Add Miscellaneous Information</ATMFieldLabel>
                    <textarea
                      className="w-full h-[177px] outline-none border rounded-md p-2"
                      placeholder={`Tell us more Information`}
                      value={values?.miscellaneousInformation}
                      onChange={(e) =>
                        setFieldValue(
                          "miscellaneousInformation",
                          e.target.value
                        )
                      }
                    />
                    <ErrorMessage name={"miscellaneousInformation"}>
                      {(errorMessage) => (
                        <ATMFieldError>{errorMessage}</ATMFieldError>
                      )}
                    </ErrorMessage>
                  </div>
                </div>
                {/* Current Location */}
                <div className="border rounded-md">
                  <div className="bg-blue-100 px-5 py-1">
                    <ATMFieldLabel>Current Location</ATMFieldLabel>
                  </div>
                  <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4 p-5">
                    <ATMSelect
                      required
                      name={`country`}
                      label="Country"
                      options={Country.getAllCountries().map((country) => ({
                        value: country.isoCode,
                        label: country.name,
                      }))}
                      onChange={(newValue) => {
                        setFieldValue(`country`, newValue);
                        setFieldValue(`state`, null);
                        setFieldValue(`city`, null);
                      }}
                      value={values?.country}
                    />

                    <ATMSelect
                      required
                      name={`state`}
                      label="State"
                      options={
                        values.country && values.country.value
                          ? State.getStatesOfCountry(values.country.value).map(
                              (state) => ({
                                value: state.isoCode,
                                label: state.name,
                              })
                            )
                          : []
                      }
                      onChange={(newValue) => {
                        setFieldValue(`state`, newValue);
                        setFieldValue(`city`, "");
                      }}
                      value={values?.state}
                    />

                    <ATMSelect
                      required
                      name={`city`}
                      label="City"
                      value={values?.city}
                      options={
                        values.country?.value && values.state?.value
                          ? City.getCitiesOfState(
                              values.country?.value,
                              values.state?.value
                            ).map((city) => ({
                              value: city.name,
                              label: city.name,
                            }))
                          : []
                      }
                      onChange={(newValue) => {
                        setFieldValue(`city`, newValue);
                      }}
                    />
                    <div className="">
                      <ATMTextField
                        required
                        type="text"
                        name="zipCode"
                        value={values.zipCode || ""}
                        label="Zip Code"
                        placeholder="Enter your zip code"
                        onChange={(e) =>
                          setFieldValue("zipCode", Number(e.target.value))
                        }
                      />
                    </div>
                  </div>
                </div>
                {/* Preferred Location */}
                <div className="border rounded-md">
                  <div className="bg-blue-100 px-5 py-1">
                    <ATMFieldLabel>Preferred Locations</ATMFieldLabel>
                  </div>
                  {/* <FieldArray name="preferredLocations">
                    {({ push, remove }) => (
                      <div className="p-5">
                        {values?.preferredLocations?.map((location, index) => (
                          <div key={index} className="flex flex-col gap-2 mb-4">
                            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4">
                              <ATMSelect
                                required
                                name={`preferredLocations.${index}.country`}
                                value={
                                  values?.preferredLocations[index].country
                                }
                                label="Country"
                                options={Country.getAllCountries().map(
                                  (country) => ({
                                    value: country.isoCode,
                                    label: country.name,
                                  })
                                )}
                                onChange={(newValue) => {
                                  setFieldValue(
                                    `preferredLocations.${index}.country`,
                                    newValue
                                  );
                                  setFieldValue(
                                    `preferredLocations.${index}.state`,
                                    ""
                                  );
                                  setFieldValue(
                                    `preferredLocations.${index}.city`,
                                    ""
                                  );
                                }}
                              />
                              <ATMSelect
                                required
                                name={`preferredLocations.${index}.state`}
                                value={values?.preferredLocations[index].state}
                                label="State"
                                options={
                                  values.preferredLocations[index].country &&
                                  values.preferredLocations[index].country.value
                                    ? State.getStatesOfCountry(
                                        values.preferredLocations[index].country
                                          .value
                                      ).map((state) => ({
                                        value: state.isoCode,
                                        label: state.name,
                                      }))
                                    : []
                                }
                                onChange={(newValue) => {
                                  setFieldValue(
                                    `preferredLocations.${index}.state`,
                                    newValue
                                  );
                                  setFieldValue(
                                    `preferredLocations.${index}.city`,
                                    ""
                                  );
                                }}
                              />
                              <ATMSelect
                                required
                                name={`preferredLocations.${index}.city`}
                                value={values?.preferredLocations[index].city}
                                label="City"
                                options={
                                  values.preferredLocations[index].country
                                    ?.value &&
                                  values.preferredLocations[index].state?.value
                                    ? City.getCitiesOfState(
                                        values.preferredLocations[index].country
                                          ?.value,
                                        values.preferredLocations[index].state
                                          ?.value
                                      ).map((city) => ({
                                        value: city.name,
                                        label: city.name,
                                      }))
                                    : []
                                }
                                onChange={(newValue) => {
                                  setFieldValue(
                                    `preferredLocations.${index}.city`,
                                    newValue
                                  );
                                }}
                              />
                              {index > 0 && (
                                <button
                                  type="button"
                                  className="text-red-600 mt-2"
                                  onClick={() => remove(index)}
                                >
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    fill="none"
                                    viewBox="0 0 24 24"
                                    strokeWidth={1.5}
                                    stroke="currentColor"
                                    className="size-6"
                                  >
                                    <path
                                      strokeLinecap="round"
                                      strokeLinejoin="round"
                                      d="m14.74 9-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 0 1-2.244 2.077H8.084a2.25 2.25 0 0 1-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 0 0-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 0 1 3.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 0 0-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 0 0-7.5 0"
                                    />
                                  </svg>
                                </button>
                              )}
                            </div>
                          </div>
                        ))}
                        <button
                          type="button"
                          className="mt-2 bg-[#1CACBD] text-white py-2 px-4 rounded-lg"
                          onClick={() => {
                            const lastLocation =
                              values.preferredLocations[
                                values.preferredLocations.length - 1
                              ];
                            if (
                              lastLocation.country &&
                              lastLocation.state &&
                              lastLocation.city
                            ) {
                              push({ country: "", state: "", city: "" });
                            } else {
                              toast.error(
                                "Please fill the last preferred location before adding more."
                              );
                            }
                          }}
                        >
                          Add More Location
                        </button>
                      </div>
                    )}
                  </FieldArray> */}
                  <FieldArray name="preferredLocations">
                    {({ push, remove }) => (
                      <div className="p-5">
                        {values?.preferredLocations?.map((location, index) => (
                          <div key={index} className="flex flex-col gap-2 mb-4">
                            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4">
                              {/* Country Dropdown */}
                              <ATMSelect
                                required
                                name={`preferredLocations.${index}.country`}
                                value={
                                  values?.preferredLocations?.[index]
                                    ?.country || ""
                                }
                                label="Country"
                                options={Country.getAllCountries().map(
                                  (country) => ({
                                    value: country.isoCode,
                                    label: country.name,
                                  })
                                )}
                                onChange={(newValue) => {
                                  if (values?.preferredLocations?.[index]) {
                                    setFieldValue(
                                      `preferredLocations.${index}.country`,
                                      newValue
                                    );
                                    setFieldValue(
                                      `preferredLocations.${index}.state`,
                                      ""
                                    );
                                    setFieldValue(
                                      `preferredLocations.${index}.city`,
                                      ""
                                    );
                                  }
                                }}
                              />
                              {/* State Dropdown */}
                              <ATMSelect
                                required
                                name={`preferredLocations.${index}.state`}
                                value={
                                  values?.preferredLocations?.[index]?.state ||
                                  ""
                                }
                                label="State"
                                options={
                                  values?.preferredLocations?.[index]?.country
                                    ?.value
                                    ? State.getStatesOfCountry(
                                        values.preferredLocations[index].country
                                          .value
                                      ).map((state) => ({
                                        value: state.isoCode,
                                        label: state.name,
                                      }))
                                    : []
                                }
                                onChange={(newValue) => {
                                  if (values?.preferredLocations?.[index]) {
                                    setFieldValue(
                                      `preferredLocations.${index}.state`,
                                      newValue
                                    );
                                    setFieldValue(
                                      `preferredLocations.${index}.city`,
                                      ""
                                    );
                                  }
                                }}
                              />
                              {/* City Dropdown */}
                              <ATMSelect
                                required
                                name={`preferredLocations.${index}.city`}
                                value={
                                  values?.preferredLocations?.[index]?.city ||
                                  ""
                                }
                                label="City"
                                options={
                                  values?.preferredLocations?.[index]?.country
                                    ?.value &&
                                  values?.preferredLocations?.[index]?.state
                                    ?.value
                                    ? City.getCitiesOfState(
                                        values.preferredLocations[index].country
                                          .value,
                                        values.preferredLocations[index].state
                                          .value
                                      ).map((city) => ({
                                        value: city.name,
                                        label: city.name,
                                      }))
                                    : []
                                }
                                onChange={(newValue) => {
                                  if (values?.preferredLocations?.[index]) {
                                    setFieldValue(
                                      `preferredLocations.${index}.city`,
                                      newValue
                                    );
                                  }
                                }}
                              />
                              {/* Remove Button */}
                              {index > 0 && (
                                <button
                                  type="button"
                                  className="text-red-600 mt-2"
                                  onClick={() => remove(index)}
                                >
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    fill="none"
                                    viewBox="0 0 24 24"
                                    strokeWidth={1.5}
                                    stroke="currentColor"
                                    className="size-6"
                                  >
                                    <path
                                      strokeLinecap="round"
                                      strokeLinejoin="round"
                                      d="m14.74 9-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 0 1-2.244 2.077H8.084a2.25 2.25 0 0 1-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 0 0-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 0 1 3.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 0 0-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 0 0-7.5 0"
                                    />
                                  </svg>
                                </button>
                              )}
                            </div>
                          </div>
                        ))}
                        {/* Add More Location Button */}
                        <button
                          type="button"
                          className="mt-2 bg-[#1CACBD] text-white py-2 px-4 rounded-lg"
                          onClick={() => {
                            const lastLocation =
                              values?.preferredLocations?.[
                                values.preferredLocations.length - 1
                              ];
                            if (
                              lastLocation?.country &&
                              lastLocation?.state &&
                              lastLocation?.city
                            ) {
                              push({ country: "", state: "", city: "" });
                            } else {
                              toast.error(
                                "Please fill the last preferred location before adding more."
                              );
                            }
                          }}
                        >
                          Add More Location
                        </button>
                      </div>
                    )}
                  </FieldArray>
                </div>
                {/* acknowledgment */}

                <div className="p-4 border rounded shadow-md  md:col-span-2 lg:col-span-3">
                  <div className="mt-3 flex items-center">
                    <input
                      type="checkbox"
                      name="acknowledgment"
                      checked={values.acknowledgment}
                      onChange={() =>
                        setFieldValue("acknowledgment", !values.acknowledgment)
                      }
                      className="w-4 h-4 text-blue-600 focus:ring-blue-500 border-gray-300 rounded"
                    />
                    <div className="ml-2 text-xs ">
                      I agree to the{" "}
                      <span
                        className="text-blue-700 underline cursor-pointer"
                        onClick={() => setTerms(true)}
                      >
                        {" "}
                        Terms and Conditions
                      </span>{" "}
                      and consent to participate in the video interview,
                      including the recording and storage of my responses for
                      recruitment purposes.
                    </div>
                  </div>
                  <ErrorMessage name={"acknowledgment"}>
                    {(errorMessage) => (
                      <ATMFieldError>{errorMessage}</ATMFieldError>
                    )}
                  </ErrorMessage>
                </div>
                {/* Submit Button */}
                <div className="text-right">
                  <button
                    type="submit"
                    className="bg-[#1CACBD] text-white py-2 px-6 rounded-md  "
                  >
                    Submit
                  </button>
                </div>
              </div>
            </Form>
          )}
        </Formik>
      )}
      {terms && (
        <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50 text-xs">
          <div className="bg-white rounded-lg shadow-lg w-[50%]  p-4 h-[300px]  overflow-x-auto">
            <div className="flex justify-end px-2">
              {" "}
              <button
                onClick={() => setTerms(false)}
                className=" text-gray-600  hover:text-gray-900 cursor-pointer"
              >
                ✖
              </button>
            </div>
            <div className="text-sm font-semibold">
              Terms and Conditions for Jivahire Video Interviews
            </div>
            <div className="text-gray-700">
              Welcome to Jivahire! By participating in our video interview
              process, you agree to the following terms and conditions:
            </div>

            <div className="space-y-4 mt-2">
              <div>
                <div className="text-sm font-semibold">
                  1. Purpose of Video Interviews
                </div>
                <p className="text-gray-700">
                  The video interviews conducted via Jivahire are solely for
                  recruitment purposes. Your video, along with your profile
                  details, will be shared with recruiters and companies
                  associated with Jivahire to evaluate your suitability for
                  various job opportunities.
                </p>
              </div>
              <div>
                <div className="text-sm font-semibold">
                  2. Consent to Share Information
                </div>
                <p className="text-gray-700">
                  By proceeding with the video interview, you explicitly agree
                  to share your recorded video and profile details with Jivahire
                  and its associated companies. These companies may include
                  potential employers or recruitment partners working with
                  Jivahire.
                </p>
              </div>
              <div>
                <div className="text-sm font-semibold">
                  3. Usage of Video and Profile Data
                </div>
                <p className="text-gray-700">
                  Your video and profile information will only be used for
                  hiring-related purposes. Jivahire ensures that this data is
                  handled in accordance with applicable privacy laws and shared
                  only with authorized personnel or organizations involved in
                  the recruitment process.
                </p>
              </div>
              <div>
                <div className="text-sm font-semibold">
                  4. Retention of Data
                </div>
                <p className="text-gray-700">
                  Your recorded video and profile information may be retained in
                  our system for a reasonable period to support ongoing or
                  future recruitment needs. You may request the deletion of your
                  data by contacting us at{" "}
                  <a
                    href="mailto:support@jivahire.com"
                    className="text-blue-500 underline"
                  >
                    support@jivahire.com
                  </a>
                  , subject to applicable legal or operational constraints.
                </p>
              </div>
              <div>
                <div className="text-sm font-semibold">
                  5. Accuracy of Information
                </div>
                <p className="text-gray-700">
                  You are responsible for ensuring the accuracy and authenticity
                  of the information and content you provide during the video
                  interview process. False or misleading information may result
                  in disqualification from the recruitment process.
                </p>
              </div>
              <div>
                <div className="text-sm font-semibold">
                  6. Non-Disclosure of Interview Content
                </div>
                <p className="text-gray-700">
                  You agree not to share, distribute, or publish any part of the
                  video interview or related materials provided by Jivahire
                  without prior written consent.
                </p>
              </div>
              <div>
                <div className="text-sm font-semibold">
                  7. Modification and Updates
                </div>
                <p className="text-gray-700">
                  Jivahire reserves the right to modify or update these terms
                  and conditions at any time. Any changes will be communicated
                  via our website or email. Continued participation in the video
                  interview process implies acceptance of any revised terms.
                </p>
              </div>
              <div>
                <div className="text-sm font-semibold">
                  8. Contact Information
                </div>
                <p className="text-gray-700">
                  If you have questions or concerns about these terms, please
                  reach out to us at
                  <a
                    href="mailto:support@jivahire.com"
                    className="text-blue-500 underline"
                  >
                    {" "}
                    support@jivahire.com
                  </a>
                  .
                </p>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default ApplyForm;
