import React, { useState } from "react";
import { useNavigate } from "react-router";
import {
  useGetDashboardDataQuery,
  useGetMyOrgApplicationsQuery,
} from "../../../app/DashboardServices/DashboardServices";
import ATMCircularProgress from "../../../atoms/ATMCircularProgress/ATMCircularProgress";
import ATMDateRangePicker from "../../../atoms/ATMDateRangePicker/ATMDateRangePicker";
import ATMHeading from "../../../atoms/ATMHeading/ATMHeading";
import { formatDate } from "../../candidate/ProfileDetails/View/ProfileDetails";
import ATMButton from "../../../atoms/ATMButton/ATMButton";

const formatDates = (dateString) => {
  if (dateString === null) return;
  const date = new Date(dateString);
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, "0");
  const day = String(date.getDate()).padStart(2, "0");
  return `${year}-${month}-${day}`;
};

const RecruiterDashboard = () => {
  const navigate = useNavigate();
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const {
    data,
    isLoading: loading,
    isFetching,
    refetch,
  } = useGetDashboardDataQuery(
    {
      start_date: formatDates(startDate),
      end_date: formatDates(endDate),
    },
    {
      skip: !startDate || !endDate, // Skip the query if either date is null
    }
  );
  const handleSelectStartDate = (date) => {
    setStartDate(date);
  };

  const handleSelectEndDate = (date) => {
    setEndDate(date);
  };

  const handleClear = () => {
    setStartDate(null);
    setEndDate(null);
  };

  const [currentPage, setCurrentPage] = useState(1);
  const pageSize = 4;
  const {
    data: mydata,
    isLoading,
    refetch: orgRefetch,
    isFetching: orgIsFetching,
  } = useGetMyOrgApplicationsQuery({
    page: currentPage,
    size: pageSize,
  });

  const handleNextPage = () => {
    if (mydata?.results?.length === pageSize) {
      setCurrentPage(currentPage + 1);
    }
  };

  const handlePreviousPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  return (
    <div className="p-4 space-y-6 font-inter">
      <div className="grid md:grid-cols-3 grid-cols-1 gap-4">
        {/* Left Column */}
        <div className="bg-gray-100 md:col-span-2 p-6 rounded-lg text-xs">
          <div>
            {/* Header */}
            <div className="flex justify-between gap-4 items-center mb-6 flex-wrap">
              <ATMHeading>Dashboard</ATMHeading>
              <div className="flex items-center gap-4">
                <ATMDateRangePicker
                  startDate={startDate}
                  endDate={endDate}
                  onSelectStartDate={handleSelectStartDate}
                  onSelectEndDate={handleSelectEndDate}
                  onClear={handleClear}
                  dateFilterKeys={[]}
                  defaultOption="7days"
                />
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth={1.5}
                  stroke="currentColor"
                  className={`size-6 cursor-pointer ${
                    isFetching ? "animate-spin" : ""
                  }`}
                  onClick={refetch}
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M16.023 9.348h4.992v-.001M2.985 19.644v-4.992m0 0h4.992m-4.993 0 3.181 3.183a8.25 8.25 0 0 0 13.803-3.7M4.031 9.865a8.25 8.25 0 0 1 13.803-3.7l3.181 3.182m0-4.991v4.99"
                  />
                </svg>
              </div>
            </div>
            {(loading || isFetching) && (
              <div className="h-32 flex justify-center items-center w-full">
                <ATMCircularProgress />
              </div>
            )}
            {/* Stats Cards */}
            {!loading && !isFetching && (
              <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4  gap-6">
                <div className="bg-white shadow rounded-lg flex flex-col justify-center items-center border-b-4 border-[#1cacbd] p-1 ">
                  <p className="text-lg font-bold">{data?.org_jobs || "00"}</p>
                  <h2 className="text-gray-600 font-medium">Total Jobs</h2>
                </div>

                <div className="bg-white shadow rounded-lg flex flex-col justify-center items-center border-b-4 p-1 border-yellow-500 ">
                  <p className="text-lg font-bold">
                    {data?.all_applicants || "00"}
                  </p>
                  <h2 className="text-gray-600  font-medium">
                    Total Applicants
                  </h2>
                </div>

                <div className="bg-white shadow rounded-lg flex flex-col justify-center items-center border-b-4 p-1 border-green-500 ">
                  <p className="text-lg font-bold">
                    {data?.applied_applicants || "00"}
                  </p>
                  <h2 className="text-gray-600  font-medium">Total Applied</h2>
                </div>

                <div className="bg-white shadow rounded-lg flex flex-col justify-center items-center border-b-4 p-1 border-orange-500 ">
                  <p className="text-lg font-bold">
                    {data?.shortlisted_applicants || "00"}
                  </p>
                  <h2 className="text-gray-600  font-medium">
                    Total Shortlisted
                  </h2>
                </div>
              </div>
            )}

            {/* More Stats */}
            {!loading && !isFetching && (
              <div className="grid grid-cols-1 sm:grid-cols-2 gap-6 mt-6">
                <div className="bg-white shadow rounded-lg flex flex-col justify-center items-center border-b-4 p-1 border-green-700 ">
                  <p className="text-lg font-bold">
                    {data?.all_applicants || "00"}
                  </p>
                  <h2 className="text-gray-600  font-medium">
                    Total Candidates
                  </h2>
                </div>

                <div className="bg-white shadow rounded-lg flex flex-col justify-center items-center border-b-4 p-1 border-orange-700 ">
                  <p className="text-lg font-bold">
                    {data?.talent_search_count || "00"}
                  </p>
                  <h2 className="text-gray-600  font-medium">
                    Talent Search Profile Counts
                  </h2>
                </div>
              </div>
            )}

            {/* Notifications */}
            <div className="mt-6  bg-white p-6 shadow rounded-lg relative">
              <h2 className="text-gray-600 text-sm font-medium mb-4">
                Notifications
              </h2>
              <span className="bg-slate-50 opacity-85 rounded-lg absolute inset-0 text-[#1cacbd] text-sm text-center flex justify-center items-center">
                Coming soon....
              </span>
            </div>
          </div>
        </div>

        {/* Right Column */}
        <div className="flex flex-col gap-4">
          {/* Recent Activity Section */}
          <div className="bg-white    text-xs relative h-40">
            <h3 className="text-sm rounded-t-lg font-medium bg-black text-white p-4">
              Recent Activity
            </h3>
            <span className="bg-slate-50  opacity-85 rounded-lg absolute inset-0 text-[#1cacbd] text-sm text-center flex justify-center items-center">
              Coming soon....
            </span>
          </div>

          {/* Upcoming Card */}
          <div className="p-4 border rounded-lg h-52 flex flex-col justify-between relative bg-gray-50">
            <div className="flex justify-between items-center pb-4 border-b">
              <h2 className="text-sm font-semibold">Upcoming Schedule</h2>
            </div>
            <div className="mt-6">
              <button className="w-full py-2 text-xs font-medium bg-gray-100 rounded-lg hover:bg-gray-200">
                Create a New Schedule
              </button>
            </div>
            <span className="bg-slate-50  opacity-85 rounded-lg absolute inset-0 text-[#1cacbd] text-sm text-center flex justify-center items-center">
              Coming soon....
            </span>
          </div>
        </div>
      </div>

      {/* Applicants Section */}
      <div className="space-y-4">
        <div className="flex justify-between items-center">
          <h2 className="text-sm font-semibold">Applicants</h2>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth={1.5}
            stroke="currentColor"
            className={`size-6 cursor-pointer ${
              orgIsFetching ? "animate-spin" : ""
            }`}
            onClick={orgRefetch}
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M16.023 9.348h4.992v-.001M2.985 19.644v-4.992m0 0h4.992m-4.993 0 3.181 3.183a8.25 8.25 0 0 0 13.803-3.7M4.031 9.865a8.25 8.25 0 0 1 13.803-3.7l3.181 3.182m0-4.991v4.99"
            />
          </svg>
        </div>

        {isLoading || orgIsFetching ? (
          <div className="h-32 flex justify-center items-center w-full">
            <ATMCircularProgress />
          </div>
        ) : (
          <div>
            {mydata?.results?.length > 0 ? (
              <div className="flex gap-2">
                <button
                  onClick={handlePreviousPage}
                  disabled={currentPage === 1}
                  className={`p-1 ${
                    currentPage === 1 ? "opacity-50 cursor-not-allowed" : ""
                  }`}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth={1.5}
                    stroke="currentColor"
                    className="size-6"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M15.75 19.5 8.25 12l7.5-7.5"
                    />
                  </svg>
                </button>

                <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-4 w-full gap-4">
                  {mydata.results.map((el) => (
                    <div
                      key={el?.id}
                      className="p-4 bg-slate-50 border flex flex-col justify-between rounded-lg capitalize"
                    >
                      <div>
                        <div className="flex gap-2 items-center">
                          <div>
                            {el?.candidate?.profile_pic ? (
                              <div
                                className="w-16 h-16 cursor-pointer"
                                onClick={() =>
                                  navigate(
                                    `/recruit/job-status/application/candidate/${el?.candidate?.id}`
                                  )
                                }
                              >
                                <img
                                  src={el?.candidate?.profile_pic}
                                  alt="Profile"
                                  className="w-full h-full rounded-full border-2 border-[#1CACBD] object-cover"
                                />
                              </div>
                            ) : (
                              <div className="uppercase text-lg bg-white text-[#1CACBD] border-[#1CACBD] border rounded-full w-16 h-16 flex justify-center items-center">
                                <span>
                                  {el?.candidate?.first_name?.charAt(0)}
                                </span>
                                <span>
                                  {el?.candidate?.last_name?.charAt(0)}
                                </span>
                              </div>
                            )}
                          </div>
                          <div>
                            <h2
                              className="text-xs font-semibold mt-2 cursor-pointer"
                              onClick={() =>
                                navigate(
                                  `/recruit/job-status/application/candidate/${el?.candidate?.id}`
                                )
                              }
                            >
                              {el?.candidate?.first_name}{" "}
                              {el?.candidate?.last_name}
                            </h2>
                            <p className="text-xs text-gray-600">
                              {el?.candidate?.title}
                            </p>
                            <p className="text-xs text-gray-600">
                              {el?.candidate?.experience_year
                                ? `${el?.candidate?.experience_year}years of Exp`
                                : ""}
                            </p>
                          </div>
                        </div>

                        <div className="mt-4 text-[11px]">
                          <p className=" text-gray-500">
                            Applied on{" "}
                            <span className="font-medium">
                              {formatDate(el?.created_at)}
                            </span>
                          </p>
                          <p className=" font-medium">{el?.job?.title}</p>
                        </div>

                        <div className="mt-4 flex flex-wrap gap-2">
                          {el?.candidate?.skills
                            ? JSON.parse(el?.candidate?.skills)?.map(
                                (skill, index) => (
                                  <div
                                    key={index}
                                    className="px-4 py-1 text-xs bg-white rounded-md border border-black"
                                  >
                                    {skill}
                                  </div>
                                )
                              )
                            : ""}
                        </div>
                      </div>

                      <div className="mt-6">
                        <ATMButton
                          type="button"
                          onClick={() =>
                            navigate(
                              `/recruit/job-status/application/view/${el?.id}/${el?.candidate?.id}/${el?.job?.id}`
                            )
                          }
                          label="  View Job Application"
                        />
                      </div>
                    </div>
                  ))}
                </div>

                <button
                  onClick={handleNextPage}
                  disabled={mydata?.results?.length < pageSize}
                  className={`p-1 ${
                    mydata?.results?.length < pageSize
                      ? "opacity-50 cursor-not-allowed"
                      : ""
                  }`}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth={1.5}
                    stroke="currentColor"
                    className="size-6"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="m8.25 4.5 7.5 7.5-7.5 7.5"
                    />
                  </svg>
                </button>
              </div>
            ) : (
              !isLoading && (
                <p className="text-center text-gray-500">No data found</p>
              )
            )}
          </div>
        )}
      </div>

      {/* Interview Results Section */}
      {mydata?.results?.length > 0 && (
        <div className="bg-white shadow-md rounded-lg overflow-hidden capitalize">
          <div className="flex justify-between items-center p-4 border-b bg-gray-50">
            <h2 className="text-sm font-semibold">Interview Results</h2>
            <button className="px-4 py-1 text-xs font-medium bg-gray-100 text-gray-600 rounded-md hover:bg-gray-200">
              Today
            </button>
          </div>

          <div className="overflow-x-auto">
            <table className="min-w-[1000px] w-full table-auto text-left border-collapse">
              <thead className="bg-gray-50 text-gray-700 text-xs font-medium">
                <tr>
                  <th className="px-6 py-3  border-b">Candidate Name</th>
                  <th className="px-6 py-3  border-b">Post Name</th>
                  <th className="px-6 py-3  border-b">Designation</th>
                  <th className="px-6 py-3  border-b">Hiring Manager</th>
                  <th className="px-6 py-3  border-b">Time</th>
                  <th className="px-6 py-3  border-b">Project</th>
                  <th className="px-6 py-3  border-b">Status</th>
                </tr>
              </thead>
              <tbody className="divide-y divide-gray-200 text-xs">
                {mydata?.results?.map((el) => (
                  <tr key={el?.id} className="hover:bg-gray-50">
                    <td className="px-6 py-4 flex items-center space-x-3">
                      <div className="h-fit w-fit">
                        {el?.candidate?.profile_pic ? (
                          <div className="w-10 h-10">
                            <img
                              src={el?.candidate?.profile_pic}
                              alt="Profile"
                              className="w-10 h-10 rounded-full border-2 border-[#1CACBD] object-cover"
                            />
                          </div>
                        ) : (
                          <div className="uppercase text-xs text-[#1CACBD] border border-[#1CACBD] rounded-full w-10 h-10 flex justify-center items-center">
                            <span>{el?.candidate?.first_name?.charAt(0)}</span>
                            <span>{el?.candidate?.last_name?.charAt(0)}</span>
                          </div>
                        )}
                      </div>
                      <span className="text-gray-800 ">
                        {el?.candidate?.first_name} {el?.candidate?.last_name}
                      </span>
                    </td>
                    <td className="px-6 py-4  text-gray-700">
                      {el?.job?.title}
                    </td>
                    <td className="px-6 py-4  text-gray-700">--</td>
                    <td className="px-6 py-4  text-gray-700">
                      {el?.job?.account_manager?.first_name}{" "}
                      {el?.job?.account_manager?.last_name}
                    </td>
                    <td className="px-6 py-4  text-gray-700">--</td>
                    <td className="px-6 py-4  text-gray-700">--</td>
                    <td className="px-6 py-4">
                      {el?.status === "submitted" ? (
                        <span className="px-3 py-1  text-white bg-green-500 rounded-full">
                          Submitted
                        </span>
                      ) : (
                        <span className="px-3 py-1  text-white bg-yellow-500 rounded-full">
                          Shortlisted
                        </span>
                      )}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      )}
    </div>
  );
};

export default RecruiterDashboard;
