import React from "react";
import { useNavigate } from "react-router-dom";
import logo from "../../../assets/logo jiva hire.png";

function Navbar({ loginbutton = true }) {
  const navigate = useNavigate();

  return (
    <>
      <div className="w-full  bg-[#FFFFFF] shadow-sm  px-4  border-b border-b-[#EEEEEE] text-xs font-inter">
        <div className="w-full  mx-auto max-w-screen-lg flex justify-between items-center h-[50px]">
          <div
            className="w-[120px] h-[40px] cursor-pointer "
            onClick={() => navigate("/candidate/login")}
          >
            <img className="h-full w-full" src={logo} alt="" />
          </div>

          <div className="flex justify-between items-center gap-2">
            {loginbutton && (
              <button
                onClick={() => navigate("/candidate/login")}
                className="min-w-[80px]  font-semibold py-2 bg-[#1CACBD] text-white flex justify-center items-center border border-[#1CACBD] rounded-xl"
              >
                Login
              </button>
            )}
          </div>
        </div>
      </div>
    </>
  );
}

export default Navbar;
