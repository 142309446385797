// import React from 'react';
// import styles from './SupportPage.module.css';

// const SettingsPage = () => {
//   return (
//     <div className={styles.settingsPage}>
//       <header className={styles.settingsHeader}>
//         <h1>Settings</h1>
//       </header>

//       <section className={styles.profileSettings}>
//         <h2>Profile Settings</h2>
//         <form>
//           <input type="text" placeholder="Full Name" required />
//           <input type="email" placeholder="Email" required />
//           <input type="text" placeholder="Username" required />
//           <button type="submit">Update Profile</button>
//         </form>
//       </section>

//       <section className={styles.accountSettings}>
//         <h2>Account Settings</h2>
//         <form>
//           <label>
//             Change Password
//             <input type="password" placeholder="New Password" required />
//           </label>
//           <label>
//             Privacy Settings
//             <select>
//               <option>Public</option>
//               <option>Private</option>
//             </select>
//           </label>
//           <label>
//             Notifications
//             <input type="checkbox" /> Enable Email Notifications
//           </label>
//           <button type="submit">Save Settings</button>
//         </form>
//       </section>

//       <section className={styles.applicationSettings}>
//         <h2>Application Settings</h2>
//         <div>
//           <label>
//             Language
//             <select>
//               <option>English</option>
//               <option>Spanish</option>
//               <option>French</option>
//             </select>
//           </label>
//           <label>
//             Timezone
//             <select>
//               <option>GMT</option>
//               <option>EST</option>
//               <option>PST</option>
//             </select>
//           </label>
//         </div>
//       </section>

//       <footer className={styles.settingsFooter}>
//         <p>© 2024 Jivahire.com All rights reserved.</p>
//         <nav>
//           <a href="/#">Privacy Policy</a>
//           <a href="/#">Terms of Service</a>
//         </nav>
//       </footer>
//     </div>
//   );
// };

// export default SettingsPage;

import { Field, Form, Formik } from "formik";
import React from "react";
import * as Yup from "yup";
import ATMHeading from "../../atoms/ATMHeading/ATMHeading";
import ATMTextField from "../../atoms/ATMTextField/ATMTextField";
import ATMPassword from "../../atoms/ATMPassword/ATMPassword";
import ATMSelect from "../../atoms/ATMSelect/ATMSelect";

const SettingsPage = () => {
  const languageOptions = [
    { value: "english", label: "English" },
    { value: "spanish", label: "Spanish" },
    { value: "french", label: "French" },
  ];

  const timezoneOptions = [
    { value: "gmt", label: "GMT" },
    { value: "est", label: "EST" },
    { value: "pst", label: "PST" },
  ];

  const privacyOptions = [
    { value: "Public", label: "Public" },
    { value: "Private", label: "Private" },
  ];

  // Validation Schema using Yup
  const validationSchema = Yup.object({
    fullName: Yup.string().required("Full Name is required"),
    email: Yup.string().email("Invalid email").required("Email is required"),
    username: Yup.string().required("Username is required"),
    password: Yup.string().required("New Password is required"),
    privacy: Yup.string().required("Privacy setting is required"),
    language: Yup.object().required("Language is required"),
    timezone: Yup.object().required("Timezone is required"),
  });

  // Initial Values for the Form
  const initialValues = {
    fullName: "",
    email: "",
    username: "",
    password: "",
    privacy: "Public",
    language: null,
    timezone: null,
  };

  // Handle Form Submit
  const handleSubmit = (values) => {
    // Handle form submission
  };

  return (
    <div className="font-inter">
      <div className="border-b p-2">
        <ATMHeading>Settings</ATMHeading>
      </div>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        {({ values, setFieldValue }) => (
          <Form className="px-2 py-4 flex flex-col gap-4">
            {/* Profile Settings */}
            <div className="flex flex-col gap-4 border p-4 rounded-lg">
              <ATMHeading>Profile Settings</ATMHeading>
              <ATMTextField
                type="text"
                name="fullName"
                value={values.fullName || ""}
                label="Full Name"
                placeholder="Enter your Full Name"
                onChange={(e) => setFieldValue("fullName", e.target.value)}
              />
              <ATMTextField
                type="text"
                name="email"
                value={values.email || ""}
                label="Email"
                placeholder="Enter your Email"
                onChange={(e) => setFieldValue("email", e.target.value)}
              />
              <ATMTextField
                type="text"
                name="Username"
                value={values.Username || ""}
                label="User Name"
                placeholder="Enter your User Name"
                onChange={(e) => setFieldValue("Username", e.target.value)}
              />

              <button
                type="submit"
                className="w-full text-xs h-[40px] bg-[#1CACBD] text-white rounded-md"
              >
                Update Profile
              </button>
            </div>

            {/* Account Settings */}
            <div className="flex flex-col gap-4 border p-4 rounded-lg">
              <ATMHeading>Account Settings</ATMHeading>
              <ATMPassword
                name="password"
                value={values.password || ""}
                label="Password"
                placeholder="Enter your Password"
                onChange={(e) => setFieldValue("password", e.target.value)}
              />
              <ATMSelect
                name="privacy"
                label="Privacy"
                onChange={(newValue) => setFieldValue("privacy", newValue)}
                placeholder="Select privacy"
                value={values.privacy || ""}
                options={privacyOptions}
              />

              <div className="flex items-center text-xs  space-x-2">
                <span>Notification</span>
                <Field
                  type="checkbox"
                  name="notifications"
                  className="h-4 w-4"
                />
                <span>Enable Email Notifications</span>
              </div>

              <button
                type="button"
                className="w-full text-xs h-[40px] bg-[#1CACBD] text-white rounded-md"
              >
                Save
              </button>
            </div>

            {/* Application Settings */}
            <div className="flex flex-col gap-4 border p-4 rounded-lg">
              <ATMHeading>Application Settings</ATMHeading>
              <ATMSelect
                name="language"
                label="Language"
                onChange={(newValue) => setFieldValue("language", newValue)}
                placeholder="Select Language"
                value={values.language || ""}
                options={languageOptions}
              />
              <ATMSelect
                name="timezone"
                label="Timezone"
                onChange={(newValue) => setFieldValue("timezone", newValue)}
                placeholder="Select Timezone"
                value={values.timezone || ""}
                options={timezoneOptions}
              />
            </div>

            {/* Footer */}
            <footer className="text-xs  text-center text-gray-500">
              <p>© 2024 Jivahire.com All rights reserved.</p>
              <nav className="space-x-4">
                <a href="/#" className="text-blue-600 hover:underline">
                  Privacy Policy
                </a>
                <a href="/#" className="text-blue-600 hover:underline">
                  Terms of Service
                </a>
              </nav>
            </footer>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default SettingsPage;
