import React, { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { FaRegBookmark } from "react-icons/fa";
import { useNavigate, useParams } from "react-router";
import {
  useGetJobJpplicationQuery,
  useShortListMutation,
} from "../../app/DashboardServices/DashboardServices";
import ATMCircularProgress from "../../atoms/ATMCircularProgress/ATMCircularProgress";
import ATMHeading from "../../atoms/ATMHeading/ATMHeading";
import { formatDate } from "../candidate/ProfileDetails/View/ProfileDetails";
import "./Scores.css";

function ApplicationView() {
  const navigate = useNavigate();
  const handleBack = () => {
    navigate(-1);
  };

  const { id, id1, id2 } = useParams();
  const [data, setData] = useState();
  const [submit, setSubmit] = useState(false);
  const [isExpanded, setIsExpanded] = useState(false);
  const [expandedRows, setExpandedRows] = useState({});
  const [expandedanswerRows, setExpandedAnswerRows] = useState({});
  const toggleLlmAnswerFull = (index) => {
    setExpandedRows((prev) => ({
      ...prev,
      [index]: !prev[index],
    }));
  };
  const toggleAnswerFull = (index) => {
    setExpandedAnswerRows((prev) => ({
      ...prev,
      [index]: !prev[index],
    }));
  };

  const {
    data: view,
    isFetching,
    isLoading,
  } = useGetJobJpplicationQuery({
    pk: id,
    cand: id1,
    job: id2,
  });

  useEffect(() => {
    setData(view?.data["job application"]);
  }, [view]);

  const [shortlist] = useShortListMutation();

  const handleShortListed = () => {
    setSubmit(true);
    const shortQuery = {
      pk: id,
      cand: id1,
      job: id2,
    };
    shortlist(shortQuery).then((res) => {
      if (res?.error) {
        toast.error(res?.error?.data?.error);
        setSubmit(false);
      } else {
        if (res?.data?.success) {
          toast.success(res?.data?.message);
          setSubmit(false);
        } else {
          toast.error(res?.error?.data?.error);
          setSubmit(false);
        }
      }
    });
  };

  const truncateWords = (text, maxWords) => {
    if (!text) return "";
    const words = text.split(" ");
    return words.length > maxWords ? words.slice(0, maxWords).join(" ") : text;
  };
  const parts = data?.job?.description?.split("\n");

  const toggleSeeMore = () => {
    setIsExpanded((prevState) => !prevState);
  };

  const [totalScore1, setTotalScore1] = useState(0);
  const [totalScore2, setTotalScore2] = useState(0);

  // Calculate total score1 and score2 when data changes
  useEffect(() => {
    if (data?.ques_ans) {
      const score1Sum = data.ques_ans.reduce(
        (sum, item) => sum + (item?.score1 || 0),
        0
      );

      const score2Sum = data.ques_ans.reduce(
        (sum, item) => sum + (item?.score2 || 0),
        0
      );

      setTotalScore1(score1Sum);
      setTotalScore2(score2Sum);
    }
  }, [data]);
  return (
    <div className="h-full text-xs font-inter px-2 ">
      {!isFetching || !isLoading ? (
        <div className="flex flex-col  gap-2 ">
          <div className="flex justify-between items-center gap-8 ">
            <div className="">
              <h2 className="font-semibold text-[#101828] text-2xl  capitalize px-1 py-1 rounded-md flex items-center gap-2">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth={1.5}
                  stroke="currentColor"
                  className="size-6  cursor-pointer"
                  onClick={handleBack}
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M10.5 19.5 3 12m0 0 7.5-7.5M3 12h18"
                  />
                </svg>
                <ATMHeading> Application View </ATMHeading>
              </h2>
            </div>

            <FaRegBookmark className="text-xl text-[#000000B2]" />
          </div>

          <div className="grid grid-cols-1 xl:grid-cols-5 lg:grid-cols-3 md:grid-cols-2  justify-between items-center gap-4 border p-2 rounded-md flex-wrap ">
            <div>
              {data?.candidate?.profile_pic ? (
                <img
                  src={data?.candidate?.profile_pic}
                  alt=" Candidate Profile"
                  className="w-32 h-32 rounded-full border-2 border-[#1CACBD] object-cover"
                />
              ) : (
                <div className="uppercase  text-4xl font-semibold text-[#1CACBD] border rounded-full w-32 h-32  flex justify-center items-center  ">
                  <span className="">
                    {data?.candidate?.first_name?.charAt(0)}
                  </span>
                  <span className="">
                    {data?.candidate?.last_name?.charAt(0)}
                  </span>
                </div>
              )}
            </div>

            <div>
              <strong>Candidate Name</strong>
              <p>
                {data?.candidate?.first_name} {data?.candidate?.last_name}
              </p>
            </div>

            <div>
              <strong>Email Id</strong>
              <p>{data?.candidate?.email}</p>
            </div>

            <div className="text-center p-4 rounded-md bg-slate-100">
              <h1 className="text-green-600 text-3xl font-semibold">
                {((totalScore1 / 500) * 100)?.toFixed(0)}
                <span className="text-sm">/100</span>
              </h1>
              <p>Avg. Skill Score</p>
            </div>

            <div className="text-center p-4 rounded-md bg-slate-100">
              <h1 className="text-green-600 text-3xl font-semibold">
                {((totalScore2 / 500) * 100)?.toFixed(0)}
                <span className="text-sm">/100</span>
              </h1>
              <p>Avg. Communication Score</p>
            </div>
          </div>

          <div className="py-4 border rounded-lg flex flex-col gap-2 items-start justify-between border-[#F0EFEF] shadow-div">
            <div className="grid grid-cols-1 xl:grid-cols-4 lg:grid-cols-2 md:grid-cols-2  gap-4  px-8  justify-between w-full border-b py-2 ">
              <div>
                <strong>Job Title</strong>
                <p className="mt-1">{data?.job?.title}</p>
              </div>
              <div>
                <strong>Salary Offered</strong>
                <p className="mt-1">
                  {data?.job?.currency} {data?.job?.salary_from}-{" "}
                  {data?.job?.currency} {data?.job?.salary_to}
                </p>
              </div>
              <div>
                <strong>Job Location</strong>
                <p className="mt-1">
                  {data?.candidate?.state} {data?.candidate?.country}
                </p>
              </div>
              <div>
                <strong>Account Manager</strong>
                <p className="mt-1">
                  {data?.job?.account_manager?.first_name}{" "}
                  {data?.job?.account_manager?.last_name}
                </p>
              </div>
            </div>
            <div className="font-normal px-8 ">
              {parts
                ?.slice(0, isExpanded ? parts.length : 8)
                ?.map((part, index) => (
                  <div key={index}>{part}</div>
                ))}
              <button onClick={toggleSeeMore} className="text-[#1CACBD]">
                {isExpanded ? "....See Less" : "See More...."}
              </button>
            </div>
          </div>

          <div className="text-sm">
            {data?.ques_ans?.map((item, index) => {
              return (
                <div
                  key={index}
                  className=" grid grid-cols-2 lg:grid-cols-3   border rounded-md "
                >
                  <div className="border-r p-4 flex items-center">
                    {item.ans_type === "video-based" ? (
                      <div className="w-full">
                        {item?.video_path && (
                          <video
                            src={`${item?.video_path.replace(/\*/g, "/")}`}
                            controls
                            className="w-full h-auto rounded-lg"
                          />
                        )}
                      </div>
                    ) : (
                      <p className="text-gray-700">{item.answer}</p>
                    )}
                  </div>
                  <div className=" flex flex-col gap-1 p-2 text-center justify-between lg:col-span-2 text-[11px]">
                    <div className=" bg-gray-200">Q.No {index + 1} </div>
                    <div className="break-words"> {item.question}</div>
                    <div className="border">
                      <div className="grid grid-cols-3">
                        <div className="bg-gray-200 ">LLM Answer: </div>{" "}
                        <div className="bg-gray-200">
                          Frontend Text Answer:{" "}
                        </div>{" "}
                        <div className="bg-gray-200">Cloud Text Answer:</div>
                      </div>
                      <div className="grid grid-cols-3 text-slate-600 text-left">
                        <div className=" font-normal border-r  p-2 ">
                          {expandedRows[index]
                            ? item.llm_answer
                            : truncateWords(item.llm_answer, 50)}{" "}
                          {item?.llm_answer &&
                            item?.llm_answer.split(" ")?.length > 50 && (
                              <button
                                onClick={() => toggleLlmAnswerFull(index)}
                                className="text-teal-500"
                                type="button"
                              >
                                {expandedRows[index]
                                  ? " See Less"
                                  : " See More"}
                              </button>
                            )}
                        </div>
                        <div className=" font-normal  border-r p-2">
                          {expandedanswerRows[index]
                            ? item?.video_front_text
                            : truncateWords(item?.video_front_text, 50)}
                          {item?.video_front_text &&
                            item?.video_front_text.split(" ")?.length > 50 && (
                              <button
                                onClick={() => toggleAnswerFull(index)}
                                className="text-teal-500 ml-2 font-medium hover:text-teal-700"
                                type="button"
                              >
                                {expandedanswerRows[index]
                                  ? "  See Less"
                                  : "  See More"}
                              </button>
                            )}
                        </div>

                        <div className="font-normal  p-2">
                          {expandedanswerRows[index]
                            ? item?.answer
                            : truncateWords(item?.answer, 50)}{" "}
                          {item?.answer &&
                            item?.answer.split(" ")?.length >= 50 && (
                              <button
                                onClick={() => toggleAnswerFull(index)}
                                className="text-teal-500  font-medium hover:text-teal-700"
                                type="button"
                              >
                                {expandedanswerRows[index]
                                  ? "See Less"
                                  : "See More"}
                              </button>
                            )}
                        </div>
                      </div>
                    </div>
                    <div className="flex  gap-4 justify-center text-center text-xs">
                      <div className="border border-green-500 rounded-md w-44">
                        <h1 className="text-lg font-bold  text-[#1CACBD]">
                          {item?.score1}
                          <span className="text-xs text-gray-800 ">/100</span>
                        </h1>
                        <span className="text-gray-600">Skill Score</span>
                      </div>
                      <div className="border border-orange-500 rounded-md w-44">
                        <h1 className="text-lg font-bold text-[#1CACBD]">
                          {item?.score2}
                          <span className="text-xs  text-gray-800">/100</span>
                        </h1>
                        <span className="text-gray-600">
                          Communication Score
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>

          <div className="border rounded-lg flex flex-col gap-7 items-start justify-between border-[#F0EFEF] shadow-div w-full p-4">
            <div className="flex justify-between items-center gap-4 w-full">
              <h2 className="font-semibold text-sm">Timeline</h2>
              {!data?.Shortlisted ? (
                <button
                  type="button"
                  className=" text-[#1CACBD] border-[#1CACBD] border rounded-md h-[30px] w-[100px] flex justify-center items-center  "
                  onClick={handleShortListed}
                >
                  {submit ? (
                    <ATMCircularProgress size="small" color="white" />
                  ) : (
                    " ShortList"
                  )}
                </button>
              ) : (
                <button
                  type="button"
                  className="bg-[#1CACBD] text-white  rounded-md h-[30px] w-[100px] "
                >
                  ShortListed
                </button>
              )}
            </div>

            <ol className="items-center flex flex-col sm:flex-row w-full pr-10  px-4">
              {[
                {
                  event: "Jd Created",
                  date: `${formatDate(data?.job?.created_at)}`,
                  color: `${
                    data?.job?.created_at ? "bg-blue-600" : "bg-blue-50"
                  }`,
                },
                {
                  event: "Invitation sent",
                  date: `${formatDate(data?.Invited)}`,
                  color: `${data?.Invited ? "bg-blue-600" : "bg-blue-50"}`,
                },
                {
                  event: "Applied For Screening",
                  date: `${formatDate(data?.last_question_asked_at)}`,
                  color: `${
                    data?.last_question_asked_at ? "bg-blue-600" : "bg-blue-50"
                  }`,
                },
                {
                  event: "ShortListed",
                  date: `${formatDate(data?.Shortlisted)}`,
                  color: `${data?.Shortlisted ? "bg-blue-600" : "bg-blue-50"}`,
                },
              ]?.map((item, index) => (
                <li key={index} className="w-full">
                  <div className=" py-4 w-full">
                    <div className="flex items-start w-full">
                      <div
                        className={`py-1 rounded-r-md border w-full ml-[-5px] ${item?.color} `}
                      ></div>
                      <div className="flex justify-center">
                        <div className="w-3 ml-[-10px] h-3 bg-white rounded-full border z-10 relative"></div>
                        <div className="absolute mt-5 text-center text-[10px]">
                          <div>{item?.event}</div>
                          <div>{item?.date}</div>
                        </div>
                      </div>
                    </div>
                  </div>
                </li>
              ))}
            </ol>

            <div className="border-t p-2 text-[#6D758F] ">
              <h1 className="text-justify">
                Your comprehensive profile is a crucial part of our Application
                Tracking System, empowering our analysis for better matches.
                Your information is securely stored in our database, ensuring
                future opportunities align with your skills and preferences.
                Rest assured, we'll leverage this data to connect you with
                relevant positions.
              </h1>
            </div>
          </div>
        </div>
      ) : (
        <div className=" h-full flex justify-center  items-center">
          <ATMCircularProgress />
        </div>
      )}
    </div>
  );
}

export default ApplicationView;
