import React from "react";

const ATMHeading = ({ children, className }) => {
  return (
    <div
      className={`text-sm font-semibold text-slate-900 font-inter ${className}`}
    >
      {children}
    </div>
  );
};

export default ATMHeading;
