import React, { useEffect, useRef, useState } from "react";
import toast from "react-hot-toast";
import { FaAnglesRight } from "react-icons/fa6";
import { useParams } from "react-router";
import { ClockLoader } from "react-spinners";
import Webcam from "react-webcam";
import { axiosCandidateRequest } from "../../api/api";
import Navbar from "../candidate/Navbars/Navbar";
import Dailog from "./Dailog";

const baseUrl = process.env.REACT_APP_VITE_BACKEND_API_URL;

const VideoRecorder = () => {
  const { id } = useParams();
  const [hasPermissions, setHasPermissions] = useState(false);
  const [permissionLoading, setPermissionLoading] = useState(true);
  const [selectedCameraId, setSelectedCameraId] = useState("");
  const [error, setError] = useState("");
  const [timer, setTimer] = useState(0);
  const [canProceed, setCanProceed] = useState(false);
  const webcamRef = useRef(null);
  const timerInterval = useRef(null);
  const [questions, setQuestions] = useState([]);
  const [totalQuestions, setTotalQuestions] = useState(0);
  const [ansType, setAnsType] = useState("");
  const [textAns, setTextAns] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [isRecording, setIsRecording] = useState(false);
  const [nextRecordingStart, setNextRecordingStart] = useState(false);
  const [candidateData, setCandidateData] = useState(null);
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
  const [recordings, setRecordings] = useState({});
  const [count, setCount] = useState(60);
  const [isTime, setIsTime] = useState(true);
  const [timeUp, setTimeUp] = useState(false);
  const storedCandidateData = localStorage.getItem("candidate_profile");
  let [transcription, setTranscription] = useState("");
  const speechRecognitionRef = useRef(null);

  const initializeSpeechRecognition = () => {
    const SpeechRecognition =
      window.SpeechRecognition || window.webkitSpeechRecognition;
    if (!SpeechRecognition) {
      console.error("Speech recognition not supported");
      return;
    }

    const recognition = new SpeechRecognition();
    recognition.continuous = true;
    recognition.interimResults = true;
    recognition.lang = "en-US";

    recognition.onresult = (event) => {
      for (let i = event.resultIndex; i < event.results.length; i++) {
        const transcript = event.results[i][0].transcript;
        if (event.results[i].isFinal) {
          setTranscription((prev) => prev + " " + transcript);
        }
      }
    };

    recognition.onerror = (event) => {
      console.error("Speech recognition error:", event.error);
    };
  

    speechRecognitionRef.current = recognition;
  };

  useEffect(() => {
    const handleBeforeUnload = (event) => {
      event.preventDefault();
      event.returnValue =
        "If you reload this page, your application request will be canceled.";
    };

    window.addEventListener("beforeunload", handleBeforeUnload);

    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, []);

  const fetchQuestions = async () => {
    setIsLoading(true);
    try {
      const response = await axiosCandidateRequest(
        `${baseUrl}/api/candidate/start-test/${id}/`,
        {
          method: "GET",
        }
      );

      if (response.status === 200) {
        setIsRecording(true);
        setAnsType(response.data.data.ans_type);
        const updatedQuestions = [response.data.data.question];
        setQuestions(updatedQuestions);
        setTotalQuestions(response.data.data.total_questions);
        startTimer();
        if (response.data.data.ans_type !== "text-based") {
          startRecording(response.data.data.question);
        }
      } else {
        toast.error("Failed to fetch questions");
      }
    } catch (error) {
      toast.error(
        error.response?.data?.message || "An unexpected error occurred"
      );
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (storedCandidateData && storedCandidateData !== "undefined") {
      setCandidateData(JSON.parse(storedCandidateData));
    }
  }, [storedCandidateData]);

  useEffect(() => {
    const muteAllAudioOutput = async () => {
      try {
        await navigator.mediaDevices.enumerateDevices();
        const audioElements = document.getElementsByTagName("audio");
        Array.from(audioElements).forEach((audio) => {
          audio.muted = true;
        });

        if (typeof HTMLMediaElement.prototype.setSinkId === "function") {
          const elements = document.querySelectorAll("audio, video");
          elements.forEach(async (element) => {
            try {
              await element.setSinkId("");
            } catch (err) {
              console.warn("Failed to set audio output:", err);
            }
          });
        }
      } catch (err) {
        console.error("Error muting audio output:", err);
      }
    };

    muteAllAudioOutput();
  }, []);

  useEffect(() => {
    const checkPermissions = async () => {
      setPermissionLoading(true);
      try {
        const result = await navigator.mediaDevices.getUserMedia({
          video: {
            deviceId: selectedCameraId
              ? { exact: selectedCameraId }
              : undefined,
          },
          audio: {
            echoCancellation: true,
            noiseSuppression: true,
            autoGainControl: true,
            sampleRate: 48000,
            channelCount: 1,
          },
        });

        if (webcamRef.current?.video) {
          webcamRef.current.video.muted = true;
          webcamRef.current.video.volume = 0;
        }
        result.getTracks().forEach((track) => track.stop());

        setHasPermissions(true);
        await fetchCameraDevices();
      } catch (err) {
        console.error("Permission error:", err);
        setError("Camera and microphone access is required");
        setHasPermissions(false);
      } finally {
        setPermissionLoading(false);
      }
    };

    checkPermissions();
  }, [selectedCameraId]);

  const fetchCameraDevices = async () => {
    try {
      const devices = await navigator.mediaDevices.enumerateDevices();
      const cameras = devices.filter((device) => device.kind === "videoinput");
      if (cameras.length > 0) {
        setSelectedCameraId(cameras[0].deviceId);
      }
    } catch (err) {
      setError("Error fetching camera devices: " + err.message);
    }
  };

  const startRecording = async (question) => {
    const candidateId = candidateData?.id;
    const applicationId = id;

    try {
      const response = await fetch(
        "https://stagingapi.jivahire.com/stream/start/",
        {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({
            candidateId,
            applicationId,
            question,
          }),
        }
      );

      if (!response.ok) {
        throw new Error("Failed to initiate upload");
      }

      const { wsUrl, s3Key } = await response.json();
      const webSocket = new WebSocket(wsUrl);

      webSocket.onopen = () => {
        webSocket.send(
          JSON.stringify({
            s3Key,
            question,
          })
        );
      };

      webSocket.onmessage = (event) => {
        try {
          const data = JSON.parse(event.data);
          if (data.type === "videoPath") {
          } else if (data.type === "error") {
          }
        } catch (error) {
          console.error("Failed to parse WebSocket message:", event.data);
        }
      };

      webSocket.onerror = (error) => console.error("WebSocket error:", error);
      webSocket.onclose = () => console.log("WebSocket connection closed");

      initializeSpeechRecognition();
      if (speechRecognitionRef.current) {
        speechRecognitionRef.current.start();
      }
      const stream = await navigator.mediaDevices.getUserMedia({
        video: true,
        audio: true,
      });

      const recorder = new MediaRecorder(stream, {
        mimeType: "video/webm",
        videoBitsPerSecond: 2500000,
        audioBitsPerSecond: 128000,
      });

      let lastChunkTime = Date.now();

      recorder.ondataavailable = (event) => {
        if (event.data.size > 0) {
          const currentTime = Date.now();
          if (currentTime - lastChunkTime >= 2000) {
            if (webSocket && webSocket.readyState === WebSocket.OPEN) {
              webSocket.send(event.data);
              lastChunkTime = currentTime;
            }
          }
        }
      };

      recorder.start(2500);
      setIsRecording(true);

      setRecordings((prev) => ({
        ...prev,
        [question]: {
          recorder,
          webSocket,
          stream,
        },
      }));
    } catch (error) {
      console.error("Error starting recording:", error);
      toast.error("Failed to start recording");
    }
  };
  // const stopRecording = async (question) => {
  //   if (speechRecognitionRef.current) {
  //     speechRecognitionRef.current.stop();
  //   }
  //   const recordingContext = recordings[question];
  //   if (recordingContext) {
  //     const { recorder, webSocket, stream } = recordingContext;
  //     if (recorder && recorder.state !== "inactive") {
  //       await new Promise((resolve) => {
  //         const handleLastData = (event) => {
  //           if (
  //             event.data.size > 0 &&
  //             webSocket?.readyState === WebSocket.OPEN
  //           ) {
  //             webSocket.send(event.data);
  //           }
  //           resolve();
  //         };

  //         recorder.addEventListener("dataavailable", handleLastData, {
  //           once: true,
  //         });
  //         recorder.requestData();
  //       });

  //       recorder.stop();
  //     }

  //     await new Promise((resolve) => setTimeout(resolve, 500));

  //     if (webSocket) {
  //       webSocket.close();
  //     }

  //     if (stream) {
  //       stream.getTracks().forEach((track) => track.stop());
  //     }
  //     // Check if transcription is empty and if the last recorded time exceeds 1 second
  //     if (transcription.trim() === "") {
  //       // If transcription is empty, set it to an empty string
  //       transcription = "";
  //     }

  //     setRecordings((prev) => {
  //       const newRecordings = { ...prev };
  //       delete newRecordings[question];
  //       return newRecordings;
  //     });
  //   }

  //   clearInterval(timerInterval.current);
  // };
  async function stopRecording(question) {
    if (speechRecognitionRef.current) {
      speechRecognitionRef.current.stop();
    }

    const recordingContext = recordings[question];
    if (recordingContext) {
      const { recorder, webSocket, stream } = recordingContext;

      if (recorder && recorder.state !== "inactive") {
        await new Promise((resolve) => {
          const handleLastData = (event) => {
            if (
              event.data.size > 0 &&
              webSocket?.readyState === WebSocket.OPEN
            ) {
              webSocket.send(event.data);
            }
            resolve();
          };

          recorder.addEventListener("dataavailable", handleLastData, {
            once: true,
          });
          recorder.requestData();
        });

        recorder.stop();
      }

      await new Promise((resolve) => setTimeout(resolve, 500));

      if (webSocket) {
        webSocket.close();
      }

      if (stream) {
        stream.getTracks().forEach((track) => track.stop());
      }

      // Check if transcription is empty
      if (transcription.trim() === "") {
        // If transcription is empty, set it to an empty string
        transcription = ""; // This line is redundant since transcription is already an empty string
      }

      setRecordings((prev) => {
        const newRecordings = { ...prev };
        delete newRecordings[question];
        return newRecordings;
      });
    }

    clearInterval(timerInterval.current);
  }

  const startTimer = () => {
    setTimeUp(false);
    clearInterval(timerInterval.current);
    setTimer(0);
    const startTime = Date.now();

    timerInterval.current = setInterval(() => {
      const elapsedTime = Math.floor((Date.now() - startTime) / 1000);

      setTimer(elapsedTime);
      if (elapsedTime >= 30) {
        setCanProceed(true);
      }

      if (elapsedTime >= 300) {
        stopRecording(questions[currentQuestionIndex]);
        setTimeUp(true);
        clearInterval(timerInterval.current);
      }
    }, 1000);
  };

  const uploadVideo = async (value) => {
    clearInterval(timerInterval.current);
    setTimer(0);
    setCanProceed(false);
    if (value) return;
    setIsLoading(true);
    try {
      await nextQuestion();
    } catch (error) {
      console.error(
        "Error:",
        error.response ? error.response.data : error.message
      );
      throw error;
    } finally {
      setIsLoading(false);
    }
  };

  const nextQuestion = async () => {
    setIsLoading(true);
    clearInterval(timerInterval.current);
    setTimer(0);
    try {
      const currentQuestion = questions[currentQuestionIndex];
      await stopRecording(currentQuestion);

      const response = await axiosCandidateRequest(
        `${baseUrl}/api/candidate/start-test/${id}/`,
        {
          method: "PATCH",
          data: {
            qna_json: {
              question: currentQuestion,
              answer: "",
              video_path: `${id}*${candidateData?.id}*${currentQuestion.replace(
                /\?/g,
                "%3F"
              )}`,
              thumbnail_path: "",
              video_front_text: transcription,
            },
          },
        }
      );

      if (response.status === 200) {
        if (questions.length < totalQuestions) {
          const newQuestion = response.data.data.question;
          setQuestions((prev) => [...prev, newQuestion]);
          setAnsType(response.data.data.ans_type);
          setCurrentQuestionIndex((prev) => prev + 1);
          startTimer();
          setTranscription("");
          if (response.data.data.ans_type !== "text-based") {
            await startRecording(newQuestion);
          }
        } else {
          if (webcamRef.current && webcamRef.current.stream) {
            const tracks = webcamRef.current.stream.getTracks();
            tracks.forEach((track) => {
              track.stop();
              track.enabled = false;
            });
          }
          setIsRecording(false);
          setOpen(true);
          setTranscription("");
        }
      }
    } catch (error) {
      toast.error(
        error.response?.data?.message || "An unexpected error occurred"
      );
    } finally {
      setIsLoading(false);
    }
  };

  const nextQuestionText = async () => {
    setIsLoading(true);
    clearInterval(timerInterval.current);
    setTimer(0);
    try {
      const response = await axiosCandidateRequest(
        `${baseUrl}/api/candidate/start-test/${id}/`,
        {
          method: "PATCH",
          data: {
            qna_json: {
              question: questions[currentQuestionIndex],
              answer: textAns,
              video_path: "",
              thumbnail_path: "",
            },
          },
        }
      );

      if (response.status === 200) {
        if (questions.length < totalQuestions) {
          setQuestions((prev) => [...prev, response.data.data.question]);
          setCurrentQuestionIndex((prev) => prev + 1);
          setTextAns("");
          setAnsType(response.data.data.ans_type);

          if (response.data.data.ans_type !== "text-based") {
            await startRecording(response.data.data.question);
          }
          startTimer();
        } else {
          if (webcamRef.current && webcamRef.current.stream) {
            const tracks = webcamRef.current.stream.getTracks();
            tracks.forEach((track) => {
              track.stop();
              track.enabled = false;
            });
          }
          setIsRecording(false);

          setOpen(true);

          setTextAns("");
        }
      }
    } catch (error) {
      toast.error(
        error.response?.data?.message || "An unexpected error occurred"
      );
    } finally {
      setIsLoading(false);
    }
  };

  const formatTime = (time) => {
    const minutes = Math.floor(time / 60);
    const seconds = time % 60;
    return `${String(minutes).padStart(2, "0")}:${String(seconds).padStart(
      2,
      "0"
    )}`;
  };

  useEffect(() => {
    return () => {
      clearInterval(timerInterval.current);
      Object.keys(recordings).forEach((question) => {
        stopRecording(question);
      });
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useEffect(() => {
    if (count === 0) setIsTime(false);
    if (!open || count === 0) return;

    const timer = setInterval(() => {
      setCount((prevCount) => prevCount - 1);
    }, 1000);

    return () => clearInterval(timer);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [count, open]);

  if (permissionLoading) {
    return (
      <div className="fixed inset-0 bg-black/75 flex items-center justify-center">
        <ClockLoader color={"#F8EDED"} loading={true} size={150} />
      </div>
    );
  }

  return (
    <>
      <Dailog
        open={open}
        setOpen={setOpen}
        heading="Your application is successfully submitted"
        para="Your application is on its way! You can check your Job Applications for updates and manage your applications in one place."
        btnText="View Job Application"
        btnLink="/candidate/my-application"
        isTime={isTime}
        count={count}
      />

      <div className="w-full min-h-screen relative select-none">
        {isLoading && (
          <div className="absolute inset-0 flex items-center justify-center bg-black bg-opacity-75 z-50">
            <ClockLoader color={"#F8EDED"} loading={true} size={150} />
          </div>
        )}

        <Navbar loginbutton={false} />
        <div className="grid grid-cols-3 items-start  font-inter text-xs max-w-screen-2xl mx-auto gap-3 h-full p-2">
          <div className=" col-span-2   justify-center flex">
            <div className="w-[500px] gap-2 flex justify-center items-center flex-col">
              {error && <div className="text-red-500">{error}</div>}

              <h1 className="font-inter font-semibold text-2xl">
                {isRecording ? "" : "Get Started"}
              </h1>

              {isRecording ? (
                ""
              ) : (
                <>
                  <p className="font-inter font-medium text-sm">
                    Setup your webcam and Audio before start the recording
                  </p>
                  <p className="font-inter font-medium text-[#ffb443f5] text-sm">
                    After clicking on start recording you will be asked few
                    questions
                  </p>
                </>
              )}

              <div className="flex justify-start text-start w-full items-center gap-2">
                <svg
                  width="16"
                  height="16"
                  viewBox="0 0 19 19"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <circle
                    cx="9.69629"
                    cy="9.21484"
                    r="6.5"
                    fill="white"
                    stroke="#FF0000"
                    strokeWidth="5"
                  />
                </svg>
                <p className="text-xs">Rec {formatTime(timer)}</p>
              </div>

              {isRecording && (
                <div className="text-black p-3 rounded-lg font-semibold bg-[#5D5D5D5E] w-full text-center">
                  {questions.length > 0 && (
                    <p>
                      {currentQuestionIndex + 1}.{" "}
                      {questions[currentQuestionIndex]}
                    </p>
                  )}
                </div>
              )}

              <div className="flex justify-center relative w-full h-full">
                {ansType === "text-based" ? (
                  <div className="w-full h-full">
                    <textarea
                      value={textAns}
                      onChange={(e) => setTextAns(e.target.value)}
                      className="w-full h-full min-h-96 bg-transparent border text-white p-2 resize-none"
                      placeholder="Type your answer here..."
                    />
                  </div>
                ) : (
                  <Webcam
                    audio
                    ref={webcamRef}
                    className="w-[100%]  bg-slate-800"
                    screenshotFormat="image/jpeg"
                    muted={true}
                    volume={0}
                  />
                )}
                {timeUp && timer === 300 && (
                  <div className="  z-50  justify-center absolute w-full h-full flex items-center ">
                    <span className="bg-red-400 text-white p-5 capitalize">
                      Time Out! Move for next Question
                    </span>
                  </div>
                )}
              </div>

              {isRecording ? (
                <div className="w-full">
                  {nextRecordingStart ? (
                    <div className="flex justify-center">
                      {" "}
                      <button
                        onClick={() => {
                          setNextRecordingStart(false);
                          uploadVideo(false);
                        }}
                        className="px-4 text-xs h-[40px] text-white bg-[#F8000F] rounded-md"
                      >
                        Start Recording for Next Question
                      </button>
                    </div>
                  ) : (
                    <div
                      className={`w-full mt-1 flex text-white ${
                        timeUp ? "justify-center" : "justify-between"
                      } items-center `}
                    >
                      {!timeUp && (
                        <button
                          onClick={() => {
                            stopRecording(questions[currentQuestionIndex]);
                            setTimeUp(true);
                          }}
                          disabled={!canProceed}
                          className={` w-[130px] ${
                            canProceed
                              ? "bg-[#F8000F]"
                              : "cursor-not-allowed bg-red-400"
                          } flex gap-2 items-center text-center justify-center rounded-lg p-2 px-5`}
                        >
                          Stop Recording
                        </button>
                      )}

                      {questions.length === totalQuestions ? (
                        <button
                          onClick={
                            ansType === "text-based"
                              ? nextQuestionText
                              : () => {
                                  stopRecording(
                                    questions[currentQuestionIndex]
                                  );
                                  uploadVideo(false);
                                }
                          }
                          disabled={!canProceed}
                          className={`bg-[#1CACBD] w-[130px] ${
                            canProceed ? "" : "cursor-not-allowed bg-[#9ad6dc]"
                          } flex gap-2 items-center text-center justify-center rounded-lg p-2 px-5`}
                        >
                          Submit
                          <FaAnglesRight />
                        </button>
                      ) : (
                        <button
                          onClick={
                            ansType === "text-based"
                              ? nextQuestionText
                              : () => {
                                  setNextRecordingStart(true);
                                  stopRecording(
                                    questions[currentQuestionIndex]
                                  );
                                  uploadVideo(true);
                                }
                          }
                          disabled={!canProceed}
                          className={`bg-[#1CACBD] w-[130px] ${
                            canProceed ? "" : "cursor-not-allowed bg-[#9ad6dc]"
                          } flex gap-2 items-center text-center justify-center rounded-lg p-2 px-5`}
                        >
                          Save & Next
                          <FaAnglesRight />
                        </button>
                      )}
                    </div>
                  )}
                </div>
              ) : (
                <div className="w-full flex justify-center mt-2">
                  <button
                    onClick={fetchQuestions}
                    className="px-4 text-xs h-[40px] text-white bg-[#F8000F] rounded-md"
                    disabled={!hasPermissions}
                  >
                    Start Recording
                  </button>
                </div>
              )}
            </div>
          </div>

          {isRecording && (
            <div className="h-fit p-3 rounded-md">
              <div className="bg-[#81BAFF17] h-fit p-3 rounded-md">
                <div>
                  Questions ({questions.length}/{totalQuestions})
                </div>
                {questions.map((question, index) => (
                  <ul key={index} className="ml-4 py-3 flex flex-col gap-3">
                    <li
                      className={`bg-[#4A5767] ${
                        index === currentQuestionIndex
                          ? "text-white"
                          : "text-[#8e9194]"
                      } p-2 rounded-lg`}
                    >
                      {index + 1}. {question}
                    </li>
                  </ul>
                ))}
              </div>

              <p className="m-2">
                You have to answer{" "}
                <strong>{totalQuestions - questions.length}</strong> more
                questions before submitting the application
              </p>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default VideoRecorder;

// import React, { useEffect, useRef, useState } from "react";
// import toast from "react-hot-toast";
// import { FaAnglesRight } from "react-icons/fa6";
// import { useParams } from "react-router";
// import { ClockLoader } from "react-spinners";
// import Webcam from "react-webcam";
// import { axiosCandidateRequest } from "../../api/api";
// import { axiosCandidateFromRequest } from "../../api/formApi";
// import Navbar from "../candidate/Navbars/Navbar";
// import Dailog from "./Dailog";

// const baseUrl = process.env.REACT_APP_VITE_BACKEND_API_URL;
// const sThreeUrl = process.env.REACT_APP_VITE_VIDEO_API_URL;

// const VideoRecorder = () => {
//   const { id } = useParams();
//   const [isRecording, setIsRecording] = useState(false);
//   const [hasPermissions, setHasPermissions] = useState(false);
//   const [permissionLoading, setPermissionLoading] = useState(true);
//   const [selectedCameraId, setSelectedCameraId] = useState("");
//   const [error, setError] = useState("");
//   const [timer, setTimer] = useState(0);
//   const [canProceed, setCanProceed] = useState(false);
//   const webcamRef = useRef(null);
//   const mediaRecorderRef = useRef(null);
//   const recordedChunks = useRef([]);
//   const timerInterval = useRef(null);
//   const [questions, setQuestions] = useState([]);
//   const [totalQuestions, setTotalQuestions] = useState(0);
//   const [ansType, setAnsType] = useState("");
//   const [textAns, setTextAns] = useState("");
//   const [isLoading, setIsLoading] = useState(false);
//   const [open, setOpen] = useState(false);
//   useEffect(() => {
//     const muteAllAudioOutput = async () => {
//       try {
//         // Get all audio output devices
//         const devices = await navigator.mediaDevices.enumerateDevices();
//         const audioOutputs = devices.filter(
//           (device) => device.kind === "audiooutput"
//         );

//         // Mute all audio elements on the page
//         const audioElements = document.getElementsByTagName("audio");
//         Array.from(audioElements).forEach((audio) => {
//           audio.muted = true;
//         });

//         // If browser supports setSinkId, set it to 'none'
//         if (typeof HTMLMediaElement.prototype.setSinkId === "function") {
//           audioOutputs.forEach(async (device) => {
//             const elements = document.querySelectorAll("audio, video");
//             elements.forEach(async (element) => {
//               try {
//                 await element.setSinkId("");
//               } catch (err) {
//                 console.warn("Failed to set audio output:", err);
//               }
//             });
//           });
//         }
//       } catch (err) {
//         console.error("Error muting audio output:", err);
//       }
//     };

//     muteAllAudioOutput();
//   }, []);
//   // Initial permission check
//   useEffect(() => {
//     const checkPermissions = async () => {
//       setPermissionLoading(true);
//       try {
//         const result = await navigator.mediaDevices.getUserMedia({
//           video: {
//             deviceId: selectedCameraId
//               ? { exact: selectedCameraId }
//               : undefined,
//           },
//           audio: {
//             echoCancellation: true,
//             noiseSuppression: true,
//             autoGainControl: true,
//             sampleRate: 48000,
//             channelCount: 1,
//           },
//         });

//         // Ensure audio output is muted
//         if (webcamRef.current?.video) {
//           webcamRef.current.video.muted = true;
//           webcamRef.current.video.volume = 0;
//         }
//         // Stop the test stream
//         result.getTracks().forEach((track) => track.stop());

//         setHasPermissions(true);
//         await fetchCameraDevices();
//       } catch (err) {
//         console.error("Permission error:", err);
//         setError("Camera and microphone access is required");
//         setHasPermissions(false);
//       } finally {
//         setPermissionLoading(false);
//       }
//     };

//     checkPermissions();
//   }, [selectedCameraId]);

//   const fetchCameraDevices = async () => {
//     try {
//       const devices = await navigator.mediaDevices.enumerateDevices();
//       const cameras = devices.filter((device) => device.kind === "videoinput");
//       if (cameras.length > 0) {
//         setSelectedCameraId(cameras[0].deviceId);
//       }
//     } catch (err) {
//       setError("Error fetching camera devices: " + err.message);
//     }
//   };

//   const startRecording = async () => {
//     try {
//       if (webcamRef.current?.video?.srcObject) {
//         webcamRef.current.video.srcObject
//           .getTracks()
//           .forEach((track) => track.stop());
//       }

//       const stream = await navigator.mediaDevices.getUserMedia({
//         video: {
//           deviceId: selectedCameraId ? { exact: selectedCameraId } : undefined,
//         },
//         audio: {
//           echoCancellation: true,
//           noiseSuppression: true,
//           autoGainControl: true,
//           sampleRate: 48000,
//           channelCount: 1,
//         },
//       });

//       webcamRef.current.video.srcObject = stream;
//       webcamRef.current.video.muted = true;
//       webcamRef.current.video.volume = 0;

//       mediaRecorderRef.current = new MediaRecorder(stream, {
//         mimeType: "video/webm",
//         audioBitsPerSecond: 128000,
//         videoBitsPerSecond: 2500000,
//       });

//       recordedChunks.current = [];

//       mediaRecorderRef.current.ondataavailable = (event) => {
//         if (event.data.size > 0) {
//           recordedChunks.current.push(event.data);
//         }
//       };

//       mediaRecorderRef.current.start(1000);
//       setIsRecording(true);
//       setError("");
//       setCanProceed(false);
//       startTimer();

//       // Add event listener for when audio output devices change
//       navigator.mediaDevices.ondevicechange = async () => {
//         const devices = await navigator.mediaDevices.enumerateDevices();
//         devices.forEach((device) => {
//           if (device.kind === "audiooutput") {
//             // Ensure any new audio output devices are also muted
//             if (
//               webcamRef.current?.video &&
//               typeof webcamRef.current.video.setSinkId === "function"
//             ) {
//               webcamRef.current.video.setSinkId("");
//             }
//           }
//         });
//       };

//       // Error handling for media recorder
//       mediaRecorderRef.current.onerror = (event) => {
//         console.error("MediaRecorder error:", event);
//         setError("Recording error: " + event.error.message);
//       };
//     } catch (err) {
//       console.error("Error starting recording:", err);
//       setError("Error starting recording: " + err.message);

//       // Attempt to clean up any partial streams
//       if (webcamRef.current?.video?.srcObject) {
//         webcamRef.current.video.srcObject
//           .getTracks()
//           .forEach((track) => track.stop());
//       }
//     }
//   };

//   const stopRecording = async () => {
//     if (mediaRecorderRef.current?.state === "recording") {
//       return new Promise((resolve) => {
//         mediaRecorderRef.current.onstop = async () => {
//           const blob = new Blob(recordedChunks.current, { type: "video/webm" });

//           if (webcamRef.current?.video?.srcObject) {
//             webcamRef.current.video.srcObject
//               .getTracks()
//               .forEach((track) => track.stop());
//             webcamRef.current.video.srcObject = null;
//           }

//           resolve(blob);
//         };

//         mediaRecorderRef.current.stop();
//         clearInterval(timerInterval.current);
//       });
//     }
//     return null;
//   };

//   const startTimer = () => {
//     clearInterval(timerInterval.current);
//     setTimer(0);
//     timerInterval.current = setInterval(() => {
//       setTimer((prev) => {
//         const newTime = prev + 1;
//         if (newTime >= 5) {
//           setCanProceed(true);
//         }
//         if (newTime >= 300) {
//           // clearInterval(timerInterval.current);
//           // stopRecording().then((blob) => {
//           //   if (blob) uploadVideo(blob);
//           // });
//           stopRecording();
//         }
//         return newTime;
//       });
//     }, 1000);
//   };

//   const fetchQuestions = async () => {
//     setQuestions([]);
//     setIsLoading(true);
//     try {
//       const response = await axiosCandidateRequest(
//         `${baseUrl}/api/candidate/start-test/${id}/`,
//         {
//           method: "GET",
//         }
//       );

//       if (response.status === 200) {
//         setIsRecording(true);
//         setAnsType(response.data.data.ans_type);
//         const updatedQuestions = [response.data.data.question];
//         setQuestions(updatedQuestions);
//         setTotalQuestions(response.data.data.total_questions);
//         startTimer();
//         if (response.data.data.ans_type !== "text-based") {
//           startRecording();
//         }
//       } else {
//         toast.error("Failed to fetch questions");
//       }
//     } catch (error) {
//       toast.error(
//         error.response?.data?.message || "An unexpected error occurred"
//       );
//     } finally {
//       setIsLoading(false);
//     }
//   };

//   const uploadVideo = async () => {
//     const blob = new Blob(recordedChunks.current, { type: "video/webm" });
//     setIsLoading(true);
//     clearInterval(timerInterval.current);
//     setTimer(0);

//     const formData = new FormData();
//     formData.append("application_id", id);
//     formData.append("candidate_id", id);
//     formData.append("bucket_name", "jivahire-job-applications");
//     formData.append("file", blob, `${questions[questions.length - 1]}.webm`);

//     try {
//       const response = await axiosCandidateFromRequest(
//         `${sThreeUrl}/job-application-upload-video/?x_api_key=ZBFezMmBuh2pkEXttdz6SwOeMgGGsG2b`,
//         {
//           method: "POST",
//           data: formData,
//         }
//       );

//       if (response.status === 200) {
//         await nextQuestion(response.data.s3_url);
//       } else {
//         toast.error("Failed to upload video");
//       }
//     } catch (error) {
//       toast.error(
//         error.response?.data?.message || "An unexpected error occurred"
//       );
//     } finally {
//       setIsLoading(false);
//     }
//   };
//   const nextQuestion = async (url) => {
//     setIsLoading(true);
//     try {
//       const response = await axiosCandidateRequest(
//         `${baseUrl}/api/candidate/start-test/${id}/`,
//         {
//           method: "PATCH",
//           data: {
//             qna_json: {
//               question: questions[questions.length - 1],
//               answer: "",
//               video_path: url,
//               thumbnail_path: "",
//             },
//           },
//         }
//       );

//       if (response.status === 200) {
//         if (questions.length < totalQuestions) {
//           setQuestions((prevQuestions) => [
//             ...prevQuestions,
//             response.data.data.question,
//           ]);
//           setAnsType(response.data.data.ans_type);
//           recordedChunks.current = [];

//           if (response.data.data.ans_type !== "text-based") {
//             const stream = await navigator.mediaDevices.getUserMedia({
//               video: true,
//               audio: true,
//             });
//             webcamRef.current.video.srcObject = stream;
//             await startRecording();
//           }
//           startTimer();
//         } else {
//           setIsRecording(false);
//           setOpen(true);
//         }
//       } else {
//         toast.error("Failed to update data");
//       }
//     } catch (error) {
//       toast.error(
//         error.response?.data?.message || "An unexpected error occurred"
//       );
//     } finally {
//       setIsLoading(false);
//     }
//   };

//   const nextQuestionText = async () => {
//     setIsLoading(true);
//     clearInterval(timerInterval.current);
//     setTimer(0);
//     try {
//       const response = await axiosCandidateRequest(
//         `${baseUrl}/api/candidate/start-test/${id}/`,
//         {
//           method: "PATCH",
//           data: {
//             qna_json: {
//               question: questions[questions.length - 1],
//               answer: textAns,
//               video_path: "",
//               thumbnail_path: "",
//             },
//           },
//         }
//       );

//       if (response.status === 200) {
//         if (questions.length < totalQuestions) {
//           setQuestions((prevQuestions) => [
//             ...prevQuestions,
//             response.data.data.question,
//           ]);
//           setTextAns("");
//           setAnsType(response.data.data.ans_type);
//           recordedChunks.current = [];

//           if (response.data.data.ans_type !== "text-based") {
//             await startRecording();
//           }
//           startTimer();
//         } else {
//           setIsRecording(false);
//           setTextAns("");
//           setOpen(true);
//         }
//       } else {
//         toast.error("Failed to update data");
//       }
//     } catch (error) {
//       toast.error(
//         error.response?.data?.message || "An unexpected error occurred"
//       );
//     } finally {
//       setIsLoading(false);
//     }
//   };

//   const formatTime = (time) => {
//     const minutes = Math.floor(time / 60);
//     const seconds = time % 60;
//     return `${String(minutes).padStart(2, "0")}:${String(seconds).padStart(
//       2,
//       "0"
//     )}`;
//   };
//   // Cleanup on unmount
//   // useEffect(() => {
//   //   return () => {
//   //     clearInterval(timerInterval.current);
//   //     if (webcamRef.current?.video?.srcObject) {
//   //       webcamRef.current.video.srcObject
//   //         .getTracks()
//   //         .forEach((track) => track.stop());
//   //     }
//   //     if (mediaRecorderRef.current?.state === "recording") {
//   //       mediaRecorderRef.current.stop();
//   //     }
//   //   };
//   // }, []);

//   useEffect(() => {
//     // Store the current webcamRef and mediaRecorderRef values in local variables
//     const webcamRefCurrent = webcamRef.current;
//     const mediaRecorderRefCurrent = mediaRecorderRef.current;

//     // Cleanup on unmount
//     return () => {
//       clearInterval(timerInterval.current);
//       if (webcamRefCurrent?.video?.srcObject) {
//         webcamRefCurrent.video.srcObject
//           .getTracks()
//           .forEach((track) => track.stop());
//       }
//       if (mediaRecorderRefCurrent?.state === "recording") {
//         mediaRecorderRefCurrent.stop();
//       }
//     };
//   }, []);

//   // Handle page visibility changes
//   useEffect(() => {
//     const handleVisibilityChange = async () => {
//       if (document.visibilityState === "hidden") {
//         clearInterval(timerInterval.current);
//         await stopRecording();
//       }
//     };

//     document.addEventListener("visibilitychange", handleVisibilityChange);
//     return () => {
//       document.removeEventListener("visibilitychange", handleVisibilityChange);
//     };
//   }, []);

//   if (permissionLoading) {
//     return (
//       <div className="fixed inset-0 bg-black/75 flex items-center justify-center">
//         <ClockLoader color={"#F8EDED"} loading={true} size={150} />
//       </div>
//     );
//   }

//   return (
//     <>
//       <Dailog
//         open={open}
//         setOpen={setOpen}
//         heading="Your application is successfully submitted"
//         para="Your application is on its way! You can check your Job Applications for updates and manage your applications in one place."
//         btnText="View Job Application"
//         btnLink="/candidate/my-application"
//       />

//       <div className="w-full  min-h-screen relative ">
//         {isLoading && (
//           <div className="absolute inset-0 flex items-center justify-center bg-black bg-opacity-75 z-50">
//             <ClockLoader color={"#F8EDED"} loading={true} size={150} />
//           </div>
//         )}

//         <Navbar loginbutton={false} />
//         <div className="flex justify-center flex-wrap font-inter text-xs max-w-screen-2xl mx-auto gap-3 h-full pt-2">
//           <div className="flex    justify-center gap-2 flex-col p-4">
//             <div className="w-[400px] gap-2 flex justify-center items-center flex-col">
//               {error && <div className="text-red-500">{error}</div>}

//               <h1 className="font-inter font-semibold  text-2xl">
//                 {isRecording ? "" : "Get Started"}
//               </h1>

//               {isRecording ? (
//                 // <div className="flex justify-between  items-center py-3 border-b w-full">
//                 //   <p className="bg-[#354657] text-white rounded-lg p-1 w-full text-center">

//                 //     <span className="text-lg">{totalQuestions}</span> Questions For This Job Application
//                 //   </p>
//                 // </div>
//                 ""
//               ) : (
//                 <>
//                   <p className="font-inter font-medium text-sm">
//                     Setup your webcam and Audio before start the recording
//                   </p>
//                   <p className="font-inter font-medium text-[#ffb443f5] text-sm">
//                     After clicking on start recording you will be asked few
//                     questions
//                   </p>
//                 </>
//               )}

//               <div className="flex justify-start text-start w-full items-center gap-2">
//                 <svg
//                   width="16"
//                   height="16"
//                   viewBox="0 0 19 19"
//                   fill="none"
//                   xmlns="http://www.w3.org/2000/svg"
//                 >
//                   <circle
//                     cx="9.69629"
//                     cy="9.21484"
//                     r="6.5"
//                     fill="white"
//                     stroke="#FF0000"
//                     strokeWidth="5"
//                   />
//                 </svg>
//                 <p className=" text-xs">Rec {formatTime(timer)}</p>
//               </div>

//               {isRecording && (
//                 <div className="text-black p-3 rounded-lg font-semibold bg-[#5D5D5D5E] w-full text-center">
//                   {questions.length > 0 && (
//                     <p>
//                       {questions.length}. {questions[questions.length - 1]}
//                     </p>
//                   )}
//                 </div>
//               )}

//               <div className=" flex justify-center ">
//                 {ansType === "text-based" ? (
//                   <div className="w-full h-full">
//                     <textarea
//                       value={textAns}
//                       onChange={(e) => setTextAns(e.target.value)}
//                       className="w-full h-full min-h-96 bg-transparent border text-white p-2 resize-none"
//                       placeholder="Type your answer here..."
//                     />
//                   </div>
//                 ) : (
//                   <Webcam
//                     audio
//                     ref={webcamRef}
//                     className=" w-[100%] bg-slate-800"
//                     screenshotFormat="image/jpeg"
//                     muted={true} // Add this line
//                     volume={0}
//                   />
//                 )}
//               </div>

//               {isRecording ? (
//                 <div
//                   className={`w-full mt-1 flex text-white ${
//                     ansType === "text-based" ? "justify-end" : "justify-between"
//                   } items-center justify-end`}
//                 >
//                   {ansType !== "text-based" && (
//                     <button
//                       onClick={stopRecording}
//                       disabled={!canProceed}
//                       className={`bg-[#F8000F] w-[130px]  ${
//                         canProceed ? "" : "cursor-not-allowed bg-red-400"
//                       } flex gap-2 items-center text-center justify-center rounded-lg p-2 px-5`}
//                     >
//                       Stop Recording
//                     </button>
//                   )}

//                   <button
//                     onClick={
//                       ansType === "text-based"
//                         ? nextQuestionText
//                         : () => stopRecording().then(uploadVideo)
//                     }
//                     disabled={!canProceed}
//                     className={`bg-[#1CACBD] w-[130px]  ${
//                       canProceed ? "" : "cursor-not-allowed bg-[#9ad6dc]"
//                     } flex gap-2 items-center text-center justify-center rounded-lg p-2 px-5`}
//                   >
//                     {questions.length === totalQuestions
//                       ? "Submit"
//                       : "Save & Next"}{" "}
//                     <FaAnglesRight />
//                   </button>
//                 </div>
//               ) : (
//                 <div className="w-full flex justify-center mt-2">
//                   <button
//                     onClick={fetchQuestions}
//                     className="px-4 text-xs h-[40px] text-white bg-[#F8000F] rounded-md"
//                     disabled={!hasPermissions}
//                   >
//                     Start Recording
//                   </button>
//                 </div>
//               )}
//             </div>
//           </div>

//           {isRecording && (
//             <div className=" h-fit  p-3 rounded-md">
//               <>
//                 <div className="bg-[#81BAFF17] h-fit  p-3 rounded-md">
//                   <div>
//                     Questions ({questions.length}/{totalQuestions})
//                   </div>
//                   {questions.map((question, index) => (
//                     <ul key={index} className="ml-4 py-3 flex flex-col gap-3">
//                       <li
//                         className={`bg-[#4A5767] ${
//                           index === questions.length - 1
//                             ? "text-white"
//                             : "text-[#8e9194]"
//                         } p-2 rounded-lg`}
//                       >
//                         {index + 1}. {question}
//                       </li>
//                     </ul>
//                   ))}
//                 </div>

//                 <p className="m-2">
//                   You have to answer{" "}
//                   <strong>{totalQuestions - questions.length}</strong> more
//                   questions before submitting the application
//                 </p>
//               </>
//             </div>
//           )}
//         </div>
//       </div>
//     </>
//   );
// };

// export default VideoRecorder;
