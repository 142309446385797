import { ErrorMessage } from "formik";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import ATMButton from "../../../../atoms/ATMButton/ATMButton";
import ATMFieldError from "../../../../atoms/ATMFieldError/ATMFieldError";
import ATMFieldLabel from "../../../../atoms/ATMFieldLabel/ATMFieldLabel";
import ATMMultiSelect from "../../../../atoms/ATMMultiSelect/ATMMultiSelect";
import ATMSelect from "../../../../atoms/ATMSelect/ATMSelect";
import ATMTextField from "../../../../atoms/ATMTextField/ATMTextField";
import VideoRecorderModal from "../../../../components/candidate/VideoRecorderModal";

const employmendata = ["full-time", "part-time"];
const experienceTypeData = [
  { value: 1, label: "Corporate Experience" },
  { value: 2, label: "Freelancing Experience" },
  { value: 3, label: "Transition Break" },
  { value: 4, label: "Volunteering Experience" },
];

const skills = [
  { value: "Data Science", label: "Data Science" },
  { value: "Market Research", label: "Market Research" },
  { value: "Java++", label: "Java++" },
  { value: "User Research", label: "User Research" },
  { value: "Python", label: "Python" },
];
const WorkExperienceFormLayout = ({
  formikProps,
  formType,
  workExperienceData,
}) => {
  const navigate = useNavigate();
  const [videoBlob, setVideoBlob] = useState("");
  const url = videoBlob ? URL.createObjectURL(videoBlob) : "";
  const { values, setFieldValue, handleBlur, isSubmitting } = formikProps;

  const [modalIsOpen, setModalIsOpen] = useState(false);

  const toggleModal = () => {
    setModalIsOpen(!modalIsOpen);
  };

  useEffect(() => {
    setFieldValue("blob", videoBlob);
    // eslint-disable-next-line
  }, [videoBlob]);

  return (
    <div className="flex flex-col gap-4 ">
      <div className=" grid grid-cols-1  md:grid-cols-2 p-9  justify-between  gap-10 border-[1px] rounded-lg border-[#E2DCEA]">
        <div className="">
          <ATMSelect
            name="experienceType"
            label="What kind of experience do you have?"
            onChange={(newValue) => setFieldValue("experienceType", newValue)}
            placeholder="Select Experience Type"
            value={values.experienceType || ""}
            options={experienceTypeData}
          />
        </div>

        <div>
          <ATMTextField
            type="text"
            name="companyName"
            value={values.companyName || ""}
            label="Company Name"
            placeholder="Enter your Company Name"
            onChange={(e) => setFieldValue("companyName", e.target.value)}
            onBlur={handleBlur}
          />
        </div>
        <div>
          <ATMTextField
            type="text"
            name="designation"
            value={values.designation || ""}
            label="Designation / Job Title"
            placeholder="Enter your Job Title"
            onChange={(e) => setFieldValue("designation", e.target.value)}
            onBlur={handleBlur}
          />
        </div>

        <div>
          <ATMFieldLabel>Job role</ATMFieldLabel>
          <div className="flex items-center gap-4 mt-2">
            {employmendata?.map((el) => {
              return (
                <div key={el} className="flex items-center gap-2">
                  <div>
                    <input
                      type="radio"
                      value={el}
                      checked={values?.employmentType === el}
                      onChange={(e) =>
                        setFieldValue("employmentType", e.target.value)
                      }
                    />
                  </div>
                  <div className="capitalize">{el}</div>
                </div>
              );
            })}
          </div>
        </div>

        {/* Date of Birth */}
        <div className=" ">
          <ATMFieldLabel>Duration</ATMFieldLabel>
          <div className="flex items-center gap-2">
            <input
              type="date"
              className="w-full px-3 py-2 border min-w-[200px] border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-indigo-500 "
              name="durationFrom"
              value={values.durationFrom}
              onChange={(e) => setFieldValue("durationFrom", e.target.value)}
              onBlur={handleBlur}
            />
            <div>To</div>
            <input
              type="date"
              className="w-full px-3 py-2 border min-w-[200px] border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-indigo-500 "
              name="durationTo"
              value={values.durationTo}
              onChange={(e) => setFieldValue("durationTo", e.target.value)}
              onBlur={handleBlur}
              min={values?.durationFrom}
            />
          </div>
          <div className="relative">
            <ErrorMessage name="dob">
              {(errorMessage) => (
                <ATMFieldError> {errorMessage} </ATMFieldError>
              )}
            </ErrorMessage>
          </div>
        </div>
      </div>

      <div className="  p-9 rounded-lg border-[1px] border-[#E2DCEA]">
        <div className="grid grid-cols-1 md:grid-cols-2   gap-4">
          {/* Profile Summary */}
          <div className=" relative">
            <ATMFieldLabel> Experience Summary</ATMFieldLabel>
            <textarea
              placeholder="Write a brief summary about yourself..."
              className=" w-full h-[223px] border-[1px] border-[#E2DCEA] rounded-lg p-3 outline-none"
              name="profileSummary"
              value={values.profileSummary || ""}
              onChange={(e) => setFieldValue("profileSummary", e.target.value)}
              onBlur={handleBlur}
            ></textarea>
            <ErrorMessage name="profileSummary">
              {(errorMessage) => (
                <ATMFieldError> {errorMessage} </ATMFieldError>
              )}
            </ErrorMessage>
          </div>
          {/* Introduction Video */}
          <div className="min-w-[300px]">
            <div>
              <ATMFieldLabel> Upload Your Experience Video</ATMFieldLabel>
            </div>{" "}
            <div className="relative flex justify-around items-center p-2  w-[290px]  h-[222.77px] border-[1px] border-[#E2DCEA] rounded-md">
              {videoBlob || workExperienceData?.upload_work_video ? (
                <span
                  onClick={toggleModal}
                  className="absolute top-[-28px] right-0 rounded-md cursor-pointer bg-red-600 text-white text-[11px] p-1"
                >
                  Re-record
                </span>
              ) : (
                ""
              )}
              {videoBlob ? (
                videoBlob ? (
                  <video
                    src={url}
                    controls
                    className="h-full w-full rounded-lg"
                  />
                ) : (
                  <video
                    className="h-full w-full rounded-lg"
                    controls
                    src={`https://d27coln91ik106.cloudfront.net/${workExperienceData?.upload_work_video?.replace(
                      /\*/g,
                      "/"
                    )}`}
                  ></video>
                )
              ) : workExperienceData?.upload_work_video ? (
                <video
                  className="h-full w-full rounded-lg"
                  controls
                  src={`https://d27coln91ik106.cloudfront.net/${workExperienceData?.upload_work_video?.replace(
                    /\*/g,
                    "/"
                  )}`}
                ></video>
              ) : (
                <div className=" flex  items-center justify-center text-center flex-col gap-3">
                  <button
                    type="button"
                    onClick={toggleModal}
                    className=" flex items-center justify-around gap-1  font-inter text-sm text-[#1CACBD] font-medium bg-[#1CACBD0F] rounded-xl"
                  >
                    <svg
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M23 19C23 19.5304 22.7893 20.0391 22.4142 20.4142C22.0391 20.7893 21.5304 21 21 21H3C2.46957 21 1.96086 20.7893 1.58579 20.4142C1.21071 20.0391 1 19.5304 1 19V8C1 7.46957 1.21071 6.96086 1.58579 6.58579C1.96086 6.21071 2.46957 6 3 6H7L9 3H15L17 6H21C21.5304 6 22.0391 6.21071 22.4142 6.58579C22.7893 6.96086 23 7.46957 23 8V19Z"
                        stroke="#1CACBD"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M12 17C14.2091 17 16 15.2091 16 13C16 10.7909 14.2091 9 12 9C9.79086 9 8 10.7909 8 13C8 15.2091 9.79086 17 12 17Z"
                        stroke="#1CACBD"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                    Record Video
                  </button>
                  <p className=" text-xs font-inter font-normal text-[#828799]">
                    Tell us about your experience! You can record one right here
                    using your webcam your journey.
                  </p>
                </div>
              )}
              {/* {modalIsOpen && ( */}
              <VideoRecorderModal
                isOpen={modalIsOpen}
                onClose={toggleModal}
                setVideoBlob={setVideoBlob}
              />
            </div>
            <div className="relative">
              <ErrorMessage name="uploadvideo">
                {(errorMessage) => (
                  <ATMFieldError> {errorMessage} </ATMFieldError>
                )}
              </ErrorMessage>
            </div>
          </div>

          <div className="md:col-span-2">
            <ATMMultiSelect
              name="skillsData"
              value={values.skillsData || []}
              onChange={(newValue) => setFieldValue("skillsData", newValue)}
              options={skills}
              label="Skills Selection From Current Experience"
              placeholder="Select Skills"
              keysToSelectoption={["Enter", ","]}
              isSkills={true}
            />
          </div>
        </div>
      </div>

      {/* Submit and Skip Buttons */}
      <div className=" flex mb-3 justify-end gap-4">
        {formType === "Add" && (
          <button
            onClick={() => navigate("/candidate/profile/education-details")}
          >
            <div className="flex items-center justify-center w-[100px] h-[32px] rounded-md border-[1px] border-[#1CACBD] text-[#1CACBD]">
              Skip
            </div>
          </button>
        )}
        <div className=" w-[100px]">
          <ATMButton
            label={formType === "Edit" ? "Save" : "Proceed"}
            loading={isSubmitting}
          />
        </div>
      </div>
    </div>
  );
};

export default WorkExperienceFormLayout;
