import React from "react";
import ATMFieldLabel from "../../../../atoms/ATMFieldLabel/ATMFieldLabel";
export const formatDate = (dateString) => {
  const options = { day: "2-digit", month: "long", year: "numeric" };
  const date = new Date(dateString);
  return date.toLocaleDateString("en-GB", options);
};

const OtherInformation = ({ userData }) => {
  const location = userData?.preferredlocation_candidate[0] || "";

  return (
    <div>
      <div className="flex font-inter text-[11px] flex-col gap-4">
        <div className=" py-9 flex  justify-around  border-[1px] rounded-lg border-[#E2DCEA] relative">
          <div>
            {/* 1 */}
            <div className=" flex flex-wrap gap-20 p-[20px] bg-[#F8F8F8]">
              <div>
                <p className="  text-[#6D758F] font-inter font-normal">
                  If checked then no need to select Visa <br />
                  Authorization
                </p>
                <div className="mt-[15px] flex items-center space-x-2">
                  <input
                    type="checkbox"
                    id="germany"
                    checked={userData?.is_residence === true}
                    className="form-checkbox text-blue-600"
                  />
                  <div className="  font-inter  font-medium text-[#344054] ">
                    Are you a resident of {userData?.country}?
                  </div>
                </div>
              </div>

              {userData?.is_residence !== true && (
                <div>
                  <div className="font-inter font-medium text-[#344054] ">
                    Current Work Authorization / Visa status
                  </div>
                  <div className="flex">{userData?.visa}</div>
                </div>
              )}
            </div>
            {/* 2 */}
          </div>
          <div className=" ">
            <ATMFieldLabel>Excepted Salary</ATMFieldLabel>

            {userData?.salary_standard ? (
              <div className=" flex items-center space-x-2 text-slate-500">
                <input
                  type="checkbox"
                  id="germany"
                  checked={userData?.salary_standard}
                  className="form-checkbox text-blue-600"
                />

                <ATMFieldLabel> As per Standard</ATMFieldLabel>
              </div>
            ) : (
              <div className=" flex gap-2 items-center text-slate-500">
                <span>{userData?.salary}</span>
                <span>{userData?.salary_type}</span>
              </div>
            )}
          </div>

          <div className="">
            <ATMFieldLabel>Job Preference</ATMFieldLabel>
            <p className=" text-slate-500">{userData?.job_preference}</p>
          </div>
        </div>
        {/* 2 */}
        <div className="border rounded-md">
          {/* 2.1 */}
          <div className="bg-blue-100 px-5 py-1">
            <ATMFieldLabel>Interests/Hobbies</ATMFieldLabel>
          </div>

          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6 p-5">
            {userData.candidatehobbies_candidate[0]?.hobbies?.map((e, ind) => (
              <div key={ind} className="">
                <div className="">
                  <div className="text-[#121212]  font-inter">
                    {e.hobbiecategory}
                  </div>

                  <hr className=" border-[#02B15D] border-[1px]" />
                  <p className=" break-words">{e.hobbiename}</p>
                </div>
              </div>
            ))}
          </div>

          {/* <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4">
            {userData.candidatehobbies_candidate.map((user) => (
              <div className=" py-2 px-4 flex items-center justify-center gap-1  rounded-2xl bg-[#00B9291A] font-inter text-[10px] font-normal text-[#02B15D] ">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth={1.5}
                  stroke="currentColor"
                  className="size-4"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M9 12.75 11.25 15 15 9.75M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"
                  />
                </svg>
                {user.hobbie.hobbie_category}
              </div>
            ))}
          </div> */}
        </div>
        <div className="border rounded-md">
          <div className="bg-blue-100 px-5 py-1">
            <ATMFieldLabel>Current Location</ATMFieldLabel>
          </div>
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 text-slate-500 gap-6 p-5">
            <div>
              <ATMFieldLabel>Country</ATMFieldLabel>
              <p className=" ">{location.country}</p>
            </div>

            <div>
              <ATMFieldLabel>State</ATMFieldLabel>
              <p className=" ">{location.state}</p>
            </div>

            <div>
              <ATMFieldLabel>City</ATMFieldLabel>
              <p className=" ">{location.city}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default OtherInformation;
