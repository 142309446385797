import ATMHeading from "../../../atoms/ATMHeading/ATMHeading";
import InviteJobCard from "./InviteJobCard";
import { axiosCandidateRequest } from "../../../api/api";
import React, { useCallback, useEffect, useState } from "react";
import toast from "react-hot-toast";
import ATMPagination from "../../../atoms/ATMPagination/ATMPagination";
import ATMCircularProgress from "../../../atoms/ATMCircularProgress/ATMCircularProgress";
const baseUrl = process.env.REACT_APP_VITE_BACKEND_API_URL;

const InvitedJobs = () => {
  const [jobType, setJobType] = useState("Active Jobs");
  const [jobData, setJobData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [pagination, setPagination] = useState({
    currentPage: 1,
    itemsPerPage: 8,
  });

  useEffect(() => {
    setPagination({ currentPage: 1, itemsPerPage: 8 });
  }, [jobType]);

  const handlePageChange = (page) => {
    setPagination((prev) => ({ ...prev, currentPage: page }));
  };

  const handleItemsPerPageChange = (itemsPerPage) => {
    setPagination((prev) => ({ ...prev, itemsPerPage, currentPage: 1 }));
  };

  const getAllJobs = useCallback(async () => {
    setIsLoading(true);
    try {
      let url = `${baseUrl}/api/candidate/active_jobs/?page=${pagination?.currentPage}&page_size=${pagination?.itemsPerPage}`;

      if (jobType === "Applied Jobs") {
        url = `${baseUrl}/api/candidate/applied_jobs/?page=${pagination?.currentPage}&page_size=${pagination?.itemsPerPage}`;
      }
      if (jobType === "Shortlist") {
        url = `${baseUrl}/api/candidate/shortlisted_jobs/?page=${pagination?.currentPage}&page_size=${pagination?.itemsPerPage}`;
      }

      const response = await axiosCandidateRequest(url, {
        method: "GET",
      });

      if (response.status === 200) {
        setJobData(response.data); // Update jobData with the response data
        setIsLoading(false);
      } else if (response.status === 400) {
        toast.error("Failed to get data");
      }
    } catch (error) {
      if (error.response && error.response.status === 400) {
        toast.error(error.response.data.message);
      } else {
        toast.error("An unexpected error occurred");
      }
    } finally {
      setIsLoading(false);
    }
  }, [jobType, pagination]); // Memoize function based on jobType and pagination

  useEffect(() => {
    getAllJobs(); // Call memoized function when jobType or pagination changes
  }, [getAllJobs]);
  const handleJobTypeChange = (type) => {
    setJobType(type);
  };
  return (
    <>
      <div className="px-2 my-2 flex justify-between">
        <ATMHeading> Invited Jobs</ATMHeading>

        {/* <div className=" flex items-center gap-2 ">
          <p className=" font-inter font-normal text-[#000000] text-xs ">
            Sort by
          </p>
          <select
            className=" p-2 pr-5 text-[#686868] font-inter font-normal text-xs border-[1px] border-[#EFEFEF]"
            name=""
            id=""
          >
            <option value="" disabled selected hidden>
              Job Title
            </option>
            <option value="option1">Option 1</option>
            <option value="option2">Option 2</option>
            <option value="option3">Option 3</option>
          </select>

          <p className=" font-inter font-normal text-[#000000] text-xs ">
            Status
          </p>
          <select
            className=" p-2 pr-5 text-[#686868] font-inter font-normal text-xs border-[1px] border-[#EFEFEF]"
            name=""
            id=""
          >
            <option value="" disabled selected hidden>
              Applied Jobs
            </option>
            <option value="option1">Option 1</option>
            <option value="option2">Option 2</option>
            <option value="option3">Option 3</option>
          </select>
        </div> */}
      </div>

      <div className="px-2">
        <ul className="mt-5 flex text-xs border-b font-inter font-medium gap-5">
          {["Active Jobs", "Applied Jobs", "Shortlist"].map((type) => (
            <li
              key={type}
              className={`pb-2  cursor-pointer ${
                jobType === type ? "  border-b-2 border-[#101828]" : ""
              }`}
              onClick={() => handleJobTypeChange(type)}
            >
              {type}
            </li>
          ))}
        </ul>
      </div>

      {isLoading ? (
        <span className="flex justify-center h-full items-center">
          <ATMCircularProgress />
        </span>
      ) : (
        <div className="  ">
          {jobData?.count === 0 ? (
            <span className="w-full flex justify-center mt-2">
              No Data Found
            </span>
          ) : (
            <div className="grid  md:grid-cols-2  lg:grid-cols-3 xl:grid-cols-4 grid-cols-1 h-full justify-start text-xs font-inter py-3 items-stretch">
              {jobData?.results?.map((data, index) => (
                <div className="px-2 py-1" key={index}>
                  <InviteJobCard data={data} />
                </div>
              ))}
            </div>
          )}
        </div>
      )}
      <div className="flex justify-end px-2 ">
        {!isLoading && jobData?.count !== 0 && (
          <ATMPagination
            totalItems={jobData?.count}
            itemsPerPage={pagination.itemsPerPage}
            currentPage={pagination.currentPage}
            onPageChange={handlePageChange}
            itemsPerPageOptions={[4, 8, 12, 16, 20, 24, 48, 96]}
            onItemsPerPageChange={handleItemsPerPageChange}
          />
        )}
      </div>
    </>
  );
};

export default InvitedJobs;
