import axios from "axios";
import { useFormik } from "formik";
import Cookies from "js-cookie";
import React, { useState } from "react";
import toast from "react-hot-toast";
import { IoMdEye, IoMdEyeOff } from "react-icons/io";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router";
import apiSlice from "../../app/ApiSlice";
import ATMButton from "../../atoms/ATMButton/ATMButton";
import ATMFieldError from "../../atoms/ATMFieldError/ATMFieldError";
import ATMFieldLabel from "../../atoms/ATMFieldLabel/ATMFieldLabel";
import HeroHeading from "../../components/recruiters/HeroHeading";
import { loginSchema } from "../../schemas/schema";
import loginImage from "../../assets/recruiter_login.png";

const baseUrl = process.env.REACT_APP_VITE_BACKEND_API_URL;
function SignIn() {
  const dispatch = useDispatch();
  const [showPassword, setShowPassword] = useState(false);
  const navigate = useNavigate();

  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
      rememberMe: false,
    },
    validationSchema: loginSchema,

    onSubmit: async (values, { resetForm, setSubmitting }) => {
      setSubmitting(true);
      try {
        const response = await axios.post(
          `${baseUrl}/api/recruiter/login/`,
          {
            email: values.email,
            password: values.password,
          },
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        );

        if (response.status === 200) {
          // toast.success("Login successful!");
          resetForm();
          dispatch(apiSlice.util.invalidateTags(["dashboard"]));
          const { access, refresh, user_type, user } = response.data.data;
          localStorage.setItem("accessToken", access);
          localStorage.setItem("refreshToken", refresh);
          localStorage.setItem("userType", user_type);
          localStorage.setItem("recuriterProfile", JSON.stringify(user));
          localStorage.setItem("recruiter_id", user.id || "");
          Cookies.set("accessToken", access, {
            secure: true,
            sameSite: "Strict",
          });
          Cookies.set("userType", user_type, {
            secure: true,
            sameSite: "Strict",
          });
          navigate("/recruit/dashboard");
        } else if (response.status === 400) {
          toast.error(
            "Invalid credentials. Please check your email and password."
          );
        } else {
          toast.error(`Unexpected error occurred: ${response.statusText}`);
        }
      } catch (error) {
        if (error.response) {
          if (error.response.status === 400) {
            toast.error(error.response.data.message || "Invalid credentials.");
          } else if (error.response.status === 401) {
            toast.error("Unauthorized. Please check your credentials.");
          } else if (error.response.status === 500) {
            toast.error("Internal server error. Please try again later.");
          } else {
            toast.error(
              `Error: ${error.response.status} - ${
                error.response.data.message || "Unknown error"
              }`
            );
          }
        } else if (error.request) {
          toast.error("Network error. Please check your internet connection.");
        } else {
          toast.error("An unexpected error occurred. Please try again.");
        }
      } finally {
        setSubmitting(false);
      }
    },
  });

  const handleShowPassword = () => {
    setShowPassword(!showPassword);
  };
  return (
    <div className="">
      <div className=" w-full px-4 py-2 bg-white  text-[11px] font-inter  ">
        <div className="py-4">
          <HeroHeading
            // heading="Login to our portal as Recruiter"
            heading="Login to our portal as Recruiter which is one Step Closer to Efficiency "
            para="Welcome to our streamlined portal, where each login propels you into a world of effortless HR management."
          />
        </div>
        <div className="grid grid-cols-1 md:grid-cols-2 items-start mt-2 select-none gap-2 h-fit">
          <div className=" h-full">
            <img
              src={loginImage}
              alt="Recruiter Login"
              className="rounded-lg object-contain w-full h-full  md:block hidden md:h-[450px] lg:h-[400px] "
            />
          </div>
          <div className=" flex flex-col h-full">
            <form onSubmit={formik.handleSubmit}>
              <div className="border border-[#E2DCEA] flex flex-col gap-4 p-5 rounded-md w-full">
                <div className="w-full">
                  <ATMFieldLabel required>Email ID</ATMFieldLabel>
                  <div className="relative">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth={1.5}
                      stroke="currentColor"
                      className="absolute top-2 left-3 text-gray-400 pointer-events-none"
                      style={{ height: 24, width: 24 }}
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M21.75 6.75v10.5a2.25 2.25 0 0 1-2.25 2.25h-15a2.25 2.25 0 0 1-2.25-2.25V6.75m19.5 0A2.25 2.25 0 0 0 19.5 4.5h-15a2.25 2.25 0 0 0-2.25 2.25m19.5 0v.243a2.25 2.25 0 0 1-1.07 1.916l-7.5 4.615a2.25 2.25 0 0 1-2.36 0L3.32 8.91a2.25 2.25 0 0 1-1.07-1.916V6.75"
                      />
                    </svg>
                    <input
                      size="lg"
                      placeholder="olivia@untitledui.com"
                      className=" pl-10 w-full border  outline-none h-[38px] px-2 rounded-md"
                      value={formik.values.email}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      id="email"
                      name="email"
                    />
                  </div>
                  {formik.errors.email && formik.touched.email ? (
                    <ATMFieldError>{formik.errors.email}</ATMFieldError>
                  ) : null}
                </div>
                <div className="w-full">
                  <ATMFieldLabel required>Your Password</ATMFieldLabel>
                  <div className="relative">
                    <input
                      size="lg"
                      type={showPassword ? "text" : "password"}
                      placeholder="Enter your password"
                      className=" w-full border  outline-none  h-[38px] px-2 rounded-md"
                      value={formik.values.password}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      id="password"
                      name="password"
                    />
                    <div
                      onClick={handleShowPassword}
                      className="absolute top-1/2 transform -translate-y-1/2 text-2xl right-3 cursor-pointer"
                    >
                      {showPassword ? (
                        <IoMdEye size={18} className="text-slate-800" />
                      ) : (
                        <IoMdEyeOff size={18} className="text-slate-800" />
                      )}
                    </div>
                  </div>
                  {formik.errors.password && formik.touched.password ? (
                    <ATMFieldError>{formik.errors.password}</ATMFieldError>
                  ) : null}
                </div>
                <div className="w-full flex justify-between items-center">
                  <div className="w-fit flex justify-between items-center gap-1 font-medium">
                    <input
                      type="checkbox"
                      checked={formik.values.rememberMe}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      name="rememberMe"
                    />{" "}
                    Remember Me
                  </div>
                  <button
                    type="button"
                    onClick={() => navigate("/recruiter/forget-password")}
                    className="capitalize text-[#1CACBD] font-normal"
                  >
                    forget your password?
                  </button>
                </div>
                <div className="flex justify-end">
                  <div className="w-[100px]">
                    <ATMButton
                      loading={formik.isSubmitting}
                      label="Login"
                      authicon
                    />
                  </div>
                </div>
              </div>
              <div className="p-5">
                <p className="text-slate-500 font-inter font-normal text-[11px] leading-4">
                  If you don’t have an account yet <br />
                  <button
                    type="button"
                    onClick={() => navigate("/recruiter/register")}
                    className="text-[#1CACBD] "
                  >
                    {" "}
                    Register as Corporate Super Admin{" "}
                  </button>{" "}
                  <br />
                  <button
                    type="button"
                    onClick={() => navigate("/recruiter/register-user")}
                    className="text-[#1CACBD] "
                  >
                    Register as Corporate User{" "}
                  </button>
                </p>
              </div>
              <div className="font-inter text-[11px] px-5">
                <ATMFieldLabel>Want to reach out directly?</ATMFieldLabel>
                {/* <div className="text-slate-600">
                  We're here to help!{" "}
                  <span
                    onClick={() => navigate("/recruiter/support")}
                    className="text-[#1CACBD] cursor-pointer"
                  >
                    Contact our support
                  </span>{" "}
                  team with any questions.
                </div> */}
                <div className="text-slate-500 font-inter font-normal text-[11px] leading-4">
                  We're here to help! Reach out to our dedicated support team
                  for any inquiries, assistance, or to schedule a demo. Your
                  success is our priority. <br />
                  <span
                    onClick={() => navigate("/recruiter/support")}
                    className="text-[#1CACBD] cursor-pointer"
                  >
                    Click here to support
                  </span>{" "}
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}

export default SignIn;
