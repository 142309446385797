import { Form, Formik } from "formik";
import React, { useState } from "react";
import toast from "react-hot-toast";
import * as Yup from "yup";
import { useSupportMutation } from "../../app/DashboardServices/DashboardServices";
import ATMButton from "../../atoms/ATMButton/ATMButton";
import ATMFieldError from "../../atoms/ATMFieldError/ATMFieldError";
import ATMFieldLabel from "../../atoms/ATMFieldLabel/ATMFieldLabel";
import ATMHeading from "../../atoms/ATMHeading/ATMHeading";
import ATMTextField from "../../atoms/ATMTextField/ATMTextField";

const SupportPage = () => {
  const [reqForsupport] = useSupportMutation();
  const [submit, setSubmit] = useState(false);
  const [responseMessage, setResponseMessage] = useState("");
  const handleSubmit = (values, { resetForm }) => {
    reqForsupport(values).then((res) => {
      if (res?.error) {
        toast.error(res?.error?.data?.message);
        setSubmit(false);
      } else {
        if (res?.data?.success) {
          resetForm();
          setSubmit(false);
          setResponseMessage(res?.data?.message);
        } else {
          toast.error(res?.error?.data?.message);
          setSubmit(false);
        }
      }
    });
  };

  const validationSchema = Yup.object({
    name: Yup.string()
      .min(2, "Name must be at least 2 characters long")
      .required("Name is required"),
    email: Yup.string()
      .email("Invalid email format")
      .required("Email is required"),
    message: Yup.string()
      .min(10, "Message must be at least 10 characters long")
      .required("Message is required"),
  });

  return (
    <div className=" font-inter text-xs p-2 min-h-full bg-white ">
      <div className="">
        <div className=" ">
          <ATMHeading> Need Support?</ATMHeading>
          <p className=" text-gray-600 mt-1 pb-2 ">
            We're here to help! Please fill out the form below, and our support
            team will get back to you as soon as possible.
          </p>
        </div>
        <div className="  rounded-lg p-4 w-full lg:w-[90%] mx-auto  mt-2  col-span-2 border ">
          <Formik
            initialValues={{
              name: "",
              email: "",
              message: "",
            }}
            validationSchema={validationSchema}
            onSubmit={handleSubmit}
          >
            {({ errors, touched, setFieldValue, values }) => (
              <Form>
                <div className="flex flex-col gap-4">
                  <ATMTextField
                    type="text"
                    name="name"
                    value={values.name}
                    label="Name"
                    placeholder="Enter your Name"
                    onChange={(e) => setFieldValue("name", e.target.value)}
                    required
                  />

                  <ATMTextField
                    type="text"
                    name="email"
                    value={values.email}
                    label="Email"
                    placeholder="Enter your Email"
                    onChange={(e) => setFieldValue("email", e.target.value)}
                    required
                  />

                  <div>
                    <ATMFieldLabel required>Message</ATMFieldLabel>
                    <textarea
                      className="w-full border border-gray-300  resize-none h-[140px] p-3 rounded-lg outline-none "
                      value={values.message}
                      onChange={(e) => setFieldValue("message", e.target.value)}
                      id="message"
                      name="message"
                    ></textarea>
                    {errors.message && touched.message ? (
                      <div>
                        <ATMFieldError>{errors.message}</ATMFieldError>
                      </div>
                    ) : null}
                  </div>
                  <div className="flex justify-end">
                    <div className="w-[110px]">
                      <ATMButton loading={submit} label="Submit" />
                    </div>
                  </div>
                  <div className="text-green-500 font-bold text-sm">
                    {responseMessage}
                  </div>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </div>
    </div>
  );
};

export default SupportPage;
