import React, { useState } from "react";
import HeroHeading from "../../components/recruiters/HeroHeading";

import { Button, Menu, MenuHandler } from "@material-tailwind/react";
import axios from "axios";
import { useFormik } from "formik";
import toast from "react-hot-toast";
import { IoMdEye, IoMdEyeOff } from "react-icons/io";
import { useNavigate } from "react-router-dom";
import ATMButton from "../../atoms/ATMButton/ATMButton.jsx";
import ATMFieldError from "../../atoms/ATMFieldError/ATMFieldError.jsx";
import ATMFieldLabel from "../../atoms/ATMFieldLabel/ATMFieldLabel.jsx";
import Dailog from "../../components/common/Dailog";
import { signUpSchema } from "../../schemas/recruiterSchema.js";
import RegisterImage from "../../assets/Register_Corporate_User.png";
const baseUrl = process.env.REACT_APP_VITE_BACKEND_API_URL;

function RegisterUser() {
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [showconPassword, setShowconPassword] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const handleShowPassword = () => {
    setShowPassword(!showPassword);
  };
  const handleShowconPassword = () => {
    setShowconPassword(!showconPassword);
  };
  let heading = "Register as Corporate User";
  let para =
    "Streamline your hiring process—register as a Corporate User on our Application Tracking System and empower your team with efficient recruitment tools and insights Your oversight is crucial in leveraging our tools for maximum efficiency and effectiveness in talent acquisition. Proceed with setting up your account and take the first step towards transforming your organization's hiring process.";

  const handleEmailChange = (e) => {
    const value = e.target.value;
    const atIndex = value.indexOf("@");
    if (atIndex !== -1) {
      const domain = value.slice(atIndex + 1);

      formik.setFieldValue("companyUrl", `${domain}`);
    }
    formik.setFieldValue("email", value); // Sync email with Formik
  };
  const formik = useFormik({
    initialValues: {
      email: "",
      firstName: "",
      lastName: "",
      companyUrl: "",
      password: "",
      confirmPassword: "",
    },
    validationSchema: signUpSchema,
    onSubmit: async (values, { resetForm, setSubmitting }) => {
      setIsLoading(true);
      try {
        const response = await axios.post(
          `${baseUrl}/api/recruiter/registration/`,
          {
            email: values.email,
            password: values.password,
            password2: values.confirmPassword,
            first_name: values.firstName,
            last_name: values.lastName,
            company_website_url: `https://${values.companyUrl}`,
          },
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        );

        if (response.status === 201) {
          resetForm();
          setOpen(!open);
          toast.success("Registration successful!");
        } else {
          toast.error("Something went wrong. Please try again.");
        }
      } catch (error) {
        if (error.response) {
          if (error.response.status === 400) {
            if (error.response.data?.message) {
              toast.error(error.response.data.message);
            } else {
              toast.error("Bad request. Please check your input.");
            }
          } else if (error.response.status === 500) {
            toast.error("Internal Server Error. Please try again later.");
          } else {
            toast.error(`Error: ${error.response.statusText}`);
          }
        } else if (error.request) {
          toast.error("Network error. Please check your internet connection.");
        } else {
          toast.error("An unexpected error occurred. Please try again.");
        }
      } finally {
        setIsLoading(false);
        setSubmitting(false);
      }
    },
  });

  return (
    <>
      <Dailog
        open={open}
        setOpen={setOpen}
        heading="We've sent an activation link to your email address for added security. Once you click the link, you'll be ready to log in and explore all the features available."
        para="Click the link to activate your account and start using our platform right away. It's that simple!"
        btnText="Login"
        btnLink="/recruiter/login"
      />
      <div className="flex p-1  py-1 text-[11px] font-inter h-full ">
        <div className="bg-white  ">
          <HeroHeading heading={heading} para={para} />
          <div className="grid grid-cols-1 items-start  md:grid-cols-2 gap-4 mt-4 ">
            <div className="col-span-1 mt-5 ">
              <img
                src={RegisterImage}
                alt="jivahire-RegisterImage"
                className="object-contain md:block hidden w-full h-full rounded-md   "
              />
            </div>

            <div className="md:col-span-1 ">
              <form
                onSubmit={formik.handleSubmit}
                className="flex flex-col gap-2"
              >
                <div className="w-full border border-[#E2DCEA] rounded-md p-4">
                  <div className="grid grid-cols-1  md:grid-cols-2 gap-5 items-center">
                    <div className="w-full ">
                      <ATMFieldLabel required> First Name</ATMFieldLabel>
                      <input
                        placeholder="Brian"
                        className="border w-full px-2 h-[38px] rounded-md outline-none"
                        value={formik.values.firstName}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        id="firstName"
                        name="firstName"
                      />
                      {formik.errors.firstName && formik.touched.firstName ? (
                        <ATMFieldError>{formik.errors.firstName}</ATMFieldError>
                      ) : null}
                    </div>
                    <div className="w-full ">
                      <ATMFieldLabel required> Last Name</ATMFieldLabel>
                      <input
                        placeholder="Clark"
                        className="border w-full h-[38px] px-2 rounded-md outline-none"
                        value={formik.values.lastName}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        id="lastName"
                        name="lastName"
                      />
                      {formik.errors.lastName && formik.touched.lastName ? (
                        <ATMFieldError>{formik.errors.lastName}</ATMFieldError>
                      ) : null}
                    </div>
                    <div className="w-full ">
                      <ATMFieldLabel required> Email ID</ATMFieldLabel>
                      <div className="relative">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          strokeWidth={1.5}
                          stroke="currentColor"
                          className="absolute  top-2 left-3 text-gray-400 pointer-events-none"
                          style={{ height: 24, width: 24 }}
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            d="M21.75 6.75v10.5a2.25 2.25 0 0 1-2.25 2.25h-15a2.25 2.25 0 0 1-2.25-2.25V6.75m19.5 0A2.25 2.25 0 0 0 19.5 4.5h-15a2.25 2.25 0 0 0-2.25 2.25m19.5 0v.243a2.25 2.25 0 0 1-1.07 1.916l-7.5 4.615a2.25 2.25 0 0 1-2.36 0L3.32 8.91a2.25 2.25 0 0 1-1.07-1.916V6.75"
                          />
                        </svg>
                        <input
                          placeholder="olivia@untitledui.com"
                          className=" pl-10 w-full border h-[38px] px-2 rounded-md outline-none"
                          labelProps={{
                            className: "before:content-none after:content-none",
                          }}
                          value={formik.values.email}
                          onChange={handleEmailChange}
                          onBlur={formik.handleBlur}
                          id="email"
                          name="email"
                        />
                      </div>
                      {formik.errors.email && formik.touched.email ? (
                        <ATMFieldError>{formik.errors.email}</ATMFieldError>
                      ) : null}
                    </div>
                  </div>
                </div>
                <div className="w-full border border-[#E2DCEA] rounded-md p-4">
                  <div className="grid  grid-cols-1  md:grid-cols-2   gap-5 items-center">
                    <div className="w-full md:col-span-2 ">
                      <ATMFieldLabel required>
                        Company Website Url
                      </ATMFieldLabel>
                      <div className="relative flex items-stretch w-full">
                        <Menu placement="bottom-end">
                          <MenuHandler>
                            <Button
                              ripple={false}
                              variant="text"
                              color="blue-gray"
                              className="flex items-center gap-2 rounded-r-none border border-r-0 border-blue-gray-200 font-normal h-[38px] px-2"
                            >
                              https://
                            </Button>
                          </MenuHandler>
                        </Menu>
                        <input
                          type="text"
                          placeholder="www.untitledui.com"
                          className="rounded-r-md  flex-1 px-2 h-[38px] border outline-none "
                          labelProps={{
                            className: "before:content-none after:content-none",
                          }}
                          containerProps={{
                            className: "min-w-0",
                          }}
                          value={formik.values.companyUrl}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          id="companyUrl"
                          name="companyUrl"
                          disabled={true}
                        />
                      </div>
                      {formik.errors.companyUrl && formik.touched.companyUrl ? (
                        <ATMFieldError>
                          {formik.errors.companyUrl}
                        </ATMFieldError>
                      ) : null}
                    </div>
                    <div className="w-full ">
                      <ATMFieldLabel required>Set your password</ATMFieldLabel>

                      <div className="relative border  rounded-md">
                        <input
                          type={showPassword ? "text" : "password"}
                          placeholder="Enter your password"
                          className=" w-full outline-none h-[38px] px-2 rounded-md"
                          labelProps={{
                            className:
                              "before:content-none after:content-none ",
                          }}
                          value={formik.values.password}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          id="password"
                          name="password"
                        />
                        <div
                          onClick={handleShowPassword}
                          className="absolute top-1/2 transform -translate-y-1/2 text-2xl right-3 cursor-pointer"
                        >
                          {showPassword ? (
                            <IoMdEye size={18} className="text-slate-800" />
                          ) : (
                            <IoMdEyeOff size={18} className="text-slate-800" />
                          )}
                        </div>
                      </div>
                      {formik.errors.password && formik.touched.password ? (
                        <ATMFieldError>{formik.errors.password}</ATMFieldError>
                      ) : null}
                    </div>
                    <div className="w-full ">
                      <ATMFieldLabel required>
                        {" "}
                        Re-enter your password
                      </ATMFieldLabel>

                      <div className="relative border rounded-md px-1">
                        <input
                          type={showconPassword ? "text" : "password"}
                          placeholder="Enter your password"
                          className=" w-full outline-none  h-[38px] px-2 rounded-md"
                          labelProps={{
                            className: "before:content-none after:content-none",
                          }}
                          value={formik.values.confirmPassword}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          id="confirmPassword"
                          name="confirmPassword"
                        />
                        <div
                          onClick={handleShowconPassword}
                          className="absolute top-1/2 transform -translate-y-1/2 text-2xl right-3 cursor-pointer"
                        >
                          {showconPassword ? (
                            <IoMdEye size={18} className="text-slate-800" />
                          ) : (
                            <IoMdEyeOff size={18} className="text-slate-800" />
                          )}
                        </div>
                      </div>
                      {formik.errors.confirmPassword &&
                      formik.touched.confirmPassword ? (
                        <ATMFieldError>
                          {formik.errors.confirmPassword}
                        </ATMFieldError>
                      ) : null}
                    </div>
                  </div>
                </div>
                <div className="flex flex-col px-4 justify-start gap-1 items-start w-full">
                  <p className="text-slate-500 font-inter font-normal text-[11px] leading-4">
                    Complete your registration to unlock advanced recruiting
                    tools, track applicant progress, and elevate your talent
                    acquisition strategy.
                  </p>

                  <div className="flex justify-end w-full">
                    <div className="w-[110px]">
                      <ATMButton
                        loading={isLoading}
                        label="Register Now"
                        authicon
                      />
                    </div>
                  </div>
                  <p className="text-slate-500 font-inter font-normal text-[11px] leading-4">
                    Register as Corporate Super Admin{" "}
                    <span
                      onClick={() => navigate("/recruiter/register")}
                      className="text-[#1CACBD] cursor-pointer"
                    >
                      {" "}
                      Click Here To Register
                    </span>
                  </p>
                  <p className="text-slate-500 font-inter font-normal text-[11px] leading-4">
                    If you have already created account then you can{" "}
                    <span
                      onClick={() => navigate("/recruiter/login")}
                      className="text-[#1CACBD] cursor-pointer"
                    >
                      {" "}
                      Click Here To Login
                    </span>
                  </p>

                  <div className="font-inter">
                    <ATMFieldLabel>Want to reach out directly?</ATMFieldLabel>
                    <div className="text-slate-500 font-inter font-normal text-[11px] leading-4">
                      We're here to help!{" "}
                      <span
                        onClick={() => navigate("/recruiter/support")}
                        className="text-[#1CACBD] cursor-pointer"
                      >
                        Contact our support
                      </span>{" "}
                      team with any questions.
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default RegisterUser;
