import React from "react";
import ATMCircularProgress from "../ATMCircularProgress/ATMCircularProgress";

const ATMButton = ({
  loading = false,
  type = "submit",
  isIcon = false,
  isleftIcon = false,
  authicon = false,
  label,
  onClick,
}) => {
  return (
    <div className="w-full">
      <button
        type={type}
        className=" w-full px-1 text-[11px] flex gap-1 text-white bg-[#1CACBD]  h-[32px] rounded-md  font-medium justify-center items-center"
        disabled={loading}
        onClick={onClick}
      >
        {loading ? (
          <div className="flex justify-center">
            <ATMCircularProgress size="small" color="white" />
          </div>
        ) : (
          <span className="flex items-center  gap-1 ">
            {isleftIcon && (
              <svg
                width="20"
                height="20"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <rect
                  x="2"
                  y="2"
                  width="20"
                  height="20"
                  rx="10"
                  stroke="#D0D5DD"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeDasharray="1 3"
                />
                <path
                  d="M12.0002 7.33398V16.6673M7.3335 12.0007H16.6668"
                  stroke="#F5F6F7"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            )}
            {label}
            {authicon && (
              <span>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth={1.5}
                  stroke="currentColor"
                  className="size-5"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="m12.75 15 3-3m0 0-3-3m3 3h-7.5M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"
                  />
                </svg>
              </span>
            )}
            {isIcon && (
              <svg
                width="12"
                height="12"
                viewBox="0 0 12 12"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M5.88477 1.88281L10.2001 6.00202L5.88477 10.1212"
                  stroke="white"
                  strokeWidth="1.28571"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M10.2002 6.00195L1.80029 6.00195"
                  stroke="white"
                  strokeWidth="1.28571"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            )}
          </span>
        )}
      </button>
    </div>
  );
};

export default ATMButton;
