import React from "react";
import { Route, BrowserRouter as Router, Routes } from "react-router-dom";
import "./App.css";
import NotFound from "./components/common/NotFound";
import ProtectedRoute from "./components/common/ProtectedRoute";
import VideoRecorder from "./components/common/VideoRecorder";
import ProfileRoot from "./layout/candidate/ProfileRoot";
import Root from "./layout/candidate/Root";
import UpdateProfileRoot from "./layout/candidate/UpdateProfileRoot";
import Auth from "./layout/recruiters/Auth";
import Dashboard from "./layout/recruiters/Dashboard";
import ActivateAccount from "./pages/auth/ActivateAccount";
import RecruiterActivateAccount from "./pages/auth/RecruiterActivateAccount";
import CandidateApplicationView from "./pages/candidate/MyJobApplication/CandidateApplicationView";
import CandidateLogin from "./pages/candidate/CandidateLogin";
import CandidateResetPassword from "./pages/candidate/CandidateResetPassword";
import EducationDetailsWrapper from "./pages/candidate/EducationDetails/View/EducationDetailsWrapper";
import CandidateForgetPassword from "./pages/candidate/ForgetPassword";
import HomePage from "./pages/candidate/Home/HomePage";
import ApplyForm from "./pages/candidate/InvitedJobs/ApplyFormForJob/ApplyForm";
import InvitedJobs from "./pages/candidate/InvitedJobs/InvitedJobs";
import JobView from "./pages/candidate/InvitedJobs/JobView/JobView";
import Logout from "./pages/candidate/Logout";
import MyJobApplication from "./pages/candidate/MyJobApplication/MyJobApplication";
import BasicDetailsView from "./pages/candidate/MyProfile/BasicDetails/View/BasicDetailsView";
import MyProfileView from "./pages/candidate/MyProfileView.jsx/MyProfileView";
import OtherInformationWrapper from "./pages/candidate/OtherInformation/View/OtherInformationWrapper";
import ProfileDetailsWrapper from "./pages/candidate/ProfileDetails/View/ProfileDetailsWrapper";
import RegisterPage from "./pages/candidate/RegisterPage";
import WorkExperienceWrapper from "./pages/candidate/WorkExperience/View/WorkExperienceWrapper";
import Activation from "./pages/common/Activation";
import Settings from "./pages/common/Settings";
import Support from "./pages/common/Support";
// import Application from "./pages/recruiters/Application";
import ApplicationView from "./pages/recruiters/ApplicationView";
import RecuiterDashboard from "./pages/recruiters/Dashboard/RecuiterDashboard";
import ForgetPassword from "./pages/recruiters/ForgetPassword";
import InviteCandidate from "./pages/recruiters/InviteCandidate";
import JivahireProfile from "./pages/recruiters/JivahireProfile/JivahireProfile";
import RecruitProfileView from "./pages/recruiters/MyProfile/View/MyProfileView";
import Operations from "./pages/recruiters/Operations";
import RecruiterLogout from "./pages/recruiters/RecruiterLogout";
import RecruiterView from "./pages/recruiters/RecruiterView";
import RegisterUser from "./pages/recruiters/RegisterUser";
import ReportsViewWrapper from "./pages/recruiters/Reports/View/ReportsViewWrapper";
import ResetPassword from "./pages/recruiters/ResetPassword";
import EditSignleResumeFormWrapper from "./pages/recruiters/ResumeUpload/EditSignleResume/EditSignleResumeFormWrapper";
import MyResmuesListWrapper from "./pages/recruiters/ResumeUpload/MyResmues/MyResmuesListWrapper";
import ResunmeUploadViewWrapper from "./pages/recruiters/ResumeUpload/ResunmeUploadViewWrapper";
import SignIn from "./pages/recruiters/SignIn";
import SignUp from "./pages/recruiters/SignUp";
import TalentSearchListWrapper from "./pages/recruiters/TalentSearch/TalentSearchListWrapper";
import VerifiedExpert from "./pages/candidate/VerifiedExpert/VerifiedExpert";
import ShareProfileView from "./pages/ShareProfileView/ShareProfileView";
import CandidateJobView from "./pages/candidate/JobView/CandidateJobView";
import CreateJob from "./pages/recruiters/CreateJob";
import Application from "./pages/recruiters/JobStatus/Application";
import RecruitersList from "./pages/recruiters/Recruiters/RecruitersList";

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/recruiter/" element={<Auth />}>
          <Route path="login" element={<SignIn />} />
          <Route path="register" element={<SignUp />} />
          <Route path="register-user" element={<RegisterUser />} />
          <Route path="reset-password/:id" element={<ResetPassword />} />
          <Route path="support" element={<Support />} />
        </Route>

        <Route path="/recruiter/forget-password" element={<ForgetPassword />} />

        <Route
          path="recruiter-activate/:id"
          element={<RecruiterActivateAccount />}
        />
        <Route path="activation/" element={<Activation />} />

        <Route
          path="/recruit/"
          element={<ProtectedRoute Component={Dashboard} />}
        >
          <Route path="logout" element={<RecruiterLogout />} />
          <Route path="dashboard" element={<RecuiterDashboard />} />
          <Route path="talentsearch" element={<TalentSearchListWrapper />} />
          <Route path="profile-view/:id" element={<JivahireProfile />} />

          <Route path="resume-upload" element={<ResunmeUploadViewWrapper />} />
          <Route path="report" element={<ReportsViewWrapper />} />
          <Route path="recruiters" element={<RecruitersList />} />
          <Route path="my-profile-view" element={<RecruitProfileView />} />
          <Route path="create-job" element={<CreateJob />} />
          <Route path="recruiter/support" element={<Support />} />
          <Route path="my-resumes" element={<MyResmuesListWrapper />} />
          <Route
            path="edit-resume/:id"
            element={<EditSignleResumeFormWrapper />}
          />
          <Route
            path="job-status/recruiter-view/:id"
            element={<RecruiterView />}
          />
          <Route
            path="job-status/invite-candidate/:id"
            element={<InviteCandidate />}
          />
          <Route path="jivahireprofile" element={<JivahireProfile />} />
          <Route path="job-status/operations" element={<Operations />} />
          <Route path="job-status/application/:id" element={<Application />} />
          <Route
            path="job-status/application/view/:id/:id1/:id2"
            element={<ApplicationView />}
          />
          <Route
            path="job-status/application/candidate/:id"
            element={<MyProfileView />}
          />
        </Route>
        <Route path="/candidate/job-view" element={<CandidateJobView />} />
        <Route path="/candidate/profile/" element={<ShareProfileView />} />
        <Route path="/candidate/" element={<Root />}>
          <Route path="register" element={<RegisterPage />} />
          <Route path="support" element={<Support />} />
          {/* <Route path="job-view" element={<CandidateJobView />} /> */}
        </Route>
        <Route path="candidate-activate/:id" element={<ActivateAccount />} />

        <Route path="/candidate/" element={<Root />}>
          <Route
            path="reset-password/:id"
            element={<CandidateResetPassword />}
          />
          <Route path="login" element={<CandidateLogin />} />
        </Route>

        <Route
          path="/candidate/forget-password"
          element={<CandidateForgetPassword />}
        />

        <Route path="Support" element={<Support />} />

        <Route
          path="/candidate/"
          element={<ProtectedRoute Component={ProfileRoot} />}
        >
          <Route path="logout" element={<Logout />} />
          <Route path="home" element={<HomePage />} />
          <Route path="support-view" element={<Support />} />
          <Route path="verified-expert" element={<VerifiedExpert />} />
          <Route path="settings" element={<Settings />} />

          <Route path="user-view/:id" element={<JobView />} />
          <Route path="apply-details/:id/:id1" element={<ApplyForm />} />
          <Route
            path="aplication-view/:id/:id1"
            element={<CandidateApplicationView />}
          />
          <Route path="my-application" element={<MyJobApplication />} />

          <Route path="invite/job-invite" element={<InvitedJobs />} />

          <Route path="profile" element={<UpdateProfileRoot />}>
            <Route path="basic-details" element={<BasicDetailsView />} />
            <Route path="profile-details" element={<ProfileDetailsWrapper />} />
            <Route path="work-experience" element={<WorkExperienceWrapper />} />
            <Route
              path="education-details"
              element={<EducationDetailsWrapper />}
            />
            <Route
              path="other-information"
              element={<OtherInformationWrapper />}
            />
            <Route path="my-profile-view" element={<MyProfileView />} />
          </Route>
        </Route>

        <Route path="recorder/:id" element={<VideoRecorder />} />
        <Route path="*" element={<NotFound />} />
      </Routes>
    </Router>
  );
}

export default App;
