import apiSlice from "../../../../app/ApiSlice";

export const myProfileApi = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    recruiterProfile: builder.query({
      providesTags: ["recruiter_profile"],
      query: () => ({
        url: `/api/recruiter/recruiter/`,
        method: "GET",
      }),
    }),
    setNewPassowrd: builder.mutation({
      query: (body) => ({
        url: `/api/recruiter/reset_pass/`,
        method: "POST",
        body,
      }),
    }),
  }),
});

export const { useRecruiterProfileQuery , useSetNewPassowrdMutation} = myProfileApi;
