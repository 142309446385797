import { ErrorMessage } from "formik";
import React, { useEffect, useState } from "react";
import { PhoneInput } from "react-international-phone";
import ATMFieldError from "../../../../atoms/ATMFieldError/ATMFieldError";
import ATMFieldLabel from "../../../../atoms/ATMFieldLabel/ATMFieldLabel";
import ATMTextField from "../../../../atoms/ATMTextField/ATMTextField";

import {
  Listbox,
  ListboxButton,
  ListboxOption,
  ListboxOptions,
} from "@headlessui/react";
import { CheckIcon, ChevronDownIcon } from "@heroicons/react/20/solid";
import clsx from "clsx";
import { Country } from "country-state-city";
import ATMButton from "../../../../atoms/ATMButton/ATMButton";
import ATMSelect from "../../../../atoms/ATMSelect/ATMSelect";
import { data } from "../../../../helpers/recruiter";
import { socialMedia } from "../../SignUp";

const MyProfileFormLayout = ({ formikProps, recruitertype, setEdit }) => {
  const { values, setFieldValue, handleBlur, handleChange, isSubmitting } =
    formikProps;

  const [domainSubcategories, setDomainSubcategories] = useState([]);

  useEffect(() => {
    const selectedIndustry = data.domain_industry.find(
      (industry) => industry.name === values?.industry?.label
    );
    setDomainSubcategories(
      selectedIndustry ? selectedIndustry.subcategories : []
    );
  }, [values?.industry]);

  const handleEmailChange = (e) => {
    const value = e.target.value;
    const atIndex = value.indexOf("@");
    if (atIndex !== -1) {
      const domain = value.slice(atIndex + 1);

      setFieldValue("companywebUrl", `${domain}`);
    }
    setFieldValue("email", value); // Sync email with Formik
  };
  return (
    <div className="flex flex-col gap-4">
      <div className="grid grid-cols-1 md:grid-cols-1 lg:grid-cols-2 border p-10 gap-4 rounded-lg">
        <div>
          <ATMTextField
            type="text"
            name="firstName"
            value={values.firstName || ""}
            label="First Name"
            placeholder="Enter your First Name"
            onChange={(e) => setFieldValue("firstName", e.target.value)}
            onBlur={handleBlur}
            required
          />
        </div>
        <div>
          <ATMTextField
            type="text"
            name="lastName"
            value={values.lastName || ""}
            label="Last Name"
            placeholder="Enter your Last Name"
            onChange={(e) => setFieldValue("lastName", e.target.value)}
            onBlur={handleBlur}
            required
          />
        </div>
        {/* email  */}
        <div className="">
          <ATMFieldLabel required> Email ID</ATMFieldLabel>
          <div className="relative">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={1.5}
              stroke="currentColor"
              className="absolute top-2 transform  left-3 text-gray-400 pointer-events-none"
              style={{ height: 24, width: 24 }}
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M21.75 6.75v10.5a2.25 2.25 0 0 1-2.25 2.25h-15a2.25 2.25 0 0 1-2.25-2.25V6.75m19.5 0A2.25 2.25 0 0 0 19.5 4.5h-15a2.25 2.25 0 0 0-2.25 2.25m19.5 0v.243a2.25 2.25 0 0 1-1.07 1.916l-7.5 4.615a2.25 2.25 0 0 1-2.36 0L3.32 8.91a2.25 2.25 0 0 1-1.07-1.916V6.75"
              />
            </svg>
            <input
              size="lg"
              placeholder="olivia@untitledui.com"
              className="w-full px-3 h-[40px] border pl-10 items-center border-gray-300 min-w-[200px] rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-indigo-500"
              disabled
              value={values.email}
              onChange={handleEmailChange}
              onBlur={handleBlur}
              id="email"
              name="email"
            />
            <ErrorMessage name="email">
              {(errorMessage) => (
                <ATMFieldError> {errorMessage} </ATMFieldError>
              )}
            </ErrorMessage>
          </div>
        </div>

        {/* contact number  */}

        {/* {profileData?.recruiter?.company?.[0]?.company_identification_numb && ( */}
        <div className="">
          <ATMFieldLabel required>Contact Number</ATMFieldLabel>
          <div className=" min-w-[200px]">
            <PhoneInput
              defaultCountry="us"
              value={values.contactNumber}
              onChange={(value) => setFieldValue("contactNumber", value)}
              onBlur={handleBlur}
              id="contactNumber"
              name="contactNumber"
              className="border w-full rounded-md px-2"
              style={{
                "--react-international-phone-height": "39px",
                "--react-international-phone-border-color": "transparent",
                "--react-international-phone-border-radius": "4px",
              }}
            />
          </div>
        </div>
      </div>
      {recruitertype?.IS_SUPERUSER && (
        <div className="grid grid-cols-1 md:grid-cols-1 lg:grid-cols-2 border p-10 gap-4 rounded-lg">
          {/* {recruitertype?.IS_SUPERUSER && (
          <div className="min-w-[300px] lg:col-span-2">
            <ATMFieldLabel>Company Logo</ATMFieldLabel>
            <div className=" h-[200px] ">
              <ATMFilePicker
                name="logo"
                value={values.logo ? URL.createObjectURL(values.logo) : ""}
                onChange={(file) => setFieldValue("logo", file)}
                placeholder="Click to upload or drag and drop"
                accept="image/jpeg,image/png,image/gif"
              />
            </div>
          </div>

          <div className="">
            <Typography
              variant="h6"
              color="blue-gray"
              className="mb-1 font-inter font-medium text-[#344054]"
            >
              Company Logo
            </Typography>
            <div
              {...getRootProps()}
              className={`w-full h-[116px] border ${
                isDragActive ? "border-blue-500" : "border-[#E5E7EB]"
              } rounded-md py-3 px-2 flex items-start justify-start cursor-pointer`}
            >
              <input {...getInputProps()} />
              <div className="flex flex-col justify-center items-center">
                <div className="w-[65px] relative h-[65px] border-dashed border-[#667085] border p-1 rounded-full flex justify-center items-center flex-col">
                  {preview ? (
                    <img src={preview} alt="Preview" className="rounded-full" />
                  ) : null}
                  <div className="absolute -right-2 -bottom-2 p-2 rounded-full border-2 border-white shadow-custom bg-[#1CACBD]">
                    <svg
                      width="14"
                      height="14"
                      viewBox="0 0 13 13"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M12.125 8.375V10.875C12.125 11.2065 11.9933 11.5245 11.7589 11.7589C11.5245 11.9933 11.2065 12.125 10.875 12.125H2.125C1.79348 12.125 1.47554 11.9933 1.24112 11.7589C1.0067 11.5245 0.875 11.2065 0.875 10.875V8.375M9.625 4L6.5 0.875M6.5 0.875L3.375 4M6.5 0.875V8.375"
                        stroke="white"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                  </div>
                </div>
                <p className="pt-1 font-inter font-normal text-[#475467] text-xs">
                  Preview
                </p>
              </div>
              <div className="ml-7 mr-3 mt-1">
                <p className="font-inter font-normal text-[#475467]">
                  {isDragActive
                    ? "Drop the files here..."
                    : "Click to upload or drag and drop SVG, PNG, JPG or GIF (max. 800x400px)"}
                </p>
              </div>
            </div>
          </div>
        )} */}
          {recruitertype?.IS_SUPERUSER && (
            <div>
              <ATMTextField
                type="text"
                name="companyName"
                value={values.companyName || ""}
                label="Company Name"
                placeholder="Enter Company Name"
                onChange={(e) => setFieldValue("companyName", e.target.value)}
                onBlur={handleBlur}
              />
            </div>
          )}
          {recruitertype?.IS_SUPERUSER && (
            <div>
              <ATMTextField
                type="text"
                name="companywebUrl"
                value={values.companywebUrl || ""}
                label="Company Website URL"
                placeholder="http://www.untitledui.com"
                onChange={(e) => setFieldValue("companywebUrl", e.target.value)}
                onBlur={handleBlur}
                required
                disabled={true}
              />
            </div>
          )}

          {/* industry */}
          {recruitertype?.IS_SUPERUSER && (
            <div className="">
              <ATMSelect
                name="industry"
                label="Industry"
                onChange={(newValue) => setFieldValue("industry", newValue)}
                placeholder="Select industry"
                value={values.industry || ""}
                options={data.domain_industry?.map((industry) => ({
                  value: industry?.id,
                  label: industry?.name,
                  key: industry?.id,
                }))}
              />
            </div>
          )}
          {/* companyDomain */}
          {recruitertype?.IS_SUPERUSER && (
            <div className="">
              <ATMSelect
                name="companyDomain"
                label="Industry Category"
                onChange={(newValue) =>
                  setFieldValue("companyDomain", newValue)
                }
                placeholder="Select Industry Category "
                value={values.companyDomain || ""}
                options={domainSubcategories?.map((domain) => ({
                  value: domain?.id,
                  label: domain?.name,
                }))}
              />
            </div>
          )}

          {recruitertype?.IS_SUPERUSER && (
            <div>
              <ATMTextField
                type="text"
                name="companyIdentificationNumber"
                value={values.companyIdentificationNumber || ""}
                label="Company Identification Number"
                placeholder=""
                onChange={(e) =>
                  setFieldValue("companyIdentificationNumber", e.target.value)
                }
                onBlur={handleBlur}
              />
            </div>
          )}

          {recruitertype?.IS_SUPERUSER && (
            <div className="">
              <ATMFieldLabel>Company Social Media</ATMFieldLabel>
              <div className="relative flex w-full">
                <Listbox
                  value={values.companySocialMedia}
                  onChange={(value) =>
                    setFieldValue("companySocialMedia", value)
                  }
                >
                  <ListboxButton
                    className={clsx(
                      "relative block w-20 font-inter font-normal rounded-s-lg py-1.5 pr-8 pl-3 text-left text-sm/6 border border-e-0 h-11 text-[#101828]",
                      "focus:outline-none data-[focus]:outline-2 data-[focus]:-outline-offset-2 data-[focus]:outline-white/25"
                    )}
                  >
                    {values?.companySocialMedia?.svg}
                    <ChevronDownIcon
                      className="group pointer-events-none absolute top-[48%] transform -translate-y-1/2 right-2.5 size-5 fill-black"
                      aria-hidden="true"
                    />
                  </ListboxButton>

                  <ListboxOptions
                    anchor="bottom"
                    transition
                    className={clsx(
                      "w-[var(--button-width)] rounded-xl border border-black bg-white p-1 [--anchor-gap:var(--spacing-1)] focus:outline-none",
                      "transition duration-100 ease-in data-[leave]:data-[closed]:opacity-0"
                    )}
                  >
                    {socialMedia?.map((socialMedia, ind) => {
                      if (!socialMedia) return null;

                      return (
                        <ListboxOption
                          key={socialMedia.id || socialMedia.name || ind}
                          value={socialMedia}
                          className="group flex cursor-default items-center gap-2 rounded-lg py-1.5 px-3 select-none data-[focus]:bg-white/10"
                        >
                          <CheckIcon className="invisible size-6 fill-black group-data-[selected]:visible" />
                          <div className="text-sm/6 text-white">
                            {socialMedia?.svg}
                          </div>
                        </ListboxOption>
                      );
                    })}
                  </ListboxOptions>
                </Listbox>

                <input
                  placeholder=""
                  className="w-full p-2 border rounded-e-lg"
                  value={values.companySocialMediaurl}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  id="companySocialMediaurl"
                  name="companySocialMediaurl"
                />
              </div>
            </div>
          )}

          {recruitertype?.IS_SUPERUSER && (
            <div className="">
              <ATMFieldLabel>Company Mission Statement</ATMFieldLabel>
              <textarea
                value={values.companyMission}
                onChange={handleChange}
                onBlur={handleBlur}
                id="companyMission"
                name="companyMission"
                className="w-full h-[116px] border border-[#E5E7EB] rounded-md resize-none p-3"
                placeholder="Please Enter Your Text Here..."
              ></textarea>
            </div>
          )}
          {recruitertype?.IS_SUPERUSER && (
            <div>
              <ATMTextField
                type="text"
                name="addressOne"
                value={values.addressOne || ""}
                label="Company Address"
                placeholder="Address Line 1"
                onChange={(e) => setFieldValue("addressOne", e.target.value)}
                onBlur={handleBlur}
              />
            </div>
          )}
          {recruitertype?.IS_SUPERUSER && (
            <div>
              <ATMTextField
                type="text"
                name="addressTwo"
                value={values.addressTwo || ""}
                label="Company Address"
                placeholder="Address Line 2"
                onChange={(e) => setFieldValue("addressTwo", e.target.value)}
                onBlur={handleBlur}
              />
            </div>
          )}

          {recruitertype?.IS_SUPERUSER && (
            <div className="">
              <ATMSelect
                name="country"
                label="Country"
                onChange={(newValue) => setFieldValue("country", newValue)}
                placeholder="Select Country"
                value={values.country || ""}
                options={Country.getAllCountries()?.map((country) => ({
                  value: country.isoCode,
                  label: country.name,
                }))}
              />
            </div>
          )}
          {recruitertype?.IS_SUPERUSER && (
            <div>
              <ATMTextField
                type="text"
                name="totalEmployees"
                value={values.totalEmployees || ""}
                label=" Number of Employees"
                placeholder="Enter  Number of Employees"
                onChange={(e) =>
                  setFieldValue("totalEmployees", e.target.value)
                }
                onBlur={handleBlur}
              />
            </div>
          )}
        </div>
      )}
      <div className="flex items-center gap-2 justify-end mb-4">
        <div
          className="w-[97px] h-[32px] rounded-md border flex justify-center items-center border-[#1CACBD] text-[#1CACBD] cursor-pointer"
          onClick={() => setEdit(true)}
        >
          Back
        </div>
        <div className="w-[97px]">
          <ATMButton loading={isSubmitting} label="Update" />
        </div>
      </div>
    </div>
  );
};

export default MyProfileFormLayout;
