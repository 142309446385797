import React from "react";
import { useLocation, useNavigate } from "react-router-dom";
import logo from "../../assets/logo jiva hire.png";

function Navbar() {
  const navigate = useNavigate();
  const location = useLocation();
  const segments = location?.pathname;
  return (
    <>
      <div className="w-full  bg-[#FFFFFF] shadow-sm  px-4  border-b border-b-[#EEEEEE] text-xs font-inter">
        <div className="w-full  mx-auto max-w-screen-lg flex justify-between items-center py-1 flex-wrap">
          <div
            className="w-[120px] h-[40px] cursor-pointer"
            onClick={() => navigate("/recruiter/login")}
          >
            <img className="h-full w-full" src={logo} alt="org_logo" />
          </div>

          <div className="flex justify-between items-center gap-2">
            <button
              type="button"
              onClick={() => navigate("/recruiter/register-user")}
              className="min-w-[80px]  font-semibold py-2 flex justify-center text-[#1CACBD] overflow-hidden items-center border border-[#1CACBD] rounded-xl"
            >
              Free Trial
            </button>
            {segments !== "/recruiter/login" && (
              <button
                type="button"
                onClick={() => navigate("/recruiter/login")}
                className="min-w-[80px]  font-semibold py-2 bg-[#1CACBD] text-white flex justify-center items-center border border-[#1CACBD] rounded-xl"
              >
                Login
              </button>
            )}
          </div>
        </div>
      </div>
    </>
  );
}

export default Navbar;
