import apiSlice from "../../../../app/ApiSlice";

export const talentSearchServicesApi = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getTalentSearchBinay: builder.query({
      query: (body) => {
        return {
          url: `/api/recruiter/talent_search/`,
          method: "POST",
          body: {
            startDate: body?.body?.startDate,
            endDate: body?.body?.endDate,
            query: body?.body?.search,
            filter: body?.body?.filter,
            is_filter: body?.body?.is_filter,
          },
          params: { page: body?.body?.page, page_size: body?.body?.size },
        };
      },
    }),

    getJDMatch: builder.query({
      query: (body) => ({
        url: `/api/recruiter/jd_match/`,
        method: "POST",
        body,
        params: { page: body?.page, size: 10 },
      }),
    }),

    magicSearch: builder.query({
      query: (body) => ({
        url: `/api/recruiter/magic_search/`,
        method: "POST",
        body: { job_id: body?.job_id, org_id: body?.org_id },
        params: { page: body?.page, page_size: body?.size },
      }),
    }),
    filterBy: builder.query({
      query: (body) => ({
        url: `/api/recruiter/filter_search/`,
        method: "POST",
        body,
      }),
    }),
    getResumePdf: builder.query({
      query: (body) => ({
        url: `/api/recruiter/resume_view/`,
        method: "POST",
        body,
        responseHandler: (response) => {
          response.blob();
        },
      }),
    }),
    sendEmail: builder.mutation({
      query: (body) => ({
        url: `/api/recruiter/talent_invite/`,
        method: "POST",
        body,
      }),
    }),
  }),
});

export const {
  useGetTalentSearchBinayQuery,
  useGetJDMatchQuery,
  useMagicSearchQuery,
  useFilterByQuery,
  useSendEmailMutation,
  useGetResumePdfQuery,
} = talentSearchServicesApi;
