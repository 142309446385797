import apiSlice from "../../../../app/ApiSlice";

export const jobStatusApi = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getAllApplications: builder.query({
      query: (body) => ({
        url: `/api/recruiter/job-applications/${body?.id}/`,
        method: "GET",
        params: { page: body?.page, page_size: body?.size },
      }),
    }),
    getAppliedApplications: builder.query({
      query: (body) => ({
        url: `/api/recruiter/job-applications-applied/${body?.id}/`,
        method: "GET",
        params: { page: body?.page, page_size: body?.size },
      }),
    }),
    getShortlistedApplications: builder.query({
      query: (body) => ({
        url: `/api/recruiter/job-applications-shortlisted/${body?.id}/`,
        method: "GET",
        params: { page: body?.page, page_size: body?.size },
      }),
    }),
  }),
});

export const {
  useGetAppliedApplicationsQuery,
  useGetAllApplicationsQuery,
  useGetShortlistedApplicationsQuery,
} = jobStatusApi;
