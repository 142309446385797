import { City, Country, State } from "country-state-city";
import { useFormik } from "formik";
import React, { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { useNavigate } from "react-router";
import Select from "react-select";
import { boolean, number, object, ref, string } from "yup";
import { axiosFromRequest } from "../../api/formApi";
import { useCreateJobMutation } from "../../app/DashboardServices/DashboardServices";
import ATMButton from "../../atoms/ATMButton/ATMButton";
import ATMFieldError from "../../atoms/ATMFieldError/ATMFieldError";
import ATMFieldLabel from "../../atoms/ATMFieldLabel/ATMFieldLabel";
import ATMHeading from "../../atoms/ATMHeading/ATMHeading";
const baseUrl2 = process.env.REACT_APP_VITE_VIDEO_API_URL;

const customStylesCountry = {
  control: (provided) => ({
    ...provided,
    outline: "none",
    boxShadow: "none",
    border: "1px solid #ccc",
    "&:hover": {
      border: "1px solid #ccc",
    },
    "&:focus": {
      border: "1px solid #ccc",
    },
  }),
};

const employementTypeoptions = [
  {
    label: "Full-time employment",
    value: "Full-time employment",
  },
  {
    label: "Part-Time employment contract",
    value: "Part-Time employment contract",
  },
  {
    label: "Temporary employment contract",
    value: "Temporary employment contract",
  },
  { label: "Freelance contract", value: "Freelance contract" },
  { label: "Internship agreement", value: "Internship agreement" },
  { label: "Volunteer agreement", value: "Volunteer agreement" },
];

const workLocationData = [
  { label: "On-Site", value: "On-Site" },
  { label: "Remote", value: "Remote" },
  { label: "Hybrid", value: "Hybrid" },
];

const jobDuration = [
  { label: "Hours", value: "Hours" },
  { label: "Days", value: "Days" },
  { label: "Weeks", value: "Weeks" },
  { label: "Months", value: "Months" },
  { label: "Years", value: "Years" },
];

export const salaryType = [
  { label: "Yearly", value: "Yearly" },
  { label: "Hourly", value: "Hourly" },
];

export const currencyType = [
  { value: "INR", label: "(INR)-₹" },
  { value: "USD", label: "(USD)-$" },
  { value: "GBP", label: "(GBP)-£" },
  { value: "SGD", label: "(SGD)-$" },
  { value: "AUD", label: "(AUD)-$" },
  { value: "EUR", label: "(EUR)-€" },
  { value: "CAD", label: "(CAD)-$" },
  { value: "CHF", label: "(CHF)-CHF" },
  { value: "PLN", label: "(PLN)-Zł" },
  { value: "NZD", label: "(NZD)-$" },
  { value: "CNY", label: "(CNY)-¥" },
  { value: "JPY", label: "(JPY)-¥" },
  { value: "PHP", label: "(PHP)-₱" },
  { value: "IDR", label: "(IDR)-RP" },
  { value: "LKR", label: "(LKR)-Rs" },
  { value: "MYR", label: "(MYR)-RM" },
  { value: "ZAR", label: "(ZAR)-R" },
  { value: "AED", label: "(AED)-AED" },
  { value: "SEK", label: "(SEK)-kr" },
  { value: "TWD", label: "(TWD)-NT$" },
  { value: "HKD", label: "(HKD)-HK$" },
  { value: "HUF", label: "(HUF)-Ft" },
  { value: "MXN", label: "(MXN)-$" },
  { value: "CRC", label: "(CRC)-₡" },
  { value: "RON", label: "(RON)-lei" },
  { value: "THB", label: "(THB)-฿" },
  { value: "ARS", label: "(ARS)-$" },
  { value: "VND", label: "(VND)-₫" },
  { value: "BGN", label: "(BGN)-BGN" },
];

function CreateJob() {
  const navigate = useNavigate();

  const [countries, setCountries] = useState([]);
  const [states, setStates] = useState([]);
  const [cities, setCities] = useState([]);
  const [promptLoading, setPromptLoading] = useState(false);
  const [promptOpen, setPromptOpen] = useState(true);
  const [submitting, setSubmitting] = useState(false);

  useEffect(() => {
    setCountries(Country.getAllCountries());
  }, []);
  const [addNewJob] = useCreateJobMutation();
  const apiKey = "ZBFezMmBuh2pkEXttdz6SwOeMgGGsG2b";

  const formik = useFormik({
    initialValues: {
      Prompt: "",
      jobTittle: "",
      jobDescription: "",
      workLocation: { label: "On-Site", value: "On-Site" },
      expierence: "",
      externalCompany: false,
      employementType: employementTypeoptions[0],
      jobDuration: jobDuration[0],
      jobDurationNumber: "",
      salaryFrom: "",
      salaryTo: "",
      salaryCurrency: currencyType?.[0],
      salaryType: { label: "Yearly", value: "Yearly" },
      country: null,
      state: null,
      city: null,
      zipCode: "",
      companyName: "",
      vacancies: 1,
      createJobSkillJson: "",
    },
    validationSchema: object().shape({
      jobTittle: string().required("Job Title is required"),
      jobDescription: string().required("Job Description is required"),
      workLocation: object().required("Work Location is required"),
      expierence: number().required("Experience is required"),
      externalCompany: boolean(),
      vacancies: number()
        .required("Vacancies is required")
        .min(1, "Vacancies must be at least 1"),
      companyName: string().when("externalCompany", {
        is: true,
        then: () => string().required("Company Name is required"),
      }),

      salaryFrom: number().min(0, "Minimum Salary must be a positive number"),

      salaryTo: number().min(
        ref("salaryFrom"),
        "Maximum Salary must be greater than or equal to Minimum Salary"
      ),

      country: object()
        .shape({
          value: string().required("Country value is required"),
          label: string().required("Country label is required"),
        })
        .required("Country is required"),
      // state: object()
      //   .shape({
      //     value: string().required("State value is required"),
      //     label: string().required("State label is required"),
      //   })
      //   .required("State is required"),
      // city: object()
      //   .shape({
      //     value: string().required("City value is required"),
      //     label: string().required("City label is required"),
      //   })
      //   .required("City is required"),
      // zipCode: number().required("Zipcode is required"),
    }),
    // validationSchema: createJobSchema,
    onSubmit: async (values, { resetForm }) => {
      setSubmitting(true);
      const formData = new FormData();
      formData.append("title", values.jobTittle || "");
      formData.append("description", values.jobDescription || "");
      formData.append(
        "aspect_of_work_location",
        values.workLocation?.value || ""
      );
      formData.append("experience", values.expierence || "");
      formData.append("company_name", values.companyName || "");
      formData.append("external_company", values.externalCompany || "");
      formData.append("employment_type", values.employementType?.value || "");
      formData.append("duration", values.jobDurationNumber || "");
      formData.append("duration_type", values.jobDuration?.value || "");
      formData.append("currency", values?.salaryCurrency?.label || "");
      formData.append("salary_from", values?.salaryFrom || 0);
      formData.append("salary_to", values?.salaryTo || 0);
      formData.append("salary_type", values.salaryType?.value || "");
      formData.append("country", values.country?.label || "");
      formData.append("state", values.state?.label || "");
      formData.append("city", values.city?.label || "");
      formData.append("zipcode", values.zipCode || "");
      formData.append("vacancies", values.vacancies || 0);
      formData.append("status", true);
      formData.append(
        "job_skill_json",
        JSON.stringify(values.createJobSkillJson)
      );

      addNewJob(formData).then((res) => {
        if (res?.error) {
          toast.error(res?.error?.data?.message);
          setSubmitting(false);
        } else {
          if (res?.data?.success) {
            setSubmitting(false);
            toast.success(res?.data?.message);
            navigate(
              `/recruit/job-status/Recruiter-view/${res.data.data.job.id}`
            );
          } else {
            toast.error(res?.error?.data?.message);
            setSubmitting(false);
          }
        }
        setSubmitting(false);
      });
    },
  });

  const handleCountryChange = (option) => {
    formik.setFieldValue("country", option);
    setStates(State.getStatesOfCountry(option.value));
    setCities([]);
    formik.setFieldValue("state", null);
    formik.setFieldValue("city", null);
  };

  const handleStateChange = (option) => {
    formik.setFieldValue("state", option);
    setCities(City.getCitiesOfState(formik.values.country.value, option.value));
    formik.setFieldValue("city", null);
  };

  const handleCityChange = (option) => {
    formik.setFieldValue("city", option);
  };

  const handleGenerateDescription = async () => {
    if (formik?.values?.Prompt.length < 20) return true;
    setPromptLoading(true);
    try {
      const formData2 = new FormData();
      formData2.append("prompt", formik.values.Prompt);

      const response2 = await axiosFromRequest(
        `${baseUrl2}/generate-job-description/?x_api_key=${apiKey}`,
        {
          method: "POST",
          data: formData2,
        }
      );
      if (response2.status === 200) {
        setPromptLoading(false);
        // Populate the job description field with the generated description
        formik.setFieldValue("jobDescription", response2.data.job_description);
        formik.setFieldValue("jobTittle", response2.data.job_title);
        const formData1 = new FormData();
        formData1.append("job_description", response2.data.job_description);
        formData1.append("job_title", response2.data.job_title);
        toast.success(response2?.data?.message);
      } else if (response2.status === 400) {
        toast.error(
          response2?.error?.data?.message ||
            "Failed to generate job description"
        );
      }
    } catch (error) {
      if (error.response && error.response.status === 400) {
        toast.error(error.response.data.message);
      } else {
        toast.error("An unexpected error occurred");
      }
    }
  };
  return (
    <div className="p-2 overflow-hidden">
      <ATMHeading>Create Job</ATMHeading>
      <div className=" flex flex-col gap-4 text-xs font-inter mt-2">
        <form
          onSubmit={formik.handleSubmit}
          className="w-full flex flex-col gap-5 border rounded-md p-4"
        >
          <div className="flex items-center gap-2">
            <div
              className={`w-14 relative h-6 flex items-center rounded-full p-1 cursor-pointer ${
                promptOpen ? "bg-[#15DB35]" : "bg-gray-300"
              }`}
              onClick={() => {
                formik.setFieldValue("jobTittle", "");
                formik.setFieldValue("jobDescription", "");
                setPromptOpen(!promptOpen);
              }}
            >
              <div
                className={`bg-white w-4 h-4 rounded-full shadow-md transform duration-500 ease-in-out ${
                  promptOpen ? "translate-x-[32px]" : ""
                }`}
              ></div>
            </div>
            <div className="text-md text-slate-500 font-bold">
              {" "}
              {promptOpen ? "Magic Job Creation" : "Manual Job Creation"}
            </div>
          </div>
          {promptOpen && (
            <div className="">
              <ATMFieldLabel required>Prompt</ATMFieldLabel>
              <input
                type="text"
                name="Prompt"
                value={formik.values.Prompt}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                className="w-full border-2 resize-none h-[40px] p-3 rounded-lg outline-none"
              ></input>
              <div>
                {formik?.values?.Prompt &&
                formik?.values?.Prompt.length < 20 ? (
                  <ATMFieldError>Minimum 20 letters Required</ATMFieldError>
                ) : null}
              </div>
              <div className="w-[180px] mt-4">
                <ATMButton
                  onClick={handleGenerateDescription}
                  type="button"
                  loading={promptLoading}
                  label="Generate Job Description"
                  isIcon
                />
              </div>
            </div>
          )}

          <div className="">
            <ATMFieldLabel required>Job Title</ATMFieldLabel>
            <input
              type="text"
              placeholder=""
              className=" border border-gray-300 w-full p-[10px] rounded-md outline-none"
              value={formik.values.jobTittle}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              id="jobTittle"
              name="jobTittle"
              disabled={promptOpen && !formik.values.jobTittle}
            />
            {formik.errors.jobTittle && formik.touched.jobTittle ? (
              <div>
                <ATMFieldError>{formik.errors.jobTittle}</ATMFieldError>
              </div>
            ) : null}
          </div>
          <div>
            <ATMFieldLabel required>Summary (Copy your JD here)</ATMFieldLabel>
            <textarea
              className="w-full border border-gray-300  p-3 rounded-lg outline-none  "
              value={formik.values.jobDescription}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              rows={10}
              id="jobDescription"
              name="jobDescription"
              disabled={promptOpen && !formik.values.jobDescription}
            ></textarea>
            {formik.errors.jobDescription && formik.touched.jobDescription ? (
              <div>
                <ATMFieldError>{formik.errors.jobDescription}</ATMFieldError>
              </div>
            ) : null}
          </div>
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
            <div className="">
              <ATMFieldLabel required>Aspect of Work Location</ATMFieldLabel>
              <div className="">
                <Select
                  value={formik.values.workLocation}
                  onChange={(newValue) =>
                    formik.setFieldValue("workLocation", newValue)
                  }
                  options={workLocationData}
                  placeholder=""
                  className="w-full h-full"
                  styles={{
                    control: (provided) => ({
                      ...provided,
                      outline: "none",
                      boxShadow: "none",
                      border: "1px solid #ccc",
                      "&:hover": {
                        border: "1px solid #ccc",
                      },
                      "&:focus": {
                        border: "1px solid #ccc",
                      },
                    }),
                  }}
                />
              </div>

              {formik.errors.workLocation && formik.touched.workLocation ? (
                <div>
                  <ATMFieldError>{formik.errors.workLocation}</ATMFieldError>
                </div>
              ) : null}
            </div>
            <div className="">
              <ATMFieldLabel required>
                Total years of experience required
              </ATMFieldLabel>
              <input
                size="lg"
                placeholder="Enter Total Experience"
                className=" border border-gray-300 w-full p-[10px] rounded-md outline-none"
                value={formik.values.expierence}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                id="expierence"
                name="expierence"
              />
              {formik.errors.expierence && formik.touched.expierence ? (
                <div>
                  <ATMFieldError>{formik.errors.expierence}</ATMFieldError>
                </div>
              ) : null}
            </div>
            <div className="">
              <ATMFieldLabel required>Number Of Vacancies</ATMFieldLabel>
              <input
                size="lg"
                placeholder="Enter Number of Vacancies"
                className=" border border-gray-300 w-full p-[10px] rounded-md outline-none"
                value={formik.values.vacancies}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                id="vacancies"
                name="vacancies"
              />
              {formik.errors.vacancies && formik.touched.vacancies ? (
                <div>
                  <ATMFieldError>{formik.errors.vacancies}</ATMFieldError>
                </div>
              ) : null}
            </div>
          </div>
          <div className="grid grid-cols-1 lg:grid-cols-3 gap-3">
            <div className="border  py-5 rounded-lg">
              <div className="w-fit flex justify-between items-center gap-1  text-[#344054] font-medium p-3">
                <input
                  type="checkbox"
                  checked={formik.values.externalCompany}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  name="externalCompany"
                />
                <ATMFieldLabel> External Company?</ATMFieldLabel>
              </div>
              <p className=" font-normal px-3 mt-1">
                You can put any external company name here for future reference
              </p>
              {formik.values.externalCompany && (
                <div className="px-3 mt-2">
                  <ATMFieldLabel required> Client Name</ATMFieldLabel>
                  <input
                    size="lg"
                    placeholder=""
                    className=" border border-gray-300 w-full p-[10px] rounded-md outline-none"
                    value={formik.values.companyName}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    id="companyName"
                    name="companyName"
                  />
                  {formik.errors.companyName && formik.touched.companyName ? (
                    <div>
                      <ATMFieldError>{formik.errors.companyName}</ATMFieldError>
                    </div>
                  ) : null}
                </div>
              )}
            </div>
            <div className="border lg:col-span-2 flex flex-col gap-3 w-full   py-5 rounded-lg px-3">
              <div className="grid grid-cols-2  gap-6">
                <div className="">
                  <ATMFieldLabel required> Employment Type </ATMFieldLabel>
                  <div className="">
                    <Select
                      value={formik.values.employementType}
                      onChange={(newValue) => {
                        formik.setFieldValue("employementType", newValue);
                        formik.setFieldValue("jobDurationNumber", "");
                      }}
                      options={employementTypeoptions}
                      placeholder=""
                      className="w-full h-full"
                      styles={{
                        control: (provided) => ({
                          ...provided,
                          outline: "none",
                          boxShadow: "none",
                          border: "1px solid #ccc",
                          "&:hover": {
                            border: "1px solid #ccc",
                          },
                          "&:focus": {
                            border: "1px solid #ccc",
                          },
                        }),
                      }}
                    />
                  </div>
                </div>
                <div
                  className={`${
                    formik?.values?.employementType?.value ===
                    "Full-time employment"
                      ? "text-gray-300"
                      : ""
                  }`}
                >
                  {" "}
                  {formik?.values?.employementType?.value ===
                  "Full-time employment" ? (
                    <ATMFieldLabel> Employment Duration</ATMFieldLabel>
                  ) : (
                    <ATMFieldLabel required> Employment Duration</ATMFieldLabel>
                  )}
                  <div className="flex  justify-between  items-center  h-[38px]  rounded-lg  bg-white  border border-gray-300 z-10">
                    <input
                      placeholder="Enter Number"
                      className="w-full  rounded-md outline-none h-full pl-2"
                      value={formik.values.jobDurationNumber}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      id="jobDurationNumber"
                      name="jobDurationNumber"
                      type="number"
                      disabled={
                        formik?.values?.employementType?.value ===
                        "Full-time employment"
                      }
                    />

                    <div className="">
                      <Select
                        value={formik.values.jobDuration}
                        onChange={(newValue) =>
                          formik.setFieldValue("jobDuration", newValue)
                        }
                        options={jobDuration}
                        placeholder=""
                        className={` min-w-[100px] border-l  pr-1 h-fit ${
                          formik?.values?.employementType?.value ===
                          "Full-time employment"
                            ? "cursor-not-allowed"
                            : ""
                        } `}
                        styles={{
                          control: (provided) => ({
                            ...provided,
                            border: "none",
                            boxShadow: "none",
                            backgroundColor: "transparent",
                            "&:focus": {
                              border: "none",
                              boxShadow: "none",
                            },
                          }),
                        }}
                        isDisabled={
                          formik?.values?.employementType?.value ===
                          "Full-time employment"
                        }
                      />
                    </div>
                  </div>
                  {formik.errors.jobDurationNumber &&
                  formik.touched.jobDurationNumber ? (
                    <div>
                      <ATMFieldError>
                        {" "}
                        {formik.errors.jobDurationNumber}
                      </ATMFieldError>
                    </div>
                  ) : null}
                </div>
              </div>

              <div className=" border rounded-md ">
                <div className="bg-slate-100 px-4 py-1 rounded-t-md ">
                  <ATMFieldLabel>Salary Range</ATMFieldLabel>
                </div>

                <div className="gap-4 items-start h-auto grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 mt-2 pt-1 pb-5 px-4 ">
                  <div className="">
                    <ATMFieldLabel>Currency</ATMFieldLabel>
                    <Select
                      value={formik.values.salaryCurrency}
                      onChange={(newValue) =>
                        formik.setFieldValue("salaryCurrency", newValue)
                      }
                      options={currencyType}
                      placeholder=""
                      className="min-w-[120px] h-full"
                      styles={{
                        control: (provided) => ({
                          ...provided,
                          outline: "none",
                          boxShadow: "none",
                          border: "1px solid #ccc",
                          "&:hover": {
                            border: "1px solid #ccc",
                          },
                          "&:focus": {
                            border: "1px solid #ccc",
                          },
                        }),
                      }}
                    />
                  </div>

                  <div className="">
                    <ATMFieldLabel>Minimum</ATMFieldLabel>
                    <input
                      placeholder=" Ex. 4000"
                      className=" border border-gray-300 w-full p-[10px] rounded-md outline-none"
                      value={formik.values.salaryFrom}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      id="salaryFrom"
                      name="salaryFrom"
                    />
                    {formik.errors.salaryFrom && formik.touched.salaryFrom && (
                      <div>
                        <ATMFieldError>
                          {" "}
                          {formik.errors.salaryFrom}
                        </ATMFieldError>
                      </div>
                    )}
                  </div>

                  <div className="">
                    <ATMFieldLabel>Maximum</ATMFieldLabel>
                    <input
                      placeholder=" Ex. 5000"
                      className=" border border-gray-300 w-full p-[10px] rounded-md outline-none"
                      value={formik.values.salaryTo}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      id="salaryTo"
                      name="salaryTo"
                    />
                    {formik.errors.salaryTo && formik.touched.salaryTo && (
                      <div>
                        <ATMFieldError> {formik.errors.salaryTo}</ATMFieldError>
                      </div>
                    )}
                  </div>

                  <div className=" ">
                    <ATMFieldLabel>Salary Mode</ATMFieldLabel>
                    <Select
                      value={formik.values.salaryType}
                      onChange={(newValue) =>
                        formik.setFieldValue("salaryType", newValue)
                      }
                      options={salaryType}
                      placeholder=""
                      className="min-w-[100px] h-full"
                      styles={{
                        control: (provided) => ({
                          ...provided,
                          outline: "none",
                          boxShadow: "none",
                          border: "1px solid #ccc",
                          "&:hover": {
                            border: "1px solid #ccc",
                          },
                          "&:focus": {
                            border: "1px solid #ccc",
                          },
                        }),
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="w-full rounded-lg border ">
            <div className="bg-slate-100 px-4 py-1 rounded-t-md ">
              <ATMFieldLabel>Location</ATMFieldLabel>
            </div>
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4 py-5 px-3  ">
              <div className="w-full">
                <ATMFieldLabel required> Country</ATMFieldLabel>
                <Select
                  styles={customStylesCountry}
                  options={countries.map((country) => ({
                    value: country.isoCode,
                    label: country.name,
                  }))}
                  onChange={handleCountryChange}
                  value={formik.values.country}
                  placeholder="Select Country"
                />
                {formik.errors.country && formik.touched.country ? (
                  <div>
                    <ATMFieldError> {formik.errors.country}</ATMFieldError>
                  </div>
                ) : null}
              </div>
              <div className="w-full">
                <ATMFieldLabel> State</ATMFieldLabel>
                <Select
                  styles={customStylesCountry}
                  options={states.map((state) => ({
                    value: state.isoCode,
                    label: state.name,
                  }))}
                  onChange={handleStateChange}
                  value={formik.values.state}
                  placeholder="Select State"
                />
                {formik.errors.state && formik.touched.state ? (
                  <div>
                    <ATMFieldError> {formik.errors.state}</ATMFieldError>
                  </div>
                ) : null}
              </div>
              <div className="w-full">
                <ATMFieldLabel> City</ATMFieldLabel>
                <Select
                  styles={customStylesCountry}
                  options={cities.map((city) => ({
                    value: city.name,
                    label: city.name,
                  }))}
                  onChange={handleCityChange}
                  value={formik.values.city}
                  placeholder="Select City"
                />
                {formik.errors.city && formik.touched.city ? (
                  <ATMFieldError> {formik.errors.city}</ATMFieldError>
                ) : null}
              </div>
              <div className="w-full flex flex-col">
                <ATMFieldLabel> Zip Code</ATMFieldLabel>
                <input
                  placeholder="Enter Zip Code"
                  className=" border border-gray-300 w-full p-[10px] rounded-md outline-none "
                  value={formik.values.zipCode}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  id="zipCode"
                  name="zipCode"
                />
                {formik.errors.zipCode && formik.touched.zipCode ? (
                  <div>
                    <ATMFieldError> {formik.errors.zipCode}</ATMFieldError>
                  </div>
                ) : null}
              </div>
            </div>
          </div>
          <div className="w-[150px]">
            <ATMButton
              loading={submitting}
              label="Publish Job Description"
              isIcon
            />
          </div>
        </form>
      </div>
    </div>
  );
}

export default CreateJob;
