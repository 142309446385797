import axios from "axios";
import { useFormik } from "formik";
import React, { useState } from "react";
import toast from "react-hot-toast";
import { useNavigate, useParams } from "react-router";
import ATMFieldError from "../../atoms/ATMFieldError/ATMFieldError";
import ATMFieldLabel from "../../atoms/ATMFieldLabel/ATMFieldLabel";
import Dailog from "../../components/common/Dailog";
import HeroHeading from "../../components/recruiters/HeroHeading";
import { resetPasswordSchema } from "../../schemas/recruiterSchema";

import image from "../../assets/reset_password.png";
import ATMButton from "../../atoms/ATMButton/ATMButton";
const baseUrl = process.env.REACT_APP_VITE_BACKEND_API_URL;

function ResetPassword() {
  const { id } = useParams();
  const [open, setOpen] = useState(false);
  const navigate = useNavigate();

  const formik = useFormik({
    initialValues: {
      password: "",
      confirmPassword: "",
    },
    validationSchema: resetPasswordSchema,
    onSubmit: async (values, { resetForm, setSubmitting }) => {
      setSubmitting(true);
      try {
        const response = await axios.post(
          `${baseUrl}/api/recruiter/new_password/${id}`,
          {
            password: values.password,
            password2: values.confirmPassword,
          },
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        );

        if (response.status === 201) {
          resetForm();
          setOpen(true);
          setSubmitting(false);
        } else if (response.status === 400) {
          toast.error("Failed to add member");
        } else {
          toast.error("Unexpected status code: " + response.status);
        }
      } catch (error) {
        if (error.response) {
          // Server responded with a status code other than 2xx
          if (error.response.status === 400) {
            toast.error(
              error.response.data.message ||
                "Bad request. Please check your input."
            );
          } else if (error.response.status === 401) {
            toast.error(error.response?.data?.message);
          } else if (error.response.status === 404) {
            toast.error(error.response?.data?.message);
          } else if (error.response.status >= 500) {
            toast.error("Server error. Please try again later.");
          } else {
            toast.error("Unexpected error occurred: " + error.response.status);
          }
        } else if (error.request) {
          // No response was received (network error)
          toast.error("Network error. Please check your internet connection.");
        } else {
          // Something else went wrong in setting up the request
          toast.error("An error occurred while processing the request.");
        }
      } finally {
        setSubmitting(false);
      }
    },
  });
  return (
    <>
      <Dailog
        open={open}
        setOpen={setOpen}
        heading="Your password has been successfully reset! We recommend choosing a strong password to ensure your account security. Now, you can log in with your new password and continue using our platform."
        para="All set! Your password has been reset. Simply use your new password to log in and get right back to where you left off."
        btnText="Login"
        btnLink="/recruiter/login"
      />
      <div className="flex justify-between font-inter text-xs items-start w-full ">
        <div className="w-full h-full mx-auto max-w-screen-2xl flex justify-center mt-2 font-inter tex-xs ">
          <div className=" p-1 bg-white flex flex-col gap-4 w-full ">
            <div>
              <HeroHeading
                heading={
                  " Login to our portal which is one Step Closer to Efficiency"
                }
                para={` Welcome to our streamlined portal, where each login propels you
                into a world of effortless HR management.`}
              />
            </div>
            <div className="grid  grid-cols-1 md:grid-cols-5 items-start mt-2 select-none gap-2 h-fit">
              <div className=" md:col-span-2 h-fit mt-5">
                <img
                  src={image}
                  alt="reset-image"
                  className="object-contain md:block hidden w-full h-[260px] lg:h-[240px] rounded-md    "
                />
              </div>
              <div className=" md:col-span-3 ">
                <form
                  onSubmit={formik.handleSubmit}
                  className="w-full  flex flex-col gap-6 border p-4 rounded-lg"
                >
                  <div className="w-full">
                    <ATMFieldLabel required> Set your password</ATMFieldLabel>
                    <input
                      placeholder="Enter your password"
                      className="border w-full  px-2 h-[38px] rounded-md outline-none"
                      value={formik.values.password}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      id="password"
                      name="password"
                    />
                    {formik.errors.password && formik.touched.password ? (
                      <ATMFieldError>{formik.errors.password}</ATMFieldError>
                    ) : null}
                  </div>
                  <div className="w-full">
                    <ATMFieldLabel required>
                      {" "}
                      Re-enter your password
                    </ATMFieldLabel>
                    <input
                      placeholder="Enter your password"
                      className="border w-full px-2 h-[38px] rounded-md outline-none"
                      value={formik.values.confirmPassword}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      id="confirmPassword"
                      name="confirmPassword"
                    />
                    {formik.errors.confirmPassword &&
                    formik.touched.confirmPassword ? (
                      <ATMFieldError>
                        {formik.errors.confirmPassword}
                      </ATMFieldError>
                    ) : null}
                  </div>
                  <div className="w-full px-4">
                    <ol className="list-decimal text-[11px] flex flex-col gap-1 text-[#475467]">
                      <li>
                        Choose a password that is at least 8 characters long for
                        added security
                      </li>
                      <li>
                        Make your password strong by including a mix of
                        uppercase letters, lowercase letters, numbers, and
                        special characters
                      </li>
                    </ol>
                  </div>
                  <div className="w-full flex justify-end">
                    <div className="w-[100px]">
                      <ATMButton
                        label="Submit"
                        authicon
                        loading={formik.isSubmitting}
                      />
                    </div>
                  </div>
                </form>
                <div className="font-inter text-[11px] p-4">
                  <ATMFieldLabel>Want to reach out directly?</ATMFieldLabel>
                  <div className="text-slate-600">
                    We're here to help!{" "}
                    <span
                      onClick={() => navigate("/recruiter/support")}
                      className="text-[#1CACBD] cursor-pointer"
                    >
                      Contact our support
                    </span>{" "}
                    team with any questions.
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default ResetPassword;
