import { Form, Formik } from "formik";
import React from "react";
import toast from "react-hot-toast";
import { useDispatch, useSelector } from "react-redux";
import { object } from "yup";
import { axiosCandidateFromRequest } from "../../../../api/formApi";
import { setRefresh } from "../../../../features/refresh/refresh";
import OtherInformationFormLayout from "../Layout/OtherInformationFormLayout";
const baseUrl = process.env.REACT_APP_VITE_BACKEND_API_URL;

const AddOtherInformationFormWrapper = ({
  setOtherInformationAdd,
  userData,
}) => {
  const dispatch = useDispatch();
  const refresh = useSelector((state) => state.refresh.refresh);
  const initialValues = {
    residence: false,
    jobPreference: "",
    visa: "",
    salary: "",
    salaryType: "",
    country: null,
    state: null,
    city: null,
    standard: false,
    hobbie: "",
  };

  const validationSchema = object().shape({});

  const handleSubmit = async (values, { resetForm, setSubmitting }) => {
    setSubmitting(true);
    try {
      const formData = new FormData();
      formData.append("is_residence", values.residence);
      formData.append("visa", values.visa);
      formData.append("job_preference", values.jobPreference);
      formData.append("salary", values.salary);
      formData.append("salary_type", values.salaryType?.label || "");
      formData.append("salary_standard", values.standard);

      const formData2 = new FormData();

      formData2.append("country", values.country ? values.country.label : "");
      formData2.append("state", values.state ? values.state.label : "");
      formData2.append("city", values.city ? values.city.label : "");

      const response = await axiosCandidateFromRequest(
        `${baseUrl}/api/candidate/user/`,
        {
          method: "PATCH",
          data: formData,
        }
      );
      let response2;
      if (userData?.preferredlocation_candidate[0]?.id) {
        response2 = await axiosCandidateFromRequest(
          `${baseUrl}/api/candidate/preferred_location/${userData?.preferredlocation_candidate[0]?.id}/`,
          {
            method: "PATCH",
            data: formData2,
          }
        );
      } else {
        response2 = await axiosCandidateFromRequest(
          `${baseUrl}/api/candidate/preferred_location/`,
          {
            method: "POST",
            data: formData2,
          }
        );
      }
      let response3;
      if (userData?.candidatehobbies_candidate?.[0]) {
        // If there's an id, it's an update, so use PATCH
        response3 = await axiosCandidateFromRequest(
          `${baseUrl}/api/candidate/hobbies/${userData?.candidatehobbies_candidate?.[0]?.id}/`,
          {
            method: "PATCH",
            data: {
              hobbies: values?.hobbie,
            },
          }
        );
      } else {
        // Otherwise, it's a new resource, so use POST
        response3 = await axiosCandidateFromRequest(
          `${baseUrl}/api/candidate/hobbies/`,
          {
            method: "POST",
            data: {
              hobbies: values?.hobbie,
            },
          }
        );
      }
      if (response2)
        if (response3)
          if (response.status === 200) {
            setSubmitting(false);
            setOtherInformationAdd(false);
            toast.success("Data updated successfully");
            dispatch(setRefresh(!refresh));
          } else if (response.status === 400) {
            toast.error("Failed to update data");
          }
    } catch (error) {
      if (error.response && error.response.status === 400) {
        toast.error(error.response.data.message);
      }
    } finally {
      setSubmitting(false);
    }
  };

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={handleSubmit}
      validationSchema={validationSchema}
      enableReinitialize
    >
      {(formikProps) => (
        <Form>
          <OtherInformationFormLayout
            formType="ADD"
            formikProps={formikProps}
          />
        </Form>
      )}
    </Formik>
  );
};

export default AddOtherInformationFormWrapper;
