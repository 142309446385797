import React, { useEffect, useState } from "react";
import toast from "react-hot-toast";
import Select from "react-select";
import { axiosCandidateRequest } from "../../../api/api";
import ATMCircularProgress from "../../../atoms/ATMCircularProgress/ATMCircularProgress";
import ATMFieldLabel from "../../../atoms/ATMFieldLabel/ATMFieldLabel";
import ATMHeading from "../../../atoms/ATMHeading/ATMHeading";
import ATMPagination from "../../../atoms/ATMPagination/ATMPagination";
import { useNavigate } from "react-router";
import { formatDate } from "../ProfileDetails/View/ProfileDetails";
const baseUrl = process.env.REACT_APP_VITE_BACKEND_API_URL;

const options = [
  { value: "", label: "All" },
  { value: "Applied", label: "Applied" },
  { value: "Shortlisted", label: "Shortlisted" },
];

const MyJobApplication = () => {
  const navigate = useNavigate();
  const [userData, setuserData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [selectValue, setSelectValue] = useState(options?.[0]);
  const [pagination, setPagination] = useState({
    currentPage: 1,
    itemsPerPage: 8,
  });

  useEffect(() => {
    setPagination({
      currentPage: 1,
      itemsPerPage: 8,
    });
  }, [selectValue]);
  const handlePageChange = (page) => {
    setPagination((prev) => ({ ...prev, currentPage: page }));
  };

  const handleItemsPerPageChange = (itemsPerPage) => {
    setPagination((prev) => ({ ...prev, itemsPerPage, currentPage: 1 }));
  };
  useEffect(() => {
    const getAllAplications = async () => {
      setIsLoading(true);
      try {
        const response = await axiosCandidateRequest(
          `${baseUrl}/api/candidate/job_applications/?page=${pagination?.currentPage}&page_size=${pagination?.itemsPerPage}`,
          {
            method: "POST",
            data: { status: selectValue?.value },
          }
        );
        if (response.status === 200) {
          setuserData(response?.data);
          setIsLoading(false);
        } else if (response.status === 400) {
          toast.error("Failed to get data");
        }
      } catch (error) {
        if (error.response && error.response.status === 400) {
          toast.error(error.response.data.message);
        } else {
          toast.error("An unexpected error occurred");
        }
      } finally {
        setIsLoading(false);
      }
    };
    getAllAplications();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectValue, pagination]);

  return (
    <div className="min-w-[1200px]">
      <div className="p-2 flex justify-between  items-center ">
        <div className="flex gap-1 items-center">
          <ATMHeading>My Applications</ATMHeading>
          <span className="w-5 h-5 bg-[#1CACBD] text-white text-[11px] items-center justify-center flex border rounded-full bg-">
            {userData?.count}
          </span>
        </div>

        <div className="flex items-center gap-3">
          <ATMFieldLabel>Status</ATMFieldLabel>
          <div className="border rounded-md  border-[#1CACBD]">
            <Select
              value={selectValue}
              onChange={(newValue) => setSelectValue(newValue)}
              options={options}
              placeholder=""
              isSearchable={false}
              className="custom-select w-[135px]"
              styles={{
                control: (provided) => ({
                  ...provided,
                  border: "none",
                  boxShadow: "none",
                  "&:focus": {
                    border: "none",
                    boxShadow: "none",
                  },
                }),
              }}
            />
          </div>
        </div>
      </div>

      {/* <div className="px-2">
        <ul className="mt-5 flex   font-inter font-medium gap-5">
          {["Active Jobs", "Inactive Jobs"].map((type) => (
            <li
              key={type}
              className={`pb-2  cursor-pointer ${
                jobType === type ? "  -2 border-[#101828]" : ""
              }`}
              onClick={() => handleJobTypeChange(type)}
            >
              {type}
            </li>
          ))}
        </ul>
      </div> */}
      {isLoading ? (
        <span className="flex justify-center h-full items-center">
          <ATMCircularProgress />
        </span>
      ) : (
        <div className="">
          {userData?.count === 0 ? (
            <span className="w-full flex justify-center mt-2">
              No Data Found
            </span>
          ) : (
            <div className="">
              <div className="overflow-x-auto  px-2  text-[11px]">
                <div className="grid grid-cols-12 gap-2  text-center rounded-md font-semibold  p-0.5">
                  <div className="col-span-2">Job Title</div>
                  <div>Job Status</div>
                  <div className="col-span-2">Company Details</div>
                  <div>Account Manager</div>
                  <div>Job Type</div>
                  <div>Job Location</div>
                  <div>Salary Offered</div>
                  <div>Date</div>
                  <div>Application Status</div>
                  <div></div>
                </div>

                {userData?.results?.map((userData) => (
                  <div
                    key={userData.id}
                    className="grid grid-cols-12 gap-2 items-center border border-slate-50 rounded-md text-center  mt-1  hover:bg-slate-200  even:bg-gray-100 odd:bg-slate-50 p-0.5"
                  >
                    <div className="col-span-2">{userData?.job.title}</div>
                    <div className="">
                      {userData?.job.status ? (
                        <span className="text-green-500 px-4 rounded-md">
                          Active
                        </span>
                      ) : (
                        <span className="text-red-500 px-4 rounded-md">
                          Inactive
                        </span>
                      )}
                    </div>
                    <div className="flex items-start gap-2 col-span-2">
                      <div className="w-10 h-10">
                        <img
                          className="w-full object-contain h-full"
                          src={userData?.job?.company?.company_logo}
                          alt="c_logo"
                        />
                      </div>
                      <div className="flex flex-col items-start text-[11px] gap-1">
                        <h1 className="text-slate-800">
                          {userData?.job.company.company_name}
                        </h1>
                        <p>{userData?.job.company.website_url}</p>
                      </div>
                    </div>
                    <div>
                      {userData?.job.account_manager.first_name}{" "}
                      {userData?.job.account_manager.last_name}
                    </div>
                    <div>{userData?.job.aspect_of_work_location}</div>
                    <div>{userData?.job.country}</div>
                    <div>
                      {userData?.job.currency} {userData?.job.salary_from} -{" "}
                      {userData?.job.salary_to} {userData?.job.salary_type}
                    </div>
                    <div className="">{formatDate(userData?.updated_at)}</div>
                    <div className="capitalize">
                      {userData?.status === "shortlist" ? (
                        <span className="px-4 rounded-lg text-orange-500">
                          {userData?.status}
                        </span>
                      ) : (
                        <span className="px-4 rounded-lg text-green-500">
                          {userData?.status}
                        </span>
                      )}
                    </div>
                    <div>
                      <button
                        onClick={() =>
                          navigate(
                            `/candidate/aplication-view/${userData.id}/${userData?.job.id}`
                          )
                        }
                        className="flex items-center text-[#1CACBD] gap-2"
                      >
                        See Details
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          strokeWidth={1.5}
                          stroke="currentColor"
                          className="size-4"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            d="m8.25 4.5 7.5 7.5-7.5 7.5"
                          />
                        </svg>
                      </button>
                    </div>
                  </div>
                ))}
              </div>
              <div className="flex justify-end px-2 my-2">
                {!isLoading && userData?.count !== 0 && (
                  <ATMPagination
                    totalItems={userData?.count}
                    itemsPerPage={pagination.itemsPerPage}
                    currentPage={pagination.currentPage}
                    onPageChange={handlePageChange}
                    itemsPerPageOptions={[4, 8, 12, 16, 20, 24, 48, 96]}
                    onItemsPerPageChange={handleItemsPerPageChange}
                  />
                )}
              </div>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default MyJobApplication;
