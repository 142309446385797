import React, { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { useNavigate, useParams } from "react-router";
import { axiosCandidateRequest } from "../../../../api/api";
import ATMCircularProgress from "../../../../atoms/ATMCircularProgress/ATMCircularProgress";
import { formatDate } from "../../ProfileDetails/View/ProfileDetails";
const baseUrl = process.env.REACT_APP_VITE_BACKEND_API_URL;

const JobView = () => {
  const [jobData, setJobData] = useState();
  const { id } = useParams();
  const navigate = useNavigate();
  const handleBack = () => {
    navigate(-1);
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axiosCandidateRequest(
          `${baseUrl}/api/candidate/job/${id}/`,
          {
            method: "GET",
          }
        );

        if (response.status === 200) {
          setJobData(response?.data?.data);
        } else if (response.status === 400) {
          toast.error("Failed to update data");
        }
      } catch (error) {
        if (error.response && error.response.status === 400) {
          toast.error(error.response.data.message);
        } else {
          toast.error("An unexpected error occurred");
        }
      }
    };

    fetchData();
  }, [id]);

  const parts = jobData?.job?.description?.split("\n");

  return (
    <>
      {!jobData ? (
        <div className=" h-full flex justify-center  items-center">
          <ATMCircularProgress />
        </div>
      ) : (
        <>
          <div className="">
            <div className="bg-[#4958AB] w-full p-4 font-inter text-xs flex gap-3">
              <div className=" flex flex-col gap-6">
                <div className="flex justify-between items-start">
                  <div className="flex flex-col gap-3">
                    <h1 className="font-inter font-semibold text-xl text-white flex items-center gap-4">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        strokeWidth={1.5}
                        stroke="currentColor"
                        className="size-6  cursor-pointer"
                        onClick={handleBack}
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          d="M10.5 19.5 3 12m0 0 7.5-7.5M3 12h18"
                        />
                      </svg>
                      Full Job Description
                    </h1>
                    <div className="bg-[#D9D9D93D] w-fit p-1 px-3 font-inter font-semibold text-white rounded-md">
                      {jobData?.applicant_count} Applicants
                    </div>
                  </div>
                </div>
                <div className="font-inter flex justify-start  flex-wrap gap-12">
                  <div className="text-white flex flex-col gap-2 min-w-[200px] border rounded-md text-center">
                    <h1 className=" font-semibold bg-blue-100 text-black py-2  rounded-t-sm">
                      Job Title
                    </h1>
                    <h1 className="font-medium p-2 ">{jobData?.job?.title}</h1>
                  </div>
                  <div className="text-white flex flex-col gap-2 min-w-[200px] border rounded-md text-center">
                    <h1 className=" font-semibold bg-blue-100 text-black py-2  rounded-t-sm">
                      Job ID
                    </h1>
                    <h1 className="font-medium p-2 ">{jobData?.job?.id}</h1>
                  </div>
                  <div className="text-white flex flex-col gap-2 min-w-[200px] border rounded-md text-center">
                    <h1 className=" font-semibold bg-blue-100 text-black py-2  rounded-t-sm">
                      Date Posted
                    </h1>
                    <h1 className="font-medium p-2 ">
                      {" "}
                      {formatDate(jobData?.job?.created_at, true)}
                    </h1>
                  </div>
                </div>
                <div className="font-inter flex justify-start gap-12">
                  <div className="text-white flex flex-col gap-2">
                    <h1 className=" font-semibold">Summary</h1>
                    {parts?.map((part, index) => (
                      <div key={index}>{part}</div>
                    ))}
                  </div>
                </div>
                <div className="border-t "></div>
                <div className="font-inter flex  gap-8 flex-wrap ">
                  <div className="text-white h-fit flex flex-col gap-2 min-w-[200px] border rounded-md text-center">
                    <h1 className=" font-semibold bg-blue-100 text-black py-2  rounded-t-sm">
                      Job Owner
                    </h1>
                    <h1 className="font-medium p-2 ">
                      {jobData?.job?.account_manager?.email}
                    </h1>
                  </div>
                  <div className="text-white h-fit flex flex-col gap-2 min-w-[200px] border rounded-md text-center">
                    <h1 className=" font-semibold bg-blue-100 text-black py-2  rounded-t-sm">
                      Location
                    </h1>
                    <h1 className="font-medium p-2 ">
                      {jobData?.job?.country}
                      {jobData?.job?.state && (
                        <span>
                          ,{jobData?.job?.state}, {jobData?.job?.city}
                        </span>
                      )}
                    </h1>
                  </div>
                  <div className="text-white h-fit flex flex-col gap-2 min-w-[200px] border rounded-md text-center">
                    <h1 className=" font-semibold bg-blue-100 text-black py-2  rounded-t-sm">
                      Salary Range
                    </h1>
                    <h1 className="font-medium p-2 ">
                      {jobData?.job?.salary_from === 0 ? (
                        "--"
                      ) : (
                        <p>
                          {" "}
                          {jobData?.job?.currency} {jobData?.job?.salary_from} -
                          {jobData?.job?.salary_to} {jobData?.job?.salary_type}
                        </p>
                      )}
                    </h1>
                  </div>
                  <div className="text-white h-fit flex flex-col gap-2 min-w-[200px] border rounded-md text-center">
                    <h1 className=" font-semibold bg-blue-100 text-black py-2  rounded-t-sm">
                      Number Vacancies
                    </h1>
                    <h1 className="font-medium p-2 ">
                      {jobData?.job?.vacancies}
                    </h1>
                  </div>

                  <div className="text-white h-fit flex flex-col gap-2 min-w-[200px] border rounded-md text-center">
                    <h1 className=" font-semibold bg-blue-100 text-black py-2  rounded-t-sm">
                      Client Name
                    </h1>
                    <h1 className="font-medium p-2 ">
                      {jobData?.job?.external_company
                        ? jobData?.job.company_name
                        : jobData?.job?.company?.company_name}
                    </h1>
                  </div>
                </div>

                <div className=" flex gap-3 items-center">
                  {jobData?.application_status === "pending" &&
                  !jobData?.applied ? (
                    <button
                      onClick={() =>
                        navigate(
                          `/candidate/apply-details/${id}/${jobData?.job?.company?.id}`
                        )
                      }
                      className="w-fit  flex gap-1 text-[#4958AB] bg-white px-[12px] py-3 rounded-lg font-inter font-semibold justify-center items-center"
                    >
                      Apply Now
                      <svg
                        width="14"
                        height="14"
                        viewBox="0 0 12 12"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M5.88477 1.88281L10.2001 6.00202L5.88477 10.1212"
                          stroke="#4958AB"
                          strokeWidth="1.28571"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                        <path
                          d="M10.2002 6.00195L1.80029 6.00195"
                          stroke="#4958AB"
                          strokeWidth="1.28571"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </svg>
                    </button>
                  ) : (
                    <div className=" h-[40px] w-[100px] text-center flex items-center justify-center border-[#1CACBD] border rounded-md text-white bg-[#1CACBD]">
                      Applied
                    </div>
                  )}

                  {/* <button className=" border-[1px] border-[#FFFFFF] w-fit  flex gap-1 text-[#FFFFFF]  px-[12px] py-3 rounded-lg font-inter font-semibold justify-center items-center">
                    Share JD link
                  </button> */}
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default JobView;
