// import React, { useCallback, useEffect, useRef, useState } from "react";
// import toast from "react-hot-toast";
// import Webcam from "react-webcam";
// import { axiosCandidateFromRequest } from "../../api/formApi";
// import ATMCircularProgress from "../../atoms/ATMCircularProgress/ATMCircularProgress";

// const VideoRecorderModal = ({
//   isOpen,
//   onClose,
//   onVideoUpload,
//   id,
//   vidoeName,
//   oldVideoPath,
// }) => {
//   const [recording, setRecording] = useState(false);
//   const [isVideoUpload, setIsVideoUpload] = useState(false);
//   const [showRecordedVideo, setShowRecordedVideo] = useState(false);
//   const [recordedChunks, setRecordedChunks] = useState([]);
//   const [timer, setTimer] = useState(0);
//   const [videoURL, setVideoURL] = useState("");
//   const [stream, setStream] = useState(null);

//   const webcamRef = useRef(null);
//   const mediaRecorderRef = useRef(null);
//   const timerInterval = useRef(null);

//   // Initialize camera stream
//   useEffect(() => {
//     if (isOpen) {
//       navigator.mediaDevices
//         .getUserMedia({ video: true, audio: true })
//         .then((streamObj) => {
//           setStream(streamObj);
//         })
//         .catch((err) => {
//           toast.error("Error accessing camera: " + err.message);
//         });
//     }

//     return () => {
//       if (stream) {
//         stream.getTracks().forEach((track) => track.stop());
//       }
//     };
//     // eslint-disable-next-line
//   }, [isOpen]);

//   // Handle data chunks from recording
//   const handleDataAvailable = useCallback((event) => {
//     if (event.data && event.data.size > 0) {
//       setRecordedChunks((prev) => [...prev, event.data]);
//     }
//   }, []);

//   // Start recording function
//   const startRecording = useCallback(() => {
//     setRecordedChunks([]);
//     if (!webcamRef.current?.stream) {
//       toast.error("Camera stream not available");
//       return;
//     }

//     try {
//       const mediaRecorder = new MediaRecorder(webcamRef.current.stream, {
//         mimeType: "video/webm;codecs=vp9,opus",
//       });

//       mediaRecorderRef.current = mediaRecorder;
//       mediaRecorder.ondataavailable = handleDataAvailable;
//       mediaRecorder.start(1000);

//       setRecording(true);
//       timerInterval.current = setInterval(() => {
//         setTimer((prevTimer) => prevTimer + 1);
//       }, 1000);
//     } catch (err) {
//       toast.error("Error starting recording: " + err.message);
//     }
//   }, [handleDataAvailable]);

//   // Stop recording function
//   const stopRecording = useCallback(() => {
//     if (mediaRecorderRef.current && recording) {
//       mediaRecorderRef.current.stop();
//       clearInterval(timerInterval.current);
//       setRecording(false);
//     }
//   }, [recording]);

//   // Save recording function
//   const saveRecording = useCallback(() => {
//     if (recordedChunks.length === 0 || timer < 5) {
//       toast.error("Recording must be at least 5 seconds long.");
//       return;
//     }

//     try {
//       const blob = new Blob(recordedChunks, {
//         type: "video/webm;codecs=vp9,opus",
//       });
//       const url = URL.createObjectURL(blob);
//       setVideoURL(url);
//       setShowRecordedVideo(true);
//     } catch (err) {
//       toast.error("Error saving recording: " + err.message);
//     }
//   }, [recordedChunks, timer]);

//   // Reset recording state
//   const resetRecording = useCallback(() => {
//     setRecordedChunks([]);
//     setTimer(0);
//     clearInterval(timerInterval.current);
//   }, []);

//   // Upload video function
//   const uploadVideo = async () => {
//     if (!recordedChunks.length) {
//       toast.error("No recording available to upload");
//       return;
//     }

//     setIsVideoUpload(true);

//     try {
//       const blob = new Blob(recordedChunks, {
//         type: "video/webm;codecs=vp9,opus",
//       });
//       const formData = new FormData();
//       formData.append("oldVideoPath", oldVideoPath);
//       formData.append("candidateId", id);
//       formData.append("profileVideo", blob, `${vidoeName}.webm`);

//       const response = await axiosCandidateFromRequest(
//         `https://stagingapi.jivahire.com/stream/user-profile-video`,
//         {
//           method: "POST",
//           data: formData,
//           headers: {
//             "Content-Type": "multipart/form-data",
//           },
//         }
//       );

//       if (response.status === 200) {
//         toast.success(response.data.message);
//         onVideoUpload(response.data.s3Key);
//         onClose();
//         resetRecording(); // Reset after successful upload
//       } else {
//         throw new Error(response.data.message);
//       }
//     } catch (error) {
//       toast.error(`Upload error: ${error.message}`);
//     } finally {
//       setIsVideoUpload(false);
//     }
//   };

//   // Format timer display
//   const formatTime = useCallback((time) => {
//     const minutes = Math.floor(time / 60);
//     const seconds = time % 60;
//     return `${minutes}:${seconds < 10 ? "0" : ""}${seconds}`;
//   }, []);

//   // Cleanup effects
//   useEffect(() => {
//     if (!isOpen) {
//       stopRecording();
//       if (videoURL) {
//         URL.revokeObjectURL(videoURL);
//         setVideoURL("");
//       }
//     }
//   }, [isOpen, stopRecording, videoURL]);

//   useEffect(() => {
//     if (timer >= 300) stopRecording(); // Auto-stop after 5 minutes
//   }, [timer, stopRecording]);

//   useEffect(() => {
//     if (isOpen) {
//       navigator.mediaDevices
//         .getUserMedia({ video: true, audio: true })
//         .then((streamObj) => {
//           setStream(streamObj);
//         })
//         .catch((err) => {
//           toast.error("Error accessing camera: " + err.message);
//         });
//     }

//     return () => {
//       // Cleanup: Stop all tracks when component unmounts
//       if (stream) {
//         stream.getTracks().forEach((track) => track.stop());
//       }
//     };
//     // eslint-disable-next-line
//   }, [isOpen]);
//   if (!isOpen) return null;

//   return (
//     <div className="fixed inset-0 z-50 bg-black bg-opacity-75 flex justify-center items-center">
//       <div className="bg-white p-2 rounded-lg">
//         <div className="flex justify-end">
//           <button onClick={onClose} className="text-sm text-[#148a9d]">
//             ✖
//           </button>
//         </div>
//         <div className="bg-transparent  rounded-lg w-[600px] h-[450px] flex flex-col items-center relative">
//           {!showRecordedVideo ? (
//             <>
//               <div className="flex ml-24 mb-1 w-full items-center gap-2">
//                 <svg
//                   width="16"
//                   height="16"
//                   viewBox="0 0 19 19"
//                   fill="none"
//                   xmlns="http://www.w3.org/2000/svg"
//                 >
//                   <circle
//                     cx="9.69629"
//                     cy="9.21484"
//                     r="6.5"
//                     fill="white"
//                     stroke="#FF0000"
//                     strokeWidth="5"
//                   />
//                 </svg>
//                 <p className="text-xs">Rec {formatTime(timer)}</p>
//               </div>
//               <div className="h-[380px] w-[550px]">
//                 <Webcam
//                   ref={webcamRef}
//                   audio={true}
//                   muted={true}
//                   className="h-full w-full"
//                 />
//               </div>
//               <div className=" flex gap-4 justify-center mt-4">
//                 <button
//                   type="button"
//                   onClick={recording ? stopRecording : startRecording}
//                   className="btn w-[120px] py-2 bg-red-500 text-white rounded-md hover:bg-red-600"
//                 >
//                   {recording ? "Stop Recording" : "Start Recording"}
//                 </button>
//                 <button
//                   type="button"
//                   onClick={saveRecording}
//                   disabled={timer < 5}
//                   className="btn w-[120px] py-2 bg-[#1CACBD] text-white rounded-md hover:bg-[#148a9d] flex justify-center "
//                 >
//                   Save Recording
//                 </button>
//               </div>
//             </>
//           ) : (
//             <>
//               <div className=" mb-1"></div>
//               <video src={videoURL} controls className="h-[395px] rounded-lg" />
//               <div className="mt-4 flex gap-4">
//                 <button
//                   type="button"
//                   onClick={() => {
//                     setShowRecordedVideo(false);
//                     resetRecording();
//                     stopRecording();
//                   }}
//                   className="btn w-[120px] py-2 bg-green-500 text-white rounded-md hover:bg-green-600"
//                 >
//                   Re-record
//                 </button>
//                 <button
//                   type="button"
//                   onClick={uploadVideo}
//                   disabled={isVideoUpload}
//                   className="btn w-[120px] py-2 bg-[#1CACBD] text-white rounded-md hover:bg-[#148a9d] flex justify-center "
//                 >
//                   {isVideoUpload ? (
//                     <ATMCircularProgress size="small" color="white" />
//                   ) : (
//                     "Submit"
//                   )}
//                 </button>
//               </div>
//             </>
//           )}
//         </div>
//       </div>
//     </div>
//   );
// };

// export default VideoRecorderModal;

import React, { useCallback, useEffect, useRef, useState } from "react";
import toast from "react-hot-toast";
import Webcam from "react-webcam";

const VideoRecorderModal = ({ isOpen, onClose, setVideoBlob }) => {
  const [recording, setRecording] = useState(false);
  const [recordedChunks, setRecordedChunks] = useState([]);
  const [timer, setTimer] = useState(0);
  const [videoURL, setVideoURL] = useState("");
  const webcamRef = useRef(null);
  const mediaRecorderRef = useRef(null);
  const timerInterval = useRef(null);
  const streamRef = useRef(null); // Use a ref to hold the stream

  // Function to stop media access
  const stopMediaAccess = useCallback(() => {
    if (streamRef.current) {
      const tracks = streamRef.current.getTracks();
      tracks.forEach((track) => {
        track.stop();
      });
      streamRef.current = null; // Clear the stream reference
    }
  }, []);

  // Initialize camera stream
  useEffect(() => {
    if (isOpen) {
      navigator.mediaDevices
        .getUserMedia({ video: true, audio: true })
        .then((streamObj) => {
          streamRef.current = streamObj; // Store the stream in the ref
          if (webcamRef.current) {
            webcamRef.current.srcObject = streamObj; // Assign stream to Webcam
          }
        })
        .catch((err) => {
          toast.error("Error accessing camera: " + err.message);
        });
    }

    return () => {
      stopMediaAccess(); // Call the function to stop media access on unmount
    };
  }, [isOpen, stopMediaAccess]);

  // Handle data chunks from recording
  const handleDataAvailable = useCallback((event) => {
    if (event.data && event.data.size > 0) {
      setRecordedChunks((prev) => [...prev, event.data]);
    }
  }, []);

  // Start recording function
  const startRecording = useCallback(() => {
    resetRecording();
    setRecordedChunks([]);
    if (!streamRef.current) {
      toast.error("Camera stream not available");
      return;
    }

    try {
      const mediaRecorder = new MediaRecorder(streamRef.current, {
        mimeType: "video/webm;codecs=vp9,opus",
      });

      mediaRecorderRef.current = mediaRecorder;
      mediaRecorder.ondataavailable = handleDataAvailable;
      mediaRecorder.start(1000);

      setRecording(true);
      timerInterval.current = setInterval(() => {
        setTimer((prevTimer) => prevTimer + 1);
      }, 1000);
    } catch (err) {
      toast.error("Error starting recording: " + err.message);
    }
    // eslint-disable-next-line
  }, [handleDataAvailable]);

  // Stop recording function
  const stopRecording = useCallback(() => {
    if (mediaRecorderRef.current && recording) {
      mediaRecorderRef.current.stop();
      clearInterval(timerInterval.current);
      setRecording(false);
    }
  }, [recording]);

  // Save recording function
  const saveRecording = useCallback(() => {
    if (recordedChunks.length === 0 || timer < 5) {
      toast.error("Recording must be at least 5 seconds long.");
      return;
    }

    try {
      const blob = new Blob(recordedChunks, {
        type: "video/webm;codecs=vp9,opus",
      });
      setVideoBlob(blob);
      const url = URL.createObjectURL(blob);
      setVideoURL(url);
      onClose();
    } catch (err) {
      toast.error("Error saving recording: " + err.message);
    }
  }, [recordedChunks, timer, setVideoBlob, onClose]);

  // Reset recording state
  const resetRecording = useCallback(() => {
    setRecordedChunks([]);
    setTimer(0);
    clearInterval(timerInterval.current);
  }, []);

  // Format timer display
  const formatTime = useCallback((time) => {
    const minutes = Math.floor(time / 60);
    const seconds = time % 60;
    return `${minutes}:${seconds < 10 ? "0" : ""}${seconds}`;
  }, []);

  // Cleanup effects
  useEffect(() => {
    if (!isOpen) {
      stopRecording();
      stopMediaAccess(); // Call the function to stop media access when modal is closed
      if (videoURL) {
        URL.revokeObjectURL(videoURL);
        setVideoURL("");
      }
    }
  }, [isOpen, stopRecording, stopMediaAccess, videoURL]);

  useEffect(() => {
    if (timer >= 300) stopRecording(); // Auto-stop after 5 minutes
  }, [timer, stopRecording]);

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 z-50 bg-black bg-opacity-75 flex justify-center items-center">
      <div className="bg-white p-2 rounded-lg">
        <div className="flex justify-end">
          <button onClick={onClose} className="text-sm text-[#148a9d]">
            ✖
          </button>
        </div>
        <div className="bg-transparent rounded-lg w-[600px] h-[450px] flex flex-col items-center relative">
          <>
            <div className="flex ml-24 mb-1 w-full items-center gap-2">
              <svg
                width="16"
                height="16"
                viewBox="0 0 19 19"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <circle
                  cx="9.69629"
                  cy="9.21484"
                  r="6.5"
                  fill="white"
                  stroke="#FF0000"
                  strokeWidth="5"
                />
              </svg>
              <p className="text-xs">Rec {formatTime(timer)}</p>
            </div>
            <div className="h-[380px] w-[550px]">
              <Webcam
                ref={webcamRef}
                audio={true}
                muted={true}
                className="h-full w-full"
              />
            </div>
            <div className="flex gap-4 justify-center mt-4">
              <button
                type="button"
                onClick={recording ? stopRecording : startRecording}
                className="btn w-[120px] py-2 bg-red-500 text-white rounded-md hover:bg-red-600"
              >
                {recording ? "Stop Recording" : "Start Recording"}
              </button>
              <button
                type="button"
                onClick={saveRecording}
                disabled={timer < 5}
                className="btn w-[120px] py-2 bg-[#1CACBD] text-white rounded-md hover:bg-[#148a9d] flex justify-center"
              >
                Save Recording
              </button>
            </div>
          </>
        </div>
      </div>
    </div>
  );
};

export default VideoRecorderModal;
