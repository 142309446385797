import axios from "axios";
import { useFormik } from "formik";
import React, { useState } from "react";
import toast from "react-hot-toast";
import { IoMdEye, IoMdEyeOff } from "react-icons/io";
import { PhoneInput } from "react-international-phone";
import "react-international-phone/style.css";
import { useNavigate } from "react-router-dom";
import ATMButton from "../../atoms/ATMButton/ATMButton";
import ATMFieldError from "../../atoms/ATMFieldError/ATMFieldError";
import ATMFieldLabel from "../../atoms/ATMFieldLabel/ATMFieldLabel";
import Dailog from "../../components/common/Dailog";
import HeroHeading from "../../components/recruiters/HeroHeading";
import { signUpSchema } from "../../schemas/schema";
import RegisterImage from "../../assets/Register_Corporate_User.png";
const baseUrl = process.env.REACT_APP_VITE_BACKEND_API_URL;

const RegisterPage = () => {
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [showconPassword, setShowconPassword] = useState(false);
  const handleShowPassword = () => {
    setShowPassword(!showPassword);
  };
  const handleShowconPassword = () => {
    setShowconPassword(!showconPassword);
  };
  const formik = useFormik({
    initialValues: {
      firstName: "",
      lastName: "",
      email: "",
      contactNumber: "",
      password: "",
      confirmPassword: "",
    },
    validationSchema: signUpSchema,
    onSubmit: async (values, { resetForm, setSubmitting }) => {
      setSubmitting(true);
      try {
        const response = await axios.post(
          `${baseUrl}/api/candidate/registration/`,
          {
            first_name: values.firstName,
            last_name: values.lastName,
            email: values.email,
            contact_number: `${values.contactNumber}`,
            password: values.password,
            password2: values.confirmPassword,
          },
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        );

        if (response.status === 201) {
          resetForm();
          setOpen(!open);
        } else if (response.status === 400) {
          toast.error("Failed to add member");
        }
      } catch (error) {
        if (error.response && error.response.status === 400) {
          toast.error(error.response.data.message);
        } else {
          toast.error("An unexpected error occurred");
        }
      } finally {
        setSubmitting(false);
      }
    },
  });

  return (
    <>
      <Dailog
        open={open}
        setOpen={setOpen}
        heading="We've sent an activation link to your email address for added security. Once you click the link, you'll be ready to log in and explore all the features available."
        para="Click the link to activate your account and start using our platform right away. It's that simple!"
        btnText="Login"
        btnLink="/candidate/login"
      />
      <div className=" w-full   font-inter text-[11px] bg-white p-4 ">
        {/* right side part */}
        <div className=" flex flex-col gap-4 ">
          <HeroHeading
            heading="Register as Candidate"
            para={`   Before applying for a job, creating an account is essential. Your
              account allows us to gather the necessary details to match you
              with the right opportunities. Providing your information ensures a
              seamless application process and enables us to tailor job
              recommendations to your skills and preferences.`}
          />
          <div className="grid grid-cols-1 items-start  md:grid-cols-3 gap-4 mt-4 ">
            <div className="col-span-1 mt-5 ">
              <img
                src={RegisterImage}
                alt="jivahire-RegisterImage"
                className="object-contain md:block hidden w-full h-full rounded-md   "
              />
            </div>

            <div className="md:col-span-2 ">
              <form
                onSubmit={formik.handleSubmit}
                className="flex flex-col gap-5"
              >
                <div className="border p-4 border-[#E2DCEA] rounded-lg flex flex-col gap-5 *: ">
                  <div className="flex flex-col gap-4">
                    <div className="grid md:grid-cols-2 items-center gap-4 grid-cols-1">
                      <div className=" flex flex-col">
                        <ATMFieldLabel required> First Name</ATMFieldLabel>
                        <input
                          placeholder="Brian"
                          className=" border outline-none px-2 h-[38px] rounded-md"
                          value={formik.values.firstName}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          id="firstName"
                          name="firstName"
                        />
                        {formik.errors.firstName && formik.touched.firstName ? (
                          <div>
                            <ATMFieldError>
                              {" "}
                              {formik.errors.firstName}
                            </ATMFieldError>
                          </div>
                        ) : null}
                      </div>
                      <div className=" flex flex-col ">
                        <ATMFieldLabel required> Last Name</ATMFieldLabel>
                        <input
                          placeholder="Clark"
                          className=" border outline-none px-2 h-[38px]  rounded-md"
                          value={formik.values.lastName}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          id="lastName"
                          name="lastName"
                        />
                        {formik.errors.lastName && formik.touched.lastName ? (
                          <div>
                            <ATMFieldError>
                              {" "}
                              {formik.errors.lastName}
                            </ATMFieldError>
                          </div>
                        ) : null}
                      </div>
                      <div className=" flex flex-col ">
                        <ATMFieldLabel required> Email ID</ATMFieldLabel>
                        <div className=" relative">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            strokeWidth={1.5}
                            stroke="currentColor"
                            className="text-gray-400 pointer-events-none  absolute top-2 left-2"
                            style={{ height: 24, width: 24 }}
                          >
                            <path
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              d="M21.75 6.75v10.5a2.25 2.25 0 0 1-2.25 2.25h-15a2.25 2.25 0 0 1-2.25-2.25V6.75m19.5 0A2.25 2.25 0 0 0 19.5 4.5h-15a2.25 2.25 0 0 0-2.25 2.25m19.5 0v.243a2.25 2.25 0 0 1-1.07 1.916l-7.5 4.615a2.25 2.25 0 0 1-2.36 0L3.32 8.91a2.25 2.25 0 0 1-1.07-1.916V6.75"
                            />
                          </svg>
                          <input
                            type="email"
                            placeholder="example@example.com"
                            className="pl-10 w-full border outline-none px-2 h-[38px]  rounded-md"
                            value={formik.values.email}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            id="email"
                            name="email"
                          />
                        </div>
                        {formik.errors.email && formik.touched.email ? (
                          <div>
                            <ATMFieldError>
                              {" "}
                              {formik.errors.email}
                            </ATMFieldError>
                          </div>
                        ) : null}
                      </div>
                      <div className=" flex flex-col ">
                        <ATMFieldLabel required> Contact Number</ATMFieldLabel>
                        <div className=" flex w-full">
                          <PhoneInput
                            defaultCountry="us"
                            value={formik.values.contactNumber}
                            onChange={(value) =>
                              formik.setFieldValue("contactNumber", value)
                            }
                            onBlur={formik.handleBlur}
                            id="contactNumber"
                            name="contactNumber"
                            className="border pl-2 w-full rounded-md"
                            style={{
                              "--react-international-phone-height": "36px",
                              "--react-international-phone-border-color":
                                "transparent",
                              "--react-international-phone-border-radius":
                                "6px",
                            }}
                          />
                        </div>
                        {formik.errors.contactNumber &&
                        formik.touched.contactNumber ? (
                          <div className="break-words overflow-wrap">
                            <ATMFieldError>
                              {" "}
                              {formik.errors.contactNumber}
                            </ATMFieldError>
                          </div>
                        ) : null}
                      </div>
                      <div className=" flex flex-col ">
                        <ATMFieldLabel> Set Your Password</ATMFieldLabel>

                        <div className="border rounded-md overflow-hidden flex justify-between items-center ">
                          <input
                            type={showPassword ? "text" : "password"}
                            placeholder="Enter your password"
                            className=" border-0 outline-none w-full px-2 h-[38px] "
                            labelProps={{
                              className:
                                "before:content-none after:content-none",
                            }}
                            value={formik.values.password}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            id="password"
                            name="password"
                          />
                          <div
                            onClick={handleShowPassword}
                            className=" cursor-pointer pr-2"
                          >
                            {showPassword ? (
                              <IoMdEye size={18} className="text-slate-800" />
                            ) : (
                              <IoMdEyeOff
                                size={18}
                                className="text-slate-800"
                              />
                            )}
                          </div>
                        </div>
                        {formik.errors.password && formik.touched.password ? (
                          <div>
                            <ATMFieldError>
                              {" "}
                              {formik.errors.password}
                            </ATMFieldError>
                          </div>
                        ) : null}
                      </div>
                      <div className=" flex flex-col ">
                        <ATMFieldLabel> Re-Enter Your Password</ATMFieldLabel>

                        <div className="border rounded-md flex justify-between items-center overflow-hidden ">
                          <input
                            type={showconPassword ? "text" : "password"}
                            placeholder="Enter your password"
                            className=" border-0 outline-none w-full px-2 h-[38px]  "
                            labelProps={{
                              className:
                                "before:content-none after:content-none",
                            }}
                            value={formik.values.confirmPassword}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            id="confirmPassword"
                            name="confirmPassword"
                          />
                          <div
                            onClick={handleShowconPassword}
                            className=" cursor-pointer pr-2"
                          >
                            {showconPassword ? (
                              <IoMdEye size={18} className="text-slate-800" />
                            ) : (
                              <IoMdEyeOff
                                size={18}
                                className="text-slate-800"
                              />
                            )}
                          </div>
                        </div>
                        {formik.errors.confirmPassword &&
                        formik.touched.confirmPassword ? (
                          <div>
                            <ATMFieldError>
                              {" "}
                              {formik.errors.confirmPassword}
                            </ATMFieldError>
                          </div>
                        ) : null}
                      </div>
                    </div>
                  </div>

                  <div className="flex justify-end w-full">
                    <div className="w-[110px]">
                      <ATMButton
                        loading={formik.isSubmitting}
                        label="Register Now"
                        authicon
                      />
                    </div>
                  </div>
                  <p className=" text-[#667085]">
                    If you have already created account then you can{"  "}
                    <button
                      onClick={() => navigate("/candidate/login")}
                      className="text-[#1CACBD]"
                    >
                      {" "}
                      Click Here To Login
                    </button>
                  </p>
                </div>
              </form>

              <div className="font-inter mt-4">
                <ATMFieldLabel>Want to reach out directly?</ATMFieldLabel>
                <div className="text-slate-600">
                  We're here to help!{" "}
                  <span
                    onClick={() => navigate("/candidate/support")}
                    className="text-[#1CACBD] cursor-pointer"
                  >
                    Contact our support
                  </span>{" "}
                  team with any questions.
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default RegisterPage;
