import apiSlice from "../../../../app/ApiSlice";
export const recuiterServicesApi = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getRecuiterList: builder.query({
      providesTags: ["active"],
      query: (body) => ({
        url: `/api/recruiter/get_recruiters/`,
        method: "GET",
        body,
      }),
    }),
    activateRecuiter: builder.mutation({
      invalidatesTags: ["active"],
      query: (body) => {
        return {
          url: `/api/recruiter/activate_recruiter/`,
          method: "POST",
          body,
        };
      },
    }),
  }),
});

export const { useGetRecuiterListQuery, useActivateRecuiterMutation } =
  recuiterServicesApi;
