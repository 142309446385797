import React, { useState } from "react";
import { useNavigate } from "react-router";
import { formatDate } from "../../../candidate/ProfileDetails/View/ProfileDetails";
import ResumeView from "../ResumeView/ResumeView";

export const truncateWords = (text, maxWords) => {
  if (!text) return "";
  const words = text.split(" ");
  return words.length > maxWords
    ? words.slice(0, maxWords).join(" ") + "......"
    : text;
};

const TalentSearchCandidateView = ({
  selectedProfiles,
  handleCheckboxChange,
  profiles = [],
  score = false,
  matchScore = false,
}) => {
  const navigate = useNavigate();
  const [view, setView] = useState("");
  const [isOpen, setIsOpen] = useState(false);
  const [expandedRows, setExpandedRows] = useState({});

  const toggleAnswerFull = (index) => {
    setExpandedRows((prev) => ({
      ...prev,
      [index]: !prev[index],
    }));
  };
  return (
    <div>
      <div className="flex flex-col gap-4 w-full text-xs font-inter">
        {profiles?.map((profile, ind) => (
          <div
            key={ind}
            className="bg-white rounded-lg border px-8 py-4 grid grid-cols-1  gap-4 md:grid-cols-2 lg:grid-cols-2 xl:grid-cols-11 min-w-full items-center relative"
          >
            <div className="flex xl:col-span-4 h-full gap-5 items-center border-r-2 pr-4 ">
              <input
                type="checkbox"
                checked={selectedProfiles[ind] || false}
                onChange={() => handleCheckboxChange(ind)}
              />
              <div>
                <div className="uppercase text-lg bg-blue-50 text-[#1CACBD] border-[#1CACBD] border rounded-full w-20 h-20 flex justify-center items-center">
                  <span>
                    {profile?.candidate_form_data?.first_name?.charAt(0)}
                  </span>
                  <span>
                    {profile?.candidate_form_data?.last_name?.charAt(0)}
                  </span>
                </div>
              </div>
              <div className=" h-full w-full gap-1 flex flex-col justify-center border-gray-300">
                <h3 className="text-sm font-medium capitalize  overflow-wrap break-word">
                  {profile?.candidate_form_data?.first_name}{" "}
                  {profile?.candidate_form_data?.middle_name}{" "}
                  {profile?.candidate_form_data?.last_name}
                </h3>
                <p className="text-xs text-gray-500  overflow-wrap break-word">
                  {profile?.email}
                </p>

                <p className="text-xs text-gray-500 ">
                  Phone: {profile?.candidate_form_data?.contactNumber}
                </p>

                {score && (
                  <p
                    className={`${
                      profile?.match_score >= 40 &&
                      "text-green-500 bg-green-100"
                    } ${
                      profile?.match_score <= 40 && "text-red-500 bg-red-100"
                    } font-bold w-fit p-1 my-2 rounded-md`}
                  >
                    Magic Score: {profile?.match_score}
                  </p>
                )}
                {matchScore && (
                  <p
                    className={`${
                      profile?.similarity_score >= 40 &&
                      "text-green-500 bg-green-100"
                    } ${
                      profile?.similarity_score <= 40 &&
                      "text-red-500 bg-red-100"
                    } font-bold w-fit p-1 my-2 rounded-md`}
                  >
                    Match Score: {profile.similarity_score}
                  </p>
                )}

                <p className="text-xs text-gray-500  capitalize  overflow-wrap break-word">
                  Current Company:{" "}
                  {profile?.candidate_form_data?.current_company || "--"}
                </p>
                <p className="text-xs text-gray-500   overflow-wrap break-word">
                  Years of Experience :{" "}
                  {profile?.candidate_form_data?.experienceYears
                    ? `${profile?.candidate_form_data?.experienceYears} Years`
                    : "--"}{" "}
                  {profile?.candidate_form_data?.experienceMonths
                    ? ` ${profile?.candidate_form_data?.experienceMonths} Months`
                    : "--"}
                </p>
                <p className="text-xs text-gray-500  overflow-wrap break-word">
                  Current Location :{" "}
                  {profile?.candidate_form_data?.city?.label ||
                    profile?.candidate_form_data?.state?.label ||
                    profile?.candidate_form_data?.country?.label ||
                    "--"}
                </p>
              </div>
            </div>
            <div className="border-r-2 h-full flex flex-col justify-center xl:col-span-3">
              <p className="font-medium">Summary</p>
              <p className="text-xs text-gray-500 break-words">
                {expandedRows[ind]
                  ? profile?.candidate_form_data?.information
                  : truncateWords(
                      profile?.candidate_form_data?.information,
                      30
                    )}
                {profile?.candidate_form_data?.information?.split(" ").length >
                  30 && (
                  <button
                    onClick={() => toggleAnswerFull(ind)}
                    className="text-[#1CACBD]"
                    type="button"
                  >
                    {expandedRows[ind] ? "....See Less" : "See More"}
                  </button>
                )}
              </p>

              <div className="grid grid-cols-1 lg:grid-cols-2 mt-2 gap-2">
                <div className="">
                  <p className="font-medium">Current Salary</p>
                  <p className="text-xs text-gray-500 ">
                    {profile?.candidate_form_data?.currentSalaryCurrency}{" "}
                    {profile?.candidate_form_data?.ctc || "--"}
                  </p>
                </div>

                <div className="">
                  <p className="font-medium">Expected Salary</p>
                  <p className="text-xs text-gray-500 ">
                    {profile?.candidate_form_data?.salaryCurrency || ""}{" "}
                    {profile?.candidate_form_data?.salaryFrom || ""} -{" "}
                    {profile?.candidate_form_data?.salaryTo || "NA"}
                  </p>
                </div>

                <div className="">
                  <p className="font-medium">Notice Period</p>
                  {profile?.candidate_form_data?.noticePeriod ? (
                    <p className="text-xs text-gray-500 ">
                      {profile?.candidate_form_data?.noticePeriod} Days
                    </p>
                  ) : (
                    "--"
                  )}
                </div>
                <div>
                  <p className="font-medium">Availability Date</p>
                  <p className="text-xs text-gray-500 ">
                    {formatDate(profile?.candidate_form_data?.availableFrom)}
                  </p>
                </div>
              </div>
            </div>
            <div className="xl:col-span-3 border-r-2 pr-2">
              <div className="mt-2">
                <p>Primary Skills</p>
                <div className="flex flex-wrap h-fit gap-1 mt-2">
                  {(profile?.candidate_form_data?.primarySkills || [])?.map(
                    (skill, index) => (
                      <span
                        key={index}
                        className="px-2 py-1  hover:bg-blue-50 border-blue-200 bg-white text-blue-500 border text-[10px] rounded-md"
                      >
                        {skill?.label}
                      </span>
                    )
                  )}
                </div>
              </div>

              <div className="mt-2">
                <p>Secondary Skills</p>
                <div className="flex flex-wrap h-fit gap-1 mt-2">
                  {(profile?.candidate_form_data?.secondarySkills || [])?.map(
                    (skill, index) => (
                      <span
                        key={index}
                        className="px-2 py-1  hover:bg-blue-50 border-blue-200 bg-white text-blue-500 border text-[10px] rounded-md"
                      >
                        {skill?.label}
                      </span>
                    )
                  )}
                </div>
              </div>

              <div className="mt-2">
                <p>Tertiary Skills</p>
                <div className="flex flex-wrap h-fit gap-1 mt-2">
                  {(profile?.candidate_form_data?.tertiarySkills || [])?.map(
                    (skill, index) => (
                      <span
                        key={index}
                        className="px-2 py-1  hover:bg-blue-50 border-blue-200 bg-white text-blue-500 border text-[10px] rounded-md"
                      >
                        {skill?.label}
                      </span>
                    )
                  )}
                </div>
              </div>
            </div>
            <div className="flex xl:col-span-1 font-medium text-[10px] ">
              <div className="flex flex-col gap-4 items-center ">
                <button
                  className="bg-[#1CACBD] text-white py-1 rounded-md border-[#1CACBD] border h-fit w-[110px] flex items-center justify-center gap-2 "
                  onClick={() =>
                    navigate(`/recruit/profile-view/${profile?.id}`)
                  }
                >
                  Resume Profile
                  <span className="text-sm font-medium mt-[-1px]">→</span>
                </button>
                <button
                  className="text-[#1CACBD] border-[#1CACBD] border py-1 rounded-md h-fit w-[110px] flex items-center justify-center gap-2"
                  onClick={() => {
                    setView(profile?.resume_links);
                    setIsOpen(true);
                  }}
                  disabled={!profile?.resume_links[0]}
                >
                  Resume PDF
                  <span className="text-sm font-medium mt-[-1px]">→</span>
                </button>
              </div>
            </div>
          </div>
        ))}
      </div>
      <div className="absolute top-10 z-50 ">
        <ResumeView
          htmlContent={view[view?.length - 1]?.resume_link}
          onClose={() => setIsOpen(false)}
          isOpen={isOpen}
        />
      </div>
    </div>
  );
};

export default TalentSearchCandidateView;
