import {
  Button,
  Dialog,
  DialogBody,
  DialogFooter,
} from "@material-tailwind/react";
import React from "react";
import { useNavigate } from "react-router-dom";

function Dailog({
  open,
  setOpen,
  heading,
  para,
  btnText,
  btnLink,
  isTime = false,
  count,
}) {
  const navigate = useNavigate();

  const handleOpen = () => setOpen(!open);
  const handleLogin = () => {
    navigate(btnLink);
  };

  return (
    <>
      <Dialog
        open={open}
        handler={handleOpen}
        className="fixed font-inter rounded-none text-xs inset-0 flex items-center justify-center bg-black bg-opacity-80 z-50"
      >
        <div className=" bg-white rounded-lg shadow-lg max-w-[700px] p-5  mx-10">
          <DialogBody className=" text-black text-xs">
            <p className="text-sm font-inter">{heading}</p>
            <p className=" text-[#6D758F] font-inter font-normal mt-5">
              {para}
            </p>
          </DialogBody>
          <DialogFooter className="flex items-start ">
            <Button
              disabled={isTime}
              className="flex items-center bg-[#1CACBD] h-[40px] outline-none"
              onClick={handleLogin}
            >
              {isTime ? (
                <span className="w-[170px]">{count}</span>
              ) : (
                <span className="flex items-center gap-2">
                  {btnText}
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth={1.5}
                    stroke="currentColor"
                    className="w-6 h-6"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M13.5 4.5 21 12m0 0-7.5 7.5M21 12H3"
                    />
                  </svg>
                </span>
              )}
            </Button>
          </DialogFooter>
        </div>
      </Dialog>
    </>
  );
}

export default Dailog;
