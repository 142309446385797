// import Cookies from "js-cookie";
// import React, { useEffect, useRef } from "react";
// import { Toaster } from "react-hot-toast";
// import { useNavigate } from "react-router";
// import { axiosCandidateRequest } from "../../api/api";
// const baseUrl = process.env.REACT_APP_VITE_BACKEND_API_URL;

// function Logout() {
//   const refreshToken = localStorage.getItem("candidaterefreshToken");
//   const navigate = useNavigate();
//   const hasCalledAPI = useRef(false); // To prevent multiple API calls in StrictMode

//   useEffect(() => {
//     const handleLogout = async () => {
//       if (hasCalledAPI.current) return; // Skip if API already called
//       hasCalledAPI.current = true; // Mark API as called
//       if (!refreshToken) return navigate("/candidate/login");
//       try {
//         const response = await axiosCandidateRequest(
//           `${baseUrl}/api/candidate/logout/`,
//           {
//             method: "POST",
//             data: { refresh_token: refreshToken },
//           }
//         );

//         if (response.status === 205) {
//           localStorage.removeItem("candidateaccessToken");
//           localStorage.removeItem("candidaterefreshToken");
//           localStorage.removeItem("candidate_user");
//           localStorage.removeItem("candidate_profile");
//           Cookies.remove("candidaterefreshToken", {
//             secure: true,
//             sameSite: "Strict",
//           });
//           Cookies.remove("candidate_user", {
//             secure: true,
//             sameSite: "Strict",
//           });
//           navigate("/candidate/login");
//         } else if (response.status === 400) {
//         }
//       } catch (error) {
//         if (error.response && error.response.status === 400) {
//         }
//       }
//     };

//     handleLogout();
//   }, [navigate, refreshToken]);
//   return (
//     <>
//       <Toaster
//         position="top-center"
//         reverseOrder={false}
//         toastOptions={{
//           // Define default options
//           className: "",
//           duration: 10000,
//           style: {
//             background: "#363636",
//             color: "#fff",
//           },

//           // Default options for specific types
//           success: {
//             duration: 10000,
//             theme: {
//               primary: "green",
//               secondary: "black",
//             },
//           },
//         }}
//       />
//     </>
//   );
// }

// export default Logout;

import Cookies from "js-cookie";
import React, { useRef, useState } from "react";
import { Toaster } from "react-hot-toast";
import { useNavigate } from "react-router";
import { axiosCandidateRequest } from "../../api/api";

const baseUrl = process.env.REACT_APP_VITE_BACKEND_API_URL;

function Logout() {
  const refreshToken = localStorage.getItem("candidaterefreshToken");
  const navigate = useNavigate();
  const hasCalledAPI = useRef(false); // Prevent multiple API calls in StrictMode
  const [open, setOpen] = useState(true); // Control dialog visibility

  const handleLogout = async () => {
    if (hasCalledAPI.current) return; // Prevent duplicate API calls
    hasCalledAPI.current = true;

    if (!refreshToken) {
      navigate("/candidate/login");
      return;
    }

    try {
      const response = await axiosCandidateRequest(
        `${baseUrl}/api/candidate/logout/`,
        {
          method: "POST",
          data: { refresh_token: refreshToken },
        }
      );

      if (response.status === 205) {
        localStorage.removeItem("candidateaccessToken");
        localStorage.removeItem("candidaterefreshToken");
        localStorage.removeItem("candidate_user");
        localStorage.removeItem("candidate_profile");
        Cookies.remove("candidaterefreshToken", {
          secure: true,
          sameSite: "Strict",
        });
        Cookies.remove("candidate_user", {
          secure: true,
          sameSite: "Strict",
        });
        navigate("/candidate/login");
      } else if (response.status === 400) {
        // Optional: Handle specific error case
      }
    } catch (error) {
      if (error.response && error.response.status === 400) {
        // Optional: Handle error case
      }
    }
  };

  const handleConfirm = () => {
    setOpen(false); // Close the dialog
    handleLogout(); // Proceed with logout
  };

  const handleCancel = () => {
    setOpen(false); // Close the dialog
    navigate(-1); // Navigate back
  };

  return (
    <>
      {/* Full-screen confirmation dialog */}
      {open && (
        <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
          <div className="bg-white rounded-lg shadow-lg w-11/12 md:w-1/3 p-6">
            <h2 className="text-xl font-semibold text-gray-800">
              Are you sure you want to logout?
            </h2>
            <p className="mt-4 text-gray-600">
              This action will log you out from your account.
            </p>
            <div className="mt-6 flex justify-end space-x-4">
              <button
                onClick={handleCancel}
                className="px-4 py-2 bg-gray-300 text-gray-700 rounded-lg hover:bg-gray-400"
              >
                No
              </button>
              <button
                onClick={handleConfirm}
                className="px-4 py-2 bg-red-600 text-white rounded-lg hover:bg-red-700"
              >
                Yes
              </button>
            </div>
          </div>
        </div>
      )}

      <Toaster
        position="top-center"
        reverseOrder={false}
        toastOptions={{
          className: "",
          duration: 10000,
          style: {
            background: "#363636",
            color: "#fff",
          },
          success: {
            duration: 10000,
            theme: {
              primary: "green",
              secondary: "black",
            },
          },
        }}
      />
    </>
  );
}

export default Logout;
