import Cookies from "js-cookie";
import React, { useRef, useState } from "react";
import { Toaster } from "react-hot-toast";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router";
import { axiosRequest } from "../../api/api";
import apiSlice from "../../app/ApiSlice";
import { resetState } from "../../app/Slice/AuthSlice";

const baseUrl = process.env.REACT_APP_VITE_BACKEND_API_URL;

function RecruiterLogout() {
  const refreshToken = localStorage.getItem("refreshToken");
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const hasCalledAPI = useRef(false);
  const [open, setOpen] = useState(true); // To control dialog visibility

  const handleLogout = async () => {
    if (hasCalledAPI.current) return;
    hasCalledAPI.current = true;

    if (!refreshToken) {
      navigate("/recruiter/login");
      return;
    }

    try {
      const response = await axiosRequest(`${baseUrl}/api/recruiter/logout/`, {
        method: "POST",
        data: { refresh_token: refreshToken },
      });

      if (response.status === 205) {
        dispatch(resetState(""));
        localStorage.removeItem("accessToken");
        localStorage.removeItem("refreshToken");
        localStorage.removeItem("userType");
        localStorage.removeItem("recuriterProfile");
        localStorage.removeItem("recruiter_id");
        Cookies.remove("accessToken", { secure: true, sameSite: "Strict" });
        Cookies.remove("userType", { secure: true, sameSite: "Strict" });
        navigate("/recruiter/login");
        dispatch(apiSlice.util.resetApiState());
      } else if (response.status === 400) {
        // Handle error if needed
      }
    } catch (error) {
      if (error.response && error.response.status === 400) {
        // Handle error if needed
      }
    }
  };

  const handleConfirm = () => {
    handleLogout(); // Proceed with logout
    setOpen(false); // Close the dialog
  };

  const handleCancel = () => {
    setOpen(false); // Close the dialog
    navigate(-1); // Navigate back
  };

  return (
    <>
      {/* Full-screen modal */}
      {open && (
        <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
          <div className="bg-white rounded-lg shadow-lg w-11/12 md:w-1/3 p-6">
            <h2 className="text-xl font-semibold text-gray-800">
              Are you sure you want to logout?
            </h2>
            <p className="mt-4 text-gray-600">
              This action will log you out from your account.
            </p>
            <div className="mt-6 flex justify-end space-x-4">
              <button
                onClick={handleCancel}
                className="px-4 py-2 bg-gray-300 text-gray-700 rounded-lg hover:bg-gray-400"
              >
                No
              </button>
              <button
                onClick={handleConfirm}
                className="px-4 py-2 bg-red-600 text-white rounded-lg hover:bg-red-700"
              >
                Yes
              </button>
            </div>
          </div>
        </div>
      )}

      <Toaster
        position="top-center"
        reverseOrder={false}
        toastOptions={{
          className: "",
          duration: 10000,
          style: {
            background: "#363636",
            color: "#fff",
          },
          success: {
            duration: 10000,
            theme: {
              primary: "green",
              secondary: "black",
            },
          },
        }}
      />
    </>
  );
}

export default RecruiterLogout;
