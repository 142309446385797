import { Form, Formik } from "formik";
import React from "react";
import toast from "react-hot-toast";
import { useDispatch, useSelector } from "react-redux";
import { array, object, string } from "yup";
import { axiosCandidateFromRequest } from "../../../../api/formApi";
import { setRefresh } from "../../../../features/refresh/refresh";
import EducationDetailsFormLayout from "../Layout/EducationDetailsFormLayout";
const baseUrl = process.env.REACT_APP_VITE_BACKEND_API_URL;

const EditEducationDetailsFormWrapper = ({
  setEducationDetailsEdit,
  userData,
  educationDetailsEditId,
}) => {
  const editData = userData?.education_candidate?.[educationDetailsEditId];
  const dispatch = useDispatch();
  const refresh = useSelector((state) => state.refresh.refresh);
  const skillsArray =
    (userData?.education_candidate && JSON.parse(editData?.skills)) || [];
  const formattedSkills = skillsArray.map((skill) => ({ label: skill }));
  const initialValues = {
    education: { label: editData?.education } || "",
    specialization: editData?.specialization || "",
    institution: editData?.institution_name || "",
    addskills: formattedSkills || [],
    startDate: editData?.duration_start || "",
    endDate: editData?.duration_end || "",
    experiencevideo: editData?.upload_education_video || "",
    blob: "",
  };

  const validationSchema = object().shape({
    addskills: array().max(100, "You can add a maximum of 100 skills"),
    specialization: string().max(
      100,
      "specialization cannot exceed 100 characters"
    ),
    institution: string().max(100, "institution cannot exceed 100 characters"),
  });

  const handleSubmit = async (values, { resetForm, setSubmitting }) => {
    setSubmitting(true);

    if (values?.blob) {
      try {
        const formData = new FormData();
        formData.append("oldVideoPath", "");
        formData.append("candidateId", userData?.id);
        formData.append(
          "profileVideo",
          values?.blob,
          `education-experience-video.webm`
        );

        const response = await axiosCandidateFromRequest(
          `https://stagingapi.jivahire.com/stream/user-profile-video`,
          {
            method: "POST",
            data: formData,
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }
        );

        if (response.status === 200) {
          try {
            const formData = {
              education: values.education?.label,
              specialization: values.specialization,
              institution_name: values.institution,
              duration_start: values.startDate,
              duration_end: values.endDate,
              skills: JSON.stringify(values.addskills.map((el) => el.label)),
              upload_education_video: response.data.s3Key,
            };

            let response2 = await axiosCandidateFromRequest(
              `${baseUrl}/api/candidate/education/${editData?.id}/`,
              {
                method: "PATCH",
                data: formData,
              }
            );

            if (response2.status === 201) {
              setSubmitting(false);
              toast.success(response2?.data?.message);
              setEducationDetailsEdit(false);
              dispatch(setRefresh(!refresh));
            } else if (response2.status === 400) {
              toast.error("Failed to update data");
            }
          } catch (error) {
            if (error.response2 && error.response2.status === 400) {
              // toast.error(error.response.data.message);
            } else {
              // toast.error("An unexpected error occurred");
            }
          }
        } else {
          throw new Error(response.data.message);
        }
      } catch (error) {
        toast.error(`Upload error: ${error.message}`);
      } finally {
        setSubmitting(false);
      }
    } else {
      try {
        const formData = {
          education: values.education?.label,
          specialization: values.specialization,
          institution_name: values.institution,
          duration_start: values.startDate,
          duration_end: values.endDate,
          skills: JSON.stringify(values.addskills.map((el) => el.label)),
          upload_education_video: values?.experiencevideo,
        };

        let response = await axiosCandidateFromRequest(
          `${baseUrl}/api/candidate/education/${editData?.id}/`,
          {
            method: "PATCH",
            data: formData,
          }
        );

        if (response.status === 201) {
          setSubmitting(false);
          toast.success(response?.data?.message);
          setEducationDetailsEdit(false);
          dispatch(setRefresh(!refresh));
        } else if (response.status === 400) {
          toast.error("Failed to update data");
        }
      } catch (error) {
        if (error.response && error.response.status === 400) {
          // toast.error(error.response.data.message);
        } else {
          // toast.error("An unexpected error occurred");
        }
      } finally {
        setSubmitting(false);
      }
    }
  };

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={handleSubmit}
      validationSchema={validationSchema}
      enableReinitialize
    >
      {(formikProps) => (
        <Form>
          <EducationDetailsFormLayout
            formType="Edit"
            formikProps={formikProps}
            education={editData}
          />
        </Form>
      )}
    </Formik>
  );
};

export default EditEducationDetailsFormWrapper;
