import axios from "axios";
import React, { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { useLocation, useNavigate } from "react-router";
import ATMCircularProgress from "../../atoms/ATMCircularProgress/ATMCircularProgress";
import ATMHeading from "../../atoms/ATMHeading/ATMHeading";
import Navbar from "../../components/candidate/Navbars/Navbar";
import { formatDate } from "../candidate/ProfileDetails/View/ProfileDetails";
import { Bar, Pie } from "react-chartjs-2";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  ArcElement,
  Tooltip,
  Legend,
} from "chart.js";
import { calculateDuration, calculateExperience } from "../candidate/MyProfileView.jsx/MyProfileView";

// Register necessary chart.js components
ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  ArcElement,
  Tooltip,
  Legend
);

const colorPalette = [
  "#3498db",
  "#2ecc71",
  "#e74c3c",
  "#9b59b6",
  "#f1c40f",
  "#e67e22",
  "#1abc9c",
  "#8e44ad",
  "#34495e",
  "#2c3e50",
];

const baseUrl = process.env.REACT_APP_VITE_BACKEND_API_URL;
const ShareProfileView = () => {
  const navigate = useNavigate();
  const [userData, setUserData] = useState();
  const [loading, setLoading] = useState(false);
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const id = searchParams.get("id");
  const code = searchParams.get("code");

  useEffect(() => {
    if (id === null || code === null) {
      navigate("/candidate/login");
      return;
    }

    const getAllJobs = async () => {
      setLoading(true);

      try {
        const url = `${baseUrl}/api/candidate/profile/${id}/?supersecret=${code}`;

        const response = await axios.get(url);

        if (response.status === 200) {
          setUserData(response?.data?.data?.["candidate"]);
        } else {
          toast.error("Failed to get data");
        }
      } catch (error) {
        if (error.response && error.response.status === 400) {
          toast.error(error.response.data.message);
        } else {
          toast.error("An unexpected error occurred");
        }
      } finally {
        setLoading(false);
      }
    };

    getAllJobs();
  }, [id, code, navigate]);
  // Data for Career Trajectory
  const careerTrajectoryData = {
    labels: userData?.workexperience_candidate?.map(
      (job) =>
        `${new Date(job?.company_start_duration).getFullYear()} - ${
          job.end_date === "Present"
            ? "Present"
            : new Date(job?.company_end_duration).getFullYear()
        }`
    ),
    datasets: [
      {
        label: "Work Experience (Years and Months)",
        data: userData?.workexperience_candidate?.map((job) =>
          calculateDuration(
            job?.company_start_duration,
            job?.company_end_duration
          )
        ),
        backgroundColor: "rgba(59, 130, 246, 0.6)",
        barThickness: 20,
      },
    ],
  };

  // Extract skills data
  const skills = userData?.skills ? JSON.parse(userData?.skills) : [];

  const skillsData = userData?.workexperience_candidate?.reduce(
    (acc, experience) => {
      const yearsOfExperience = calculateExperience(
        experience.company_start_duration,
        experience.company_end_duration
      );

      // Parse the stringified skills into an array
      const skills = JSON.parse(experience.skills); // Convert stringified skills to an array

      skills.forEach((skill) => {
        if (acc[skill]) {
          acc[skill] += yearsOfExperience; // Accumulate experience for the same skill
        } else {
          acc[skill] = yearsOfExperience; // Initialize the experience for the skill
        }
      });

      return acc;
    },
    {}
  );
  const totalExperience = skillsData
    ? Object.values(skillsData).reduce((sum, exp) => sum + exp, 0)
    : 0;

  const skillsAcquiredData = {
    labels: skillsData ? Object.keys(skillsData) : [], // Skill names
    datasets: [
      {
        label: "Years of Experience",
        data: skillsData
          ? Object.values(skillsData).map(
              (exp) => ((exp / totalExperience) * 100).toFixed(0) // Calculate percentage of total experience
            )
          : [], // Empty array if skillsData is undefined or null
        backgroundColor: skillsData
          ? Object.keys(skillsData).map(
              (_, index) => colorPalette[index % colorPalette.length] // Assign colors dynamically
            )
          : [], // Empty array if skillsData is undefined or null
      },
      {
        label: "Experience",
        data: [],
        backgroundColor: ["#fff"],
      },
    ],
  };

  const skillProficiencyData = {
    labels: skillsData ? Object.keys(skillsData) : [],
    datasets: [
      {
        label: "Years of Experience",
        data: skillsData
          ? Object.values(skillsData)?.map(
              (exp) => exp.toFixed(1) // Calculate percentage of total experience
            )
          : [],
        backgroundColor: "rgba(255, 99, 132, 0.6)",
      },
    ],
  };

  return (
    <div className="h-full ">
      <Navbar loginbutton={false} />
      {loading ? (
        <div className="flex text-black h-full justify-center items-center">
          <ATMCircularProgress />
        </div>
      ) : (
        // <div className="  bg-white   font-inter text-xs text-slate-600">
        //   <div className="p-2">
        //     <ATMHeading>Profile</ATMHeading>
        //   </div>

        //   <div className=" p-2 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3  gap-4">
        //     {/* Left Section - Profile and Bio */}
        //     <div className=" bg-gray-800 text-white rounded-lg p-4">
        //       <div className="">
        //         <h2 className="text-2xl text-white font-bold capitalize">
        //           HEY! I'm {userData?.first_name}, {userData?.title}
        //         </h2>
        //         <p className="text-sm ">{userData?.profile_summary}</p>
        //       </div>
        //     </div>

        //     <div className="border rounded-lg p-2 text-xs font-medium  ">
        //       <div className=" overflow-hidden   ">
        //         <img
        //           className=" object-cover  w-full  h-[270px] rounded-lg"
        //           src={userData?.profile_pic}
        //           alt="profile"
        //         />
        //       </div>
        //       <div className="flex justify-between items-center flex-wrap gap-4">
        //         <div className="font-bold capitalize text-sm  ">
        //           {userData?.first_name} {""}
        //           {userData?.last_name}
        //         </div>
        //         <div className="text-xs font-medium border-l text-end pl-4 ">
        //           {userData?.title}
        //         </div>
        //       </div>
        //       <div className=" ">@{userData?.email}</div>
        //       <div className="mt-2">{userData?.contact_number}</div>
        //     </div>
        //     <div className=""></div>

        //     <div className="border rounded-lg">
        //       <div className="bg-blue-100  rounded-t-lg px-4 py-2 font-semibold">
        //         Interest and Hobbies
        //       </div>
        //       <div className="flex flex-wrap gap-4 p-2">
        //         {userData?.candidatehobbies_candidate?.map((el) => {
        //           return (
        //             <div className="border rounded-xl px-4 py-1 text-center">
        //               {el?.hobbie?.hobbie_category}
        //             </div>
        //           );
        //         })}
        //       </div>
        //     </div>
        //     <div className="border rounded-lg">
        //       <div className="bg-blue-100  rounded-t-lg px-4 font-semibold py-2">
        //         About Interest
        //       </div>
        //       <div className=" p-2">
        //         Lorem ipsum dolor sit, amet consectetur adipisicing elit. Optio
        //         doloribus quasi quo sed soluta. Qui sit cum ex, mollitia,
        //         praesentium, nihil laboriosam tempora laudantium pariatur quis
        //         nam atque quo eum.
        //       </div>
        //     </div>
        //     <div className="border rounded-lg">
        //       <div className="bg-blue-100  rounded-t-lg px-4 font-semibold py-2">
        //         languages Profeciency
        //       </div>
        //       <div className="flex flex-wrap gap-4  p-2">
        //         {userData?.languages
        //           ? JSON.parse(userData?.languages)?.map((el) => {
        //               return (
        //                 <div className="border rounded-xl px-4 py-1 text-center">
        //                   {el}
        //                 </div>
        //               );
        //             })
        //           : ""}
        //       </div>
        //     </div>

        //     <div className="font-bold text-xl md:col-span-2 lg:col-span-3">
        //       Qualification
        //     </div>
        //     {userData?.education_candidate?.map((el) => {
        //       return (
        //         <div key={el?.id} className="border rounded-lg">
        //           <div className="bg-gray-800 text-white py-5 rounded-t-lg px-4 font-semibold">
        //             <span>{el?.institution_name}</span> <br />
        //             <span>{el?.education}</span> <br />
        //             <span>
        //               ({formatDate(el?.duration_start)} To{" "}
        //               {formatDate(el?.duration_end)})
        //             </span>
        //             <span></span>
        //           </div>
        //           <div className=" gap-4  p-2">
        //             <span>-{el?.specialization}</span> <br />
        //             <span>
        //               {el?.skills
        //                 ? JSON.parse(el?.skills)?.map((el) => {
        //                     return <div className="">-{el}</div>;
        //                   })
        //                 : ""}
        //             </span>
        //           </div>
        //         </div>
        //       );
        //     })}

        //     <div className="font-bold text-xl md:col-span-2 lg:col-span-3">
        //       Work Experience
        //     </div>
        //     {userData?.workexperience_candidate?.map((el) => {
        //       return (
        //         <div key={el?.id} className="border rounded-lg">
        //           <div className="border-b  py-2 rounded-t-lg px-4">
        //             <span className=" font-semibold">{el?.job_title}</span>{" "}
        //             <br />
        //             <span>{el?.company_name}</span> <br />
        //             <span>{el?.experience}</span> <br />
        //             <span>
        //               ({formatDate(el?.company_start_duration)} To{" "}
        //               {formatDate(el?.company_end_duration)})
        //             </span>
        //             <span></span>
        //           </div>
        //           <div className=" gap-4  p-2">
        //             <span className="break-words">
        //               {el?.work_experience_summary}
        //             </span>{" "}
        //             <br />
        //             <span className="flex flex-wrap gap-2 mt-2">
        //               {el?.skills
        //                 ? JSON.parse(el?.skills)?.map((el) => {
        //                     return (
        //                       <div className="border rounded-xl px-2 py-2 w-fit">
        //                         {el}
        //                       </div>
        //                     );
        //                   })
        //                 : ""}
        //             </span>
        //           </div>
        //         </div>
        //       );
        //     })}
        //   </div>

        //   <div className="p-5">
        //     <span>
        //       Your comprehensive profile is a crucial part of our Application
        //       Tracking System, empowering our analysis for better matches. Your
        //       information is securely stored in our database, ensuring future
        //       opportunities align with your skills and preferences. Rest
        //       assured, we'll leverage this data to connect you with relevant
        //       positions.
        //     </span>
        //     <span></span>
        //   </div>
        // </div>
        <div className="  bg-white shadow-lg rounded-lg  font-inter text-xs text-slate-600">
          <div className="px-2 py-1 border-b flex gap-4    items-center">
           
            <ATMHeading>Profile</ATMHeading>
          </div>
          <div className=" p-2 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3  gap-4">
            <div className=" bg-gray-800 text-white rounded-lg p-4 flex flex-col justify-between">
              <div className="">
                <h2 className="text-2xl text-white font-bold capitalize">
                  HEY! I'm {userData?.first_name}, <br /> {userData?.title}
                </h2>
              </div>
              <p className="text-sm break-words font-normal">
                {userData?.profile_summary}
              </p>
              <div className="flex items-center flex-wrap gap-2 ">
                {skills?.map((el, ind) => {
                  return (
                    <div
                      key={ind}
                      className="border px-4 py-0.5 rounded-lg flex justify-center items-end "
                    >
                      {el}
                    </div>
                  );
                })}
              </div>
            </div>

            <div className="border rounded-lg p-2 text-xs font-medium shadow-md ">
              <div className=" overflow-hidden   ">
                <img
                  className=" object-fill  w-full  h-[200px] rounded-lg"
                  src={userData?.profile_pic}
                  alt="profile"
                />
              </div>
              <div className="flex justify-between items-center flex-wrap gap-4 mt-1">
                <div className="font-bold capitalize text-sm  ">
                  {userData?.first_name} {""}
                  {userData?.last_name}
                </div>
                <div className="text-xs font-medium border-l text-end pl-4 ">
                  {userData?.title}
                </div>
              </div>
              <div className=" ">@{userData?.email}</div>
              <div className="mt-2">{userData?.contact_number}</div>
            </div>
            <div className="bg-slate-300 rounded-lg  overflow-hidden">
              <video
                className="w-full h-full"
                controls
                src=""
                poster={userData?.profile_pic}
              ></video>
            </div>

            {/* Career Trajectory */}
            <div className="bg-white border rounded-lg p-4 ">
              <h3 className="text-lg font-semibold mb-4">Career Trajectory</h3>

              <Bar
                data={careerTrajectoryData}
                options={{
                  responsive: true,
                  plugins: {
                    legend: {
                      display: true,
                      position: "top",
                    },
                    tooltip: {
                      callbacks: {
                        label: (context) => {
                          const job =
                            userData?.workexperience_candidate?.[
                              context.dataIndex
                            ];
                          const years = context.raw;
                          return `${job?.company_name}: ${years} years`;
                        },
                      },
                    },
                  },
                  scales: {
                    y: {
                      beginAtZero: true,
                      title: {
                        display: true,
                        text: "Years of Experience",
                      },
                    },
                    x: {
                      title: {
                        display: true,
                        text: "Companies and Timeline",
                      },
                      ticks: {
                        callback: function (value, index) {
                          // Show the company name and timeline for each label
                          const label = this.getLabelForValue(value);
                          return label;
                        },
                      },
                    },
                  },
                }}
                height={200}
                width={400}
              />
            </div>

            {/* Skills Acquired */}
            <div className="bg-white border rounded-lg p-4 flex flex-col items-center space-y-4">
              <h3 className="text-lg font-semibold">Skills Acquired</h3>

              {/* Chart and Details Section */}
              <div className="flex items-center justify-between w-full h-full">
                {/* Pie Chart */}
                <div className="flex-grow max-w-[50%]">
                  <Pie
                    data={skillsAcquiredData}
                    options={{
                      responsive: true,
                      maintainAspectRatio: false,
                      plugins: {
                        legend: {
                          display: false,
                        },
                        tooltip: {
                          callbacks: {
                            label: () => {
                              return ``;
                            },
                          },
                        },
                        datalabels: {
                          color: "#fff",
                          font: {
                            weight: "normal",
                            size: 11,
                          },
                          formatter: (value) => {
                         
                            const percentage = value; // Calculate percentage
                            return `${percentage}%`; // Display percentage
                          },
                        },
                      },
                    }}
                  />
                </div>

                {/* Skill Details */}
                <ul className="space-y-2 text-xs text-right ml-4 flex-shrink-0">
                  {skillsAcquiredData.labels.map((label, index) => (
                    <li key={index} className="flex items-center">
                      <span
                        className="inline-block w-3 h-3 rounded-full mr-2"
                        style={{
                          backgroundColor:
                            skillsAcquiredData.datasets[0].backgroundColor[
                              index
                            ],
                        }}
                      ></span>
                      <span className="font-bold">{label}:</span>{" "}
                      {skillsAcquiredData.datasets[0].data[index]}%{" "}
                    </li>
                  ))}
                </ul>
              </div>
            </div>

            {/* Skill Proficiency */}
            <div className="bg-white border rounded-lg p-4 ">
              <h3 className="text-lg font-semibold mb-4">Skill Proficiency</h3>
              <Bar
                data={skillProficiencyData}
                options={{ responsive: true }}
                height={200}
                width={400}
              />
            </div>

            {userData?.candidatehobbies_candidate?.length > 0 && (
              <div className="border rounded-lg">
                <div className="bg-blue-100  rounded-t-lg px-4 py-2 font-semibold">
                  Interest and Hobbies
                </div>
                <div className="flex flex-wrap gap-4 p-2">
                  {userData?.candidatehobbies_candidate?.map((el) => {
                    return (
                      <div className="border rounded-xl px-4 py-1 text-center">
                        {el?.hobbie?.hobbie_category}
                      </div>
                    );
                  })}
                </div>
              </div>
            )}
            {userData?.candidatehobbies_candidate?.length > 0 && (
              <div className="border rounded-lg relative">
                <div className="bg-blue-100  rounded-t-lg px-4 font-semibold py-2">
                  About Interest
                </div>
                <div className=" p-2">
                  Lorem ipsum dolor sit, amet consectetur adipisicing elit.
                  Optio doloribus quasi quo sed soluta. Qui sit cum ex,
                  mollitia, praesentium, nihil laboriosam tempora laudantium
                  pariatur quis nam atque quo eum.
                </div>
                <span className="bg-blue-100 opacity-85  rounded-lg absolute inset-0 text-[#1cacbd] text-xl text-center flex justify-center items-center">
                  Coming soon....
                </span>
              </div>
            )}
            {userData?.languages !== "[]" && (
              <div className="border rounded-lg">
                <div className="bg-blue-100  rounded-t-lg px-4 font-semibold py-2">
                  languages Profeciency
                </div>
                <div className="flex flex-wrap gap-4  p-2">
                  {userData?.languages
                    ? JSON.parse(userData?.languages)?.map((el) => {
                        return (
                          <div className="border rounded-xl px-4 py-1 text-center">
                            {el}
                          </div>
                        );
                      })
                    : ""}
                </div>
              </div>
            )}

            {userData?.education_candidate?.length > 0 && (
              <div className="font-bold text-xl md:col-span-2 lg:col-span-3">
                Qualification
              </div>
            )}
            {userData?.education_candidate?.map((el) => {
              return (
                <div key={el?.id} className="border rounded-lg">
                  <div className="bg-gray-800 text-white py-5 rounded-t-lg px-4 font-semibold">
                    <span>{el?.institution_name}</span> <br />
                    <span>{el?.education}</span> <br />
                    <span>
                      ({formatDate(el?.duration_start)} To{" "}
                      {formatDate(el?.duration_end)})
                    </span>
                    <span></span>
                  </div>
                  <div className=" gap-4  p-2">
                    <span>-{el?.specialization}</span> <br />
                    <span>
                      {el?.skills
                        ? JSON.parse(el?.skills)?.map((el) => {
                            return <div className="">-{el}</div>;
                          })
                        : ""}
                    </span>
                  </div>
                </div>
              );
            })}

            {userData?.workexperience_candidate?.length > 0 && (
              <div className="font-bold text-xl md:col-span-2 lg:col-span-3">
                Work Experience
              </div>
            )}
            {userData?.workexperience_candidate?.map((el) => {
              return (
                <div key={el?.id} className="border rounded-lg">
                  <div className="border-b  py-2 rounded-t-lg px-4">
                    <span className=" font-semibold">{el?.job_title}</span>{" "}
                    <br />
                    <span>{el?.company_name}</span> <br />
                    <span>{el?.experience}</span> <br />
                    <span>
                      ({formatDate(el?.company_start_duration)} To{" "}
                      {formatDate(el?.company_end_duration)})
                    </span>
                    <span></span>
                  </div>
                  <div className=" gap-4  p-2">
                    <span className="break-words">
                      {el?.work_experience_summary}
                    </span>{" "}
                    <br />
                    <span className="flex flex-wrap gap-2 mt-2">
                      {el?.skills
                        ? JSON.parse(el?.skills)?.map((el, ind) => {
                            return (
                              <div
                                key={ind}
                                className="border rounded-xl px-2 py-2 w-fit"
                              >
                                {el}
                              </div>
                            );
                          })
                        : ""}
                    </span>
                  </div>
                </div>
              );
            })}
          </div>

          <div className="p-5">
            <span>
              Your comprehensive profile is a crucial part of our Application
              Tracking System, empowering our analysis for better matches. Your
              information is securely stored in our database, ensuring future
              opportunities align with your skills and preferences. Rest
              assured, we'll leverage this data to connect you with relevant
              positions.
            </span>
            <span></span>
          </div>
        </div>
      )}
    </div>
  );
};

export default ShareProfileView;
