import React, { useState } from "react";
import { useGetAllMyOrgJobsQuery } from "../../../../app/DashboardServices/DashboardServices";
import ATMCircularProgress from "../../../../atoms/ATMCircularProgress/ATMCircularProgress";
import ATMFieldLabel from "../../../../atoms/ATMFieldLabel/ATMFieldLabel";
import { useFetchData } from "../../../../hooks/useFetchData";

const TagJdList = ({ setSelectedJobs, selectedJobs }) => {
  const [searchValue, setSearchValue] = useState("");

  const { data, isLoading } = useFetchData(useGetAllMyOrgJobsQuery, {
    body: {
      search: searchValue,
      fields: [],
    },
  });

  const handleJobSelect = (job) => {
    setSelectedJobs((prev) =>
      prev?.find((j) => j?.id === job?.id)
        ? prev // Prevent duplicates
        : [...prev, job]
    );
    setSearchValue("");
  };

  const handleJobRemove = (jobId) => {
    setSelectedJobs((prev) => prev.filter((j) => j.id !== jobId));
  };

  return (
    <div>
      <div className="grid grid-cols-1 lg:grid-cols-1 gap-4">
        <div>
          <span className="text-sm font-semibold text-[#1CACBD]">
            Step 3 -{" "}
          </span>
          <ATMFieldLabel> Tagged JD (Optional) </ATMFieldLabel>

          <div
            className="flex items-center border rounded-md"
          >
            <div className="relative w-full mr-1">
              <input
                minLength={2}
                maxLength={50}
                className="block bg-white border-0 outline-none rounded-l-md h-[34px] placeholder:text-xs pl-2 min-w-full"
                placeholder="Search jobs by title and Tag JD"
                type="text"
                name="searchValue"
                value={searchValue}
                onChange={(e) => setSearchValue(e.target.value)}
                onKeyPress={(e) => {
                  if (e.key === "Enter") {
                    setSearchValue(e.target.value);
                  }
                }}
              />
            </div>
            <span className="  flex items-center  px-2 border-l  h-full ">
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                className="cursor-pointer"
                onClick={() => {
                  setSearchValue(searchValue);
                }}
              >
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M15.802 14.2644H14.9922L14.7051 13.9876C15.7097 12.819 16.3145 11.3018 16.3145 9.65141C16.3145 5.97131 13.3315 2.98828 9.65141 2.98828C5.97131 2.98828 2.98828 5.97131 2.98828 9.65141C2.98828 13.3315 5.97131 16.3145 9.65141 16.3145C11.3018 16.3145 12.819 15.7097 13.9876 14.7051L14.2644 14.9922V15.802L19.3898 20.9172L20.9172 19.3898L15.802 14.2644ZM9.65108 14.2643C7.09859 14.2643 5.03814 12.2039 5.03814 9.65138C5.03814 7.09889 7.09859 5.03845 9.65108 5.03845C12.2036 5.03845 14.264 7.09889 14.264 9.65138C14.264 12.2039 12.2036 14.2643 9.65108 14.2643Z"
                  fill="#222222"
                />
              </svg>
            </span>
          </div>
        </div>
        {/* Selected Jobs */}
        <div className="flex flex-wrap gap-2 my-1">
          {selectedJobs?.map((job) => (
            <div
              key={job.id}
              className="flex items-center bg-gray-300 px-2 py-1 rounded-md text-xs"
            >
              {job.title}
              <span
                onClick={() => handleJobRemove(job.id)}
                className="ml-2 text-red-500 hover:border hover:border-red-500 rounded-full w-4 h-4 text-center cursor-pointer  font-semibold"
              >
                X
              </span>
            </div>
          ))}
        </div>
      </div>

      <span className="flex justify-center mt-2">
        {" "}
        {isLoading ? (
          <ATMCircularProgress size="small" />
        ) : (
          data?.length === 0 && "Data Not Found"
        )}
      </span>

      <div className="text-xs font-inter grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4 mt-4">
        {!isLoading &&
          searchValue &&
          data?.map((job) => (
            <div
              key={job.id}
              className={`flex flex-col gap-2 rounded-xl bg-[#F7F8FD] border-2  p-5`}
            >
              <div>
                <h1 className="text-[#202020] font-semibold text-xs">
                  {job.title}
                </h1>
              </div>

              <a
                rel="noopener noreferrer"
                target="_blank"
                href={`/recruit/job-status/recruiter-view/${job.id}`}
                className="text-[#1F42FA] font-medium border-b-blue-400 border-b w-fit"
              >
                View Job Details
              </a>
              <div className="flex items-center justify-between gap-2">
                <div className="flex  items-center gap-1">
                  <svg
                    width="16"
                    height="14"
                    viewBox="0 0 11 15"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M5.10803 0.851562C2.30993 0.851562 0.046875 3.00535 0.046875 5.66834C0.046875 9.28092 5.10803 14.6138 5.10803 14.6138C5.10803 14.6138 10.1692 9.28092 10.1692 5.66834C10.1692 3.00535 7.90612 0.851562 5.10803 0.851562ZM5.1078 7.38945C4.11002 7.38945 3.30024 6.61876 3.30024 5.66917C3.30024 4.71958 4.11002 3.94889 5.1078 3.94889C6.10557 3.94889 6.91535 4.71958 6.91535 5.66917C6.91535 6.61876 6.10557 7.38945 5.1078 7.38945Z"
                      fill="#898989"
                    />
                  </svg>
                  <p className="text-[#202020] mt-1  font-normal">
                    Office: {job?.city || job?.state || job?.country}
                  </p>
                </div>
                <div className="flex  items-center gap-1 ">
                  <svg
                    width="16"
                    height="14"
                    viewBox="0 0 16 14"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M13.8577 3.53565H11.6886V2.15943C11.6886 1.39562 11.0451 0.783203 10.2425 0.783203H5.90441C5.10186 0.783203 4.45837 1.39562 4.45837 2.15943V3.53565H2.2893C1.48675 3.53565 0.843262 4.14807 0.843262 4.91187V12.4811C0.843262 13.2449 1.48675 13.8573 2.2893 13.8573H13.8577C14.6602 13.8573 15.3037 13.2449 15.3037 12.4811V4.91187C15.3037 4.14807 14.6602 3.53565 13.8577 3.53565ZM5.9045 2.16056H10.2426V3.5373H5.9045V2.16056ZM13.8582 12.4824H2.28982V11.1056H13.8582V12.4824ZM13.8577 9.04006H2.28933V4.91139H4.4584V6.28762H5.90444V4.91139H10.2426V6.28762H11.6886V4.91139H13.8577V9.04006Z"
                      fill="#898989"
                    />
                  </svg>
                  <p className="text-[#202020] mt-1 font-normal">
                    {job?.aspect_of_work_location}
                  </p>
                </div>
              </div>
              <div className="flex  items-center gap-1">
                <svg
                  width="16"
                  height="14"
                  viewBox="0 0 16 14"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M13.8577 3.53565H11.6886V2.15943C11.6886 1.39562 11.0451 0.783203 10.2425 0.783203H5.90441C5.10186 0.783203 4.45837 1.39562 4.45837 2.15943V3.53565H2.2893C1.48675 3.53565 0.843262 4.14807 0.843262 4.91187V12.4811C0.843262 13.2449 1.48675 13.8573 2.2893 13.8573H13.8577C14.6602 13.8573 15.3037 13.2449 15.3037 12.4811V4.91187C15.3037 4.14807 14.6602 3.53565 13.8577 3.53565ZM5.9045 2.16056H10.2426V3.5373H5.9045V2.16056ZM13.8582 12.4824H2.28982V11.1056H13.8582V12.4824ZM13.8577 9.04006H2.28933V4.91139H4.4584V6.28762H5.90444V4.91139H10.2426V6.28762H11.6886V4.91139H13.8577V9.04006Z"
                    fill="#898989"
                  />
                </svg>
                <p className="text-[#202020] mt-1  font-normal ">
                  Salary:
                  {job?.salary_from === 0 ? (
                    "--"
                  ) : (
                    <span>
                      {job?.currency} {job?.salary_from}-{job?.salary_to}
                    </span>
                  )}
                </p>
              </div>
              <div className="flex items-start gap-1">
                <svg
                  width="16"
                  height="14"
                  viewBox="0 0 15 15"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <g clipPath="url(#clip0_9326_55716)">
                    <path
                      d="M7.82555 6.90904C9.39631 6.90904 10.6697 5.72048 10.6697 4.25432C10.6697 2.78817 9.39631 1.59961 7.82555 1.59961C6.25479 1.59961 4.98145 2.78817 4.98145 4.25432C4.98145 5.72048 6.25479 6.90904 7.82555 6.90904Z"
                      stroke="#898989"
                      strokeWidth="1.5"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M3.3167 13.4446H12.3347C13.334 13.4446 14.1037 12.6426 13.6371 11.8178C12.9506 10.6046 11.3856 9.15625 7.8257 9.15625C4.26576 9.15625 2.70077 10.6046 2.01431 11.8178C1.54764 12.6426 2.31739 13.4446 3.3167 13.4446Z"
                      stroke="#898989"
                      strokeWidth="1.5"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </g>
                  <defs>
                    <clipPath id="clip0_9326_55716">
                      <rect
                        width="14.0989"
                        height="13.1601"
                        fill="white"
                        transform="translate(0.776367 0.941406)"
                      />
                    </clipPath>
                  </defs>
                </svg>

                <p className="text-[#202020]  font-normal">
                  Account Manager: {job?.account_manager?.first_name}
                </p>
              </div>
              <div className="flex justify-end">
                <button
                  type="button"
                  onClick={() => handleJobSelect(job)}
                  className="bg-[#1CACBD] p-2 rounded-md text-white text-center"
                >
                  Tag This JD
                </button>
              </div>
            </div>
          ))}
      </div>
    </div>
  );
};

export default TagJdList;
