import { Form, Formik } from "formik";
import React from "react";
import toast from "react-hot-toast";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { array, object, string } from "yup";
import { axiosCandidateFromRequest } from "../../../../api/formApi";
import { setRefresh } from "../../../../features/refresh/refresh";
import WorkExperienceFormLayout from "../Layout/WorkExperienceFormLayout";
const baseUrl = process.env.REACT_APP_VITE_BACKEND_API_URL;

const AddWorkExperienceFormWrapper = ({ setWorkExperienceAdd, userData }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const refresh = useSelector((state) => state.refresh.refresh);
  const initialValues = {
    blob: "",
    experienceType: "",
    companyName: "",
    employmentType: "",
    durationFrom: null,
    durationTo: null,
    profileSummary: "",
    experienceVideo: "",
    designation: "",
    skillsData: [],
  };

  const validationSchema = object().shape({
    skillsData: array().max(100, "You can add a maximum of 100 skills"),
    profileSummary: string().max(
      700,
      "profileSummary cannot exceed 700 characters"
    ),
    companyName: string().max(40, "companyName cannot exceed 40 characters"),
    designation: string().max(40, "designation cannot exceed 40 characters"),
  });

  // const handleSubmit = async (values, { setSubmitting }) => {
  //   setSubmitting(true);
  //   try {
  //     const formData = {
  //       experience: values.experienceType?.label,
  //       company_name: values.companyName,
  //       company_start_duration: values.durationFrom,
  //       company_end_duration: values.durationTo,
  //       job_title: values.designation,
  //       work_experience_summary: values.profileSummary,
  //       skills: JSON.stringify(values.skillsData.map((el) => el.label)),
  //       upload_work_video: values?.experienceVideo,
  //       full_or_part_time:
  //         values?.employmentType === "full-time" ? true : false,
  //     };
  //     let response = await axiosCandidateFromRequest(
  //       `${baseUrl}/api/candidate/work_experience/`,
  //       {
  //         method: "POST",
  //         data: formData,
  //       }
  //     );

  //     if (response.status === 201) {
  //       setSubmitting(false);
  //       toast.success("Data updated successfully");
  //       dispatch(setRefresh(!refresh));
  //       setWorkExperienceAdd(false);
  //       navigate(`/candidate/profile/education-details`);
  //     } else if (response.status === 400) {
  //       toast.error("Failed to update data");
  //     }
  //   } catch (error) {
  //     if (error.response && error.response.status === 400) {
  //       toast.error(error.response.data.message);
  //     } else {
  //       toast.error("An unexpected error occurred");
  //     }
  //   } finally {
  //     setSubmitting(false);
  //   }
  // };

  const handleSubmit = async (values, { setSubmitting }) => {
    setSubmitting(true);
    if (values?.blob) {
      try {
        const formData = new FormData();
        formData.append("oldVideoPath", "");
        formData.append("candidateId", userData?.id);
        formData.append(
          "profileVideo",
          values?.blob,
          `work-experience-video.webm`
        );

        const response = await axiosCandidateFromRequest(
          `https://stagingapi.jivahire.com/stream/user-profile-video`,
          {
            method: "POST",
            data: formData,
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }
        );

        if (response.status === 200) {
          try {
            const formData2 = {
              experience: values.experienceType?.label,
              company_name: values.companyName,
              company_start_duration: values.durationFrom,
              company_end_duration: values.durationTo,
              job_title: values.designation,
              full_or_part_time:
                values?.employmentType === "full-time" ? true : false,
              work_experience_summary: values.profileSummary,
              skills: JSON.stringify(values.skillsData.map((el) => el.label)),
              upload_work_video: response.data.s3Key,
            };

            let response2 = await axiosCandidateFromRequest(
              `${baseUrl}/api/candidate/work_experience/`,
              {
                method: "POST",
                data: formData2,
              }
            );

            if (response2.status === 201) {
              setSubmitting(false);
              toast.success("Data Add successfully");
              dispatch(setRefresh(!refresh));
              setWorkExperienceAdd(false);
              navigate(`/candidate/profile/education-details`);
            } else if (response2.status === 400) {
              toast.error("Failed to update data");
            }
          } catch (error) {
            if (error.response2 && error.response2.status === 400) {
              toast.error(error.response2.data.message);
            } else {
              toast.error("An unexpected error occurred");
            }
          }
        } else {
          throw new Error(response.data.message);
        }
      } catch (error) {
        toast.error(`Upload error: ${error.message}`);
      } finally {
        setSubmitting(false);
      }
    } else {
      try {
        const formData = {
          experience: values.experienceType?.label,
          company_name: values.companyName,
          company_start_duration: values.durationFrom,
          company_end_duration: values.durationTo,
          job_title: values.designation,
          full_or_part_time:
            values?.employmentType === "full-time" ? true : false,
          work_experience_summary: values.profileSummary,
          skills: JSON.stringify(values.skillsData.map((el) => el.label)),
          upload_work_video: values?.experienceVideo,
        };

        let response = await axiosCandidateFromRequest(
          `${baseUrl}/api/candidate/work_experience/`,
          {
            method: "POST",
            data: formData,
          }
        );

        if (response.status === 201) {
          setSubmitting(false);
          toast.success("Data Add successfully");
          dispatch(setRefresh(!refresh));
          setWorkExperienceAdd(false);
          navigate(`/candidate/profile/education-details`);
        } else if (response.status === 400) {
          toast.error("Failed to update data");
        }
      } catch (error) {
        if (error.response && error.response.status === 400) {
          toast.error(error.response.data.message);
        } else {
          toast.error("An unexpected error occurred");
        }
      } finally {
        setSubmitting(false);
      }
    }
  };
  return (
    <Formik
      initialValues={initialValues}
      onSubmit={handleSubmit}
      validationSchema={validationSchema}
      enableReinitialize
    >
      {(formikProps) => (
        <Form>
          <WorkExperienceFormLayout
            formType="Add"
            formikProps={formikProps}
            workExperienceData={{}}
          />
        </Form>
      )}
    </Formik>
  );
};

export default AddWorkExperienceFormWrapper;
