import React, { useState } from "react";
import MyResmuesList from "./MyResmuesList";
import { useUpLoadedResumeQuery } from "../Services/FormServices";
import ATMCircularProgress from "../../../../atoms/ATMCircularProgress/ATMCircularProgress";
import ATMHeading from "../../../../atoms/ATMHeading/ATMHeading";
import ATMPagination from "../../../../atoms/ATMPagination/ATMPagination";
import { useFetchData } from "../../../../hooks/useFetchData";

const MyResmuesListWrapper = () => {
  const [pagination, setPagination] = useState({
    currentPage: 1,
    itemsPerPage: 24,
  });

  const { data, isLoading, isFetching, totalData } = useFetchData(
    useUpLoadedResumeQuery,
    {
      body: {
        page: pagination.currentPage,
        size: pagination.itemsPerPage,
      },
    }
  );

  const handlePageChange = (page) => {
    setPagination((prev) => ({ ...prev, currentPage: page }));
  };

  const handleItemsPerPageChange = (itemsPerPage) => {
    setPagination((prev) => ({ ...prev, itemsPerPage, currentPage: 1 })); // Reset to page 1 when changing items per page
  };

  return (
    <div className="min-h-full flex flex-col ">
      {/* Header Section */}
      <div className="flex gap-1 items-center border-b p-2">
        <ATMHeading>My Resumes</ATMHeading>
        <span className="w-5 h-5 bg-[#1CACBD] text-white text-[11px] items-center justify-center flex border rounded-full bg-">
          {totalData}
        </span>
      </div>

      {/* Content Section */}
      <div className="flex-grow mt-4  min-w-[1000px] overflow-x-auto">
        {isLoading || isFetching ? (
          <div className="flex justify-center items-center h-full">
            <ATMCircularProgress />
          </div>
        ) : (
          <div>
            <MyResmuesList listData={data} pagination={pagination} />
          </div>
        )}
      </div>

      {/* Pagination Section */}
      <div className="flex justify-end my-2  min-w-[1000px]">
        <ATMPagination
          totalItems={totalData}
          itemsPerPage={pagination.itemsPerPage}
          currentPage={pagination.currentPage}
          onPageChange={handlePageChange}
          itemsPerPageOptions={[4, 8, 12, 16, 20, 24, 48, 96]}
          onItemsPerPageChange={handleItemsPerPageChange}
        />
      </div>
    </div>
  );
};

export default MyResmuesListWrapper;
