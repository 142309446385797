import * as Yup from "yup";

export const signUpSchema = Yup.object().shape({
  email: Yup.string()
    .email("Invalid email format")
    .required("Email is required"),
  firstName: Yup.string().required("First name is required"),
  lastName: Yup.string().required("Last name is required"),
  companyUrl: Yup.string().required("Company URL is required"),
  password: Yup.string()
    .min(6, "Password must be at least 8 characters")
    .required("Password is required"),
  confirmPassword: Yup.string()
    .oneOf([Yup.ref("password"), null], "Passwords must match")
    .required("Confirm password is required"),
});

export const resetPasswordSchema = Yup.object().shape({
  password: Yup.string()
    .min(6, "Password must be at least 8 characters")
    .required("Password is required"),
  confirmPassword: Yup.string()
    .oneOf([Yup.ref("password"), null], "Passwords must match")
    .required("Confirm password is required"),
});

export const createJobSchema = Yup.object().shape({
  jobTittle: Yup.string().required("Job Title is required"),
  jobDescription: Yup.string().required("Job Description is required"),
  workLocation: Yup.object().required("Work Location is required"),
  expierence: Yup.number().required("Experience is required"),
  externalCompany: Yup.boolean(),
  vacancies: Yup.number()
    .required("Vacancies is required")
    .min(1, "Vacancies must be at least 1"),
  companyName: Yup.string().when("externalCompany", {
    is: true,
    then: () => Yup.string().required("Company Name is required"),
  }),
  // jobDurationNumber: Yup.number()
  //   .test(
  //     "is-required",
  //     "Job Duration is required when Employment Type is not Full Time.",
  //     function (value) {
  //       var { employementType } = this.parent;

  //       return (
  //         employementType?.value === "Full-time employment" || value != null
  //       );
  //     }
  //   )
  //   .nullable(),
  salaryFrom: Yup.number()
    .required("Minimum Salary is required")
    .min(0, "Minimum Salary must be a positive number"),

  salaryTo: Yup.number()
    .required("Maximum Salary is required")
    .min(
      Yup.ref("salaryFrom"),
      "Maximum Salary must be greater than or equal to Minimum Salary"
    )
    .test(
      "not-equal",
      "Maximum Salary must not be equal to Salary from",
      function (value) {
        const { salaryFrom } = this.parent;
        return value !== salaryFrom;
      }
    ),
  country: Yup.object()
    .shape({
      value: Yup.string().required("Country value is required"),
      label: Yup.string().required("Country label is required"),
    })
    .required("Country is required"),
  state: Yup.object()
    .shape({
      value: Yup.string().required("State value is required"),
      label: Yup.string().required("State label is required"),
    })
    .required("State is required"),
  city: Yup.object()
    .shape({
      value: Yup.string().required("City value is required"),
      label: Yup.string().required("City label is required"),
    })
    .required("City is required"),
  zipCode: Yup.number().required("Zipcode is required"),
});

export const commentSchema = Yup.object().shape({
  comment: Yup.string().required("Job Title is required"),
});

export const inviteSchema = Yup.object().shape({
  emails: Yup.array().of(
    Yup.string().email("Invalid email address").required("Email is required")
  ),
});

export const superAdminSchema = Yup.object().shape({
  firstName: Yup.string()
    .required("First name is required")
    .min(2, "First name must be at least 2 characters")
    .max(50, "First name must be less than 50 characters"),

  lastName: Yup.string()
    .required("Last name is required")
    .min(2, "Last name must be at least 2 characters")
    .max(50, "Last name must be less than 50 characters"),

  email: Yup.string()
    .email("Invalid email address")
    .required("Email is required"),
  companyUrl: Yup.string().required("Company URL is required"),
  // email: string().email("Invalid email address").required("Email is required"),

  // companyUrl: string()
  //   .required("Company URL is required")
  //   .test(
  //     "matches-email-domain",
  //     "Company URL must match the email domain",
  //     function (value) {
  //       const { email } = this.parent; // Get the email field from the form's parent
  //       if (!email) return true; // If email is not set, we can't validate yet

  //       // Extract domain from email
  //       const emailDomain = email.split("@")[1];
  //       if (!emailDomain) return false; // If no domain exists in the email, validation fails

  //       // Check if companyUrl contains the domain or ends with it
  //       const companyUrlDomain = value ? value.split("/")[2] : ""; // Extract the domain part from the URL
  //       return companyUrlDomain && companyUrlDomain.endsWith(emailDomain);
  //     }
  //   ),

  password: Yup.string()
    .required("Password is required")
    .min(6, "Password must be at least 8 characters")
    .max(100, "Password must be less than 100 characters"),

  // contactNumber: Yup.string()
  //   .required("Contact number is required")
  //   .min(9, "Contact number must be at least 10 digits")
  //   .max(15, "Contact number must be less than 15 digits"),
  companyMission: Yup.string()
    .required("Company Mission  is required")
    .max(300, "Company Mission must be less than 300 characters"),
  companyName: Yup.string()
    .required("Company name is required")
    .max(100, "Company name must be less than 100 characters"),

  logo: Yup.mixed()
    .required("Logo is required")
    .test("fileSize", "File size should be less than 5MB", (value) => {
      return value && value.size <= 5 * 1024 * 1024; // 5MB in bytes
    })
    .test("fileType", "PDF files are not allowed", (value) => {
      return value && value.type !== "application/pdf"; // Prevent PDF files
    }),

  addressOne: Yup.string().required("Address line 1 is required"),

  addressTwo: Yup.string(),

  country: Yup.object()
    .shape({
      value: Yup.string().required("Country value is required"),
      label: Yup.string().required("Country label is required"),
    })
    .required("Country is required"),

  totalEmployees: Yup.string().required("Total employees is required"),
});
