import { Form, Formik } from "formik";
import React from "react";
import toast from "react-hot-toast";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { array, date, object, string } from "yup";
import { axiosCandidateFromRequest } from "../../../../api/formApi";
import { setRefresh } from "../../../../features/refresh/refresh";
import ProfileDetailsForm from "../Layout/ProfileDetailsForm";
const baseUrl = process.env.REACT_APP_VITE_BACKEND_API_URL;

// const validateImage = (file) => {
//   if (!file) return false;

//   const validTypes = ["image/svg+xml", "image/png", "image/jpeg", "image/gif"];
//   if (!validTypes.includes(file.type)) return false;

//   // Create an image element to check dimensions
//   const img = new Image();
//   img.src = URL.createObjectURL(file);

//   return new Promise((resolve, reject) => {
//     img.onload = () => {
//       URL.revokeObjectURL(img.src);
//       if (img.width <= 800 && img.height <= 400) {
//         resolve(true);
//       } else {
//         resolve(false);
//       }
//     };
//     img.onerror = () => {
//       URL.revokeObjectURL(img.src);
//       resolve(false);
//     };
//   });
// };

const EditProfileDetailsFormWrapper = ({ setProfileEdit, userData }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const refresh = useSelector((state) => state.refresh.refresh);
  const languagesArray =
    (userData?.languages && JSON.parse(userData?.languages)) || [];
  const formattedLanguages = languagesArray.map((lang) => ({
    label: lang,
    value: lang,
  }));
  const skillsArray = (userData?.skills && JSON.parse(userData?.skills)) || [];
  const formattedSkills = skillsArray.map((skill) => ({
    label: skill,
    value: skill,
  }));
  const initialValues = {
    blob: "",
    dob: userData?.date_of_birth || null,
    title: userData?.title || "",
    skillsData: formattedSkills || [],
    languageData: formattedLanguages || [],
    uploadvideo: userData?.introduction_video || "",
    profilePhoto: userData?.profile_pic || "",
    profilesummary: userData?.profile_summary || "",
    city: { label: userData?.city } || null,
    state: { label: userData?.state } || null,
    country: { label: userData?.country } || null,
    zipCode: userData?.zipcode || "",
  };

  const validationSchema = object().shape({
    country: object().required("Country is required"),
    dob: date().test(
      "is-18",
      "You must be at least 18 years old",
      function (value) {
        if (!value) return false;

        const today = new Date();
        const birthDate = new Date(value);
        const age = today.getFullYear() - birthDate.getFullYear();
        const monthDifference = today.getMonth() - birthDate.getMonth();
        const dayDifference = today.getDate() - birthDate.getDate();
        if (
          monthDifference < 0 ||
          (monthDifference === 0 && dayDifference < 0)
        ) {
          return age - 1 >= 18;
        }

        return age >= 18;
      }
    ),
    title: string().max(30, "title cannot exceed 30 characters"),
    skillsData: array().max(100, "You can add a maximum of 100 skills"),
    languageData: array().max(100, "You can add a maximum of 100 languages"),
    profilesummary: string().max(
      1500,
      "Profile Summary cannot exceed 1500 characters"
    ),
    zipCode: string().max(50, "ZipCode cannot exceed 50 characters"),
  });

  const handleSubmit = async (values, { setSubmitting }) => {
    setSubmitting(true);
    if (values?.blob) {
      try {
        const formData = new FormData();
        formData.append("oldVideoPath", userData?.introduction_video);
        formData.append("candidateId", userData?.id);
        formData.append("profileVideo", values?.blob, `profile-video.webm`);

        const response = await axiosCandidateFromRequest(
          `https://stagingapi.jivahire.com/stream/user-profile-video`,
          {
            method: "POST",
            data: formData,
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }
        );

        if (response.status === 200) {
          // toast.success(response.data.message);
          try {
            const formData = new FormData();
            formData.append("title", values.title);
            let formattedDob = "";
            if (values.dob) {
              const date = new Date(values.dob);
              const year = date.getFullYear();
              const month = String(date.getMonth() + 1).padStart(2, "0"); // Months are 0-indexed
              const day = String(date.getDate()).padStart(2, "0");
              formattedDob = `${year}-${month}-${day}`;
            }
            formData.append("date_of_birth", formattedDob || "");
            if (values.profilePhoto !== userData?.profile_pic) {
              formData.append(
                "profile_pic",
                values.profilePhoto === userData?.profile_pic
                  ? ""
                  : values.profilePhoto
              );
            }
            formData.append(
              "skills",
              JSON.stringify(values?.skillsData?.map((el) => el.label))
            );
            formData.append(
              "languages",
              JSON.stringify(values?.languageData?.map((el) => el.label))
            );
            formData.append("introduction_video", response.data.s3Key);
            formData.append("profile_summary", values.profilesummary);
            formData.append("city", values.city?.label || "");
            formData.append("state", values.state?.label || "");
            formData.append("country", values.country?.label || "");
            formData.append("zipcode", values.zipCode);

            const response2 = await axiosCandidateFromRequest(
              `${baseUrl}/api/candidate/user/`,
              {
                method: "PATCH",

                data: formData,
              }
            );

            if (response2.status === 200) {
              toast.success("Data updated successfully");
              localStorage.setItem(
                "candidate_profile",
                JSON.stringify(response2.data.data.candidate)
              );
              setProfileEdit(false);
              setSubmitting(false);
              dispatch(setRefresh(!refresh));
              if (!userData?.title) {
                navigate(`/candidate/profile/work-experience`);
              }
            } else if (response2.status === 400) {
              toast.error("Failed to update data");
            }
          } catch (error) {
            if (error.response2 && error.response2.status === 400) {
              toast.error(error.response2.data.message);
            } else {
              toast.error("An unexpected error occurred ");
            }
          }
        } else {
          throw new Error(response.data.message);
        }
      } catch (error) {
        toast.error(`Upload error: ${error.message}`);
      } finally {
        setSubmitting(false);
      }
    } else {
      try {
        const formData = new FormData();
        formData.append("title", values.title);
        let formattedDob = "";
        if (values.dob) {
          const date = new Date(values.dob);
          const year = date.getFullYear();
          const month = String(date.getMonth() + 1).padStart(2, "0"); // Months are 0-indexed
          const day = String(date.getDate()).padStart(2, "0");
          formattedDob = `${year}-${month}-${day}`;
        }
        formData.append("date_of_birth", formattedDob || "");
        if (values.profilePhoto !== userData?.profile_pic) {
          formData.append(
            "profile_pic",
            values.profilePhoto === userData?.profile_pic
              ? ""
              : values.profilePhoto
          );
        }
        formData.append(
          "skills",
          JSON.stringify(values?.skillsData?.map((el) => el.label))
        );
        formData.append(
          "languages",
          JSON.stringify(values?.languageData?.map((el) => el.label))
        );
        formData.append("introduction_video", "");
        formData.append("profile_summary", values.profilesummary);
        formData.append("city", values.city?.label || "");
        formData.append("state", values.state?.label || "");
        formData.append("country", values.country?.label || "");
        formData.append("zipcode", values.zipCode);

        const response2 = await axiosCandidateFromRequest(
          `${baseUrl}/api/candidate/user/`,
          {
            method: "PATCH",

            data: formData,
          }
        );

        if (response2.status === 200) {
          toast.success("Data updated successfully");
          localStorage.setItem(
            "candidate_profile",
            JSON.stringify(response2.data.data.candidate)
          );
          setProfileEdit(false);
          setSubmitting(false);
          dispatch(setRefresh(!refresh));
          if (!userData?.title) {
            navigate(`/candidate/profile/work-experience`);
          }
        } else if (response2.status === 400) {
          toast.error("Failed to update data");
        }
      } catch (error) {
        if (error.response2 && error.response2.status === 400) {
          toast.error(error.response2.data.message);
        } else {
          toast.error("An unexpected error occurred ");
        }
      } finally {
        setSubmitting(false);
      }
    }
  };

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={handleSubmit}
      validationSchema={validationSchema}
      enableReinitialize
    >
      {(formikProps) => (
        <Form>
          <ProfileDetailsForm
            formType="Edit"
            formikProps={formikProps}
            userData={userData}
          />
        </Form>
      )}
    </Formik>
  );
};

export default EditProfileDetailsFormWrapper;
