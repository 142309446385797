import React, { useEffect, useRef, useState } from "react";
import ReactDatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { formatDate } from "../../pages/candidate/ProfileDetails/View/ProfileDetails";

const getToday = () => {
  const now = new Date();
  return { startDate: now, endDate: now };
};

const getYesterday = () => {
  const now = new Date();
  const yesterday = new Date(now);
  yesterday.setDate(now.getDate() - 1);
  return { startDate: yesterday, endDate: yesterday };
};

const getLastNDays = (days) => {
  const now = new Date();
  const start = new Date(now);
  start.setDate(now.getDate() - days);
  return { startDate: start, endDate: now };
};

const getLastNYears = (years) => {
  const now = new Date();
  const start = new Date(now);
  start.setFullYear(now.getFullYear() - years);
  return { startDate: start, endDate: now };
};

const ATMDateRangePicker = ({
  startDate,
  endDate,
  onSelectStartDate,
  onSelectEndDate,
  onClear,
  defaultOption = "",
}) => {
  const [datePortalOpen, setDatePortalOpen] = useState(false);
  const buttonRef = useRef(null);
  const dateRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        buttonRef.current &&
        !buttonRef.current.contains(event.target) &&
        dateRef.current &&
        !dateRef.current.contains(event.target)
      ) {
        setDatePortalOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => document.removeEventListener("mousedown", handleClickOutside);
  }, []);
  useEffect(() => {
    setDatePortalOpen(false);
  }, [endDate]);
  useEffect(() => {
    if (defaultOption && !startDate && !endDate) {
      switch (defaultOption) {
        case "7days":
          const { startDate: start, endDate: end } = getLastNDays(7);
          onSelectStartDate(start);
          onSelectEndDate(end);
          break;
        // Add other cases if needed
        default:
          // Handle the default case (e.g., when `defaultOption` is an unrecognized value)
          // console.log("Unrecognized option:", defaultOption);
          break;
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [startDate, endDate]);
  const handleQuickPicker = (start, end) => {
    onSelectStartDate(start);
    onSelectEndDate(end);
    setDatePortalOpen(false);
  };

  const quickPickerOptions = [
    { label: "Today", ...getToday() },
    { label: "Yesterday", ...getYesterday() },
    { label: "Last 7 Days", ...getLastNDays(7) },
    { label: "Last 30 Days", ...getLastNDays(30) },
    { label: "Last 60 Days", ...getLastNDays(60) },
    { label: "Last 90 Days", ...getLastNDays(90) },
    { label: "Last 1 Year", ...getLastNYears(1) },
  ];

  return (
    <div className="relative font-inter">
      {/* Main Input */}
      <div
        ref={buttonRef}
        onClick={() => setDatePortalOpen((prev) => !prev)}
        className={`relative flex items-center w-full h-[40px] px-3 bg-white border rounded-lg hover:bg-gray-50 transition-colors ${
          startDate || endDate ? "border-[#1CACBD]" : "border-gray-200"
        }`}
      >
        {/* Clear Button */}
        {(startDate || endDate) && defaultOption === "" && (
          <button
            onClick={onClear}
            className="absolute -top-2 -right-2 w-5 h-5 flex items-center justify-center bg-white border border-red-400 text-red-400 rounded-full hover:bg-red-50 transition-colors z-10"
          >
            ×
          </button>
        )}

        {/* Select Date Button */}
        <div className="flex items-center gap-2 cursor-pointer text-[10px] md:text-xs text-gray-500">
          <span> Date Selector</span>
        </div>

        {/* Selected Date Display */}
        {(startDate || endDate) && (
          <div className="flex  items-center gap-2 ml-3 pl-3 border-l border-gray-200">
            <span className="px-2 py-1 text-[10px] md:text-xs rounded bg-[#E6F6F8] text-[#1CACBD]">
              {startDate ? formatDate(startDate) : "From"}
            </span>
            <span className="text-gray-400">-</span>
            <span className="px-2 py-1 text-[10px] md:text-xs rounded bg-[#E6F6F8] text-[#1CACBD]">
              {endDate ? formatDate(endDate) : "To"}
            </span>
          </div>
        )}
      </div>

      {/* Date Picker Modal */}
      {datePortalOpen && (
        <>
          {/* Mobile Overlay */}
          <div
            className="fixed inset-0 bg-black/50 z-40 md:hidden"
            onClick={() => setDatePortalOpen(false)}
          />

          <div
            ref={dateRef}
            className="bg-white absolute z-10 rounded-md shadow-lg overflow-auto"
          >
            {/* Mobile Header */}
            <div className="flex items-center justify-between p-4 border-b md:hidden">
              <h3 className="text-lg font-medium">Select Date Range</h3>
              <button
                onClick={() => setDatePortalOpen(false)}
                className="text-gray-500 hover:text-gray-700"
              >
                ✕
              </button>
            </div>

            <div className="flex ">
              {/* Quick Picker */}
              <div className="w-full md:w-48 border-b md:border-b-0 md:border-r border-gray-200">
                <ul className="p-3 space-y-1">
                  {quickPickerOptions?.map((option, index) => {
                    const isSelected =
                      option.startDate.getTime() === startDate?.getTime() &&
                      option.endDate.getTime() === endDate?.getTime();
                    return (
                      <li
                        key={index}
                        onClick={() =>
                          handleQuickPicker(option.startDate, option.endDate)
                        }
                        className={`px-3 py-2 text-sm rounded-md cursor-pointer transition-colors
                          ${
                            isSelected
                              ? "bg-[#E6F6F8] text-[#1CACBD]"
                              : "text-gray-600 hover:bg-gray-50"
                          }`}
                      >
                        {option.label}
                      </li>
                    );
                  })}
                </ul>
              </div>

              {/* Calendar Section */}
              <div className=" p-4">
                <div className="flex flex-col ">
                  {/* From Date */}
                  <div className="flex flex-col">
                    <div className="mb-2 text-sm font-medium text-gray-700">
                      From
                    </div>
                    <ReactDatePicker
                      selected={startDate ? new Date(startDate) : null}
                      onChange={onSelectStartDate}
                      selectsStart
                      startDate={startDate}
                      endDate={endDate}
                      inline
                      className="!border-none "
                    />
                  </div>

                  {/* To Date */}
                  <div className="flex flex-col">
                    <div className="mb-2 text-sm font-medium text-gray-700">
                      To
                    </div>
                    <ReactDatePicker
                      selected={endDate ? new Date(endDate) : null}
                      onChange={onSelectEndDate}
                      selectsEnd
                      startDate={startDate}
                      endDate={endDate}
                      minDate={startDate}
                      maxDate={new Date()}
                      inline
                      className="!border-none"
                    />
                  </div>
                </div>
              </div>
            </div>

            {/* Footer */}
            <div className="border-t border-gray-200">
              <button
                onClick={onClear}
                className="w-full py-3 text-sm font-medium text-red-600 hover:bg-gray-50 transition-colors"
              >
                Clear Filter
              </button>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default ATMDateRangePicker;
