import { Form, Formik } from "formik";
import React, { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { useNavigate, useParams } from "react-router-dom";
import { boolean, number, object, string } from "yup";
import { useGetResumeByIdQuery } from "../../../../app/DashboardServices/DashboardServices";
import { useResumeUpdateMutation } from "../Services/FormServices";
import EditSignleResumeForm from "./EditSignleResumeForm";
import ATMCircularProgress from "../../../../atoms/ATMCircularProgress/ATMCircularProgress";
import ATMHeading from "../../../../atoms/ATMHeading/ATMHeading";
import {
  calculateExperience,
  getCurrentCompany,
} from "../UploadSingleResume/UploadSingleResumeFormWrapper";

const EditSignleResumeFormWrapper = () => {
  const navigate = useNavigate();
  const { id } = useParams();

  const {
    data: viewData,
    refetch,
    isLoading,
    isFetching,
  } = useGetResumeByIdQuery({ id });
  useEffect(() => {
    refetch();
  }, [refetch]);

  const candidateData = viewData?.data;
  const rec_id = localStorage.getItem("recruiter_id");
  const org = localStorage.getItem("recuriterProfile");
  const org_id = org ? JSON.parse(org) : "";

  const [submit, setSubmit] = useState(false);
  const [resumeBinary, setResumeBinary] = useState(null);
  const [selectedJobs, setSelectedJobs] = useState([]);
  useEffect(() => {
    setSelectedJobs(candidateData?.tagged_jobs || []);
  }, [candidateData]);
  const [careerData, setCareerData] = useState();
  const [trackResumeData, setTrackResumeData] = useState([]);
  const [editResume] = useResumeUpdateMutation();
  const { years, months } = calculateExperience(careerData?.work_experience);
  const currentCompany = getCurrentCompany(careerData?.work_experience);
  const [text, setText] = useState("");
  var initialValues;
  if (!careerData) {
    initialValues = {
      first_name: candidateData?.candidate_form_data?.first_name || "",
      middle_name: candidateData?.candidate_form_data?.middle_name || "",
      last_name: candidateData?.candidate_form_data?.last_name || "",

      candidateEmailFromResume:
        candidateData?.candidate_form_data?.candidateEmailFromResume || "",
      current_company:
        candidateData?.candidate_form_data?.current_company || "",
      contactNumber: candidateData?.candidate_form_data?.contactNumber || "",
      experience:
        parseFloat(
          `${candidateData?.candidate_form_data?.experienceYears}.${candidateData?.candidate_form_data?.experienceMonths}`
        ) || "",
      experienceYears:
        candidateData?.candidate_form_data?.experienceYears || "",
      experienceMonths:
        candidateData?.candidate_form_data?.experienceMonths || "",

      noticePeriod: candidateData?.candidate_form_data?.noticePeriod || "",
      currentSalaryCurrency:
        candidateData?.candidate_form_data?.currentSalaryCurrency || "",
      ctc: candidateData?.candidate_form_data?.ctc || "",
      availableFrom: candidateData?.candidate_form_data?.availableFrom || null,
      immediatelyAvailable:
        candidateData?.candidate_form_data?.immediatelyAvailable || false,
      jobPreference: candidateData?.candidate_form_data?.jobPreference || "",
      asPerStandard: candidateData?.candidate_form_data?.asPerStandard || false,
      salaryCurrency: candidateData?.candidate_form_data?.salaryCurrency || "",
      salaryType: candidateData?.candidate_form_data?.salaryType || "",
      currentSalaryType:
        candidateData?.candidate_form_data?.currentSalaryType || "",

      salaryTo: candidateData?.candidate_form_data?.salaryTo || "",
      salaryFrom: candidateData?.candidate_form_data?.salaryFrom || "",
      information: candidateData?.candidate_form_data?.information || "",
      country: candidateData?.candidate_form_data?.country || null,
      state: candidateData?.candidate_form_data?.state || null,
      city: candidateData?.candidate_form_data?.city || null,
      zipCode: candidateData?.candidate_form_data?.zipCode || "",
      preferredLocations:
        candidateData?.candidate_form_data?.preferredLocations?.map(
          (el) => el
        ) || [{ country: null, state: null, city: null }],
      candidate_linkdin_link:
        candidateData?.candidate_form_data?.candidate_linkdin_link || "",
      source: candidateData?.candidate_form_data?.source || "",
      hiring: candidateData?.candidate_form_data?.hiring || "",
      career_score_json: "",
      resume_link: "",
      recruiter_id: "",
      primarySkills:
        candidateData?.candidate_form_data?.primarySkills?.map((el) => el) ||
        [],
      secondarySkills:
        candidateData?.candidate_form_data?.secondarySkills?.map((el) => el) ||
        [],
      tertiarySkills:
        candidateData?.candidate_form_data?.tertiarySkills?.map((el) => el) ||
        [],
    };
  } else {
    initialValues = {
      first_name: careerData?.first_name || "",
      middle_name: careerData?.middle_name || "",
      last_name: careerData?.last_name || "",
      candidateEmailFromResume: careerData?.email || "",
      current_company: currentCompany || "",
      contactNumber: "",
      experience: parseFloat(`${years}.${months}`) || "",
      experienceYears: years || "",
      experienceMonths: months || "",
      noticePeriod: "",
      currentSalaryCurrency: "",
      currentSalaryType: "",
      ctc: "",
      availableFrom: null,
      immediatelyAvailable: false,
      jobPreference: "",
      asPerStandard: true,
      salaryCurrency: "",
      salaryType: "",
      salaryTo: "",
      salaryFrom: "",
      information: "",
      country:
        {
          label: careerData?.location?.country,
          value: careerData?.location?.country,
        } || null,
      state:
        {
          label: careerData?.location?.state,
          value: careerData?.location?.state,
        } || null,
      city:
        {
          label: careerData?.location?.city,
          value: careerData?.location?.city,
        } || null,
      zipCode: "",
      preferredLocations: [{ country: null, state: null, city: null }],
      candidate_linkdin_link: "",
      source: "",
      hiring: "",
      career_score_json: "",
      resume_link: "",
      recruiter_id: "",
      primarySkills:
        Object.keys(careerData?.skills?.primary || {}).map((el) => {
          return {
            label: el,
            value: el,
          };
        }) || "",
      secondarySkills:
        Object.keys(careerData?.skills?.secondary || {})?.map((el) => {
          return {
            label: el,
            value: el,
          };
        }) || "",
      tertiarySkills:
        Object.keys(careerData?.skills?.tertiary || {})?.map((el) => {
          return {
            label: el,
            value: el,
          };
        }) || "",
    };
  }

  const validationSchema = object().shape({
    asPerStandard: boolean().required("Asperstander is required"),
    candidateEmailFromResume: string().email().required("Email is Required"),
    country: object().required("Country is required"),
    salaryFrom: number().test(
      "conditional-required",
      "Minimum Salary is required",
      function (value) {
        const { asPerStandard } = this.parent;
        // If asPerStandard is false, salaryFrom must be defined and >= 0
        if (!asPerStandard) {
          return value != null && value >= 0;
        }
        return true; // If asPerStandard is true, no requirement on salaryFrom
      }
    ),
    salaryTo: number()
      .test(
        "conditional-required",
        "Maximum Salary is required",
        function (value) {
          const { asPerStandard } = this.parent;
          // If asPerStandard is false, salaryTo must be defined
          if (!asPerStandard) {
            return value != null;
          }
          return true; // If asPerStandard is true, no requirement on salaryTo
        }
      )
      .test(
        "not-equal",
        "Maximum must be greater than Minimum",
        function (value) {
          const { salaryFrom, asPerStandard } = this.parent;
          // If asPerStandard is false, check that salaryTo is greater than salaryFrom
          if (!asPerStandard) {
            return value >= salaryFrom;
          }
          return true; // If asPerStandard is true, no comparison needed
        }
      ),
  });

  const handleSubmit = async (values, { resetForm }) => {
    setSubmit(true);
    const { career_score_json, resume_link, recruiter_id, ...filteredValues } =
      values;
    const formData = new FormData();
    formData.append("candidate_form_data", JSON.stringify(filteredValues));
    formData.append(
      "same_resume",
      trackResumeData?.message === "Resume uploaded successfully" ? false : true
    );
    formData.append("resume_id", candidateData?.id);
    formData.append("form_data_updated_by_recruiter_id", rec_id);

    formData.append("email", values?.candidateEmailFromResume);
    formData.append("org_id", org_id?.company?.id);
    formData.append("form_exist", true);
    formData.append("tagged_jobs", JSON.stringify(selectedJobs));
    formData.append(
      "career_score_json",
      JSON.stringify(trackResumeData?.response?.career_score_data) || ""
    );
    if (trackResumeData?.message === "Resume uploaded successfully") {
      formData.append("raw_resume_text", text);
      formData.append(
        "resume_link",
        trackResumeData?.response?.resume_s3_url?.s3_url || ""
      );
      formData.append(
        "resume_file",
        trackResumeData?.message === "Resume uploaded successfully"
          ? resumeBinary
          : ""
      );
    }

    editResume(formData).then((res) => {
      if (res?.error) {
        toast.error(res?.error?.data?.message);
        setSubmit(false);
      } else {
        if (res?.data?.success) {
          toast.success(res?.data?.message);
          setTrackResumeData([]);

          resetForm();
          setSubmit(false);

          setResumeBinary(null);
          navigate("/recruit/my-resumes");
        } else {
          toast.error(res?.error?.data?.message);
          setSubmit(false);
        }
      }
    });
  };
  return (
    <>
      <div className="px-2">
        <ATMHeading>Edit Resume</ATMHeading>
      </div>
      <div className="flex-grow mt-4 h-full">
        {isLoading || isFetching ? (
          <div className="flex justify-center items-center ">
            <ATMCircularProgress />
          </div>
        ) : (
          <div className="border p-4 mt-2 rounded-md flex flex-col gap-4 mx-4 ">
            <Formik
              initialValues={initialValues}
              onSubmit={handleSubmit}
              validationSchema={validationSchema}
              enableReinitialize
            >
              {(formikProps) => (
                <Form>
                  <EditSignleResumeForm
                    formikProps={formikProps}
                    setTrackResumeData={setTrackResumeData}
                    submit={submit}
                    resumeBinary={resumeBinary}
                    setResumeBinary={setResumeBinary}
                    candidateData={candidateData}
                    selectedJobs={selectedJobs}
                    setSelectedJobs={setSelectedJobs}
                    setCareerData={setCareerData}
                    org_id={org_id}
                    setText={setText}
                  />
                </Form>
              )}
            </Formik>
          </div>
        )}
      </div>
    </>
  );
};

export default EditSignleResumeFormWrapper;
