import axios from "axios";
import { useFormik } from "formik";
import React, { useState } from "react";
import toast, { Toaster } from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import ATMButton from "../../atoms/ATMButton/ATMButton";
import ATMFieldError from "../../atoms/ATMFieldError/ATMFieldError";
import ATMFieldLabel from "../../atoms/ATMFieldLabel/ATMFieldLabel";
import Dailog from "../../components/common/Dailog";
import HeroHeading from "../../components/recruiters/HeroHeading";
import Navbar from "../../components/recruiters/Navbar";
import { forgetPassword } from "../../schemas/schema";
import image from "../../assets/recruiter_reset_password.png";
const baseUrl = process.env.REACT_APP_VITE_BACKEND_API_URL;

function ForgetPassword() {
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);

  const formik = useFormik({
    initialValues: {
      email: "",
    },
    validationSchema: forgetPassword,
    onSubmit: async (values, { resetForm, setSubmitting }) => {
      setSubmitting(true);
      try {
        const response = await axios.get(
          `${baseUrl}/api/recruiter/forgot_password/${values.email}`,
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        );

        if (response.status === 201) {
          toast.success("Email Sent");
          resetForm();
          setSubmitting(false);
        } else if (response.status === 400) {
          toast.error("Something went wrong");
        }
      } catch (error) {
        if (error.response && error.response.status === 400) {
          toast.error(error.response?.data?.message);
        } else {
          toast.error("An unexpected error occurred");
        }
      } finally {
        setSubmitting(false);
      }
    },
  });

  return (
    <>
      <Toaster
        position="top-center"
        reverseOrder={false}
        toastOptions={{
          className: "",
          duration: 10000,
          style: {
            background: "#363636",
            color: "#fff",
          },
          success: {
            duration: 10000,
            theme: {
              primary: "green",
              secondary: "black",
            },
          },
        }}
      />
      <Dailog
        open={open}
        setOpen={setOpen}
        heading="We've sent an activation link to your email address for added security. Once you click the link, you'll be ready to log in and explore all the features available."
        para="Click the link to activate your account and start using our platform right away. It's that simple!"
        btnText="Login"
        btnLink="/recruiter/login"
      />

      <div className="w-full h-screen text-[11px] text-xs font-inter">
        <Navbar />
        <div className="mx-auto max-w-screen-lg ">
          <div className=" p-1 md:rounded-lg bg-white flex flex-col gap-4 w-full ">
            <div>
              <HeroHeading
                heading={"You can reset the password for smooth functionality"}
                para={`Welcome to our streamlined portal, where each login propels you into a world of effortless HR management.`}
              />
            </div>
            <div className="grid grid-cols-5  gap-2">
              <div className=" col-span-2 h-fit ">
                <img
                  src={image}
                  alt="reset-image"
                  className="object-contain md:block hidden w-full h-[260px] lg:h-[240px] rounded-md    "
                />
              </div>

              <div className="col-span-3">
                <form onSubmit={formik.handleSubmit}>
                  <div className="rounded-md border font-inter ">
                    <div className="w-full p-4 flex flex-col items-start gap-2  text-[11px]">
                      <div className="w-full">
                        <ATMFieldLabel required>Email ID</ATMFieldLabel>
                        <div className="h-[50px]">
                          <div className="relative w-full">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              fill="none"
                              viewBox="0 0 24 24"
                              strokeWidth={1.5}
                              stroke="currentColor"
                              className="text-gray-400 pointer-events-none  absolute top-2 left-2"
                              style={{ height: 24, width: 24 }}
                            >
                              <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                d="M21.75 6.75v10.5a2.25 2.25 0 0 1-2.25 2.25h-15a2.25 2.25 0 0 1-2.25-2.25V6.75m19.5 0A2.25 2.25 0 0 0 19.5 4.5h-15a2.25 2.25 0 0 0-2.25 2.25m19.5 0v.243a2.25 2.25 0 0 1-1.07 1.916l-7.5 4.615a2.25 2.25 0 0 1-2.36 0L3.32 8.91a2.25 2.25 0 0 1-1.07-1.916V6.75"
                              />
                            </svg>
                            <input
                              size="lg"
                              placeholder="Oliva@untitledui.com"
                              className="border rounded-md outline-none w-full h-full pl-10 py-3"
                              value={formik.values.email}
                              onChange={formik.handleChange}
                              onBlur={formik.handleBlur}
                              id="email"
                              name="email"
                            />
                          </div>
                          {formik.errors.email && formik.touched.email ? (
                            <ATMFieldError>{formik.errors.email}</ATMFieldError>
                          ) : null}
                        </div>
                      </div>
                      <div>
                        We have sent you the verification email for resetting
                        password
                      </div>
                      <div className="flex w-full justify-end">
                        <div className="w-[120px]">
                          <ATMButton
                            loading={formik.isSubmitting}
                            label="Submit"
                            authicon
                          />
                        </div>
                      </div>
                      <div className="font-inter">
                        <ATMFieldLabel>
                          Want to reach out directly?
                        </ATMFieldLabel>
                        <div className="text-slate-600">
                          We're here to help! Reach out to our dedicated support
                          team for any inquiries, assistance, or to schedule a
                          demo. Your success is our priority.{" "}
                          <span
                            onClick={() => navigate("/recruiter/support")}
                            className="text-[#1CACBD] cursor-pointer flex flex-nowrap"
                          >
                            Click here to Support
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default ForgetPassword;
