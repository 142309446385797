import React, { useEffect, useState } from "react";
import { formatDate } from "../../candidate/ProfileDetails/View/ProfileDetails";
import { Bar, Pie } from "react-chartjs-2";
import ChartDataLabels from "chartjs-plugin-datalabels"; // Import the data labels plugin

import {
  Title,
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  ArcElement,
  Tooltip,
  Legend,
} from "chart.js";
import { useGetResumeByIdQuery } from "../../../app/DashboardServices/DashboardServices";
import { useNavigate, useParams } from "react-router";
import ATMHeading from "../../../atoms/ATMHeading/ATMHeading";
import ATMCircularProgress from "../../../atoms/ATMCircularProgress/ATMCircularProgress";
import { calculateDuration } from "../../candidate/MyProfileView.jsx/MyProfileView";

// Register necessary chart.js components
ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  ArcElement,
  Tooltip,
  Legend,
  Title,
  ChartDataLabels
);
const colorPalette = [
  "#3498db",
  "#2ecc71",
  "#e74c3c",
  "#9b59b6",
  "#f1c40f",
  "#e67e22",
  "#1abc9c",
  "#8e44ad",
  "#34495e",
  "#2c3e50",
];
// const calculateDuration = (start_date, end_date) => {
//   if (!start_date) return 0;
//   const start = start_date === null ? new Date() : new Date(start_date);
//   const end =
//     end_date === null || end_date === "Present"
//       ? new Date()
//       : new Date(end_date);

//   const months =
//     (end.getFullYear() - start.getFullYear()) * 12 +
//     (end.getMonth() - start.getMonth());
//   return months;
// };

const JivahireProfile = () => {
  const [userData, setUserData] = useState([]);
  const { id } = useParams();
  const navigate = useNavigate();
  const handleBack = () => {
    navigate(-1);
  };
  const { data, refetch, isLoading } = useGetResumeByIdQuery({
    id,
  });
  useEffect(() => {
    refetch();
    setUserData(data?.data);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refetch, isLoading]);
  const careerTrajectoryData = {
    labels: userData?.career_score_json?.work_experience?.map(
      (job) =>
        `${new Date(job.start_date).getFullYear()} - ${
          job.end_date === "Present"
            ? "Present"
            : new Date(job.end_date).getFullYear()
        }`
    ),
    datasets: [
      {
        label: "Work Experience (Years and Months)",
        data: userData?.career_score_json?.work_experience?.map((job) =>
          calculateDuration(job.start_date, job.end_date)
        ),
        backgroundColor: "rgba(59, 130, 246, 0.6)",
        barThickness: 20,
      },
    ],
  };

  // Extract skills data
  const skills = userData?.career_score_json?.skills?.primary || {};
  const totalExperience = Object.values(skills).reduce(
    (acc, skill) => acc + skill.years_of_experience,
    0
  );

  const skillsAcquiredData = {
    labels: Object.keys(skills), // Skill names
    datasets: [
      {
        label: "Years of Experience",
        data: Object.values(skills).map(
          (skill) => (skill.years_of_experience / totalExperience) * 100 // Calculate percentage
        ), // Extract scores
        backgroundColor: Object.keys(skills).map(
          (_, index) => colorPalette[index % colorPalette.length] // Assign colors dynamically
        ),
      },
      {
        label: "Years of Experience",
        data: [],
        backgroundColor: ["#fff"],
      },
    ],
  };

  const skillProficiencyData = {
    labels: Object.keys(skills),
    datasets: [
      {
        label: "Years of Experience",
        data: Object.values(skills).map((skill) => skill.years_of_experience),
        backgroundColor: "rgba(255, 99, 132, 0.6)",
      },
    ],
  };
  const allSkills = [
    ...(userData?.candidate_form_data?.primarySkills ?? []),
    ...(userData?.candidate_form_data?.secondarySkills ?? []),
    ...(userData?.candidate_form_data?.tertiarySkills ?? []),
  ];
  return (
    <div className="h-full">
      {isLoading ? (
        <div className="flex text-black h-full justify-center items-center">
          <ATMCircularProgress />
        </div>
      ) : (
        <div className="  bg-white  max-w-screen-2xl    font-inter text-xs text-slate-600">
          <div className="px-2 py-1 border-b flex gap-4    items-center">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={1.5}
              stroke="currentColor"
              className="size-6  cursor-pointer"
              onClick={handleBack}
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M10.5 19.5 3 12m0 0 7.5-7.5M3 12h18"
              />
            </svg>
            <ATMHeading>Profile</ATMHeading>
          </div>
          <div className=" p-2 grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3  gap-4">
            {/* Left Section - Profile and Bio */}
            <div className=" bg-gray-800 text-white rounded-lg p-4">
              <div className="">
                <h2 className="text-lg text-white font-bold capitalize">
                  HEY! I'm {userData?.candidate_form_data?.first_name}, <br />
                  {
                    userData?.career_score_json?.work_experience[0]
                      ?.experience_title
                  }
                </h2>
                <p className="text-xs text-slate-100 break-words mt-3">
                  {" "}
                  {
                    userData?.career_score_json?.work_experience[0]
                      ?.experience_summary
                  }
                </p>
              </div>
            </div>

            <div className="border rounded-lg p-2 text-xs font-medium  ">
              <div className=" overflow-hidden   ">
                <img
                  className=" object-contain  w-full  h-[250px] rounded-lg"
                  src={
                    userData?.profile_pic ||
                    "https://static.vecteezy.com/system/resources/previews/036/594/092/non_2x/man-empty-avatar-photo-placeholder-for-social-networks-resumes-forums-and-dating-sites-male-and-female-no-photo-images-for-unfilled-user-profile-free-vector.jpg"
                  }
                  alt="profile"
                />
              </div>
              <div className="flex justify-between items-center flex-wrap gap-4">
                <div className="font-bold capitalize text-sm  ">
                  {userData?.candidate_form_data?.first_name} {""}
                  {userData?.candidate_form_data?.last_name}
                </div>
                <div className="text-xs font-medium border-l text-end pl-4 ">
                  {userData?.candidate_form_data?.title}
                </div>
              </div>
              <div className=" ">@{userData?.career_score_json?.email}</div>
              <div className="mt-1">
                {userData?.career_score_json?.contact_number}
              </div>
            </div>
            <div className="border rounded-lg p-2 text-xs font-medium  ">
              <h3 className="text-lg font-semibold mb-4">All Skills</h3>
              <ul className="flex flex-wrap gap-2 ">
                {allSkills.map((skill, index) => (
                  <li
                    key={index}
                    className="border rounded-md bg-[#1CACBD] shadow-md p-2  text-white "
                  >
                    {skill.label}
                  </li>
                ))}
              </ul>
            </div>
            {/* Career Trajectory */}
            <div className="bg-white border rounded-lg p-4 ">
              <h3 className="text-lg font-semibold mb-4">Career Trajectory</h3>
              <Bar
                data={careerTrajectoryData}
                options={{
                  responsive: true,
                  plugins: {
                    legend: {
                      display: true,
                      position: "top",
                    },
                    tooltip: {
                      callbacks: {
                        label: (context) => {
                          const job =
                            userData?.career_score_json?.work_experience?.[
                              context.dataIndex
                            ];
                          const years = context.raw;
                          return `${job?.company_name}: ${years} years`;
                        },
                      },
                    },
                  },
                  scales: {
                    y: {
                      beginAtZero: true,
                      title: {
                        display: true,
                        text: "Years of Experience",
                      },
                    },
                    x: {
                      title: {
                        display: true,
                        text: "Companies and Timeline",
                      },
                      ticks: {
                        callback: function (value, index) {
                          // Show the company name and timeline for each label
                          const label = this.getLabelForValue(value);
                          return label;
                        },
                      },
                    },
                  },
                }}
                height={200}
                width={400}
              />
            </div>

            {/* Skills Acquired */}

            <div className="bg-white border rounded-lg p-4 flex flex-col items-center space-y-4">
              <h3 className="text-lg font-semibold">Skills Acquired</h3>

              {/* Chart and Details Section */}
              <div className="flex items-center justify-between w-full h-full">
                {/* Pie Chart */}
                <div className="flex-grow max-w-[60%]">
                  <Pie
                    data={skillsAcquiredData}
                    options={{
                      responsive: true,
                      maintainAspectRatio: false,
                      plugins: {
                        legend: {
                          display: false,
                        },
                        tooltip: {
                          callbacks: {
                            label: () => {
                              return ``;
                            },
                          },
                        },
                        datalabels: {
                          color: "#fff",
                          font: {
                            weight: "normal",
                            size: 11,
                          },
                          formatter: (value) => {
                            const percentage = value; // Calculate percentage
                            return `${percentage.toFixed(0)}%`; // Display percentage
                          },
                        },
                      },
                    }}
                  />
                </div>

                {/* Skill Details */}
                <ul className="space-y-2 text-xs text-right ml-4 flex-shrink-0">
                  {skillsAcquiredData.labels.map((label, index) => (
                    <li key={index} className="flex items-center">
                      <span
                        className="inline-block w-3 h-3 rounded-full mr-2"
                        style={{
                          backgroundColor:
                            skillsAcquiredData.datasets[0].backgroundColor[
                              index
                            ],
                        }}
                      ></span>
                      <span className="font-bold">{label}:</span>{" "}
                      {skillsAcquiredData.datasets[0].data[index].toFixed(0)}%
                    </li>
                  ))}
                </ul>
              </div>
            </div>

            {/* Skill Proficiency */}
            <div className="bg-white border rounded-lg p-4 ">
              <h3 className="text-lg font-semibold mb-4">Skill Proficiency</h3>
              <Bar
                data={skillProficiencyData}
                options={{ responsive: true }}
                height={200}
                width={400}
              />
            </div>
            {userData?.candidatehobbies_candidate?.length > 0 && (
              <div className="border rounded-lg">
                <div className="bg-blue-100  rounded-t-lg px-4 py-2 font-semibold">
                  Interest and Hobbies
                </div>
                <div className="flex flex-wrap gap-4 p-2">
                  {userData?.candidatehobbies_candidate?.map((el) => {
                    return (
                      <div className="border rounded-xl px-4 py-1 text-center">
                        {el?.hobbie?.hobbie_category}
                      </div>
                    );
                  })}
                </div>
              </div>
            )}
            {/* <div className="border rounded-lg">
              <div className="bg-blue-100  rounded-t-lg px-4 font-semibold py-2">
                About Interest
              </div>
              <div className=" p-2"></div>
            </div> */}
            {userData?.languages && (
              <div className="border rounded-lg">
                <div className="bg-blue-100  rounded-t-lg px-4 font-semibold py-2">
                  languages Profeciency
                </div>
                <div className="flex flex-wrap gap-4  p-2">
                  {userData?.languages
                    ? JSON.parse(userData?.languages)?.map((el) => {
                        return (
                          <div className="border rounded-xl px-4 py-1 text-center">
                            {el}
                          </div>
                        );
                      })
                    : ""}
                </div>
              </div>
            )}

            {userData?.education_candidate?.length > 0 && (
              <div className="font-bold text-xl md:col-span-2 lg:col-span-3">
                Qualification
              </div>
            )}
            {userData?.education_candidate?.map((el) => {
              return (
                <div key={el?.id} className="border rounded-lg">
                  <div className="bg-gray-800 text-white py-5 rounded-t-lg px-4 font-semibold">
                    <span>{el?.institution_name}</span> <br />
                    <span>{el?.education}</span> <br />
                    <span>
                      ({formatDate(el?.duration_start)} To{" "}
                      {formatDate(el?.duration_end)})
                    </span>
                    <span></span>
                  </div>
                  <div className=" gap-4  p-2">
                    <span>-{el?.specialization}</span> <br />
                    <span>
                      {el?.skills
                        ? JSON.parse(el?.skills)?.map((el) => {
                            return <div className="">-{el}</div>;
                          })
                        : ""}
                    </span>
                  </div>
                </div>
              );
            })}

            {userData?.career_score_json?.work_experience?.length > 0 && (
              <div className="font-bold text-xl md:col-span-2 lg:col-span-3">
                Work Experience
              </div>
            )}
            {userData?.career_score_json?.work_experience?.map((el, ind) => {
              return (
                <div key={ind} className="border rounded-lg">
                  <div className="border-b  py-2 rounded-t-lg px-4">
                    <span className=" font-semibold">
                      {el?.experience_title}
                    </span>{" "}
                    <br />
                    <span>{el?.company_name}</span> <br />
                    <span>{el?.experience}</span> <br />
                    <span>
                      ({formatDate(el?.start_date)} To{" "}
                      {el?.end_date === "Present"
                        ? formatDate(new Date())
                        : formatDate(el?.end_date)}
                      )
                    </span>
                    <span></span>
                  </div>
                  <div className=" gap-4  p-2">
                    <span className="break-words">
                      {el?.experience_summary}
                    </span>{" "}
                    <br />
                    <span className="flex flex-wrap gap-2 mt-2">
                      {el?.skills
                        ? el?.skills?.map((el, ind) => {
                            return (
                              <div
                                key={ind}
                                className="border rounded-xl px-2 py-2 w-fit"
                              >
                                {el}
                              </div>
                            );
                          })
                        : ""}
                    </span>
                  </div>
                </div>
              );
            })}
          </div>

          <div className="p-5">
            <span>
              Your comprehensive profile is a crucial part of our Application
              Tracking System, empowering our analysis for better matches. Your
              information is securely stored in our database, ensuring future
              opportunities align with your skills and preferences. Rest
              assured, we'll leverage this data to connect you with relevant
              positions.
            </span>
            <span></span>
          </div>
        </div>
      )}
    </div>
  );
};

export default JivahireProfile;
