import React from "react";

const ATMFieldLabel = ({
  children,
  hidden = false,
  required = false,
  className = "",
}) => {
  return (
    <div
      className={`text-[11px] font-semibold text-slate-700 font-inter ${
        hidden && "hidden"
      } ${className}`}
    >
      {children} {required && <span className="text-red-500 text-xs">*</span>}
    </div>
  );
};

export default ATMFieldLabel;
