import axios from "axios";
import { useFormik } from "formik";
import Cookies from "js-cookie";
import React, { useState } from "react";
import toast from "react-hot-toast";
import { IoMdEye, IoMdEyeOff } from "react-icons/io";
import { useNavigate } from "react-router";
import ATMButton from "../../atoms/ATMButton/ATMButton";
import ATMFieldError from "../../atoms/ATMFieldError/ATMFieldError";
import ATMFieldLabel from "../../atoms/ATMFieldLabel/ATMFieldLabel";
import HeroHeading from "../../components/recruiters/HeroHeading";
import { loginSchema } from "../../schemas/schema";
import loginImage from "../../assets/recruiter_login.png";
const baseUrl = process.env.REACT_APP_VITE_BACKEND_API_URL;

function CandidateLogin() {
  const [showPassword, setShowPassword] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();

  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
      rememberMe: false,
    },
    validationSchema: loginSchema,
    onSubmit: async (values, { resetForm }) => {
      setIsLoading(true);
      try {
        const response = await axios.post(
          `${baseUrl}/api/candidate/login/`,
          {
            email: values.email,
            password: values.password,
          },
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        );

        if (response.status === 200) {
          resetForm();
          localStorage.setItem(
            "candidateaccessToken",
            response.data.data.access
          );
          localStorage.setItem(
            "candidaterefreshToken",
            response.data.data.refresh
          );
          localStorage.setItem("candidate_user", response.data.data.user_type);
          localStorage.setItem(
            "candidate_profile",
            JSON.stringify(response.data.data.user)
          );
          Cookies.set("candidateaccessToken", response.data.data.access, {
            secure: true,
            sameSite: "Strict",
          });
          Cookies.set("candidate_user", response.data.data.user_type, {
            secure: true,
            sameSite: "Strict",
          });
          navigate("/candidate/home");
        } else if (response.status === 400) {
          toast.error("Failed to add member");
        }
      } catch (error) {
        if (error.response && error.response.status === 400) {
          toast.error(error.response.data.message);
        } else {
          toast.error("An unexpected error occurred");
        }
      } finally {
        setIsLoading(false);
      }
    },
  });

  const handleShowPassword = () => {
    setShowPassword(!showPassword);
  };

  return (
    <>
      <div className="p-4 text-[11px] font-inter bg-white  ">
        <div className=" select-none gap-4 flex flex-col ">
          <HeroHeading
            heading="Login to our portal as candidate which is one Step Closer to Efficiency"
            para="Welcome to our streamlined portal, where each login propels you into a world of effortless HR management."
          />
          <div className="grid grid-cols-1 md:grid-cols-2 items-start mt-2 select-none gap-2 h-fit">
            <div className="">
              <img
                src={loginImage}
                alt="Recruiter Login"
                className="object-contain w-full h-full  md:block hidden md:h-[315px] "
              />
            </div>

            <div>
              <form onSubmit={formik.handleSubmit}>
                <div className="border border-[#E2DCEA] flex flex-col gap-4 p-5 rounded-lg w-full">
                  {/* Your form fields go here... */}
                  <div className="w-full">
                    <ATMFieldLabel required>Email ID</ATMFieldLabel>
                    <div className="relative">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        strokeWidth={1.5}
                        stroke="currentColor"
                        className="text-gray-400 pointer-events-none  absolute top-2 left-2"
                        style={{ height: 24, width: 24 }}
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          d="M21.75 6.75v10.5a2.25 2.25 0 0 1-2.25 2.25h-15a2.25 2.25 0 0 1-2.25-2.25V6.75m19.5 0A2.25 2.25 0 0 0 19.5 4.5h-15a2.25 2.25 0 0 0-2.25 2.25m19.5 0v.243a2.25 2.25 0 0 1-1.07 1.916l-7.5 4.615a2.25 2.25 0 0 1-2.36 0L3.32 8.91a2.25 2.25 0 0 1-1.07-1.916V6.75"
                        />
                      </svg>
                      <input
                        size="lg"
                        placeholder="olivia@untitledui.com"
                        className="pl-10 w-full border outline-none h-[38px] px-2 rounded-md"
                        value={formik.values.email}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        id="email"
                        name="email"
                      />
                    </div>
                    {formik.errors.email && formik.touched.email ? (
                      <ATMFieldError>{formik.errors.email}</ATMFieldError>
                    ) : null}
                  </div>
                  <div className="w-full">
                    <ATMFieldLabel required>Your Password</ATMFieldLabel>
                    <div className="relative">
                      <input
                        size="lg"
                        type={showPassword ? "text" : "password"}
                        placeholder="Enter your password"
                        className="!border-t-blue-gray-200 border outline-none pr-3 w-full  h-[38px] px-2 rounded-md"
                        value={formik.values.password}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        id="password"
                        name="password"
                        autoComplete="current-password"
                      />
                      <div
                        onClick={handleShowPassword}
                        className="absolute top-1/2 transform -translate-y-1/2 text-2xl right-3 cursor-pointer"
                      >
                        {showPassword ? (
                          <IoMdEye size={18} className="text-slate-800" />
                        ) : (
                          <IoMdEyeOff size={18} className="text-slate-800" />
                        )}
                      </div>
                    </div>
                    {formik.errors.password && formik.touched.password ? (
                      <ATMFieldError>{formik.errors.password}</ATMFieldError>
                    ) : null}
                  </div>
                  <div className="w-full flex justify-between items-center">
                    <div className="w-fit flex justify-between items-center gap-1 font-medium">
                      <input
                        type="checkbox"
                        checked={formik.values.rememberMe}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        name="rememberMe"
                      />{" "}
                      Remember Me
                    </div>

                    <span
                      onClick={() => navigate("/candidate/forget-password")}
                      className="text-[#1CACBD] cursor-pointer"
                    >
                      {" "}
                      Forget your password?
                    </span>
                  </div>
                  <div className="flex w-full justify-end">
                    <div className="w-[100px]">
                      <ATMButton loading={isLoading} label="Login" authicon />
                    </div>
                  </div>
                </div>
              </form>

              <p className="text-slate-600 my-2">
                If you don’t have an account yet as a candidate
                <span
                  onClick={() => navigate("/candidate/register")}
                  className="text-[#1CACBD] cursor-pointer"
                >
                  {" "}
                  Please Click Here To Register
                </span>
              </p>

              <div className="font-inter">
                <ATMFieldLabel>Want to reach out directly?</ATMFieldLabel>
                <div className="text-slate-600">
                  We're here to help!{" "}
                  <span
                    onClick={() => navigate("/candidate/support")}
                    className="text-[#1CACBD] cursor-pointer"
                  >
                    Contact our support
                  </span>{" "}
                  team with any questions.
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default CandidateLogin;
