import React from "react";
import { Toaster } from "react-hot-toast";
import NavForEditProfile from "../../components/candidate/Navbars/NavForEditProfile";

const item = [
  {
    id: 0,
    name: "MAIN MENU",
    heading: true,
    svg: null,
    link: "",
  },
  {
    id: 1,
    name: "Dashboard",
    svg: (
      <svg
        fill="none"
        viewBox="0 0 18 24"
        strokeWidth={2}
        stroke="currentColor"
        className="size-4"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M1 9.5C0.723858 9.5 0.5 9.27614 0.5 9V1C0.5 0.723858 0.723858 0.5 1 0.5H7C7.27614 0.5 7.5 0.723858 7.5 1V9C7.5 9.27614 7.27614 9.5 7 9.5H1ZM1 17.5C0.723858 17.5 0.5 17.2761 0.5 17V13C0.5 12.7239 0.723858 12.5 1 12.5H7C7.27614 12.5 7.5 12.7239 7.5 13V17C7.5 17.2761 7.27614 17.5 7 17.5H1ZM11 17.5C10.7239 17.5 10.5 17.2761 10.5 17V9C10.5 8.72386 10.7239 8.5 11 8.5H17C17.2761 8.5 17.5 8.72386 17.5 9V17C17.5 17.2761 17.2761 17.5 17 17.5H11ZM10.5 1C10.5 0.723858 10.7239 0.5 11 0.5H17C17.2761 0.5 17.5 0.723858 17.5 1V5C17.5 5.27614 17.2761 5.5 17 5.5H11C10.7239 5.5 10.5 5.27614 10.5 5V1Z"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    ),
    link: "/recruit/dashboard",
  },
  {
    id: 2,
    name: "Create Job",
    svg: (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="2 2 18 22"
        strokeWidth={2}
        stroke="currentColor"
        className="size-4"
      >
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M12 9v6m3-3H9m12 0a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"
        />
      </svg>
    ),
    link: "/recruit/create-job",
  },

  {
    id: 3,
    name: "Job Status",
    svg: (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 2 22 22"
        strokeWidth={2}
        stroke="currentColor"
        className="size-4"
      >
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M12 6v6h4.5m4.5 0a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"
        />
      </svg>
    ),
    link: "/recruit/job-status/operations",
  },
  {
    id: 4,
    name: "Talent Search",
    svg: (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 2 24 24"
        strokeWidth={2}
        stroke="currentColor"
        className="size-4"
      >
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M18 18.72a9.094 9.094 0 0 0 3.741-.479 3 3 0 0 0-4.682-2.72m.94 3.198.001.031c0 .225-.012.447-.037.666A11.944 11.944 0 0 1 12 21c-2.17 0-4.207-.576-5.963-1.584A6.062 6.062 0 0 1 6 18.719m12 0a5.971 5.971 0 0 0-.941-3.197m0 0A5.995 5.995 0 0 0 12 12.75a5.995 5.995 0 0 0-5.058 2.772m0 0a3 3 0 0 0-4.681 2.72 8.986 8.986 0 0 0 3.74.477m.94-3.197a5.971 5.971 0 0 0-.94 3.197M15 6.75a3 3 0 1 1-6 0 3 3 0 0 1 6 0Zm6 3a2.25 2.25 0 1 1-4.5 0 2.25 2.25 0 0 1 4.5 0Zm-13.5 0a2.25 2.25 0 1 1-4.5 0 2.25 2.25 0 0 1 4.5 0Z"
        />
      </svg>
    ),
    link: "/recruit/talentsearch",
  },
  {
    id: 5,
    name: "Resume Upload",
    svg: (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 2 24 24"
        strokeWidth={2}
        stroke="currentColor"
        className="size-4"
      >
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M9 8.25H7.5a2.25 2.25 0 0 0-2.25 2.25v9a2.25 2.25 0 0 0 2.25 2.25h9a2.25 2.25 0 0 0 2.25-2.25v-9a2.25 2.25 0 0 0-2.25-2.25H15m0-3-3-3m0 0-3 3m3-3V15"
        />
      </svg>
    ),
    link: "/recruit/resume-upload",
  },
  {
    id: 6,
    name: "My Resumes",
    svg: (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 2 24 24"
        strokeWidth={1.5}
        stroke="currentColor"
        className="size-4"
      >
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M19.5 14.25v-2.625a3.375 3.375 0 0 0-3.375-3.375h-1.5A1.125 1.125 0 0 1 13.5 7.125v-1.5a3.375 3.375 0 0 0-3.375-3.375H8.25m5.231 13.481L15 17.25m-4.5-15H5.625c-.621 0-1.125.504-1.125 1.125v16.5c0 .621.504 1.125 1.125 1.125h12.75c.621 0 1.125-.504 1.125-1.125V11.25a9 9 0 0 0-9-9Zm3.75 11.625a2.625 2.625 0 1 1-5.25 0 2.625 2.625 0 0 1 5.25 0Z"
        />
      </svg>
    ),
    link: "/recruit/my-resumes",
  },

  {
    id: 7,
    name: "Report",
    svg: (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        strokeWidth={2}
        stroke="currentColor"
        className="size-4"
      >
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M3 3v1.5M3 21v-6m0 0 2.77-.693a9 9 0 0 1 6.208.682l.108.054a9 9 0 0 0 6.086.71l3.114-.732a48.524 48.524 0 0 1-.005-10.499l-3.11.732a9 9 0 0 1-6.085-.711l-.108-.054a9 9 0 0 0-6.208-.682L3 4.5M3 15V4.5"
        />
      </svg>
    ),
    link: "/recruit/report",
  },
  {
    id: 8,
    name: "ORG Recruiters",
    svg: (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 2 24 24"
        strokeWidth={2}
        stroke="currentColor"
        className="size-4"
      >
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M18 18.72a9.094 9.094 0 0 0 3.741-.479 3 3 0 0 0-4.682-2.72m.94 3.198.001.031c0 .225-.012.447-.037.666A11.944 11.944 0 0 1 12 21c-2.17 0-4.207-.576-5.963-1.584A6.062 6.062 0 0 1 6 18.719m12 0a5.971 5.971 0 0 0-.941-3.197m0 0A5.995 5.995 0 0 0 12 12.75a5.995 5.995 0 0 0-5.058 2.772m0 0a3 3 0 0 0-4.681 2.72 8.986 8.986 0 0 0 3.74.477m.94-3.197a5.971 5.971 0 0 0-.94 3.197M15 6.75a3 3 0 1 1-6 0 3 3 0 0 1 6 0Zm6 3a2.25 2.25 0 1 1-4.5 0 2.25 2.25 0 0 1 4.5 0Zm-13.5 0a2.25 2.25 0 1 1-4.5 0 2.25 2.25 0 0 1 4.5 0Z"
        />
      </svg>
    ),
    link: "/recruit/recruiters",
  },
  {
    id: 9,
    name: "OTHER",
    heading: true,
    svg: null,
    link: "",
  },
  {
    id: 10,
    name: "My Profile",
    svg: (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 2 24 24"
        strokeWidth={2}
        stroke="currentColor"
        className="size-4"
      >
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M15.75 6a3.75 3.75 0 1 1-7.5 0 3.75 3.75 0 0 1 7.5 0ZM4.501 20.118a7.5 7.5 0 0 1 14.998 0A17.933 17.933 0 0 1 12 21.75c-2.676 0-5.216-.584-7.499-1.632Z"
        />
      </svg>
    ),
    link: "/recruit/my-profile-view",
  },
  {
    id: 11,
    name: "Support",
    svg: (
      <svg
        fill="none"
        viewBox="0 0 18 22"
        strokeWidth={2}
        stroke="currentColor"
        className="size-4"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M4.31375 4.81375L8.02375 8.52375M12.9762 13.4762L16.6862 17.1862M16.6862 4.81375L12.9762 8.52375L16.065 5.435M4.31375 17.1862L8.02375 13.4762M19.25 11C19.25 15.8325 15.3325 19.75 10.5 19.75C5.66751 19.75 1.75 15.8325 1.75 11C1.75 6.16751 5.66751 2.25 10.5 2.25C15.3325 2.25 19.25 6.16751 19.25 11ZM14 11C14 12.933 12.433 14.5 10.5 14.5C8.567 14.5 7 12.933 7 11C7 9.067 8.567 7.5 10.5 7.5C12.433 7.5 14 9.067 14 11Z"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    ),
    link: "/recruit/recruiter/support",
  },
  {
    id: 12,
    name: "Logout",
    svg: (
      <svg
        fill="none"
        viewBox="0 0 20 24"
        strokeWidth={2}
        stroke="currentColor"
        className="size-4"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M7 19H3C2.46957 19 1.96086 18.7893 1.58579 18.4142C1.21071 18.0391 1 17.5304 1 17V3C1 2.46957 1.21071 1.96086 1.58579 1.58579C1.96086 1.21071 2.46957 1 3 1H7M14 15L19 10M19 10L14 5M19 10H7"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    ),
    link: "/recruit/logout",
  },
];
function Dashboard() {
  const user = localStorage.getItem("recuriterProfile");
  const isSuperAdmin = user ? JSON.parse(user) : "";
  const filteredItems = item.filter((menuItem) => {
    if (!isSuperAdmin?.IS_SUPERUSER) {
      // Hide super admin-specific items
      return menuItem.id !== 7 &&  menuItem.id !== 8; // Example: If 'id: 4' is a super admin-only item
    }
    return true;
  });
  return (
    <>
      <Toaster
        position="top-center"
        reverseOrder={false}
        toastOptions={{
          className: "",
          duration: 10000,
          style: {
            background: "#363636",
            color: "#fff",
          },
          success: {
            duration: 10000,
            theme: {
              primary: "green",
              secondary: "black",
            },
          },
        }}
      />
      <div className="">
        <NavForEditProfile item={filteredItems} />
      </div>
    </>
  );
}

export default Dashboard;
