import React, { useRef, useState, useEffect } from "react";
import Select from "react-select";
import makeAnimated from "react-select/animated";
import ATMFieldError from "../ATMFieldError/ATMFieldError";
import { ErrorMessage } from "formik";
import ATMFieldLabel from "../ATMFieldLabel/ATMFieldLabel";

const animatedComponents = makeAnimated();

const ATMMultiSelect = ({
  name,
  value,
  onChange,
  options,
  label,
  placeholder = "Select...",
  className = "",
  showSelected = false,
  isSkills = false,
  required = false,
  keysToSelectoption = ["Enter", " ", ","],
}) => {
  const selectRef = useRef(null);
  const containerRef = useRef(null);
  const customStyles = {
    control: (provided) => ({
      ...provided,
      border: "none",
      outlineColor: "white",
      boxShadow: "none",
      backgroundColor: "transparent",
    }),
    menuPortal: (provided) => ({
      ...provided,
      zIndex: 10000,
    }),
    multiValue: (provided) => ({
      ...provided,
      backgroundColor: isSkills ? "#FFEDD5" : "#e0e0e0", // Light orange for skills or grey for non-skills
      borderRadius: "0.375rem", // Optional: adjust border radius
    }),
    multiValueLabel: (provided) => ({
      ...provided,
      color: isSkills ? "#333" : "#000", // Dark text for better contrast (light orange or grey background)
      padding: "0.25rem 0.5rem",
    }),
    multiValueRemove: (provided) => ({
      ...provided,
      color: "#e53e3e", // Red color for the "remove" button
      ":hover": {
        borderRadius: "0 0.375rem 0.375rem 0", // Optional: adjust border radius
        backgroundColor: "rgba(240, 0, 0, 0.3)", // Red transparent background on hover
        color: "rgb(255 ,0,0)", // Keep red on hover
      },
    }),
  };
  const [inputValue, setInputValue] = useState("");
  const [currentOptions, setCurrentOptions] = useState(options);
  const [menuIsOpen, setMenuIsOpen] = useState(false);

  // Handle input change and add new option
  const handleInputChange = (newValue) => {
    setInputValue(newValue);
    return newValue;
  };

  const handleKeyDown = (event) => {
    const keysToSelect = keysToSelectoption;

    if (keysToSelect.includes(event.key) && inputValue.trim() !== "") {
      event.preventDefault();

      const existingOption = currentOptions.find(
        (option) => option.label.toLowerCase() === inputValue.toLowerCase()
      );

      if (!existingOption) {
        const newOption = {
          value: `${Date.now()}-${Math.floor(Math.random() * 1000)}`,
          label: inputValue,
        };

        const updatedOptions = [...currentOptions, newOption];
        setCurrentOptions(updatedOptions);

        // Select the new option
        const updatedValue = [...(value || []), newOption];
        onChange(updatedValue, { action: "select-option" });
        setInputValue("");
      } else {
        const updatedValue = [...(value || []), existingOption];
        onChange(updatedValue, { action: "select-option" });
        setInputValue("");
      }
      setMenuIsOpen(false);
    }
  };

  const handleRemoveOption = (optionToRemove) => {
    const updatedValue = value.filter(
      (option) => option.value !== optionToRemove.value
    );
    onChange(updatedValue, { action: "remove-option" });
  };

  const handleFocus = () => {
    setMenuIsOpen(true); // Open the dropdown when focused
  };

  // Close the menu when clicking outside the component
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        containerRef.current &&
        !containerRef.current.contains(event.target)
      ) {
        setMenuIsOpen(false); // Close the menu if the click is outside the container
      }
    };

    // Attach the event listener to the document
    document.addEventListener("mousedown", handleClickOutside);

    // Cleanup the event listener on component unmount
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <>
      {label && <ATMFieldLabel required={required}>{label}</ATMFieldLabel>}
      <div
        ref={containerRef} // Attach the containerRef to the wrapping div
        onClick={() => {
          selectRef.current.focus();
        }}
        className={`relative ${className}`}
      >
        <div className={`relative rounded-md border min-w-[200px]`}>
          <Select
            name={name}
            value={showSelected ? null : value}
            onChange={(newValue, actionMeta) => {
              onChange(newValue, actionMeta);
              selectRef.current.focus();
            }}
            options={currentOptions}
            isClearable
            closeMenuOnSelect
            placeholder={placeholder}
            onInputChange={handleInputChange}
            inputValue={inputValue}
            onKeyDown={handleKeyDown}
            ref={selectRef}
            styles={customStyles}
            isMulti
            components={animatedComponents}
            menuIsOpen={menuIsOpen}
            onFocus={handleFocus}
            className=""
          />
        </div>
        {showSelected && value.length > 0 && (
          <div className="mt-2">
            <h4 className="font-semibold">Selected Options:</h4>
            <ul className="mt-2 flex flex-wrap gap-4">
              {value.map((selectedOption) => (
                <li key={selectedOption.value} className="flex w-fit relative">
                  <p className="border px-3 py-1 rounded-md">
                    {selectedOption.label}
                  </p>
                  <button
                    onClick={() => handleRemoveOption(selectedOption)}
                    className="absolute top-0 right-0 bg-white font-bold"
                    style={{ transform: "translate(20%, -40%)" }}
                  >
                    X
                  </button>
                </li>
              ))}
            </ul>
          </div>
        )}
        {name && (
          <ErrorMessage name={name}>
            {(errorMessage) => <ATMFieldError>{errorMessage}</ATMFieldError>}
          </ErrorMessage>
        )}
      </div>
    </>
  );
};

export default ATMMultiSelect;
