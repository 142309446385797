import React from "react";
// import { currencyType } from "../../src/pages/recruiters/CreateJob";
import ATMFieldLabel from "../ATMFieldLabel/ATMFieldLabel";
import { ErrorMessage } from "formik";
import ATMFieldError from "../ATMFieldError/ATMFieldError.jsx";
import { currencyType } from "../../pages/recruiters/CreateJob.jsx";
const salarytype = [
  { value: "Hourly", label: "Hourly" },
  { value: "Daily", label: "Daily" },
  { value: "Weekly", label: "Weekly" },
  { value: "Bi-weekly ", label: "Bi-weekly " },
  { value: "Monthly", label: "Monthly" },
  { value: "Yearly", label: "Yearly" },
];

const ATMCurrencyAmount = ({
  name,
  currencyname,
  label,
  value,
  onCurrencyChange,
  onSalaryTypeChange,
  onChange,
  currencyValue,
  SalaryTypeValue,
  placeholder,
  currencyhide = true,
  inputhide = true,
  salarytypehide = false,
  disabled = false,
  required = false,
}) => {
  const handleAmountChange = (e) => {
    const newValue = e.target.value;
    const regex = /^\d*\.?\d*$/;
    if (regex.test(newValue) || newValue === "") {
      onChange(e);
    }
  };

  const handleCurrencyChange = (e) => {
    const newValue = e.target.value;
    onCurrencyChange(newValue);
  };
  const handleSalaryTypeChange = (e) => {
    const newValue = e.target.value;
    onSalaryTypeChange(newValue);
  };

  const handleKeyPress = (e) => {
    const char = String.fromCharCode(e.which);
    const regex = /^[0-9]$/;

    // Prevent non-numeric characters
    if (!regex.test(char) && e.which !== 8) {
      e.preventDefault();
    }
  };

  return (
    <div className="grid grid-cols-1 w-full">
      {label ? (
        <ATMFieldLabel required={required}>{label}</ATMFieldLabel>
      ) : (
        <div className="text-white">.</div>
      )}

      <div className="flex items-center gap-1 w-full">
        {salarytypehide && (
          <div className="">
            <select
              onChange={handleSalaryTypeChange}
              className={`h-[34px]  rounded-md outline-none border w-22 px-1 `}
              value={SalaryTypeValue}
              disabled={disabled}
            >
              <option value="" disabled className="">
                Salary Type
              </option>
              {salarytype.map((currency) => (
                <option key={currency.value} value={currency.value}>
                  {currency.label}
                </option>
              ))}
            </select>
          </div>
        )}
        {(currencyhide || inputhide) && (
          <div className="border w-full flex items-center overflow-hidden  rounded-md">
            {currencyhide && (
              <div className="w-full">
                <select
                  onChange={handleCurrencyChange}
                  className={`h-[32px] outline-none min-w-20 w-full px-2 ${
                    inputhide && " border-r"
                  } `}
                  value={currencyValue}
                  disabled={disabled}
                >
                  <option value="" disabled className="">
                    Currency
                  </option>
                  {currencyType.map((currency) => (
                    <option key={currency.value} value={currency.value}>
                      {currency.label}
                    </option>
                  ))}
                </select>

                <div>
                  {currencyname && (
                    <ErrorMessage name={currencyname}>
                      {(errorMessage) => (
                        <div className="">
                          <ATMFieldError> {errorMessage} </ATMFieldError>
                        </div>
                      )}
                    </ErrorMessage>
                  )}
                </div>
              </div>
            )}
            {inputhide && (
              <div className="w-full">
                <input
                  value={value}
                  onChange={handleAmountChange}
                  onKeyPress={handleKeyPress}
                  placeholder={placeholder}
                  className="h-[32px]  px-2 border-0 outline-none min-w-full"
                  disabled={disabled}
                />

                {name && (
                  <ErrorMessage name={name}>
                    {(errorMessage) => (
                      <div className="">
                        <ATMFieldError> {errorMessage} </ATMFieldError>
                      </div>
                    )}
                  </ErrorMessage>
                )}
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default ATMCurrencyAmount;
