import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { formatDate } from "../ProfileDetails/View/ProfileDetails";
import { useCandidateProfileViewQuery } from "../../../app/DashboardServices/DashboardServices";
import { useNavigate, useParams } from "react-router";
import ATMCircularProgress from "../../../atoms/ATMCircularProgress/ATMCircularProgress";
import { Bar, Pie } from "react-chartjs-2";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  ArcElement,
  Tooltip,
  Legend,
} from "chart.js";
import ATMHeading from "../../../atoms/ATMHeading/ATMHeading";
import ReactPlayer from "react-player";

// Register necessary chart.js components
ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  ArcElement,
  Tooltip,
  Legend
);

const colorPalette = [
  "#3498db",
  "#2ecc71",
  "#e74c3c",
  "#9b59b6",
  "#f1c40f",
  "#e67e22",
  "#1abc9c",
  "#8e44ad",
  "#34495e",
  "#2c3e50",
];

export const calculateExperience = (start_date, end_date) => {
  const start = new Date(start_date);
  const end = new Date(end_date);
  const years = end.getFullYear() - start.getFullYear();
  const months = end.getMonth() - start.getMonth();
  return years + months / 12; // Calculate experience in years
};

export const calculateDuration = (start_date, end_date) => {
  const start = new Date(start_date);
  const end = end_date === "Present" ? new Date() : new Date(end_date);

  const totalMonths =
    (end.getFullYear() - start.getFullYear()) * 12 +
    (end.getMonth() - start.getMonth());

  const yearsWithDecimal = (totalMonths / 12).toFixed(1); // Convert months to years with 1 decimal
  return parseFloat(yearsWithDecimal); // Return as a number for chart compatibility
};

const MyProfileView = () => {
  const navigate = useNavigate();
  const handleBack = () => {
    navigate(-1);
  };
  const [userData, setUserData] = useState();
  const { id } = useParams();
  const [currentIndex, setCurrentIndex] = useState(0);

  const [currentEducationPage, setCurrentEducationPage] = useState(0);
  const [currentWorkPage, setCurrentWorkPage] = useState(0);

  const itemsPerPage = 3;

  // Education pagination handlers
  const handleNextEducationPage = () => {
    if (
      (currentEducationPage + 1) * itemsPerPage <
      userData?.education_candidate?.length
    ) {
      setCurrentEducationPage(currentEducationPage + 1);
    }
  };

  const handlePrevEducationPage = () => {
    if (currentEducationPage > 0) {
      setCurrentEducationPage(currentEducationPage - 1);
    }
  };

  // Work Experience pagination handlers
  const handleNextWorkPage = () => {
    if (
      (currentWorkPage + 1) * itemsPerPage <
      userData?.workexperience_candidate?.length
    ) {
      setCurrentWorkPage(currentWorkPage + 1);
    }
  };

  const handlePrevWorkPage = () => {
    if (currentWorkPage > 0) {
      setCurrentWorkPage(currentWorkPage - 1);
    }
  };

  // Calculate the start and end index for slicing the data
  const startEducationIndex = currentEducationPage * itemsPerPage;
  const endEducationIndex = startEducationIndex + itemsPerPage;

  const startWorkIndex = currentWorkPage * itemsPerPage;
  const endWorkIndex = startWorkIndex + itemsPerPage;

  // Call the query hook here
  const { data, isLoading } = useCandidateProfileViewQuery(
    { pk: id },
    { skip: !id }
  );
  const userDataFromStore = useSelector(
    (state) => state.candidateData.candidateData
  );
  useEffect(() => {
    if (id && data) {
      setUserData(data?.data?.["candidate"]);
    } else {
      setUserData(userDataFromStore);
    }
  }, [id, data, userDataFromStore]);

  // Data for Career Trajectory
  const careerTrajectoryData = {
    labels: userData?.workexperience_candidate?.map(
      (job) =>
        `${new Date(job?.company_start_duration).getFullYear()} - ${
          job.end_date === "Present"
            ? "Present"
            : new Date(job?.company_end_duration).getFullYear()
        }`
    ),
    datasets: [
      {
        label: "Work Experience (Years and Months)",
        data: userData?.workexperience_candidate?.map((job) =>
          calculateDuration(
            job?.company_start_duration,
            job?.company_end_duration
          )
        ),
        backgroundColor: "rgba(59, 130, 246, 0.6)",
        barThickness: 20,
      },
    ],
  };

  // Extract skills data
  const skills = userData?.skills ? JSON.parse(userData?.skills) : [];

  const skillsData = userData?.workexperience_candidate?.reduce(
    (acc, experience) => {
      const yearsOfExperience = calculateExperience(
        experience.company_start_duration,
        experience.company_end_duration
      );

      // Parse the stringified skills into an array
      const skills = JSON.parse(experience?.skills?.toLowerCase()); // Convert stringified skills to an array

      skills.forEach((skill) => {
        if (acc[skill]) {
          acc[skill] += yearsOfExperience; // Accumulate experience for the same skill
        } else {
          acc[skill] = yearsOfExperience; // Initialize the experience for the skill
        }
      });

      return acc;
    },
    {}
  );
  const totalExperience = skillsData
    ? Object.values(skillsData).reduce((sum, exp) => sum + exp, 0)
    : 0;

  const skillsAcquiredData = {
    labels: skillsData ? Object.keys(skillsData) : [], // Skill names
    datasets: [
      {
        label: "Years of Experience",
        data: skillsData
          ? Object.values(skillsData).map(
              (exp) => ((exp / totalExperience) * 100).toFixed(0) // Calculate percentage of total experience
            )
          : [], // Empty array if skillsData is undefined or null
        backgroundColor: skillsData
          ? Object.keys(skillsData).map(
              (_, index) => colorPalette[index % colorPalette.length] // Assign colors dynamically
            )
          : [], // Empty array if skillsData is undefined or null
      },
      {
        label: "Experience",
        data: [],
        backgroundColor: ["#fff"],
      },
    ],
  };

  const skillProficiencyData = {
    labels: skillsData ? Object.keys(skillsData) : [],
    datasets: [
      {
        label: "Years of Experience",
        data: skillsData
          ? Object.values(skillsData)?.map(
              (exp) => exp.toFixed(1) // Calculate percentage of total experience
            )
          : [],
        backgroundColor: "rgba(255, 99, 132, 0.6)",
      },
    ],
  };

  const videos = [
    {
      title: "Introduction Video",
      link: userData?.introduction_video,
    },
    ...(Array.isArray(userData?.workexperience_candidate)
      ? userData?.workexperience_candidate?.map((work) => ({
          title: `Work Experience Video (${work?.experience} - ${
            work?.company_name || "No Company"
          })`,
          link: work?.upload_work_video,
        }))
      : []),
    ...(Array.isArray(userData?.education_candidate)
      ? userData?.education_candidate?.map((education) => ({
          title: `${education?.education} Education Video`,
          link: education?.upload_education_video,
        }))
      : []),
  ];

  const handleNext = () => {
    setCurrentIndex((prevIndex) => (prevIndex + 1) % videos.length);
  };

  const handlePrev = () => {
    setCurrentIndex(
      (prevIndex) => (prevIndex - 1 + videos.length) % videos.length
    );
  };

  return (
    <div className="h-full">
      {isLoading ? (
        <div className="flex text-black h-full justify-center items-center">
          <ATMCircularProgress />
        </div>
      ) : (
        <div className="  bg-white shadow-lg rounded-lg  font-inter text-xs text-slate-600">
          <div className="px-2 py-1 border-b flex gap-4    items-center">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={1.5}
              stroke="currentColor"
              className="size-6  cursor-pointer"
              onClick={handleBack}
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M10.5 19.5 3 12m0 0 7.5-7.5M3 12h18"
              />
            </svg>
            <ATMHeading>Profile</ATMHeading>
          </div>
          <div className=" p-2 grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3   gap-4">
            <div className=" bg-gray-800 text-white rounded-lg p-4 flex gap-2 flex-col justify-between">
              <div className="">
                <h2 className="text-2xl text-white font-bold capitalize">
                  HEY! I'm {userData?.first_name}, <br /> {userData?.title}
                </h2>
                <p className="text-[10px] text-slate-100 break-words mt-2">
                  {userData?.profile_summary}
                </p>
              </div>
              <div className="flex items-center flex-wrap gap-2 ">
                {skills?.map((el, ind) => {
                  return (
                    <div
                      key={ind}
                      className="border px-4 py-0.5 rounded-lg flex justify-center items-end "
                    >
                      {el}
                    </div>
                  );
                })}
              </div>
            </div>
            <div className="border rounded-lg p-2 text-xs font-medium shadow-md ">
              <div className=" overflow-hidden   ">
                {userData?.profile_pic ? (
                  <img
                    className=" object-contain w-full h-[250px] rounded-lg"
                    src={userData?.profile_pic}
                    alt="profile"
                  />
                ) : (
                  <div className="uppercase gap-1 text-8xl bg-white text-[#1CACBD] border-[#1CACBD]  text-center   w-full border rounded-md h-[250px] flex justify-center items-center">
                    <span>{userData?.first_name?.charAt(0)}</span>
                    <span>{userData?.last_name?.charAt(0)}</span>
                  </div>
                )}
              </div>
              <div className="flex justify-between items-center flex-wrap gap-4 mt-2">
                <div className="font-bold capitalize text-sm  ">
                  {userData?.first_name} {""}
                  {userData?.last_name}
                </div>
                <div className="text-xs font-medium border-l text-end pl-4 ">
                  {userData?.title}
                </div>
              </div>
              <div className=" ">@{userData?.email}</div>
              <div className="mt-1">{userData?.contact_number}</div>
            </div>
            <div className="bg-white border rounded-lg p-4 overflow-hidden">
              <div className="flex flex-col  items-center h-full w-full transition-all duration-500 ease-in-out">
                {/* Video Player */}
                <div className="flex  overflow-hidden rounded-lg w-68 h-60">
                  <ReactPlayer
                    url={`https://d27coln91ik106.cloudfront.net/${videos[
                      currentIndex
                    ].link?.replace(/\*/g, "/")}`}
                    width="100%"
                    height="100%"
                    controls
                    style={{ objectFit: "cover" }}
                  />
                </div>

                {/* Title */}
                <div className="p-1 rounded-b-xl text-center">
                  <p className="text-[11px] font-medium">
                    {videos[currentIndex].title}
                  </p>
                </div>

                {/* Navigation Buttons */}
                <div className="flex justify-end items-center mt-2 gap-4">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth={1.5}
                    stroke="currentColor"
                    className="size-6 cursor-pointer text-blue-400"
                    onClick={handlePrev}
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="m11.25 9-3 3m0 0 3 3m-3-3h7.5M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"
                    />
                  </svg>
                  <span className="text-gray-600 font-medium">
                    Video {currentIndex + 1} of {videos.length}
                  </span>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth={1.5}
                    stroke="currentColor"
                    className="size-6 cursor-pointer text-blue-400"
                    onClick={handleNext}
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="m12.75 15 3-3m0 0-3-3m3 3h-7.5M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"
                    />
                  </svg>
                </div>
              </div>
            </div>

            {/* Career Trajectory */}
            <div className="bg-white border rounded-lg p-4 ">
              <h3 className="text-lg font-semibold mb-4">Career Trajectory</h3>
              <div className="flex  justify-center items-center mb-2">
                <div className="w-4 h-4 bg-blue-400 rounded-full mr-2"></div>
                <span className="text-xs">
                  Work Experience (Years and Months)
                </span>
              </div>
              <Bar
                data={careerTrajectoryData}
                options={{
                  responsive: true,
                  plugins: {
                    legend: {
                      display: false,
                      position: "top",
                    },
                    tooltip: {
                      callbacks: {
                        label: (context) => {
                          const job =
                            userData?.workexperience_candidate?.[
                              context.dataIndex
                            ];
                          const years = context.raw;
                          return `${job?.company_name}: ${years} years`;
                        },
                      },
                    },
                  },
                  scales: {
                    x: {
                      title: {
                        display: true,
                        text: "Companies and Timeline",
                      },
                      ticks: {
                        callback: function (value, index) {
                          // Show the company name and timeline for each label
                          const label = this.getLabelForValue(value);
                          return label;
                        },
                      },
                    },
                  },
                }}
                height={200}
                width={400}
              />
            </div>
            {/* Skills Acquired */}
            <div className="bg-white border rounded-lg p-4 flex flex-col items-center space-y-4">
              <h3 className="text-lg font-semibold">Skills Acquired</h3>

              {/* Chart and Details Section */}
              <div className="flex   items-center justify-between w-full h-full">
                {/* Pie Chart */}
                <div className="flex-grow max-w-[50%]">
                  <Pie
                    data={skillsAcquiredData}
                    options={{
                      responsive: true,
                      maintainAspectRatio: false,
                      plugins: {
                        legend: {
                          display: false,
                        },
                        tooltip: {
                          callbacks: {
                            label: (context) => {
                              return ``;
                            },
                          },
                        },
                        datalabels: {
                          color: "#fff",
                          font: {
                            weight: "normal",
                            size: 11,
                          },
                          formatter: (value) => {
                            const percentage = value; // Calculate percentage
                            return `${percentage}%`; // Display percentage
                          },
                        },
                      },
                    }}
                  />
                </div>

                {/* Skill Details */}
                <ul className="space-y-1 text-[11px] text-right ml-4 flex-shrink-0">
                  {skillsAcquiredData?.labels?.map((label, index) => (
                    <li key={index} className="flex items-center capitalize">
                      <span
                        className="inline-block w-3 h-3 rounded-full mr-2"
                        style={{
                          backgroundColor:
                            skillsAcquiredData.datasets[0].backgroundColor[
                              index
                            ],
                        }}
                      ></span>
                      <span className="font-bold">{label}:</span>{" "}
                      {skillsAcquiredData.datasets[0].data[index]}%{" "}
                    </li>
                  ))}
                </ul>
              </div>
            </div>
            {/* Skill Proficiency */}
            <div className="bg-white border rounded-lg p-4 ">
              <h3 className="text-lg font-semibold mb-4">Skill Proficiency</h3>
              <div className="flex  justify-center items-center mb-2">
                <div className="w-4 h-4 bg-[#ff99af] rounded-full mr-2"></div>
                <span className="text-xs">Years of Experience</span>
              </div>
              <Bar
                data={skillProficiencyData}
                options={{
                  responsive: true,
                  plugins: {
                    legend: {
                      display: false,
                    },
                  },
                }}
                height={200}
                width={400}
              />
            </div>
            {userData?.languages !== "[]" && (
              <div className="border rounded-lg">
                <div className="bg-blue-100  rounded-t-lg px-4 font-semibold py-2">
                  Languages Profeciency
                </div>
                <div className="flex flex-wrap gap-2  p-2">
                  {userData?.languages
                    ? JSON.parse(userData?.languages)?.map((el) => {
                        return (
                          <div className="border rounded-xl px-2 text-[10px]  py-1 text-center">
                            {el}
                          </div>
                        );
                      })
                    : ""}
                </div>
              </div>
            )}
            {userData?.candidatehobbies_candidate?.length > 0 && (
              <div className="border rounded-lg">
                <div className="bg-blue-100  rounded-t-lg px-4 py-2 font-semibold">
                  Interest and Hobbies
                </div>
                <div className="flex flex-wrap gap-2 p-2">
                  {userData?.candidatehobbies_candidate?.[0]?.hobbies?.map(
                    (el) => {
                      return (
                        <div className="border rounded-xl px-2 text-[10px] py-1 text-center">
                          {el?.hobbiecategory}
                        </div>
                      );
                    }
                  )}
                </div>
              </div>
            )}
            {userData?.candidatehobbies_candidate?.length > 0 && (
              <div className="border rounded-lg relative">
                <div className="bg-blue-100  rounded-t-lg px-4 font-semibold py-2">
                  About Interest
                </div>
                <div className=" p-2"></div>
                <span className="bg-white opacity-85  rounded-lg absolute inset-0 text-[#1cacbd] text-xl text-center flex justify-center items-center">
                  Coming soon....
                </span>
              </div>
            )}
            {userData?.education_candidate?.length > 0 && (
              <div className="font-bold text-xl md:col-span-2 lg:col-span-3">
                Qualification
              </div>
            )}
            {userData?.education_candidate
              ?.slice(startEducationIndex, endEducationIndex)
              .map((el) => (
                <div key={el?.id} className="border rounded-lg mb-4">
                  <div className="bg-gray-800 text-white py-5 rounded-t-lg px-4 font-semibold">
                    <span>{el?.institution_name}</span> <br />
                    <span>{el?.education}</span> <br />
                    <span>
                      ({formatDate(el?.duration_start)} To{" "}
                      {formatDate(el?.duration_end)})
                    </span>
                  </div>
                  <div className="gap-4 p-2">
                    <span>-{el?.specialization}</span> <br />
                    <span>
                      {el?.skills
                        ? JSON.parse(el?.skills)?.map((skill, index) => (
                            <div key={index}>-{skill}</div>
                          ))
                        : ""}
                    </span>
                  </div>
                </div>
              ))}

            <div className="flex justify-end gap-4 items-center mt-[-20px] md:col-span-2 lg:col-span-3">
              <button
                type="button"
                onClick={handlePrevEducationPage}
                disabled={currentEducationPage === 0}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth={1.5}
                  stroke="currentColor"
                  className="size-6  text-blue-400"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="m11.25 9-3 3m0 0 3 3m-3-3h7.5M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"
                  />
                </svg>
              </button>

              <button
                type="button"
                onClick={handleNextEducationPage}
                disabled={
                  (currentEducationPage + 1) * itemsPerPage >=
                  userData?.education_candidate?.length
                }
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth={1.5}
                  stroke="currentColor"
                  className="size-6  text-blue-400"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="m12.75 15 3-3m0 0-3-3m3 3h-7.5M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"
                  />
                </svg>
              </button>
            </div>

            {userData?.workexperience_candidate?.length > 0 && (
              <div className="font-bold text-xl md:col-span-2 lg:col-span-3">
                Work Experience
              </div>
            )}
            {userData?.workexperience_candidate
              ?.slice(startWorkIndex, endWorkIndex)
              .map((el) => (
                <div key={el?.id} className="border rounded-lg mb-4">
                  <div className="border-b py-2 rounded-t-lg px-4">
                    <span className="font-semibold">{el?.job_title}</span>{" "}
                    <br />
                    <span>{el?.company_name}</span> <br />
                    <span>{el?.experience}</span> <br />
                    <span>
                      ({formatDate(el?.company_start_duration)} To{" "}
                      {formatDate(el?.company_end_duration)})
                    </span>
                  </div>
                  <div className="gap-4 p-2">
                    <span className="break-words">
                      {el?.work_experience_summary}
                    </span>{" "}
                    <br />
                    <span className="flex flex-wrap gap-2 mt-2">
                      {el?.skills
                        ? JSON.parse(el?.skills)?.map((skill, index) => (
                            <div
                              key={index}
                              className="border rounded-xl px-2 py-2 w-fit"
                            >
                              {skill}
                            </div>
                          ))
                        : ""}
                    </span>
                  </div>
                </div>
              ))}

            <div className="flex justify-end gap-4 items-center mt-[-20px] md:col-span-2 lg:col-span-3">
              <button
                type="button"
                onClick={handlePrevWorkPage}
                disabled={currentWorkPage === 0}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth={1.5}
                  stroke="currentColor"
                  className="size-6  text-blue-400"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="m11.25 9-3 3m0 0 3 3m-3-3h7.5M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"
                  />
                </svg>
              </button>

              <button
                type="button"
                onClick={handleNextWorkPage}
                disabled={
                  (currentWorkPage + 1) * itemsPerPage >=
                  userData?.workexperience_candidate?.length
                }
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth={1.5}
                  stroke="currentColor"
                  className="size-6  text-blue-400"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="m12.75 15 3-3m0 0-3-3m3 3h-7.5M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"
                  />
                </svg>
              </button>
            </div>
          </div>

          <div className="p-5">
            <span>
              Your comprehensive profile is a crucial part of our Application
              Tracking System, empowering our analysis for better matches. Your
              information is securely stored in our database, ensuring future
              opportunities align with your skills and preferences. Rest
              assured, we'll leverage this data to connect you with relevant
              positions.
            </span>
            <span></span>
          </div>
        </div>
      )}
    </div>
  );
};

export default MyProfileView;
