import React from "react";
import { CiBookmark } from "react-icons/ci";
import { useNavigate } from "react-router-dom";
// bg-[#F7F8FD]
function JobCard({ data }) {
  const navigate = useNavigate();
  return (
    <>
      {
        <div className=" text-[11px] font-inter bg-[#F7F8FD] border  rounded-md flex flex-col justify-between">
          <div className=" flex flex-col gap-2 text-[#202020]  font-normal  p-4 ">
            <div className="flex justify-between  gap-2">
              <div className="px-2 py-0.5 text-center bg-white border border-[#EBEEFF] border-b-4 border-b-[#1CACBD]  rounded-md w-[110px]">
                <h2 className=" font-semibold  text-[#515151] text-base">
                  {data?.total_candidates}
                </h2>
                <p className="text-[#333333] break-words text-center">
                  Total Candidates
                </p>
              </div>

              <div className="px-2  py-0.5 bg-white border text-center  border-[#EBEEFF]  border-b-4 border-b-orange-400 rounded-md w-[110px]">
                <h2 className=" font-semibold  text-[#515151] text-base">
                  {data?.shortlisted}
                </h2>
                <p className="text-[#333333] text-center">Shortlisted</p>
              </div>
            </div>

            <div className="flex items-start justify-between gap-2">
              <div className=" flex items-end gap-2 font-semibold">
                <img
                  src={data?.company?.company_logo}
                  alt=""
                  className="w-[80px] h-[40px] rounded-md object-contain "
                />
                <h1 className="">{data?.company?.company_name}</h1>
              </div>
              <CiBookmark className="text-lg text-[#898989] font-semibold" />
            </div>

            <div className="">
              <h1 className="font-semibold ">{data?.title}</h1>
              <button
                onClick={() =>
                  navigate(`/recruit/job-status/recruiter-view/${data.id}`)
                }
                className="text-[#1CACBD]  font-medium"
              >
                View Job Details
              </button>
            </div>

            <div className="flex  items-center gap-1">
              <svg
                width="16"
                height="14"
                viewBox="0 0 11 15"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M5.10803 0.851562C2.30993 0.851562 0.046875 3.00535 0.046875 5.66834C0.046875 9.28092 5.10803 14.6138 5.10803 14.6138C5.10803 14.6138 10.1692 9.28092 10.1692 5.66834C10.1692 3.00535 7.90612 0.851562 5.10803 0.851562ZM5.1078 7.38945C4.11002 7.38945 3.30024 6.61876 3.30024 5.66917C3.30024 4.71958 4.11002 3.94889 5.1078 3.94889C6.10557 3.94889 6.91535 4.71958 6.91535 5.66917C6.91535 6.61876 6.10557 7.38945 5.1078 7.38945Z"
                  fill="#898989"
                />
              </svg>
              <p className="">
                Office: {data?.country || data?.state || data?.city}
              </p>
            </div>
            <div className="flex  items-center gap-1 ">
              <svg
                width="16"
                height="14"
                viewBox="0 0 16 14"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M13.8577 3.53565H11.6886V2.15943C11.6886 1.39562 11.0451 0.783203 10.2425 0.783203H5.90441C5.10186 0.783203 4.45837 1.39562 4.45837 2.15943V3.53565H2.2893C1.48675 3.53565 0.843262 4.14807 0.843262 4.91187V12.4811C0.843262 13.2449 1.48675 13.8573 2.2893 13.8573H13.8577C14.6602 13.8573 15.3037 13.2449 15.3037 12.4811V4.91187C15.3037 4.14807 14.6602 3.53565 13.8577 3.53565ZM5.9045 2.16056H10.2426V3.5373H5.9045V2.16056ZM13.8582 12.4824H2.28982V11.1056H13.8582V12.4824ZM13.8577 9.04006H2.28933V4.91139H4.4584V6.28762H5.90444V4.91139H10.2426V6.28762H11.6886V4.91139H13.8577V9.04006Z"
                  fill="#898989"
                />
              </svg>
              <p className="">{data?.aspect_of_work_location}</p>
            </div>

            <div className="flex  items-center gap-1">
              <svg
                width="16"
                height="14"
                viewBox="0 0 16 14"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M13.8577 3.53565H11.6886V2.15943C11.6886 1.39562 11.0451 0.783203 10.2425 0.783203H5.90441C5.10186 0.783203 4.45837 1.39562 4.45837 2.15943V3.53565H2.2893C1.48675 3.53565 0.843262 4.14807 0.843262 4.91187V12.4811C0.843262 13.2449 1.48675 13.8573 2.2893 13.8573H13.8577C14.6602 13.8573 15.3037 13.2449 15.3037 12.4811V4.91187C15.3037 4.14807 14.6602 3.53565 13.8577 3.53565ZM5.9045 2.16056H10.2426V3.5373H5.9045V2.16056ZM13.8582 12.4824H2.28982V11.1056H13.8582V12.4824ZM13.8577 9.04006H2.28933V4.91139H4.4584V6.28762H5.90444V4.91139H10.2426V6.28762H11.6886V4.91139H13.8577V9.04006Z"
                  fill="#898989"
                />
              </svg>
              <div className="">
                Salary:
                {data?.salary_from === 0 ? (
                  "--"
                ) : (
                  <span>
                    {data?.currency} {data?.salary_from}-{data?.salary_to} {""}
                    {data?.salary_type}
                  </span>
                )}
              </div>
            </div>

            <div className="flex items-start gap-1">
              <svg
                width="16"
                height="14"
                viewBox="0 0 15 15"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <g clipPath="url(#clip0_9326_55716)">
                  <path
                    d="M7.82555 6.90904C9.39631 6.90904 10.6697 5.72048 10.6697 4.25432C10.6697 2.78817 9.39631 1.59961 7.82555 1.59961C6.25479 1.59961 4.98145 2.78817 4.98145 4.25432C4.98145 5.72048 6.25479 6.90904 7.82555 6.90904Z"
                    stroke="#898989"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M3.3167 13.4446H12.3347C13.334 13.4446 14.1037 12.6426 13.6371 11.8178C12.9506 10.6046 11.3856 9.15625 7.8257 9.15625C4.26576 9.15625 2.70077 10.6046 2.01431 11.8178C1.54764 12.6426 2.31739 13.4446 3.3167 13.4446Z"
                    stroke="#898989"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </g>
                <defs>
                  <clipPath id="clip0_9326_55716">
                    <rect
                      width="14.0989"
                      height="13.1601"
                      fill="white"
                      transform="translate(0.776367 0.941406)"
                    />
                  </clipPath>
                </defs>
              </svg>

              <p className="">Job Owner: {data?.account_manager?.email}</p>
            </div>
            <div className="flex  gap-1">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth="1.5"
                stroke="currentColor"
                width="18"
                height="14"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M18 18.72a9.094 9.094 0 0 0 3.741-.479 3 3 0 0 0-4.682-2.72m.94 3.198.001.031c0 .225-.012.447-.037.666A11.944 11.944 0 0 1 12 21c-2.17 0-4.207-.576-5.963-1.584A6.062 6.062 0 0 1 6 18.719m12 0a5.971 5.971 0 0 0-.941-3.197m0 0A5.995 5.995 0 0 0 12 12.75a5.995 5.995 0 0 0-5.058 2.772m0 0a3 3 0 0 0-4.681 2.72 8.986 8.986 0 0 0 3.74.477m.94-3.197a5.971 5.971 0 0 0-.94 3.197M15 6.75a3 3 0 1 1-6 0 3 3 0 0 1 6 0Zm6 3a2.25 2.25 0 1 1-4.5 0 2.25 2.25 0 0 1 4.5 0Zm-13.5 0a2.25 2.25 0 1 1-4.5 0 2.25 2.25 0 0 1 4.5 0Z"
                />
              </svg>

              <div className=" ">
                Managed By:{" "}
                {/* {data?.managed_by?.emails.map((el, ind) => {
                return <p>{el}</p>;
              })} */}
                <div className="text-[10px]">
                  {data?.managed_by?.emails?.[0]}
                  {data?.managed_by?.emails[1] && (
                    <button
                      onClick={() =>
                        navigate(
                          `/recruit/job-status/recruiter-view/${data.id}`
                        )
                      }
                      className="text-blue-600  font-medium ml-2"
                    >
                      ...See More
                    </button>
                  )}
                </div>
              </div>
            </div>
          </div>

          <div className="flex justify-end border-t border-[#DBE1FF] px-4 py-2">
            <button
              onClick={() =>
                navigate(`/recruit/job-status/application/${data.id}`)
              }
              className="flex text-[11px] py-1 px-2 rounded-md border text-white bg-[#1CACBD] justify-center gap-2 items-center"
            >
              View Applicants
              <svg
                width="7"
                height="11"
                viewBox="0 0 7 11"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M1.02002 9.81836L5.35815 5.40942L1.02002 1.00047"
                  stroke="#fff"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </button>
          </div>
        </div>
      }
    </>
  );
}

export default JobCard;
