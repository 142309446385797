import { Form, Formik } from "formik";
import React from "react";
import toast from "react-hot-toast";
import * as Yup from "yup";
import ATMButton from "../../../atoms/ATMButton/ATMButton";
import ATMPassword from "../../../atoms/ATMPassword/ATMPassword";
import { useSetNewPassowrdMutation } from "./Services/MyprofileSrevice";

const PasswordChangeForm = ({ setPassOpen }) => {
  const [newpassword] = useSetNewPassowrdMutation();
  const validationSchema = Yup.object({
    currentPassword: Yup.string().required("Current password is required"),
    newPassword: Yup.string()
      .min(6, "Password must be at least 6 characters")
      .matches(/[a-z]/, "Password must contain at least one lowercase letter")
      .matches(/[A-Z]/, "Password must contain at least one uppercase letter")
      .matches(/\d/, "Password must contain at least one number")
      .required("New password is required"),
    confirmPassword: Yup.string()
      .oneOf([Yup.ref("newPassword"), null], "Passwords must match")
      .required("Please confirm your new password"),
  });

  const initialValues = {
    currentPassword: "",
    newPassword: "",
    confirmPassword: "",
  };

  const handleSubmit = (values) => {
    const formData = new FormData();
    formData.append("current_password", values?.currentPassword);
    formData.append("new_password", values?.newPassword);
    newpassword(formData).then((res) => {
      if (res?.error) {
        toast.error(res?.error?.data?.error);
      } else {
        if (res?.data?.success) {
          setPassOpen(false);
          toast.success(res?.data?.success);
        } else {
          toast.error(res?.error?.data?.message);
        }
      }
    });
  };

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 z-50 flex items-center justify-center">
      <div className="bg-white p-6 rounded-lg shadow-lg max-w-xl  w-full">
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
        >
          {({ values, setFieldValue }) => (
            <Form className="px-2 py-4 flex flex-col gap-4">
              <div>
                <ATMPassword
                  name="currentPassword"
                  value={values.currentPassword || ""}
                  label="Current Password"
                  placeholder="Enter your Current Password"
                  onChange={(e) =>
                    setFieldValue("currentPassword", e.target.value)
                  }
                />
              </div>

              <div className="">
                <ATMPassword
                  name="newPassword"
                  value={values.newPassword || ""}
                  label="New Password"
                  placeholder="Enter your New Password"
                  onChange={(e) => setFieldValue("newPassword", e.target.value)}
                />
              </div>

              <div className="">
                <ATMPassword
                  name="confirmPassword"
                  value={values.confirmPassword || ""}
                  label="Confirm New Password"
                  placeholder="Enter your Confirm New Password"
                  onChange={(e) =>
                    setFieldValue("confirmPassword", e.target.value)
                  }
                />
              </div>
              <div className="flex justify-end items-center gap-4">
                <button
                  type="button"
                  onClick={() => setPassOpen(false)}
                  className=" w-[100px] px-1 text-[11px] flex gap-1 text-[#1CACBD] border border-[#1CACBD]  h-[32px] rounded-md  font-medium justify-center items-center"
                >
                  Cancel
                </button>
                <div className="w-[100px]">
                  <ATMButton label="Submit" type="submit" loading={false} />
                </div>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </div>
  );
};

export default PasswordChangeForm;
