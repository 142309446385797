import { City, Country, State } from "country-state-city";
import { ErrorMessage } from "formik";
import React, { useEffect, useState } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useNavigate } from "react-router-dom";
import ATMButton from "../../../../atoms/ATMButton/ATMButton";
import ATMFieldError from "../../../../atoms/ATMFieldError/ATMFieldError";
import ATMFieldLabel from "../../../../atoms/ATMFieldLabel/ATMFieldLabel";
import ATMFilePicker from "../../../../atoms/ATMFIlePicker/ATMFilePicker";
import ATMMultiSelect from "../../../../atoms/ATMMultiSelect/ATMMultiSelect";
import ATMSelect from "../../../../atoms/ATMSelect/ATMSelect";
import ATMTextField from "../../../../atoms/ATMTextField/ATMTextField";
import VideoRecorderModal from "../../../../components/candidate/VideoRecorderModal";
const languageOptions = [
  {
    value: "Hindi",
    label: "Hindi",
  },
  {
    value: "English",
    label: "English",
  },
  {
    value: "Spanish",
    label: "Spanish",
  },
  {
    value: "French",
    label: "French",
  },
  {
    value: "German",
    label: "German",
  },
  {
    value: "Chinese",
    label: "Chinese",
  },
  {
    value: "Japanese",
    label: "Japanese",
  },
  {
    value: "Russian",
    label: "Russian",
  },
  {
    value: "Arabic",
    label: "Arabic",
  },
  {
    value: "Portuguese",
    label: "Portuguese",
  },
];

const ProfileDetailsForm = ({ formikProps, userData }) => {
  const [videoBlob, setVideoBlob] = useState("");
  const url = videoBlob ? URL.createObjectURL(videoBlob) : "";
  const navigate = useNavigate();
  // const userData = useSelector((state) => state.candidateData.candidateData);
  const { values, setFieldValue, handleBlur, isSubmitting, handleChange } =
    formikProps;
  const [skills] = useState([
    { value: "User Research", label: "User Research" },
    { value: "Python", label: "Python" },
    { value: "UI Design", label: "UI Design" },
  ]);

  const [modalIsOpen, setModalIsOpen] = useState(false);

  const toggleModal = () => {
    setModalIsOpen(!modalIsOpen);
  };

  useEffect(() => {
    setFieldValue("blob", videoBlob);
    // eslint-disable-next-line
  }, [videoBlob]);
  return (
    <div className="flex flex-col gap-4 my-4">
      <div className=" grid grid-cols-1  md:grid-cols-2 p-9  justify-between  gap-10 border-[1px] rounded-lg border-[#E2DCEA]">
        {/* Profile Photo Upload */}
        <div className="min-w-[300px]  ">
          <ATMFieldLabel> Your Photo</ATMFieldLabel>
          <p className="text-[11px] font-inter font-normal text-[#667085]">
            This will be displayed on your profile
          </p>
          <div className=" h-[222px] min-w-28  ">
            <ATMFilePicker
              name="profilePhoto"
              value={values.profilePhoto}
              onChange={(file) => setFieldValue("profilePhoto", file)}
              placeholder="Click to upload or drag and drop"
              accept="image/jpeg,image/png,image/gif"
            />
          </div>
        </div>

        {/* Introduction Video */}
        <div className="min-w-[300px] ">
          <div>
            <ATMFieldLabel> Upload Your Introduction Video</ATMFieldLabel>
            <p className="text-[#667085] font-inter font-normal text-[11px] ">
              This will be displayed on your profile.
            </p>
          </div>{" "}
          <div className="relative flex justify-around items-center p-2  w-[290px]  h-[222.77px] border-[1px] border-[#E2DCEA] rounded-md">
            {videoBlob || userData?.introduction_video ? (
              <span
                onClick={toggleModal}
                className="absolute top-[-28px] right-0 rounded-md cursor-pointer bg-red-600 text-white text-[11px] p-1"
              >
                Re-record
              </span>
            ) : (
              ""
            )}
            {videoBlob ? (
              videoBlob ? (
                <video
                  src={url}
                  controls
                  className="h-full w-full rounded-lg"
                />
              ) : (
                <video
                  className="h-full w-full rounded-lg"
                  controls
                  src={`https://d27coln91ik106.cloudfront.net/${userData?.introduction_video?.replace(
                    /\*/g,
                    "/"
                  )}`}
                ></video>
              )
            ) : userData?.introduction_video ? (
              <video
                className="h-full w-full rounded-lg"
                controls
                src={`https://d27coln91ik106.cloudfront.net/${userData?.introduction_video?.replace(
                  /\*/g,
                  "/"
                )}`}
              ></video>
            ) : (
              <div className=" flex  items-center justify-center text-center flex-col gap-3">
                <button
                  type="button"
                  onClick={toggleModal}
                  className=" flex items-center justify-around gap-1  font-inter text-[11px]  py-2 px-4 text-[#1CACBD] font-medium bg-[#1CACBD0F] rounded-xl"
                >
                  <svg
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M23 19C23 19.5304 22.7893 20.0391 22.4142 20.4142C22.0391 20.7893 21.5304 21 21 21H3C2.46957 21 1.96086 20.7893 1.58579 20.4142C1.21071 20.0391 1 19.5304 1 19V8C1 7.46957 1.21071 6.96086 1.58579 6.58579C1.96086 6.21071 2.46957 6 3 6H7L9 3H15L17 6H21C21.5304 6 22.0391 6.21071 22.4142 6.58579C22.7893 6.96086 23 7.46957 23 8V19Z"
                      stroke="#1CACBD"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M12 17C14.2091 17 16 15.2091 16 13C16 10.7909 14.2091 9 12 9C9.79086 9 8 10.7909 8 13C8 15.2091 9.79086 17 12 17Z"
                      stroke="#1CACBD"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                  Record Video
                </button>
                <p className=" text-[11px] font-inter font-normal text-[#828799]">
                  Tell us about your experience! You can record one right here
                  using your webcam your journey.
                </p>
              </div>
            )}
            {/* {modalIsOpen && ( */}
              <VideoRecorderModal
                isOpen={modalIsOpen}
                onClose={toggleModal}
                setVideoBlob={setVideoBlob}
              />
          </div>
          <div className="relative">
            <ErrorMessage name="uploadvideo">
              {(errorMessage) => (
                <ATMFieldError> {errorMessage} </ATMFieldError>
              )}
            </ErrorMessage>
          </div>
        </div>

        <div>
          <ATMTextField
            type="text"
            name="title"
            value={values.title}
            label="Title:"
            placeholder="Enter title"
            onChange={(e) => setFieldValue("title", e.target.value)}
            onBlur={handleBlur}
          />
        </div>

        {/* Date of Birth */}
        <div className="w-full">
          <ATMFieldLabel>Date Of Birth</ATMFieldLabel>

          <div className="w-full">
            <DatePicker
              id="dob"
              name="dob"
              selected={values.dob}
              onChange={(date) => setFieldValue("dob", date)}
              dateFormat="dd/MM/yyyy" // custom date format
              className="h-[40px] border px-2 w-full rounded-md outline-none"
            />
          </div>

          <div className="relative">
            <ErrorMessage name="dob">
              {(errorMessage) => (
                <ATMFieldError> {errorMessage} </ATMFieldError>
              )}
            </ErrorMessage>
          </div>
        </div>

        <div>
          <ATMMultiSelect
            name="languageData"
            value={values.languageData || []}
            onChange={(newValue) => setFieldValue("languageData", newValue)}
            options={languageOptions}
            label="Select language"
            placeholder="Select Language"
            keysToSelectoption={["Enter", ","]}
          />
        </div>

        <div className="">
          <ATMMultiSelect
            name="skillsData"
            value={values.skillsData || []}
            onChange={(newValue) => setFieldValue("skillsData", newValue)}
            options={skills}
            label="Add Your Skills"
            placeholder="Select Skills"
            keysToSelectoption={["Enter", ","]}
            isSkills={true}
          />
        </div>

        {/* Profile Summary */}
        <div className="md:col-span-2 relative">
          <ATMFieldLabel> Profile Summary</ATMFieldLabel>
          <textarea
            placeholder="Write a brief summary about yourself..."
            className=" w-full h-[100px] border-[1px] border-[#E2DCEA] rounded-lg p-3 outline-none"
            name="profilesummary"
            value={values.profilesummary || ""}
            onChange={handleChange}
            onBlur={handleBlur}
          ></textarea>
          <ErrorMessage name="profilesummary">
            {(errorMessage) => <ATMFieldError> {errorMessage} </ATMFieldError>}
          </ErrorMessage>
        </div>
      </div>

      <div className="  p-9 rounded-lg border-[1px] border-[#E2DCEA]">
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 justify-around  gap-4">
          {/* Location */}
          <ATMSelect
            required
            name={`country`}
            label="Country"
            options={Country.getAllCountries().map((country) => ({
              value: country.isoCode,
              label: country.name,
            }))}
            onChange={(newValue) => {
              setFieldValue(`country`, newValue);
              setFieldValue(`state`, null);
              setFieldValue(`city`, null);
            }}
            value={values?.country || null}
          />

          <ATMSelect
            name={`state`}
            label="State"
            options={
              values.country && values.country.value
                ? State.getStatesOfCountry(values.country.value).map(
                    (state) => ({
                      value: state.isoCode,
                      label: state.name,
                    })
                  )
                : []
            }
            onChange={(newValue) => {
              setFieldValue(`state`, newValue);
              setFieldValue(`city`, null);
            }}
            value={values?.state || null}
          />

          <ATMSelect
            name={`city`}
            label="City"
            value={values?.city || null}
            options={
              values.country?.value && values.state?.value
                ? City.getCitiesOfState(
                    values.country?.value,
                    values.state?.value
                  ).map((city) => ({
                    value: city.name,
                    label: city.name,
                  }))
                : []
            }
            onChange={(newValue) => {
              setFieldValue(`city`, newValue);
            }}
          />
          <div>
            <ATMTextField
              type="number"
              name="zipCode"
              value={values.zipCode || ""}
              label="Zip Code"
              placeholder="Enter your zip code"
              onChange={(e) => setFieldValue("zipCode", e.target.value)}
              onBlur={handleBlur}
            />
          </div>
        </div>
      </div>

      {/* Submit and Skip Buttons */}
      <div className=" flex justify-end gap-4">
        {!userData?.date_of_birth && (
          <button
            onClick={() => navigate("/candidate/profile/work-experience")}
          >
            <div className=" flex items-center justify-center w-[100px] h-[32px] rounded-md border-[1px] border-[#1CACBD] text-[#1CACBD]">
              Skip
            </div>
          </button>
        )}
        <div className=" w-[100px]">
          <ATMButton
            label={userData?.date_of_birth ? "Save" : "Proceed"}
            loading={isSubmitting}
          />
        </div>
      </div>
    </div>
  );
};

export default ProfileDetailsForm;
