import React, { useState } from "react";

import axios from "axios";
import { useFormik } from "formik";
import toast from "react-hot-toast";
import { IoMdEye, IoMdEyeOff } from "react-icons/io";
import { object, string } from "yup";
import ATMButton from "../../../../atoms/ATMButton/ATMButton";
import ATMFieldError from "../../../../atoms/ATMFieldError/ATMFieldError";
import ATMFieldLabel from "../../../../atoms/ATMFieldLabel/ATMFieldLabel";
import apiSlice from "../../../../app/ApiSlice";
import { useDispatch } from "react-redux";
const baseUrl = process.env.REACT_APP_VITE_BACKEND_API_URL;

function AddRecruiterForm({ setOpen }) {
  const dispatch = useDispatch();
  const [showPassword, setShowPassword] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const handleShowPassword = () => {
    setShowPassword(!showPassword);
  };
  const ProfileData = localStorage.getItem("recuriterProfile")
    ? JSON.parse(localStorage.getItem("recuriterProfile"))
    : {};

  const expectedDomain = ProfileData?.email?.split("@")[1];
  const handleEmailChange = (e) => {
    const value = e.target.value;
    const atIndex = value.indexOf("@");
    if (atIndex !== -1) {
      const domain = value.slice(atIndex + 1);

      formik.setFieldValue("companyUrl", `${domain}`);
    }
    formik.setFieldValue("email", value); // Sync email with Formik
  };
  const formik = useFormik({
    initialValues: {
      email: "",
      companyUrl: "",
      password: "",
    },
    validationSchema: object().shape({
      //   email: string().email().required("Please enter your email"),
      email: string()
        .email("Invalid email address")
        .required("Please enter your email")
        .test(
          "domain-match",
          "Email domain must match your profile domain",
          (value) => {
            if (!value || !expectedDomain) return true; // Skip validation if either is missing
            const enteredDomain = value.split("@")[1];
            return enteredDomain === expectedDomain;
          }
        ),
      password: string()
        .min(6, "Password must be at least 6 characters")
        .required("Password is required"),
    }),

    onSubmit: async (values, { resetForm, setSubmitting }) => {
      setIsLoading(true);
      try {
        const response = await axios.post(
          `${baseUrl}/api/recruiter/registration/`,
          {
            email: values.email,
            password: values.password,
            password2: values.password,
            first_name: "",
            last_name: "",
            company_website_url: `https://${values.companyUrl}`,
          },
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        );

        if (response.status === 201) {
          dispatch(apiSlice.util.invalidateTags(["active"]));
          resetForm();
          setOpen(false);
          toast.success("Registration successful!");
        } else {
          toast.error("Something went wrong. Please try again.");
        }
      } catch (error) {
        if (error.response) {
          if (error.response.status === 400) {
            if (error.response.data?.message) {
              toast.error(error.response.data.message);
            } else {
              toast.error("Bad request. Please check your input.");
            }
          } else if (error.response.status === 500) {
            toast.error("Internal Server Error. Please try again later.");
          } else {
            toast.error(`Error: ${error.response.statusText}`);
          }
        } else if (error.request) {
          toast.error("Network error. Please check your internet connection.");
        } else {
          toast.error("An unexpected error occurred. Please try again.");
        }
      } finally {
        setIsLoading(false);
        setSubmitting(false);
      }
    },
  });

  return (
    <>
      <div className="fixed inset-0 bg-black bg-opacity-15 z-50 flex items-center text-[11px] justify-center">
        <div className="bg-white p-6 rounded-lg shadow-lg max-w-xl  w-full">
          <form onSubmit={formik.handleSubmit} className="flex flex-col gap-2">
            <div className="w-full border border-[#E2DCEA] rounded-md p-4 grid grid-cols-1 gap-4">
              <div className="w-full ">
                <ATMFieldLabel required> Email ID</ATMFieldLabel>
                <div className="relative">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth={1.5}
                    stroke="currentColor"
                    className="absolute  top-2 left-3 text-gray-400 pointer-events-none"
                    style={{ height: 24, width: 24 }}
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M21.75 6.75v10.5a2.25 2.25 0 0 1-2.25 2.25h-15a2.25 2.25 0 0 1-2.25-2.25V6.75m19.5 0A2.25 2.25 0 0 0 19.5 4.5h-15a2.25 2.25 0 0 0-2.25 2.25m19.5 0v.243a2.25 2.25 0 0 1-1.07 1.916l-7.5 4.615a2.25 2.25 0 0 1-2.36 0L3.32 8.91a2.25 2.25 0 0 1-1.07-1.916V6.75"
                    />
                  </svg>
                  <input
                    placeholder="olivia@untitledui.com"
                    className=" pl-10 w-full border h-[38px] px-2 rounded-md outline-none"
                    labelProps={{
                      className: "before:content-none after:content-none",
                    }}
                    value={formik.values.email}
                    onChange={handleEmailChange}
                    onBlur={formik.handleBlur}
                    id="email"
                    name="email"
                  />
                </div>
                {formik.errors.email && formik.touched.email ? (
                  <ATMFieldError>{formik.errors.email}</ATMFieldError>
                ) : null}
              </div>
              <div className="w-full ">
                <ATMFieldLabel required>Password</ATMFieldLabel>

                <div className="relative border  rounded-md">
                  <input
                    type={showPassword ? "text" : "password"}
                    placeholder="Enter your password"
                    className=" w-full outline-none h-[38px] px-2 rounded-md"
                    labelProps={{
                      className: "before:content-none after:content-none ",
                    }}
                    value={formik.values.password}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    id="password"
                    name="password"
                  />
                  <div
                    onClick={handleShowPassword}
                    className="absolute top-1/2 transform -translate-y-1/2 text-2xl right-3 cursor-pointer"
                  >
                    {showPassword ? (
                      <IoMdEye size={18} className="text-slate-800" />
                    ) : (
                      <IoMdEyeOff size={18} className="text-slate-800" />
                    )}
                  </div>
                </div>
                {formik.errors.password && formik.touched.password ? (
                  <ATMFieldError>{formik.errors.password}</ATMFieldError>
                ) : null}
              </div>
              <div className="flex justify-end w-full items-center gap-4">
                <button
                  type="button"
                  onClick={() => setOpen(false)}
                  className=" w-[100px] border px-1 text-[11px] flex gap-1 text-[#1CACBD] border-[#1CACBD]  h-[32px] rounded-md  font-medium justify-center items-center"
                >
                  Cancel
                </button>
                <div className="w-[100px]">
                  <ATMButton loading={isLoading} label="Save" authicon />
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </>
  );
}

export default AddRecruiterForm;
