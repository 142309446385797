import React, { useEffect, useRef, useState } from "react";
import { Outlet, useLocation, useNavigate } from "react-router";
import logo from "../../../assets/logo jiva hire.png";
import Footer from "./Footer";

function NavForEditProfile({ item }) {
  const menuRef = useRef(null);

  const [recruiterData, setRecruiterData] = useState(null);
  const [candidateData, setCandidateData] = useState(null);

  const storedData = localStorage.getItem("recuriterProfile");
  const storedCandidateData = localStorage.getItem("candidate_profile");
  useEffect(() => {
    if (storedData && storedData !== undefined && storedData !== null) {
      setRecruiterData(JSON.parse(storedData));
    }
  }, [storedData]);
  useEffect(() => {
    if (
      storedCandidateData &&
      storedCandidateData !== undefined &&
      storedCandidateData !== null
    ) {
      setCandidateData(JSON?.parse(storedCandidateData));
    }
  }, [storedCandidateData]);

  const [show, setShow] = useState(false);
  const [sideShow, setSideShow] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const segments = location?.pathname?.split("/");
  const result = segments.slice(0, 3).join("/");
  const navigateResult = segments.slice(1, 2).join("/");

  const handleNavigate = () => {
    if (navigateResult === "candidate") {
      navigate("/candidate/home");
    } else {
      navigate("/recruit/dashboard");
    }
  };

  const orglogo = recruiterData?.company?.company_logo;
  const orgName = recruiterData?.company?.company_name?.split(" ")[0];
  const handleOutsideClick = (e) => {
    if (menuRef.current && !menuRef.current.contains(e.target)) {
      setShow(false);
    }
  };
  useEffect(() => {
    if (show) {
      document.addEventListener("mousedown", handleOutsideClick);
    } else {
      document.removeEventListener("mousedown", handleOutsideClick);
    }

    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
    };
  }, [show]);
  return (
    <div className="flex flex-col min-h-screen">
      {/* Header */}
      <header className="sticky top-0 z-50 bg-white shadow">
        <div className="flex justify-between  w-full  py-1 max-w-screen-2xl mx-auto gap-2">
          <div
            className="min-w-40 px-2 cursor-pointer flex items-center justify-start"
            onClick={handleNavigate}
          >
            {navigateResult !== "candidate" && (
              <div>
                {orglogo && (
                  <img
                    className="w-[120px] h-[40px] object-contain border border-gray-100"
                    src={orglogo}
                    alt="Org Logo"
                  />
                )}
                {!orglogo && (
                  <div className="font-semibold text-[#1CACBD] capitalize  font-serif ">
                    <span className="text-3xl ">{orgName?.charAt(0)}</span>
                    <span>{orgName?.slice(1)}</span>
                  </div>
                )}
              </div>
            )}
            {navigateResult === "candidate" && (
              <img className="w-[120px] h-[40px]" src={logo} alt="Logo" />
            )}
          </div>
          <div className="flex  items-center justify-end w-full gap-4">
            {/* <div className="w-[400px]">
              <label className="relative block ">
                <span className="absolute inset-y-0 left-0 flex items-center pl-2 border-r pr-1">
                  <svg
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M15.802 14.2644H14.9922L14.7051 13.9876C15.7097 12.819 16.3145 11.3018 16.3145 9.65141C16.3145 5.97131 13.3315 2.98828 9.65141 2.98828C5.97131 2.98828 2.98828 5.97131 2.98828 9.65141C2.98828 13.3315 5.97131 16.3145 9.65141 16.3145C11.3018 16.3145 12.819 15.7097 13.9876 14.7051L14.2644 14.9922V15.802L19.3898 20.9172L20.9172 19.3898L15.802 14.2644ZM9.65108 14.2643C7.09859 14.2643 5.03814 12.2039 5.03814 9.65138C5.03814 7.09889 7.09859 5.03845 9.65108 5.03845C12.2036 5.03845 14.264 7.09889 14.264 9.65138C14.264 12.2039 12.2036 14.2643 9.65108 14.2643Z"
                      fill="#222222"
                    />
                  </svg>
                </span>
                <input
                  maxLength={50}
                  className="w-full block bg-white border outline-none rounded-md py-1 pl-12 pr-3 "
                  placeholder="Search any job or any candidate....."
                  type="text"
                  name="searchValue"
                  value={searchValue}
                  onChange={(e) => {
                    setSearchValue(e.target.value);
                  }}
                />
                {searchValue && (
                  <div className="absolute left-9 px-3 rounded-md mt-[1px] bg-blue-50 w-full border cursor-pointer overflow-auto">
                    {searchValue}
                  </div>
                )}
              </label>
            </div> */}

            <div className="flex items-center">
              {/* <svg
                width="40"
                height="30"
                viewBox="0 0 40 40"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <rect
                  x="0.5"
                  y="0.5"
                  width="39"
                  height="39"
                  rx="5.5"
                  fill="white"
                />
                <rect
                  x="0.5"
                  y="0.5"
                  width="39"
                  height="39"
                  rx="5.5"
                  stroke="#F7F7F7"
                />
                <path
                  d="M21.4417 27.4993C21.2952 27.7519 21.0849 27.9616 20.8319 28.1073C20.5788 28.253 20.292 28.3297 20 28.3297C19.708 28.3297 19.4212 28.253 19.1681 28.1073C18.9151 27.9616 18.7048 27.7519 18.5583 27.4993M25 16.666C25 15.3399 24.4732 14.0682 23.5355 13.1305C22.5979 12.1928 21.3261 11.666 20 11.666C18.6739 11.666 17.4021 12.1928 16.4645 13.1305C15.5268 14.0682 15 15.3399 15 16.666C15 22.4993 12.5 24.166 12.5 24.166H27.5C27.5 24.166 25 22.4993 25 16.666Z"
                  stroke="#667085"
                  strokeWidth="1.66667"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg> */}
              {navigateResult === "candidate" ? (
                <div className="relative">
                  <div
                    className="mx-2 relative inline-flex  cursor-pointer"
                    onClick={() => setShow(!show)}
                  >
                    {candidateData && candidateData?.profile_pic ? (
                      <img
                        src={candidateData?.profile_pic}
                        alt="prifle-Photo"
                        className="inline-block relative object-cover object-center rounded-full w-10 h-10"
                      />
                    ) : (
                      <div className="uppercase  bg-blue-50 font-semibold text-[#1CACBD] border rounded-full w-9 h-9 flex justify-center items-center  ">
                        <span className="">
                          {candidateData?.first_name?.charAt(0)}
                        </span>
                        <span className="">
                          {candidateData?.last_name?.charAt(0)}
                        </span>
                      </div>
                    )}
                    <span className="absolute min-w-[12px] min-h-[12px] rounded-full py-1 px-1 text-xs font-medium content-[''] leading-none grid place-items-center top-[14%] right-[14%] translate-x-2/4 -translate-y-2/4 bg-green-500 text-white border-2 border-white"></span>
                  </div>
                  {show && (
                    <div
                      ref={menuRef}
                      className="border p-4 rounded-lg text-xs absolute bg-white text-slate-700 shadow-2xl right-4 top-10 w-[320px] font-inter  flex flex-col  gap-2"
                    >
                      <div className="flex items-center justify-between ">
                        <span className="font-semibold">Name:</span>
                        <div className="flex items-center capitalize gap-2">
                          <span>{candidateData?.first_name}</span>
                          <span> {candidateData?.last_name}</span>
                        </div>
                      </div>
                      <div className="flex items-center justify-between ">
                        <span className="font-semibold">Phone: </span>
                        <span> {candidateData?.contact_number}</span>
                      </div>

                      <div className="flex items-center justify-between ">
                        <span className="font-semibold">Email:</span>{" "}
                        {candidateData?.email}
                      </div>
                      <div
                        className="border-t w-full flex justify-end font-bold cursor-pointer pt-2 hover:text-black"
                        onClick={() => {
                          navigate("/candidate/logout");
                          setShow(false);
                        }}
                      >
                        Logout
                      </div>
                    </div>
                  )}
                </div>
              ) : (
                <div className="relative">
                  <div
                    className="mx-2 relative inline-flex  cursor-pointer"
                    onClick={() => setShow(!show)}
                  >
                    {recruiterData ? (
                      <div className="uppercase  bg-blue-50 font-semibold text-[#1CACBD] border rounded-full w-9 h-9 flex justify-center items-center  ">
                        <span className="">
                          {recruiterData?.first_name?.charAt(0)}
                        </span>
                        <span className="">
                          {recruiterData?.last_name?.charAt(0)}
                        </span>
                      </div>
                    ) : (
                      <img
                        src="https://static.vecteezy.com/system/resources/thumbnails/002/318/271/small/user-profile-icon-free-vector.jpg"
                        alt="avatar"
                        className="inline-block relative object-cover object-center rounded-full w-10 h-10"
                      />
                    )}
                    <span className="absolute min-w-[12px] min-h-[12px] rounded-full py-1 px-1 text-xs font-medium content-[''] leading-none grid place-items-center top-[14%] right-[14%] translate-x-2/4 -translate-y-2/4 bg-green-500 text-white border-2 border-white"></span>
                  </div>
                  {show && (
                    <div
                      ref={menuRef}
                      className="border p-4 rounded-lg text-xs absolute bg-white text-slate-700 shadow-2xl right-4 top-10 w-[320px] font-inter  flex flex-col  gap-2"
                    >
                      <div className="flex items-center justify-between ">
                        <span className="font-semibold">Name:</span>
                        <div className="flex items-center capitalize gap-2">
                          <span>{recruiterData?.first_name}</span>
                          <span> {recruiterData?.last_name}</span>
                        </div>
                      </div>
                      <div className="flex items-center justify-between ">
                        <span className="font-semibold">Phone: </span>
                        <span> {recruiterData?.contact_number}</span>
                      </div>

                      <div className="flex items-center justify-between ">
                        <span className="font-semibold">Email:</span>{" "}
                        {recruiterData?.email}
                      </div>
                      <div
                        className="border-t w-full flex justify-end font-bold cursor-pointer pt-2 hover:text-black"
                        onClick={() => {
                          navigate("/recruit/logout");
                          setShow(false);
                        }}
                      >
                        Logout
                      </div>
                    </div>
                  )}
                </div>
              )}
            </div>
          </div>
        </div>
      </header>

      <main className="flex-grow flex w-full max-w-screen-2xl mx-auto h-full">
        <nav
          className={` bg-[#1CACBD0A] text-[#6D758F]  relative ${
            sideShow ? "min-w-10" : "min-w-40 pr-2 "
          }`}
        >
          <ul className="flex flex-col gap-1 sticky top-14">
            {!sideShow ? (
              <div className="absolute top-[-1px] right-0 ">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 22"
                  strokeWidth={2}
                  stroke="currentColor"
                  className="size-5 cursor-pointer"
                  onClick={() => setSideShow(true)}
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M3.75 6.75h16.5M3.75 12h16.5M12 17.25h8.25"
                  />
                </svg>
              </div>
            ) : (
              <div className="absolute  w-full px-2  ">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 22"
                  strokeWidth={2}
                  stroke="currentColor"
                  className="size-5 cursor-pointer"
                  onClick={() => setSideShow(false)}
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M3.75 6.75h16.5M3.75 12h16.5m-16.5 5.25h16.5"
                  />
                </svg>
              </div>
            )}
            {item?.map((item, index) => (
              <li
                key={index}
                className={`flex items-center justify-start  h-6 px-2 font-inter gap-1 ${
                  item.heading ? " uppercase " : "text-base font-medium"
                } 
                 ${
                   result === item?.link?.split("/")?.slice(0, 3)?.join("/")
                     ? "bg-[#1CACBD] text-white   rounded-sm "
                     : ""
                 }
                `}
              >
                <span>
                  {item.heading ? (
                    <div className="text-[11px] font-bold font-manrope text-black ">
                      {!sideShow && item.name}
                    </div>
                  ) : (
                    <button
                      onClick={() => {
                        navigate(item.link);
                      }}
                      className={`flex items-center text-[11px] font-inter font-semibold gap-1 `}
                    >
                      <span>{item.svg}</span>{" "}
                      {!sideShow && <span>{item.name}</span>}
                    </button>
                  )}
                </span>
              </li>
            ))}
          </ul>
        </nav>

        {/* Main Content */}

        <div className="flex-grow overflow-y-hidden overflow-x-auto  ">
          <Outlet />
        </div>
      </main>

      <footer className="border-t shadow  ">
        <Footer />
      </footer>
    </div>
  );
}

export default NavForEditProfile;
