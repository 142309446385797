import React, { useState } from "react";
import { useSelector } from "react-redux";
import AddOtherInformationFormWrapper from "../Add/AddOtherInformationFormWrapper";
import OtherInformation from "./OtherInformation";
import EditOtherInformationFormWrapper from "../Edit/EditOtherInformationFormWrapper";
import ATMHeading from "../../../../atoms/ATMHeading/ATMHeading";

const OtherInformationWrapper = () => {
  const [otherInformationEdit, setOtherInformationEdit] = useState(false);
  const [otherInformationAdd, setOtherInformationAdd] = useState(false);
  const userData = useSelector((state) => state.candidateData.candidateData);
  const areBothFieldsEmpty =
    userData?.preferredlocation_candidate &&
    userData?.job_preference &&
    userData?.candidatehobbies_candidate;
  return (
    <div className="text-[11px] font-inter  flex flex-col gap-4 px-2 ">
      <div className="relative">
        <ATMHeading>Other Information</ATMHeading>
        <p className="flex gap-2 text-[#FE9F11] text-xs font-inter font-normal">
          <svg
            width="20"
            height="20"
            viewBox="0 0 24 23"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M1.74805 4.42116C1.74805 2.9319 2.97116 1.72461 4.47995 1.72461H18.8224C20.3313 1.72461 21.5543 2.9319 21.5543 4.42116V14.5332C21.5543 16.0225 20.3313 17.2298 18.8224 17.2298H15.2368L11.6512 21.2746L8.06557 17.2298H4.47995C2.97116 17.2298 1.74805 16.0225 1.74805 14.5332V4.42116Z"
              stroke="#FE9F11"
              strokeWidth="1.5"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M11.6504 6.10742V8.80397"
              stroke="#FE9F11"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M12.3347 12.848C12.3347 13.2202 12.0289 13.5221 11.6517 13.5221C11.2746 13.5221 10.9688 13.2202 10.9688 12.848C10.9688 12.4757 11.2746 12.1738 11.6517 12.1738C12.0289 12.1738 12.3347 12.4757 12.3347 12.848Z"
              stroke="#FE9F11"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
          This information will be displayed only to recruiters. Will not be
          part of public profile view.
        </p>
        <p className="text-slate-500">
          Expand your profile by adding your educational background. Share your
          qualifications, degrees, and academic achievements to enhance your
          profile
        </p>
        {!otherInformationEdit && (
          <div
            onClick={() => setOtherInformationEdit(true)}
            className="cursor-pointer absolute top-0 right-0  hover:text-black text-slate-600 "
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={1.5}
              stroke="currentColor"
              className="size-6"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="m16.862 4.487 1.687-1.688a1.875 1.875 0 1 1 2.652 2.652L10.582 16.07a4.5 4.5 0 0 1-1.897 1.13L6 18l.8-2.685a4.5 4.5 0 0 1 1.13-1.897l8.932-8.931Zm0 0L19.5 7.125M18 14v4.75A2.25 2.25 0 0 1 15.75 21H5.25A2.25 2.25 0 0 1 3 18.75V8.25A2.25 2.25 0 0 1 5.25 6H10"
              />
            </svg>
          </div>
        )}
      </div>

      <div>
        {areBothFieldsEmpty && !otherInformationEdit && !otherInformationAdd ? (
          <OtherInformation userData={userData} />
        ) : !areBothFieldsEmpty || otherInformationAdd ? (
          <AddOtherInformationFormWrapper
            setOtherInformationAdd={setOtherInformationAdd}
            userData={userData}
          />
        ) : (
          otherInformationEdit && (
            <EditOtherInformationFormWrapper
              setOtherInformationEdit={setOtherInformationEdit}
              userData={userData}
            />
          )
        )}
      </div>
    </div>
  );
};

export default OtherInformationWrapper;
