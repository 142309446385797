import {
  Listbox,
  ListboxButton,
  ListboxOption,
  ListboxOptions,
} from "@headlessui/react";
import { CheckIcon, ChevronDownIcon } from "@heroicons/react/20/solid";
import { Button, Menu, MenuHandler } from "@material-tailwind/react";
import clsx from "clsx";
import { Country } from "country-state-city";
import React, { useCallback, useEffect, useState } from "react";
import { PhoneInput } from "react-international-phone";
import Select from "react-select";
import HeroHeading from "../../components/recruiters/HeroHeading";
import { data } from "../../helpers/recruiter";

import axios from "axios";
import { useFormik } from "formik";
import { useDropzone } from "react-dropzone";
import toast from "react-hot-toast";
import { IoMdEye, IoMdEyeOff } from "react-icons/io";
import "react-international-phone/style.css";
import { useNavigate } from "react-router-dom";
import ATMButton from "../../atoms/ATMButton/ATMButton";
import ATMFieldError from "../../atoms/ATMFieldError/ATMFieldError";
import ATMFieldLabel from "../../atoms/ATMFieldLabel/ATMFieldLabel";
import Dailog from "../../components/common/Dailog";
import { superAdminSchema } from "../../schemas/recruiterSchema";
const baseUrl = process.env.REACT_APP_VITE_BACKEND_API_URL;

export const socialMedia = [
  {
    id: 1,
    name: "linkedin",
    svg: (
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g clipPath="url(#clip0_9273_21982)">
          <path
            d="M22.2283 0H1.77167C1.30179 0 0.851161 0.186657 0.518909 0.518909C0.186657 0.851161 0 1.30179 0 1.77167V22.2283C0 22.6982 0.186657 23.1488 0.518909 23.4811C0.851161 23.8133 1.30179 24 1.77167 24H22.2283C22.6982 24 23.1488 23.8133 23.4811 23.4811C23.8133 23.1488 24 22.6982 24 22.2283V1.77167C24 1.30179 23.8133 0.851161 23.4811 0.518909C23.1488 0.186657 22.6982 0 22.2283 0ZM7.15333 20.445H3.545V8.98333H7.15333V20.445ZM5.34667 7.395C4.93736 7.3927 4.53792 7.2692 4.19873 7.04009C3.85955 6.81098 3.59584 6.48653 3.44088 6.10769C3.28591 5.72885 3.24665 5.31259 3.32803 4.91145C3.40941 4.51032 3.6078 4.14228 3.89816 3.85378C4.18851 3.56529 4.55782 3.36927 4.95947 3.29046C5.36112 3.21165 5.77711 3.25359 6.15495 3.41099C6.53279 3.56838 6.85554 3.83417 7.08247 4.17481C7.30939 4.51546 7.43032 4.91569 7.43 5.325C7.43386 5.59903 7.38251 5.87104 7.27901 6.1248C7.17551 6.37857 7.02198 6.6089 6.82757 6.80207C6.63316 6.99523 6.40185 7.14728 6.14742 7.24915C5.893 7.35102 5.62067 7.40062 5.34667 7.395ZM20.4533 20.455H16.8467V14.1933C16.8467 12.3467 16.0617 11.7767 15.0483 11.7767C13.9783 11.7767 12.9283 12.5833 12.9283 14.24V20.455H9.32V8.99167H12.79V10.58H12.8367C13.185 9.875 14.405 8.67 16.2667 8.67C18.28 8.67 20.455 9.865 20.455 13.365L20.4533 20.455Z"
            fill="#0A66C2"
          />
        </g>
        <defs>
          <clipPath id="clip0_9273_21982">
            <rect width="24" height="24" fill="white" />
          </clipPath>
        </defs>
      </svg>
    ),
  },
  {
    id: 2,
    name: "stackoverflow",
    svg: (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        x="0px"
        y="0px"
        width="24"
        height="24"
        viewBox="0 0 48 48"
      >
        <path fill="#607D8B" d="M9 28H12V42H9z"></path>
        <path fill="#607D8B" d="M9 39H35V42H9z"></path>
        <path fill="#607D8B" d="M32 28H35V42H32zM15 34H29V37H15z"></path>
        <path
          fill="#A68A6E"
          d="M14.88 29H28.880000000000003V32H14.88z"
          transform="rotate(6.142 21.88 30.5)"
        ></path>
        <path
          fill="#EF6C00"
          d="M29.452 11.646H43.451V14.647H29.452z"
          transform="rotate(81.234 36.453 13.148)"
        ></path>
        <path
          fill="#FF9800"
          d="M23.576 13.578H37.574V16.579H23.576z"
          transform="rotate(60.79 30.576 15.079)"
        ></path>
        <path
          fill="#D38B28"
          d="M18.395 18.275H32.393V21.276H18.395z"
          transform="rotate(34.765 25.396 19.777)"
        ></path>
        <path
          fill="#C09553"
          d="M15.977 23.499H29.976V26.5H15.977z"
          transform="rotate(19.785 22.978 25.003)"
        ></path>
      </svg>
    ),
  },
  {
    id: 3,
    name: "google",
    svg: (
      <svg
        width="19"
        height="19"
        viewBox="0 0 19 19"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g clipPath="url(#clip0_9273_21979)">
          <path
            d="M18.8147 9.71943C18.8147 9.07365 18.7623 8.42437 18.6506 7.78906H9.68994V11.4473H14.8213C14.6084 12.6272 13.9242 13.6709 12.9224 14.3342V16.7079H15.9837C17.7815 15.0533 18.8147 12.6097 18.8147 9.71943Z"
            fill="#4285F4"
          />
          <path
            d="M9.69012 18.9999C12.2523 18.9999 14.4131 18.1586 15.9874 16.7065L12.926 14.3328C12.0743 14.9123 10.9747 15.2404 9.69361 15.2404C7.2152 15.2404 5.11378 13.5683 4.35979 11.3203H1.20068V13.7673C2.8134 16.9753 6.09817 18.9999 9.69012 18.9999Z"
            fill="#34A853"
          />
          <path
            d="M4.35612 11.3207C3.95818 10.1408 3.95818 8.86319 4.35612 7.68332V5.23633H1.20051C-0.146913 7.92069 -0.146913 11.0833 1.20051 13.7677L4.35612 11.3207Z"
            fill="#FBBC04"
          />
          <path
            d="M9.69012 3.76015C11.0445 3.7392 12.3535 4.24885 13.3344 5.18436L16.0467 2.47207C14.3293 0.859358 12.0498 -0.0272856 9.69012 0.000640194C6.09817 0.000640194 2.8134 2.02526 1.20068 5.23672L4.3563 7.68372C5.1068 5.4322 7.21171 3.76015 9.69012 3.76015Z"
            fill="#EA4335"
          />
        </g>
        <defs>
          <clipPath id="clip0_9273_21979">
            <rect width="19" height="19" fill="white" />
          </clipPath>
        </defs>
      </svg>
    ),
  },
  {
    id: 4,
    name: "github",
    svg: (
      <svg
        width="21"
        height="21"
        viewBox="0 0 21 21"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g clipPath="url(#clip0_9273_21980)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M10.5 0C4.70085 0 0 4.7082 0 10.5178C0 15.1641 3.00825 19.1068 7.18095 20.497C7.70595 20.5936 7.89705 20.2692 7.89705 19.9899C7.89705 19.741 7.88865 19.0785 7.8834 18.2017C4.9623 18.837 4.34595 16.7916 4.34595 16.7916C3.86925 15.5757 3.18045 15.2523 3.18045 15.2523C2.22705 14.6013 3.2529 14.6139 3.2529 14.6139C4.30605 14.6874 4.86045 15.6975 4.86045 15.6975C5.79705 17.304 7.3185 16.8399 7.91595 16.5711C8.01255 15.8917 8.28345 15.4287 8.58375 15.1662C6.25275 14.9005 3.801 13.9975 3.801 9.96765C3.801 8.82 4.2105 7.88025 4.88145 7.14525C4.7733 6.8796 4.41315 5.80965 4.98435 4.36275C4.98435 4.36275 5.86635 4.07925 7.87185 5.44005C8.7284 5.20644 9.61216 5.08745 10.5 5.0862C11.3925 5.0904 12.2902 5.20695 13.1292 5.44005C15.1336 4.07925 16.0135 4.3617 16.0135 4.3617C16.5868 5.80965 16.2256 6.8796 16.1185 7.14525C16.7905 7.88025 17.1979 8.82 17.1979 9.96765C17.1979 14.008 14.742 14.8974 12.4036 15.1578C12.7806 15.4823 13.1156 16.1238 13.1156 17.1055C13.1156 18.5105 13.1029 19.6455 13.1029 19.9899C13.1029 20.2713 13.2919 20.5989 13.8253 20.496C15.9162 19.7947 17.7338 18.4541 19.0213 16.6635C20.3087 14.873 21.0009 12.7232 21 10.5178C21 4.7082 16.2981 0 10.5 0Z"
            fill="black"
          />
        </g>
        <defs>
          <clipPath id="clip0_9273_21980">
            <rect width="21" height="21" fill="white" />
          </clipPath>
        </defs>
      </svg>
    ),
  },
];

const customStylesCountry = {
  control: (baseStyles) => ({
    ...baseStyles,
    height: "34px",
    minHeight: "34px",
    border: "none",
    outlineColor: "white",
    boxShadow: "none",
    backgroundColor: "transparent",
  }),
  menu: (baseStyles) => ({
    ...baseStyles,
    zIndex: 9999,
  }),
  menuPortal: (baseStyles) => ({
    ...baseStyles,
    zIndex: 9999,
  }),
};

function SignUp() {
  const navigate = useNavigate();
  let heading = "Register as Corporate Super Admin";
  let para =
    "Begin your journey as a Corporate Super Admin and unlock the full capabilities of our platform. With this pivotal role, you'll orchestrate recruitment workflows, manage user access, and ensure seamless operation across the system. Your oversight is crucial in leveraging our tools for maximum efficiency and effectiveness in talent acquisition. Proceed with setting up your account and take the first step towards transforming your organization's hiring process.";
  const [countries, setCountries] = useState([]);
  const [preview, setPreview] = useState(null);
  const [open, setOpen] = useState(false);
  const [domainSubcategories, setDomainSubcategories] = useState([]);
  const [showPassword, setShowPassword] = useState(false);

  useEffect(() => {
    setCountries(Country.getAllCountries());
  }, []);

  const formik = useFormik({
    initialValues: {
      firstName: "",
      lastName: "",
      email: "",
      password: "",
      contactNumber: "",
      companyName: "",
      companyUrl: "",
      industry: "",
      companyDomain: "",
      companyIdentificationNumber: "",
      companySocialMedia: socialMedia[0],
      companySocialMediaurl: "",
      logo: "",
      companyMission: "",
      addressOne: "",
      addressTwo: "",
      country: null,
      totalEmployees: "",
    },
    validationSchema: superAdminSchema,
    onSubmit: async (values, { resetForm }) => {
      const formData = new FormData();
      formData.append("first_name", values.firstName);
      formData.append("last_name", values.lastName);
      formData.append("email", values.email);
      formData.append("contact_number", values.contactNumber);
      formData.append("password", values.password);
      formData.append("company_name", values.companyName);
      formData.append("company_website_url", `https://${values.companyUrl}`);
      formData.append("industry", values.industry?.label);
      formData.append("company_domain", values.companyDomain?.label);
      formData.append(
        "company_identification_number",
        values.companyIdentificationNumber
      );
      formData.append("linkedin", values.companySocialMediaurl);
      formData.append("stackoverflow", values.companySocialMediaurl);
      formData.append("google", values.companySocialMediaurl);
      formData.append("github", values.companySocialMediaurl);
      formData.append("company_logo", values.logo);
      formData.append("company_mission_statment", values.companyMission);
      formData.append("address", values.addressOne);
      formData.append("country", values.country?.label);
      formData.append("numb_employees", values.totalEmployees);

      try {
        const response = await axios.post(
          `${baseUrl}/api/recruiter/super_admin/registration/`,
          formData,
          {
            headers: {
              accept: "application/json",
            },
          }
        );

        if (response.status === 201) {
          toast.success("Registration successful!");
          resetForm();
          setOpen(!open);
        } else if (response.status === 400) {
          const errorMessage = response.data?.message || "Failed to add member";
          toast.error(errorMessage);
        } else {
          toast.error(`Unexpected error occurred: ${response.statusText}`);
        }
      } catch (error) {
        if (error.response) {
          if (error.response.status === 400) {
            const errorMessage =
              error.response.data?.message || "Failed to add member";
            toast.error(errorMessage);
          } else if (error.response.status === 500) {
            toast.error("Internal server error. Please try again later.");
          } else {
            toast.error(
              `Error: ${error.response.status} - ${
                error.response.data.message || "Unknown error"
              }`
            );
          }
        } else if (error.request) {
          toast.error(
            "Network error. Please check your connection and try again."
          );
        } else {
          toast.error("An unexpected error occurred.");
        }
      }
    },
  });
  const handleEmailChange = (e) => {
    const value = e.target.value;
    const atIndex = value.indexOf("@");
    if (atIndex !== -1) {
      const domain = value.slice(atIndex + 1);
      formik.setFieldValue("companyUrl", `${domain}`);
    }
    formik.setFieldValue("email", value); // Sync email with Formik
  };
  const handleCountryChange = (option) => {
    formik.setFieldValue("country", option);
  };

  const handleIndustryChange = (industryName) => {
    const selectedIndustry = data.domain_industry.find(
      (industry) => industry.name === industryName?.label
    );
    setDomainSubcategories(
      selectedIndustry ? selectedIndustry.subcategories : []
    );
    formik.setFieldValue("industry", industryName);
  };

  const onDrop = useCallback(
    (acceptedFiles) => {
      const file = acceptedFiles[0];
      if (file) {
        const reader = new FileReader();
        reader.onloadend = () => {
          setPreview(reader.result);
        };
        reader.readAsDataURL(file);
        formik.setFieldValue("logo", file);
      }
    },
    [formik]
  );

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept: ".png,.jpg,.jpeg,",
  });

  const handleShowPassword = () => {
    setShowPassword(!showPassword);
  };
  return (
    <>
      <Dailog
        open={open}
        setOpen={setOpen}
        heading="We've sent an activation link to your email address for added security. Once you click the link, you'll be ready to log in and explore all the features available."
        para="Click the link to activate your account and start using our platform right away. It's that simple!"
        btnText="Login"
        btnLink="/recruiter/login"
      />
      <div className="flex bg-white h-full  py-1 px-4 text-[11px] font-inter">
        <div className="">
          <HeroHeading heading={heading} para={para} />
          <div className="grid grid-cols-1 md:grid-cols-3   gap-4 my-7">
            <div className=" flex flex-col gap-2 h-full">
              <div className="h-full">
                <img
                  src="https://jivahire-assets.b-cdn.net/Sign%20Up%20Screen/1x/IMG%201_Sign%20up_Recruiter.png"
                  alt=""
                  className="object-cover  md:block hidden  w-full h-full rounded-lg"
                />
              </div>
              <div className="flex justify-between gap-2 h-full">
                <div className="h-full ">
                  <img
                    src="https://jivahire-assets.b-cdn.net/Sign%20Up%20Screen/1x/IMG%202_Sign%20up_Recruiter.png"
                    alt=""
                    className="object-cover  md:block hidden  w-full h-full rounded-lg"
                  />
                </div>
                <div className="h-full ">
                  <img
                    src="https://jivahire-assets.b-cdn.net/Sign%20Up%20Screen/1x/IMG%203_Sign%20up_Recruiter.png"
                    alt=""
                    className="object-cover  md:block hidden  w-full h-full rounded-lg"
                  />
                </div>
              </div>
              <div className="h-full ">
                <img
                  src="https://jivahire-assets.b-cdn.net/Sign%20Up%20Screen/1x/IMG%204_Sign%20up_Recruiter.png"
                  alt=""
                  className="object-cover w-full h-full  md:block hidden  rounded-lg"
                />
              </div>
            </div>

            <div className="md:col-span-2 h-full">
              <form
                onSubmit={formik.handleSubmit}
                className="flex flex-col gap-5"
              >
                <div className="w-full border border-[#E2DCEA] rounded-lg ">
                  <div className="bg-blue-100 px-5 py-3 rounded-t-md">
                    <ATMFieldLabel> Personal Details</ATMFieldLabel>
                  </div>
                  <div className="grid grid-cols-1 md:grid-cols-1 lg:grid-cols-2 xl:grid-cols-3 gap-6 items-center p-5">
                    <div className="w-full ">
                      <ATMFieldLabel required>First Name</ATMFieldLabel>
                      <input
                        size="lg"
                        placeholder="Brian"
                        className="w-full p-2 border rounded-md outline-none"
                        value={formik.values.firstName}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        id="firstName"
                        name="firstName"
                      />
                      {formik.errors.firstName && formik.touched.firstName ? (
                        <ATMFieldError>{formik.errors.firstName}</ATMFieldError>
                      ) : null}
                    </div>
                    <div className="w-full ">
                      <ATMFieldLabel required>Last Name</ATMFieldLabel>
                      <input
                        size="lg"
                        placeholder="Clark"
                        className="w-full p-2 border rounded-md outline-none"
                        value={formik.values.lastName}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        id="lastName"
                        name="lastName"
                      />
                      {formik.errors.lastName && formik.touched.lastName ? (
                        <ATMFieldError>{formik.errors.lastName}</ATMFieldError>
                      ) : null}
                    </div>
                    <div className="w-full">
                      <ATMFieldLabel required>Email ID</ATMFieldLabel>
                      <input
                        size="lg"
                        placeholder="Brian"
                        className="w-full p-2 border rounded-md outline-none"
                        value={formik.values.email}
                        onChange={handleEmailChange}
                        onBlur={formik.handleBlur}
                        id="email"
                        name="email"
                      />
                      {formik.errors.email && formik.touched.email ? (
                        <div className="relative">
                          {" "}
                          <ATMFieldError>{formik.errors.email}</ATMFieldError>
                        </div>
                      ) : null}
                    </div>
                    <div className="w-full lg:col-span-2">
                      <ATMFieldLabel> Contact Number</ATMFieldLabel>
                      <div className="flex w-full h-full ">
                        <PhoneInput
                          defaultCountry="us"
                          value={formik.values.contactNumber}
                          onChange={(value) =>
                            formik.setFieldValue("contactNumber", value)
                          }
                          onBlur={formik.handleBlur}
                          id="contactNumber"
                          name="contactNumber"
                          className="border min-w-full rounded-lg  outline-none"
                          style={{
                            width: "100%",
                            "--react-international-phone-height": "33px",
                            "--react-international-phone-border-color":
                              "transparent",
                            "--react-international-phone-border-radius": "6px",
                          }}
                        />
                      </div>
                      {formik.errors.contactNumber &&
                      formik.touched.contactNumber ? (
                        <div>
                          {" "}
                          <ATMFieldError>
                            {formik.errors.contactNumber}
                          </ATMFieldError>
                        </div>
                      ) : null}
                    </div>

                    <div className="w-full ">
                      <ATMFieldLabel required> Set Your Password</ATMFieldLabel>
                      <div className="relative">
                        <input
                          size="lg"
                          type={showPassword ? "text" : "password"}
                          placeholder="Enter your password"
                          className="w-full p-2 border rounded-md outline-none"
                          value={formik.values.password}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          id="password"
                          name="password"
                        />
                        <div
                          onClick={handleShowPassword}
                          className="absolute top-1/2 transform -translate-y-1/2 text-2xl right-3 cursor-pointer"
                        >
                          {showPassword ? (
                            <IoMdEye size={18} className="text-slate-800" />
                          ) : (
                            <IoMdEyeOff size={18} className="text-slate-800" />
                          )}
                        </div>
                      </div>
                      {formik.errors.password && formik.touched.password ? (
                        <ATMFieldError>{formik.errors.password}</ATMFieldError>
                      ) : null}
                    </div>
                  </div>
                </div>
                <div className="w-full border border-[#E2DCEA] rounded-lg">
                  <div className="bg-blue-100 px-5 py-3 rounded-t-md">
                    <ATMFieldLabel> Company Account Set-up</ATMFieldLabel>
                  </div>
                  <div className="grid grid-cols-1 md:grid-cols-1 lg:grid-cols-2 gap-6 items-center p-5">
                    <div className="w-full ">
                      <ATMFieldLabel required> Company Name</ATMFieldLabel>
                      <input
                        size="lg"
                        placeholder="Brian clark"
                        className="w-full p-2 border rounded-md outline-none"
                        value={formik.values.companyName}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        id="companyName"
                        name="companyName"
                      />
                      {formik.errors.companyName &&
                      formik.touched.companyName ? (
                        <ATMFieldError>
                          {formik.errors.companyName}
                        </ATMFieldError>
                      ) : null}
                    </div>
                    <div className="w-full ">
                      <ATMFieldLabel required>
                        {" "}
                        Company Website Url
                      </ATMFieldLabel>
                      <div className="relative flex w-full">
                        <Menu placement="bottom-end">
                          <MenuHandler>
                            <Button
                              ripple={false}
                              variant="text"
                              color="blue-gray"
                              className="flex text-[11px] items-center gap-2 p-2 rounded-r-none border border-r-0 border-blue-gray-200 font-normal"
                            >
                              https://
                            </Button>
                          </MenuHandler>
                        </Menu>
                        <input
                          placeholder="untitledui.com"
                          className="w-full p-2 border rounded-e-lg outline-none"
                          value={formik.values.companyUrl}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          id="companyUrl"
                          name="companyUrl"
                          disabled={true}
                        />
                      </div>
                      {formik.errors.companyUrl && formik.touched.companyUrl ? (
                        <ATMFieldError>
                          {formik.errors.companyUrl}
                        </ATMFieldError>
                      ) : null}
                    </div>
                    <div className="w-full ">
                      <ATMFieldLabel> Industry</ATMFieldLabel>

                      <div className="border rounded-md">
                        <Select
                          styles={customStylesCountry}
                          options={data.domain_industry?.map((industry) => ({
                            value: industry?.id || "",
                            label: industry?.name || "",
                          }))}
                          onChange={handleIndustryChange}
                          value={formik.values.industry}
                          placeholder="Select industry"
                          isClearable={true}
                        />
                      </div>

                      {formik.errors.industry && formik.touched.industry ? (
                        <ATMFieldError>{formik.errors.industry}</ATMFieldError>
                      ) : null}
                    </div>
                    <div className="w-full ">
                      <ATMFieldLabel> Industry Category</ATMFieldLabel>
                      <div className="border rounded-md">
                        <Select
                          styles={customStylesCountry}
                          options={domainSubcategories?.map((person) => ({
                            value: person?.id || "",
                            label: person?.name || "",
                          }))}
                          onChange={(value) =>
                            formik.setFieldValue("companyDomain", value)
                          }
                          value={formik.values.companyDomain}
                          placeholder="Select Industry Category"
                        />
                      </div>

                      {formik.errors.companyDomain &&
                      formik.touched.companyDomain ? (
                        <ATMFieldError>
                          {formik.errors.companyDomain}
                        </ATMFieldError>
                      ) : null}
                    </div>
                    <div className="w-full ">
                      <ATMFieldLabel>
                        Company Identification Number
                      </ATMFieldLabel>
                      <input
                        size="lg"
                        placeholder=""
                        className="w-full p-2 border rounded-md outline-none"
                        value={formik.values.companyIdentificationNumber}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        id="companyIdentificationNumber"
                        name="companyIdentificationNumber"
                      />
                      {formik.errors.companyIdentificationNumber &&
                      formik.touched.companyIdentificationNumber ? (
                        <ATMFieldError>
                          {formik.errors.companyIdentificationNumber}
                        </ATMFieldError>
                      ) : null}
                    </div>
                    <div className="w-full ">
                      <ATMFieldLabel>Company Social Media</ATMFieldLabel>
                      <div className="relative flex w-full">
                        <Listbox
                          value={formik.values.companySocialMedia}
                          onChange={(value) =>
                            formik.setFieldValue("companySocialMedia", value)
                          }
                        >
                          <ListboxButton
                            className={clsx(
                              "relative block w-20 font-inter font-normal rounded-s-lg py-1.5 pr-8 pl-3 text-left text-sm/6 border border-e-0 h-[34px] text-[#101828]",
                              "focus:outline-none data-[focus]:outline-2 data-[focus]:-outline-offset-2 data-[focus]:outline-white/25"
                            )}
                          >
                            {formik.values?.companySocialMedia?.svg}
                            <ChevronDownIcon
                              className="group pointer-events-none absolute top-[48%] transform -translate-y-1/2 right-2.5 size-5 fill-black"
                              aria-hidden="true"
                            />
                          </ListboxButton>
                          <ListboxOptions
                            anchor="bottom"
                            transition
                            className={clsx(
                              "w-[var(--button-width)]  p-1 [--anchor-gap:var(--spacing-1)] focus:outline-none",
                              "transition duration-100 ease-in data-[leave]:data-[closed]:opacity-0"
                            )}
                          >
                            <ListboxOptions
                              anchor="bottom"
                              transition
                              className={clsx(
                                "w-[var(--button-width)] rounded-xl border border-black bg-white p-1 [--anchor-gap:var(--spacing-1)] focus:outline-none",
                                "transition duration-100 ease-in data-[leave]:data-[closed]:opacity-0"
                              )}
                            >
                              {socialMedia.map((socialMedia, ind) => (
                                <ListboxOption
                                  key={socialMedia.name + ind}
                                  value={socialMedia}
                                  className="group flex cursor-default items-center gap-2 rounded-lg py-1.5 px-3 select-none data-[focus]:bg-white/10"
                                >
                                  <CheckIcon className="invisible size-6 fill-black group-data-[selected]:visible" />
                                  <div className="text-sm/6 text-white">
                                    {socialMedia.svg}
                                  </div>
                                </ListboxOption>
                              ))}
                            </ListboxOptions>
                          </ListboxOptions>
                        </Listbox>
                        <input
                          placeholder=""
                          className="w-full p-2 border rounded-e-lg outline-none "
                          value={formik.values.companySocialMediaurl}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          id="companySocialMediaurl"
                          name="companySocialMediaurl"
                        />
                      </div>
                      {formik.errors.companySocialMediaurl &&
                      formik.touched.companySocialMediaurl ? (
                        <ATMFieldError>
                          {formik.errors.companySocialMediaurl}
                        </ATMFieldError>
                      ) : null}
                    </div>
                    <div className="w-full">
                      <ATMFieldLabel required>Company Logo</ATMFieldLabel>
                      <div
                        {...getRootProps()}
                        className={`w-full h-[116px] border ${
                          isDragActive ? "border-blue-500" : "border-[#E5E7EB]"
                        } rounded-md py-3 px-2 flex items-start justify-start cursor-pointer`}
                      >
                        <input {...getInputProps()} />
                        <div className="flex flex-col justify-center items-center">
                          <div className="w-[65px] relative h-[65px] border-dashed border-[#667085] border p-1 rounded-full flex justify-center items-center flex-col">
                            {preview ? (
                              <img
                                src={preview}
                                alt="Preview"
                                className="rounded-full"
                              />
                            ) : null}
                            <div className="absolute -right-2 -bottom-2 p-2 rounded-full border-2 border-white shadow-custom bg-[#1CACBD]">
                              <svg
                                width="14"
                                height="14"
                                viewBox="0 0 13 13"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  d="M12.125 8.375V10.875C12.125 11.2065 11.9933 11.5245 11.7589 11.7589C11.5245 11.9933 11.2065 12.125 10.875 12.125H2.125C1.79348 12.125 1.47554 11.9933 1.24112 11.7589C1.0067 11.5245 0.875 11.2065 0.875 10.875V8.375M9.625 4L6.5 0.875M6.5 0.875L3.375 4M6.5 0.875V8.375"
                                  stroke="white"
                                  strokeWidth="1.5"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                />
                              </svg>
                            </div>
                          </div>
                          <p className="pt-1 font-inter font-normal text-[#475467] text-xs">
                            Preview
                          </p>
                        </div>
                        <div className="ml-7 mr-3 mt-1">
                          <p className="font-inter font-normal text-[#475467]">
                            {isDragActive
                              ? "Drop the files here..."
                              : "Click to upload or drag and drop SVG, PNG, JPG or GIF (max. 800x400px) (max. file size 5MB)"}
                          </p>
                        </div>
                      </div>
                      {formik.errors.logo && formik.touched.logo && (
                        <div>
                          <ATMFieldError>{formik.errors.logo}</ATMFieldError>
                        </div>
                      )}
                    </div>
                    <div className="w-full">
                      <ATMFieldLabel required>
                        Company Mission Statement
                      </ATMFieldLabel>
                      <textarea
                        value={formik.values.companyMission}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        id="companyMission"
                        name="companyMission"
                        className="w-full h-[115px] border border-[#E5E7EB] rounded-md resize-none p-3 outline-none"
                        placeholder="Please Enter Your Text Here..."
                      ></textarea>
                      {formik.errors.companyMission &&
                      formik.touched.companyMission ? (
                        <ATMFieldError>
                          {formik.errors.companyMission}
                        </ATMFieldError>
                      ) : null}
                    </div>
                    <div className="w-full">
                      <ATMFieldLabel required>Company Address</ATMFieldLabel>
                      <input
                        size="lg"
                        placeholder="Company Line 1"
                        className="w-full p-2 border rounded-md outline-none"
                        value={formik.values.addressOne}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        id="addressOne"
                        name="addressOne"
                      />
                      {formik.errors.addressOne && formik.touched.addressOne ? (
                        <ATMFieldError>
                          {formik.errors.addressOne}
                        </ATMFieldError>
                      ) : null}
                    </div>
                    <div className="w-full">
                      <ATMFieldLabel> Company Address</ATMFieldLabel>
                      <input
                        size="lg"
                        placeholder="Company Line 2"
                        className="w-full p-2 border rounded-md outline-none"
                        value={formik.values.addressTwo}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        id="addressTwo"
                        name="addressTwo"
                      />
                      {formik.errors.addressTwo && formik.touched.addressTwo ? (
                        <ATMFieldError>
                          {formik.errors.addressTwo}
                        </ATMFieldError>
                      ) : null}
                    </div>
                    <div className="w-full">
                      <ATMFieldLabel required>
                        {" "}
                        Please Select Country
                      </ATMFieldLabel>
                      <div className="border rounded-md">
                        <Select
                          styles={customStylesCountry}
                          options={countries.map((country) => ({
                            value: country?.isoCode || "",
                            label: country?.name || "",
                          }))}
                          onChange={handleCountryChange}
                          value={formik.values.country}
                          placeholder="Select Country"
                        />
                      </div>

                      {formik.touched.country && formik.errors.country ? (
                        <ATMFieldError>{formik.errors.country}</ATMFieldError>
                      ) : null}
                    </div>
                    <div className="w-full">
                      <ATMFieldLabel required>
                        {" "}
                        Number of Employees
                      </ATMFieldLabel>
                      <input
                        size="lg"
                        placeholder="1000-1500"
                        className="w-full p-2 border rounded-lg outline-none"
                        value={formik.values.totalEmployees}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        id="totalEmployees"
                        name="totalEmployees"
                      />
                      {formik.errors.totalEmployees &&
                      formik.touched.totalEmployees ? (
                        <ATMFieldError>
                          {formik.errors.totalEmployees}
                        </ATMFieldError>
                      ) : null}
                    </div>
                  </div>
                  <div className="flex flex-col justify-start gap-2 items-start w-full px-5 pb-5">
                    <div className="w-[110px]">
                      <ATMButton
                        loading={formik.isSubmitting}
                        label="Register Now"
                        authicon
                      />
                    </div>

                    <p className="font-inter font-normal text-[#667085] ">
                      Register as Corporate User{" "}
                      <span
                        onClick={() => navigate("/recruiter/register-user")}
                        className="text-blue-500 cursor-pointer"
                      >
                        {" "}
                        Click Here To Register
                      </span>
                    </p>
                    <p className="font-inter font-normal text-[#667085]">
                      If you have already created account then you can{" "}
                      <span
                        onClick={() => navigate("/recruiter/login")}
                        className="text-blue-500 cursor-pointer"
                      >
                        {" "}
                        Click Here To Login
                      </span>
                    </p>

                    <div className="font-inter text-[11px] my-2">
                      <ATMFieldLabel>Want to reach out directly?</ATMFieldLabel>
                      <div className="text-slate-600">
                        We're here to help!{" "}
                        <span
                          onClick={() => navigate("/recruiter/support")}
                          className="text-blue-500 cursor-pointer"
                        >
                          Contact our support
                        </span>{" "}
                        team with any questions.
                      </div>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default SignUp;
