import React from "react";

const HeroHeading = React.memo(({ heading, para }) => {
  return (
    <div className="flex justify-center items-start flex-col gap-1">
      <h2 className="font-inter font-extrabold text-lg text-[#1D2939]">
        {heading}
      </h2>
      <p className="text-slate-500 font-inter font-normal text-[11px] leading-4">
        {para}
      </p>
    </div>
  );
});

export default HeroHeading;
