import React, { useEffect, useState } from "react";
import toast from "react-hot-toast";
import ATMCircularProgress from "../../../atoms/ATMCircularProgress/ATMCircularProgress";
import ATMHeading from "../../../atoms/ATMHeading/ATMHeading";
import {
  useActivateRecuiterMutation,
  useGetRecuiterListQuery,
} from "./services/RecruiterListServices";
import ATMButton from "../../../atoms/ATMButton/ATMButton";
import AddRecruiterForm from "./AddRecruiter/AddRecruiterForm";

const RecruitersList = () => {
  const [recuiterList, setRecuiterList] = useState([]);
  const [open, setOpen] = useState(false);
  const { data, isLoading } = useGetRecuiterListQuery();
  const [active] = useActivateRecuiterMutation();

  useEffect(() => {
    if (data) {
      setRecuiterList(data);
    }
  }, [data]);

  const hanndleChange = ({ id, status }) => {
    const formdata = {
      pk: id,
      status: status,
    };
    active(formdata).then((res) => {
      if (res?.error) {
        toast.error(res?.error?.data?.message);
      } else {
        if (res?.data?.success) {
          toast.success(res?.data?.message);
        } else {
          toast.error(res?.error?.data?.message);
        }
      }
    });
  };
  return (
    <div className="h-full ">
      {open && <AddRecruiterForm setOpen={setOpen} />}
      <div className="border-b p-2 flex justify-between">
        <ATMHeading>ORG Recruiters</ATMHeading>

        <div className="w-[150px]">
          <ATMButton
            label="Add Recruiter"
            isleftIcon
            onClick={() => setOpen(true)}
          />
        </div>
      </div>
      {isLoading ? (
        <div className=" h-full  flex justify-center  items-center">
          <ATMCircularProgress />
        </div>
      ) : (
        <div className="overflow-x-auto min-w-[600px] p-2 font-inter">
          <div className="grid grid-cols-7 items-center text-center text-xs font-semibold p-2  rounded-md">
            <span className="">S.No</span>
            <span className="">ID</span>
            <span className="col-span-2">Name</span>
            <span className="col-span-2">Email</span>
            <span className="">Verified</span>
          </div>
          <div>
            {recuiterList?.map((item, index) => (
              <tr
                key={item?.id}
                className="grid grid-cols-7 text-xs items-center hover:bg-slate-200 rounded-md text-center even:bg-gray-50 odd:bg-slate-100  mt-1 p-2"
              >
                <td className=" ">{index + 1}</td>
                <td className="">{item?.id}</td>
                <td className="col-span-2 break-words">
                  {item?.first_name} {item?.last_name}
                </td>
                <td className="col-span-2 break-words">{item?.email}</td>
                <td className="">
                  <div className="flex justify-center items-center gap-2">
                    {/* Switch */}
                    <button
                      onClick={() =>
                        hanndleChange({
                          id: item?.id,
                          status: !item?.email_verified,
                        })
                      }
                      className={`relative w-12 h-6 rounded-full transition-colors ${
                        item?.email_verified ? "bg-green-500" : "bg-gray-300"
                      }`}
                    >
                      {/* Toggle */}
                      <span
                        className={`absolute top-0.5 left-0.5 w-5 h-5 bg-white rounded-full shadow transform transition-transform ${
                          item?.email_verified
                            ? "translate-x-6"
                            : "translate-x-0"
                        }`}
                      ></span>
                    </button>
                    {/* Label */}
                    {/* <span className="text-gray-700">
                        {item?.email_verified ? "Verified" : "Not Verified"}
                      </span> */}
                  </div>
                </td>
              </tr>
            ))}
          </div>
        </div>
      )}
    </div>
  );
};

export default RecruitersList;
